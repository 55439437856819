import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Setting | Settings',
  organisationDescription:
    "This is the organisation that you're part of. On this page you can change some of the settings.",
  scanInstruction: 'Scan this QR code in the Google Authenticator app',
  installInstruction: 'Download and install the Google Authenticator app',
  successfullyConfigured: 'Two Factor Authentication is successfully configured',
  invalidCode: 'Code is invalid',
  enableInstruction: 'Enable your Google Authenticator',
  authenticationCode: 'Authentication code',
  twoFactorpreference: 'Two factor authentication preference',
  twoFactorAuthenticationTitle: 'Two Factor Authentication',

  minCharacters: 'Minimum 12 characters',
  lowercase: 'At least one lowercase',
  uppercase: 'At least one uppercase',
  numeric: 'At least one numeric',
  specialCharacters: 'At least one special character',
  passwordConfirmation: 'Confirm password',

  preferences: {
    saved: 'Preferences saved.',
    title: 'Preferences',
    description:
      "This page allows you to change settings that reflect the behavior of the platform. These settings are all personal, we call them 'Your Preferences'.",
    defaultExportMapping: 'By default, expand export mappings of current workspace.',
    defaultMasterDataProducts:
      'In ‘Products’, set ‘Variant Name’ filter on ‘Master Data’ by default.',
    defaultEmailRetailerFilled:
      'When exporting products, automatically fill in the retailer’s e-mail address.',
    defaultArchivedNotVisible:
      'By default, only archived products will not be shown in the product overview.',
    applicationScaling: 'Application scaling.',
    newLayoutTitle: 'New layout',
    newLayout:
      'You now have the opportunity to try out our New Layout in bèta mode by enabling it. ' +
      "And don't worry, you can always switch back to the old layout if you're " +
      'more comfortable with it. The new layout provides a more organized and intuitive user experience.' +
      'The navigation items are better organized, easier to navigate, and feature clearer icons to help users quickly find their desired content.' +
      ' The new design also features a more consistent look and feel, making it easier for users to recognize content across the entire platform.',
    betaFeatures: 'Beta features',
  },

  notifications: {
    email: {
      title: 'Email Notification Preferences',
      subtitle:
        'Customize your email alerts for task-related actions, task assignments, comments, and status changes.',
      taskCreated: 'Task Assigned Notifications',
      taskCreatedDescription: 'Get notified via email when assigned to a task.',
      comments: 'Task Comment Notifications',
      commentsDescription:
        'Get notified via email when a new comment is added to a task that you are assigned to.',
      statusChange: 'Task Status Change Notifications',
      statusChangeDescription:
        'Get notified via email when the status on a task that you are assigned to has changed',
    },
  },

  profile: {
    title: 'Profile',
    description: 'Here you can change settings regarding your profile.',
  },
  security: {
    title: 'Security settings',
    description: 'On this page you can find all the settings regarding security',
    enableAuth: 'Enable 2FA',
  },

  organisation: {
    title: 'Current organisation',
    description:
      "This is the organisation that you're part of. On this page you can change some of the settings.",
    visitWorkspace: 'To add workspaces to an organisation visit the workspaces page.',
    typeDescription:
      'The type of customer will determine how the platform will look. for example an retailer has no use for the channel analytics.',
    workflowLabels: 'Workflow labels',
    labelsDescription:
      'These labels determine the flow of enriching products. In the near future these labels can be changed, for now thet are the same for each organisation.',
    organisationDetails: 'organisation_details',
  },

  subNavigation: {
    title: 'Settings',
    personal: 'Personal',
    profile: 'Profile',
    preferences: 'Preferences',
    security: 'Security',
    connectedWorkspaces: 'Connected Workspaces',
    clients: 'Workspace',
    organisation: 'Organisation',
  },

  company: 'Company',

  googleAuthenticator: 'Google Authenticator',
  googleAuthenticatorDescription:
    'Use the Authenticator app to get free verification codes, even when your phone is offline. Available for Android and iPhone.',
  connectedWorkspaces: {
    title: 'Connected workspaces',
    description: 'These are all the other workspaces that are available to you.',
  },
};

const translation: Translation = {
  settings: translationObject,
};

export default translation;
