<script setup lang="ts">
import { ExportMappingViewService } from '@/general/services/overview-service/modules/export-mapping/export-mapping-view.service';
import { tsKey } from '@/general/services/translations/translation.key';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Ref, provide, ref } from 'vue';
import { exportMappingServiceKey } from '../services/keys/export-mapping.keys';

import { useService } from '@/general/composables/useService';
import OverView from '@/general/ui/views/over-view/over-view.vue';
import CreateExportMapping from '../components/create-export-mapping.vue';
import DuplicateExportMapping from '../components/duplicate-export-mapping.vue';
import ExportMappingTable from '../components/export-mapping-table.vue';

const ts = ref(new TranslationService('retailer', 'export_mappings'));
const service = useService(exportMappingServiceKey, ExportMappingViewService);

provide(exportMappingServiceKey, service);
provide(tsKey, ts as Ref<TranslationService>);
</script>
<template>
  <OverView
    platform="retailer"
    module-name="export_mappings"
    :service="service"
    :has-search="false"
  >
    <template #content>
      <ExportMappingTable />
    </template>
    <template #crudComponent>
      <CreateExportMapping />
    </template>
    <template #others>
      <!-- Duplicate modal -->
      <DuplicateExportMapping
        v-if="service.partialObject.datamodel && service.partialObject.module"
        :visible="service.duplicateDialog"
      />
    </template>
  </OverView>
</template>
