<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { ExportMappingDetailsViewService } from '@/general/services/overview-service/modules/export-mapping/export-mapping-details.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Attribute, AttributeTypeEnum } from 'platform-unit2-api';
import useAttributeOption from 'utils/attribute-option';
import { computed, onMounted, ref } from 'vue';
import { exportMappingDetailsServiceKey } from '../services/keys/export-mapping-details.keys';

import EmptyState from '@/general/ui/components/empty-state.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import { onBeforeRouteLeave } from 'vue-router';

const ts = new TranslationService('retailer', 'export_mappings');
const service = useService(exportMappingDetailsServiceKey, ExportMappingDetailsViewService);

const searchText = ref<string>('');

const filteredAttributes = computed(() => {
  return searchText.value
    ? service.selectedMapping?.datamodel?.attributes.filter((attribute) =>
        attribute.key.toLowerCase().includes(searchText.value.toLowerCase()),
      ) ?? []
    : service.selectedMapping?.datamodel?.attributes ?? [];
});

const { getFieldDefinitionOption } = useAttributeOption();

const getSources = (attribute?: Attribute) => {
  const type = attribute?.options
    ? getFieldDefinitionOption(attribute?.options, 'type', undefined) || AttributeTypeEnum.DEFAULT
    : AttributeTypeEnum.DEFAULT;

  return service.sourcesPerFieldType[type as AttributeTypeEnum];
};

onMounted(async () => {
  await service.fetchAll();
});

onBeforeRouteLeave(() => {
  service.resetSupportedMappingItems();
});
</script>
<template>
  <div class="w-full">
    <LoadingIndicator v-if="service.isLoadingDetailsPage" />

    <section v-else class="flex gap-3 h-full overflow-y-hidden p-3">
      <Fieldset
        :legend="ts.tModule('datamodel_attributes')"
        class="flex overflow-y-scroll w-6"
        :pt="{ contentContainer: { class: 'w-full' } }"
      >
        <div>
          <div class="grid my-2 overflow-x-hidden overflow-y-auto">
            <!-- Search Input -->
            <IconField icon-position="left" class="mr-2 w-full">
              <InputIcon class="pi pi-search" />
              <InputText v-model="searchText" :placeholder="ts.tGlobal('search')" class="w-full" />
            </IconField>

            <EmptyState
              v-if="filteredAttributes.length === 0"
              :empty-state-title="ts.tModule('export_mapping_supported.no_attributes_title')"
              :empty-state-subtitle="ts.tModule('export_mapping_supported.no_attributes_subtitle')"
              :icon-name="'datamodels'"
              :button-visible="false"
            />
            <div
              v-for="attribute in service.sortMapping(filteredAttributes, 'key')"
              v-else
              :key="attribute.id"
              class="col-12 pl-0"
            >
              <div
                class="border-1 border-200 border-round hover:surface-50 p-2 py-3"
                :class="service.selectedEndpointId == null ? 'surface-50' : ''"
              >
                <div class="flex justify-content-between pb-2">
                  <div>
                    <span class="font-bold">
                      {{ service.isOriginalKeyValid(attribute.id) ?? attribute.key }}
                    </span>
                    <p class="text-xs">
                      {{ attribute.options ? attribute.options.type : 'DEFAULT' }}
                    </p>
                  </div>
                  <div
                    v-if="service.isOriginalKeyValid(attribute.id) !== undefined"
                    class="flex flex-wrap justify-content-end w-4"
                  >
                    <p
                      v-tooltip.top="{
                        value: attribute.key,
                      }"
                      class="original-name text-sm"
                    >
                      <b>{{ ts.tModule('export_mapping_supported.original') }}:</b>
                      {{ attribute.key }}
                    </p>
                  </div>
                </div>

                <div class="align-items-center flex justify-content-between">
                  <div class="align-content-end flex w-full">
                    <div style="width: 45%">
                      <LocaleSelect
                        v-model="service.selectedLocale[attribute.id]"
                        :disabled="service.selectedEndpointId == null"
                        hide-label
                        class="mr-2"
                      />
                    </div>
                    <div style="width: 45%">
                      <Select
                        v-model="service.selectedSource[attribute.id]"
                        :disabled="service.selectedEndpointId == null"
                        class="align-items-center h-fullflex mr-2 w-full"
                        option-label="label"
                        :options="getSources(attribute)"
                        :placeholder="ts.tModule('export_mapping_supported.select_source')"
                        :show-clear="true"
                      />
                    </div>

                    <div class="align-items-center flex justify-content-center" style="width: 10%">
                      <Button
                        text
                        :disabled="
                          service.selectedEndpointId == null ||
                          service.selectedSource[attribute.id] == null
                        "
                        class="button-plus-custom p-button-sm"
                        icon="mdi mdi-plus"
                        @click="service.addAttribute(attribute)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fieldset>
      <Fieldset
        :legend="ts.tModule('export_template_attributes')"
        class="flex overflow-y-scroll w-6"
        :pt="{
          contentContainer: { class: 'w-full' },
        }"
      >
        <EmptyState
          v-if="service.endpoints.length === 0"
          :empty-state-title="ts.tModule('export_mapping_supported.no_template_endpoints_title')"
          :empty-state-subtitle="
            ts.tModule('export_mapping_supported.no_template_endpoints_subtitle')
          "
          :icon-name="'datamodels'"
          :button-visible="false"
        />
        <div
          v-for="endpoint in service.sortMapping(service.endpoints, 'label')"
          v-else
          :key="endpoint.id"
          class="mb-3 mt-3"
        >
          <div class="flex justify-content-between">
            <label class="block font-bold mb-1" :for="endpoint.label">
              {{ endpoint.label }}
            </label>
          </div>
          <small class="block mb-2 text-sm">{{ endpoint.description }}</small>
          <div class="grid mr-4">
            <div class="col-12 md-12">
              <div
                class="align-items-center flex w-full"
                :class="
                  service.selectedEndpointId == endpoint.id
                    ? 'border-1 border-round border-primary'
                    : ''
                "
              >
                <RadioButton
                  v-model="service.selectedEndpointId"
                  :value="endpoint.id"
                  class="mx-2"
                />
                <AutoComplete
                  v-model="service.editableMappingChipItems[endpoint.id]"
                  readonly
                  disabled
                  multiple
                  :typeahead="false"
                  class="w-full"
                >
                  <template #chip="slotProps">
                    <div class="align-items-center bg-gray-100 border-round-3xl flex px-2 py-1">
                      <span v-if="slotProps.value.attribute.key != null" class="text-lg">
                        {{
                          service.isOriginalKeyValid(slotProps.value.attribute.id) ??
                          slotProps.value.attribute.key
                        }}
                      </span>
                      <span v-else> {{ slotProps.value }} </span>
                      <span v-if="slotProps.value.source_locale_value != null" class="ml-2 text-sm">
                        [ {{ slotProps.value.source_locale_value }} ]</span
                      >
                      <span v-if="slotProps.value.source != null" class="text-sm"
                        >&nbsp; [ {{ slotProps.value.source }} ]</span
                      >
                      <p
                        v-if="service.isOriginalKeyValid(slotProps.value.attribute.id)"
                        v-tooltip.top="{
                          value: slotProps.value.attribute.key,
                        }"
                        class="ml-2 original-name text-sm"
                      >
                        <b>{{ ts.tModule('export_mapping_supported.original') }}:</b>
                        {{ slotProps.value.attribute.key }}
                      </p>
                    </div>
                  </template>
                </AutoComplete>
                <Button
                  :disabled="
                    service.editableMappingChipItems[endpoint.id] <= 0 ||
                    service.editableMappingChipItems[endpoint.id] == null
                  "
                  style="border-radius: 0 3px 3px 0"
                  class="h-full"
                  icon="mdi mdi-delete"
                  @click="service.removeAll(endpoint.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </Fieldset>
    </section>
  </div>
</template>
