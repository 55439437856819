<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { DatamodelAttributesService } from '@/general/services/overview-service/modules/datamodel-attributes.service';
import { tsKey } from '@/general/services/translations/translation.key';
import { TranslationService } from '@/general/services/translations/translation.service';
import OverView from '@/general/ui/views/over-view/over-view.vue';
import { Ref, provide, ref } from 'vue';
import DatamodelAttributeActions from '../components/datamodel-attribute-actions.vue';
import DatamodelEditor from '../components/datamodel-attributes-editor.vue';
import DatamodelEditorSkeleton from '../components/datamodel-editor-skeleton.vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';

const ts = ref(new TranslationService('supplier', 'datamodels'));

const service = useService(datamodelAttributeServiceKey, DatamodelAttributesService);

provide(datamodelAttributeServiceKey, service);
provide(tsKey, ts as Ref<TranslationService>);
</script>

<template>
  <OverView platform="supplier" module-name="datamodels" :service="service" :has-search="false">
    <template #content>
      <DatamodelAttributeActions />
      <DatamodelEditor />
      <ConfirmDialog group="dirty-dialog" />
    </template>
    <template #skeleton>
      <DatamodelEditorSkeleton />
    </template>
  </OverView>
</template>
