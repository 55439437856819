import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'New Export',
  subTitle: 'Here you can create a new Export.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Export',
};

const update: TranslationObjectAction = {
  title: 'Edit Export',
  subTitle: 'Here you can edit a Export.',
};

const translationObject: TranslationObject = {
  title: 'Export | Exports',
  placeholder: 'Select Export | Select Export',
  emptyStateSubtitle:
    'Start by creating your first export on the product overview page.\n When your export is created it will show here',
  create: create,
  delete: deleteTranslation,
  update: update,

  //other

  export_restart: {
    title: 'Restart export',
    sources: 'Select sources to export',
    product_attributes: 'Product attributes',
    product_assets: 'Products assets',
    date_limit: 'Only export products updated',
    since_always: 'Since always (export all products)',
    since_last_export: 'Since last export',
    between_dates: 'Between dates',
    select_dates: 'Select a range of dates',
    custom_message: 'Enter your custom message',
  },
  table_header: {
    retailer: 'Retailer',
    user: 'User',
    status: 'Status',
    created_at: '@:global.created_at',
    name: '@:global.name',
    workspace: 'Workspace',
    next_at: 'Next export at',
    gtin: 'GTIN',
    category: '@:global.category',
    brand: '@:global.brand',
    products: 'Products',
    type: 'Type',
    exported_by: 'Exported by',
    started_at: 'Started at',
    errors: 'Errors',
    actions: 'Actions',
  },
  actionMenu: {
    goTo: 'Go to products',
    restartWhole: 'Restart whole export',
    restartFailed: 'Restart failed export',
    delete: 'Delete export',
    download: 'Download export',
  },
  tooltips: {
    download: 'Download export',
    start: '@:global.start',
    pause: '@:global.pause',
    edit: '@:global.edit',
    delete: '@:global.delete',
    restart: '@:global.restart',
    go_to_products: 'Go to products',
    go_to_labels: 'Go to labels',
    searchPlaceholder: 'Search on export, type, status etc...',
    channel_filter_placeholder: 'All channels',
    copyToClipboardSuccess: 'Successfully copied to clipboard',
    missingPipeline: 'Selected pipeline is missing',
    missingPipelineId: 'Selected pipeline ID is missing',
  },
  details: {
    title: 'Export details',
    dry_run: 'Dry run',
    include_data: 'Include data',
    include_assets: 'Include assets',
    channel: 'Channel',
    locale: 'Locale',
    mail_to: 'Mail to:',
    unknown_owner: 'Unknown owner',
  },
  details_modal: {
    title: 'Export Details',
    date: 'Date',
    errors: 'Errors',
    products: 'Products',
    exported_by: 'Exported by',
    exportId: 'Export ID',
    channel: 'Channel',
    locale: 'Locale',
    mailed_to: 'Mailed to',
    data_included: 'Data is included',
    assets_included: 'Assets are included',
    export_log: 'Export log',
    export_statuses: 'Export statuses',
    gtin: 'GTIN',
    message: 'Message',
    status: 'Status',
    timestamp: 'Timestamp',
    raw_code: 'Raw Code',
    goto: 'Go to product',
    submission_id: 'Submission ID',
    unknown: 'Unknown',
    empty_table_title: 'There are no messages in the export log yet',
    empty_table_subtitle: 'Once a created export has  errors, the messages will show here',
    tab_product_errors: 'Product errors',
    tab_pipeline_statuses: 'Export statuses',
    ignore_product_status: 'Ignore product status',
  },
  errors: {
    restarted_failed: 'We are unable to restart the export. Please try again.',
    update_failed: 'We are unable to update the export. Please try again.',
  },
  success: {
    restarted_successfully: 'The export was succesfully restarted',
    paused_successfully:
      'The export was succesfully paused. You can resume the export by clicking the start button',
    resumed_successfully:
      'The export was succesfully resumed. You can pause the export by clicking the pause button',
    updated_successfully: 'The product list has been updated successfully.',
  },
  // Old translations
  empty: 'No exports could be found',
  channel: 'Channel',
  email_settings: 'E-mail settings',
  action_name: 'Data export',
  automatic_exports: 'Automatic export',
  start: 'Start export',
  new: 'New export',
  date_limit_label: 'Only export products updated',
  date_limit_always: 'since always (export all products)',
  date_limit_export: 'since last export',
  date_limit_dates: 'between dates',
  select_date_range: 'Select a date range',
  exported_products: 'Exported products',
  select_language: 'Select language',
  add_product: 'Add single product',
  add_products: 'Add products',
  add_products_by_gtin: 'Add products by GTIN',
  add_products_by_gtin_help:
    "You can paste a list of GTIN's, separated by comma's, spaces or new lines.",
  add_products_by_gtin_result: "Searching {amount} GTIN's:",
  gtins: "GTIN's",
  advanced: 'Advanced',
  advanced_help:
    'Product relationships help you to add many products at once. For example, you can add ' +
    'all products within a certain category.',
  add_by_relation: 'Add products by relation',
  add_by_module: 'Add products for current channel',
  show_all: 'Show all',
  email_to: 'E-mail to',
  contact: 'Contact',
  custom_message: 'Custom message',
  show_advanced_settings: 'Advanced settings',
  snackbars: {
    starting_failed: 'Failed to start export. Please try again.',
    added_products: 'Added {amount} new products.',
  },
  modals: {
    unpublished_title: 'Start Export',
    unpublished:
      'Some products are not published and cannot be exported, are you sure you want to start the export anyway?',
    settings: {
      confirm: 'Apply',
      retailer_email: 'Retailer email',
      source_title: 'Select sources to export',
      export_data: 'Product attributes',
      export_assets: 'Product assets',
      source_warning: "Settings can't be applied, select at least one source to export",
      schedule_export: 'Schedule the export',
      schedule_description: "don't check this item if you want to directly go to step 4",
    },
    schedule: {
      name: 'Type a name for the scheduled export',
    },
    click_to_add: 'Click to add',
  },
  dialogs: {
    add_by_module_title: 'Add products for current channel',
    add_by_module_body:
      'Are you sure you want to add all products with a variant for <strong>{module}</strong>?',
    add_by_module_confirm: 'Add products',
  },
  toast: {
    start_summary: 'Export has been started',
    start_detail: 'Navigate to the exports overview page to see your export(s)',
    export_filter_gtin_failed: 'GTIN filter options',
    export_pipeline_messages_failed: 'Export messages',
  },
  export_scheduled: {
    title: 'Scheduled Export | Scheduled Exports',
    empty: 'No scheduled exports could be found',
    new: {
      title: 'New scheduled export',
      subtitle: 'Here you can create a new scheduled export.',
    },
    edit: {
      title: 'Edit scheduled export',
      subtitle: 'Here you can edit a scheduled export.',
      paused: 'Is paused',
    },
    toast: {
      success_summary: 'Export has been scheduled',
      success_detail:
        'Navigate to the exports scheduled overview page to see your export schedule(s)',
    },
    is_paused: 'Is paused',
    cron: 'Cron',
    paused: 'Paused',
    running: 'Running',
    ignore_product_status: 'Ignore product status',
    updateProducts: {
      title: 'Update products',
      subtitle: 'Here you can update the list of products included in the scheduled export',
    },
  },
  createExport: {
    title: 'Custom Export',
    file_picker_label: 'Choose your file',
    success_message: 'Successfully started the export',
    error_message: 'We were unable start the export. Please try again',
  },
};

const translation: Translation = {
  exports: translationObject,
};

export default translation;
