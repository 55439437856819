<script setup lang="ts">
import { Attribute } from 'platform-unit2-api/attributes';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { TemplateEndpoint } from 'platform-unit2-api/template-endpoints';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import useDebounce from 'utils/debounce';
import { computed, onMounted, ref, watch } from 'vue';

import { useSort } from '@/general/composables/useSort';
import { TranslationService } from '@/general/services/translations/translation.service';

/** Props */
const props = defineProps<{
  userAttributes: Attribute[];
  addToDatamodel: Attribute[];
  datamodel?: Datamodel;
  datamodels: Datamodel[];
}>();

/** Emits */
const emit = defineEmits<{
  (e: 'removeFromUserAttributes', attribute: Attribute[]): void;
  (e: 'addAttributesToUserDatamodel', attribute: Attribute[]): void;
}>();

/** Composables  */
const { sortArray } = useSort();

/** Services */
const ts = new TranslationService('retailer', 'datamodels');

/** Constants */
const loading = ref(true);
const datamodelsArray = ref<Datamodel[]>([]);
const selectedDatamodels = ref<Datamodel[]>([]);

/** Attributes count */
const attributesCount = computed(() =>
  selectedDatamodels.value.reduce((total, datamodel) => (total += datamodel.attributes.length), 0),
);

/** Selected Attributes count in each datamodel */
const selectedAtrributesCount = (selectedDatamodel: Datamodel) => {
  let commonAttribuesCount = 0;
  selectedDatamodel.attributes.forEach(
    (attr) => (commonAttribuesCount += userAttributeIds.value.includes(attr.id) ? 1 : 0),
  );
  return commonAttribuesCount;
};

/** Check if the attributes is already on user selection or not */
/** get the user attributes Ids to exclude from attribute list */
const userAttributeIds = computed(() =>
  sortArray(props.userAttributes, 'key')?.map((attr) => attr.id),
);
const isUserAttribute = (attribute_id: number) => userAttributeIds.value.includes(attribute_id);

/** Show the button to remove attribute from user attributes */
const hoveredAttribute = ref<number>();

const setHoveredAttribute = (attribute_id: number) => {
  hoveredAttribute.value = hoveredAttribute.value !== attribute_id ? undefined : attribute_id;
};

/** Remove from user attributes by clicking minus */
const removeFromUserAttributes = (attributes: Attribute[]) => {
  emit('removeFromUserAttributes', attributes);
};

/** Get the template endpoints */
const endpoints = ref<TemplateEndpoint[]>([]);

/** Filter the datamodels and attributes to just show the ones related to template */
const datamodelObject = ref<Record<number, number[]>>({} as Record<number, number[]>); // It's an object with the datamodel id as key and attribute ids as the value

const retailerDatamodelIds = ref<number[]>([]);

const createDatamodelsObject = () => {
  datamodelsArray.value.forEach((datamodel) => {
    datamodelObject.value = { ...datamodelObject.value, [datamodel.id]: [] };
    sortArray(datamodel.attributes, 'key');
    datamodel.attributes.forEach((attr) => {
      datamodelObject.value[datamodel.id].push(attr.id);
    });
  });
  retailerDatamodel();
};

const retailerDatamodel = () => {
  Object.entries(datamodelObject.value).map(([key, value]) => {
    endpoints.value.forEach((endpoint) => {
      if (value.includes(endpoint.attribute_id)) {
        !retailerDatamodelIds.value.includes(+key) && retailerDatamodelIds.value.push(+key);
      }
    });
  });
  selectedDatamodels.value = datamodelsArray.value;
};

/** Final datamodels- remove some in retailers mode if neccessary */
const finalDatamodels = computed(() => {
  const finalDdatamodels: Datamodel[] = datamodelsArray.value;

  return finalDdatamodels;
});

/** Generate class for each individual attribute div */
const selectedAttributesToAdd = ref<number[]>([]);

const attributeClass = (attributeId: number) => {
  const returnedAttributeIds = props.addToDatamodel.map((attr) => attr.id);
  return returnedAttributeIds.includes(attributeId)
    ? 'returned'
    : selectedAttributesToAdd.value.includes(attributeId)
    ? 'remove'
    : undefined;
};

/** Emit the event to add attribute to user's datamodel */
const addToUserDatamodel = (selectedAttributes: Attribute[]) => {
  selectedAttributesToAdd.value = selectedAttributes
    ? selectedAttributes.map((attr) => attr.id)
    : []; // To change the html class
  emit('addAttributesToUserDatamodel', selectedAttributes);
};

const addAllToUserDatamodel = () => {
  const allAttributes: Attribute[] = [];
  const datamodels = searchedWord.value ? filteredDatamodels.value : selectedDatamodels.value;
  datamodels &&
    datamodels.forEach((dm) =>
      dm.attributes.map((attr) => {
        if (
          !userAttributeIds.value.includes(attr.id) &&
          !allAttributes.some((element: Attribute) => {
            if (element.id === attr.id) {
              return true;
            }

            return false;
          })
        ) {
          allAttributes.push(attr);
        }
      }),
    );
  emit('addAttributesToUserDatamodel', allAttributes);
};

/** Search in attributes */
const { debounce } = useDebounce();

const searchedWord = ref<string>('');
const filteredDatamodels = ref<Datamodel[] | undefined>([]);

watch(
  () => searchedWord.value,
  () => {
    const search = debounce(() => {
      filteredDatamodels.value = JSON.parse(
        JSON.stringify(datamodelsArray.value),
      ) as typeof datamodelsArray.value;

      filteredDatamodels.value.reduce((filteredDatamodels, currentDatamodel) => {
        currentDatamodel.attributes = currentDatamodel.attributes.filter(
          (attribute) =>
            attribute.key.toLowerCase().indexOf(searchedWord.value.toLocaleLowerCase()) != -1,
        );
        return [...filteredDatamodels, currentDatamodel];
      }, [] as Datamodel[]);
      // Remove the datamodels with no attributes
      filteredDatamodels.value = filteredDatamodels.value.filter(
        (datamodel) => datamodel.attributes.length,
      );
    }, 1000);
    search();
  },
);

watch(
  () => props.datamodels,
  () => {
    datamodelsArray.value = props.datamodels;
    createDatamodelsObject();
    loading.value = false;
  },
);

/** onMounted lifecyclehook */
onMounted(async () => {
  datamodelsArray.value = props.datamodels;
  createDatamodelsObject();
  loading.value = false;
});
</script>

<template>
  <div :style="{ width: '47%' }" class="flex flex-column">
    <span class="font-bold text-lg"> {{ ts.tModule('predefined_datamodels.title') }}</span>
    <div class="bg-gray-50 border-1 border-gray-100 flex flex-column flex-grow-1 my-2 p-3 pb-0">
      <!-- select box -->
      <div class="flex pb-0">
        <MultiSelect
          v-model="selectedDatamodels"
          :options="finalDatamodels"
          option-label="name"
          placeholder="Select datamodels"
          :filter="true"
          display="chip"
          class="align-items-center mr-2 w-8"
        >
          <template #value="slotProps">
            <div v-for="option of slotProps.value" :key="option.id" class="inline">
              {{ option.name }},
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              Select datamodels
            </template>
          </template>
          <template #option="slotProps">
            <div class="flex justify-content-between w-full">
              <span>{{ slotProps.option.name }}</span>
              <span>{{ slotProps.option.attributes.length }}</span>
            </div>
          </template>
        </MultiSelect>

        <IconField icon-position="left" class="w-4">
          <InputIcon class="pi pi-search" />
          <InputText
            v-model="searchedWord"
            type="text"
            class="w-full"
            placeholder="Search attributes..."
          />
        </IconField>
      </div>
      <!-- divider -->
      <Divider />

      <!-- Attributes -->
      <div class="align-items-center flex justify-content-between mb-3">
        <span class="font-bold"
          >{{ ts.tModule('datamodel_attributes.name') }}
          <span class="bg-gray-800 border-round-sm px-1 text-sm text-white">
            {{ attributesCount }}
          </span>
        </span>
        <Button
          v-if="attributesCount !== 0"
          label="Add all"
          text
          plain
          @click="addAllToUserDatamodel"
        />
      </div>
      <div
        class="grid justify-content-center place-items-center text-center"
        :style="{ height: '31vh' }"
      >
        <!-- Loading -->
        <LoadingIndicator v-if="loading" />
        <!-- No datamodel selected -->
        <div
          v-else-if="!selectedDatamodels.length"
          class="align-items-center flex flex-column justify-content-center w-full"
        >
          <i class="bg-gray-100 border-circle mdi mdi-playlist-plus p-2 text-3xl text-primary"></i>
          <span class="block font-bold mb-2 mt-4 text-lg">
            {{ ts.tModule('datamodel_attributes.adding') }}</span
          >
          <p>{{ ts.tModule('datamodel_attributes.select_datamodel') }}</p>
        </div>
        <!-- Show the attributes -->
        <div v-else-if="!attributesCount" class="align-items-center grid">
          {{ ts.tModule('datamodel_attributes.no_attributes_in_selected_datamodel') }}
        </div>
        <div v-else class="flex flex-column mx-2 w-full">
          <ScrollPanel :style="{ height: '31vh' }" class="datamodel-scrollpanel">
            <div
              v-for="selectedDatamodel in searchedWord ? filteredDatamodels : selectedDatamodels"
              :key="selectedDatamodel.id"
              class="font-bold my-3 text-left w-full"
            >
              <span>
                {{ selectedDatamodel.name }}
                <span class="font-normal text-gray-500 text-sm"
                  >({{ selectedAtrributesCount(selectedDatamodel) }}/{{
                    selectedDatamodel.attributes.length
                  }})</span
                >
              </span>
              <div class="border-gray-200 border-left-1 pl-3">
                <div
                  v-for="attr in selectedDatamodel.attributes"
                  :key="attr.id"
                  class="bg-white border-1 border-gray-200 font-normal my-2 p-2 pl-3 w-full"
                  :class="attributeClass(attr.id)"
                >
                  <div
                    class="align-items-center flex justify-content-between w-full"
                    @mouseleave="setHoveredAttribute(0)"
                  >
                    <div class="align-items-center flex" style="width: 80%">
                      <Tag
                        v-tooltip.bottom="ts.tModule('datamodel_attributes.attribute_id')"
                        :style="{
                          background: 'var(--gray-100)',
                          color: 'var(--gray-500)',
                        }"
                        :value="attr.id"
                        class="mr-2"
                      />
                      <span class="ml-1 overflow-hidden text-overflow-ellipsis">
                        {{ attr.key }}
                      </span>
                    </div>
                    <i
                      v-if="isUserAttribute(attr.id) && hoveredAttribute !== attr.id"
                      class="mdi mdi-check mr-2 p-1 text-blue-700"
                      :style="{ width: '20px', height: '20px' }"
                      @mouseenter="setHoveredAttribute(attr.id)"
                    />
                    <i
                      v-else-if="isUserAttribute(attr.id) && hoveredAttribute === attr.id"
                      class="border-1 border-gray-200 border-round-sm cursor-pointer hover:bg-pink-50 hover:border-pink-200 mdi mdi-minus mr-2 p-1"
                      :style="{ width: '20px', height: '20px' }"
                      @click="removeFromUserAttributes([attr])"
                    />
                    <i
                      v-else
                      class="bg-primary border-round-sm cursor-pointer hover:bg-blue-500 mdi mdi-plus mr-2 p-1 text-white"
                      :style="{ width: '20px', height: '20px' }"
                      @click="addToUserDatamodel([attr])"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollPanel>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.datamodel-scrollpanel .remove {
  transition: all 0.8s ease;
}

.datamodel-scrollpanel .returned {
  background-color: #fff;
  opacity: 1;
  position: relative;
  animation-name: fadeIn;
  animation-duration: 1s;
}

@keyframes fadeIn {
  from {
    background-color: green;
    opacity: 0;
  }
  to {
    background-color: #fff;
    opacity: 1;
  }
}
</style>
