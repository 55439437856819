import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Settings',
  description:
    'This is the workspace you are currently using, you can change some of the information on this screen.',
  generalContact: 'General contact',
  financialContact: 'Financial contact',
  logo: 'Workspace logo',
  name: 'Workspace name',
  save: 'Save changes',
};
const translation: Translation = {
  'workspace-settings': translationObject,
};

export default translation;
