<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import PrismCode from 'retailer/modules/editor/components/prism-code.vue';
import Tiptap from 'retailer/modules/editor/components/tiptap.vue';
import { onBeforeMount, ref, Ref } from 'vue';

import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';

/** Services */
const ts = new TranslationService('retailer', 'editor');
const toastService = ToastService.getInstance();

/** Constants */
const rawHtmlInputValue: Ref<string> = ref('');
const replaceHtmlModalVisible = ref(false);
const editorValue: Ref<string> = ref('');
const value: Ref<string> = ref('');

const indentHtml = (html: string) => {
  const tabSize = '  ';
  let result = '';
  let indent = '';
  html.split(/>\s*</).forEach(function (element: any) {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tabSize.length);
    }

    result += indent + '<' + element + '>\r\n';
    if (element.match(/^<?\w[^>]*[^\/]$/)) {
      indent += tabSize;
    }
  });
  return result.substring(1, result.length - 3);
};

const indentEditorValue = (input: string) => {
  editorValue.value = indentHtml(input);
};

const copyToClipboard = () => {
  try {
    const copyText = document.querySelector('.ProseMirror')!.innerHTML;
    navigator.clipboard.writeText(copyText);
    toastService.displaySuccessToast(ts.tModule('succesfullyCopied'));
  } catch (error) {
    toastService.displayErrorToast(ts.unexpectedError);
  }
};

const openRawHtmlModal = () => {
  replaceHtmlModalVisible.value = true;
};

const confirmReplaceContent = () => {
  editorValue.value = indentHtml(rawHtmlInputValue.value.trim());
  value.value = rawHtmlInputValue.value;

  rawHtmlInputValue.value = '';
  replaceHtmlModalVisible.value = false;
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    replaceHtmlModalVisible.value = false;
  },
});

const insertButton = new CreateButton({
  label: ts.tModule('insert'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => confirmReplaceContent(),
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, insertButton],
});

onBeforeMount(() => {
  value.value = ts.tModule('instructions').toString();
  editorValue.value = indentHtml(value.value);
});
</script>
<template>
  <div>
    <BaseDialog
      :visible="replaceHtmlModalVisible"
      :title="ts.tModule('dialog.title')"
      :subtitle="ts.tModule('dialog.subtitle')"
      :maximizable="true"
      :buttons-options="buttonsOptions"
      size="medium"
      close-on-escape
      @update:visible="replaceHtmlModalVisible = false"
    >
      <Textarea
        v-model="rawHtmlInputValue"
        class="w-full"
        rows="10"
        :placeholder="ts.tModule('insertHtmlPlaceholder')"
      />
    </BaseDialog>

    <div style="min-height: 50vh" class="bg-white border-round p-4 shadow-1">
      <div class="mb-2">
        <Button class="mr-2" @click="openRawHtmlModal">
          {{ ts.tModule('insertHtml') }}
        </Button>
        <Button @click="copyToClipboard">
          {{ ts.tModule('copyContents') }}
        </Button>
      </div>

      <div class="grid">
        <div class="col-6">
          <div class="border-1 border-200 editor-wrapper w-full">
            <Tiptap
              :model-value="value"
              :allow-images="false"
              :allow-videos="false"
              @update:model-value="indentEditorValue"
            ></Tiptap>
          </div>
        </div>
        <div class="col-6">
          <PrismCode class="highlighted-code" language="html" :model-value="editorValue" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
/* Basic editor styles */
.ProseMirror {
  padding: 1rem;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem !important;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  ul {
    list-style-type: disc !important;
  }
  ol {
    list-style-type: decimal !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  &.ProseMirror-focused {
    outline: none;
  }
}

.p-breadcrumb {
  background: none;
  border: 0;
  padding: 0;
}

.p-breadcrumb :deep(.pi-chevron-right) {
  color: #fff;
  padding: 0 3px 0 3px;
  font-size: smaller;
}
.p-breadcrumb :deep(.p-menuitem-link .p-menuitem-text) {
  color: #fff;
}
.p-breadcrumb :deep(.p-menuitem-link .p-menuitem-icon) {
  color: #fff;
}
</style>
