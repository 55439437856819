<script setup lang="ts">
import { inject } from 'vue';
import { productTableServiceKey } from '../../key/product-table-service.key';

const productTableService = inject(productTableServiceKey)!;
</script>

<template>
  <Message
    v-if="productTableService.hasFilteredGtins"
    class="w-full"
    severity="info"
    :closable="true"
    ><div class="mb-1">
      <b> {{ productTableService.ts.tModule('active_gtins_search') }}</b>
    </div>
  </Message>
</template>
