<script setup lang="ts">
import apiImage from '@/assets/images/setting-developers/api-coming-soon.svg';
import { TranslationService } from '@/general/services/translations/translation.service';

/* Services */
const ts = new TranslationService('retailer', 'developers');
</script>

<template>
  <div class="align-items-center flex flex-column gap-5 pt-6">
    <Image :src="apiImage" alt="API" class="thumbnail" />
    <h3>{{ ts.tModule('log_coming_soon') }}</h3>
    <p class="text-500">{{ ts.tModule('log_description') }}</p>
  </div>
</template>
