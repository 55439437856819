<script setup lang="ts">
import { inject } from 'vue';
import { productTableServiceKey } from '../../key/product-table-service.key';

const productTableService = inject(productTableServiceKey)!;
</script>

<template>
  <Button
    v-tooltip.bottom="{
      value: productTableService.ts.tModule('deleted_products'),
      class: 'text-sm',
    }"
    class="bg-white border-100 ml-2 text-800"
    @click="productTableService.openDeletedProducts()"
    ><i class="mdi mdi-folder-remove-outline"
  /></Button>
</template>
