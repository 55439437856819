<script setup lang="ts">
import { destroyService } from '@/general/composables/useService';
import { useService } from '@/general/composables/useService/useService';
import ApplicationHeader from '@/general/ui/layout/components/application-header.vue';
import { RetailerImportMappingItemsViewService } from 'retailer/modules/import-mapping-items/ts/services/retailer-import-mapping-items-view.service';
import { onBeforeRouteLeave } from 'vue-router';
import { importMappingItemsServiceKey } from '../ts/keys/import-mapping-items.keys';

const service = useService(importMappingItemsServiceKey, RetailerImportMappingItemsViewService)!;

onBeforeRouteLeave((_, __, next) => {
  if (service.deleted) {
    destroyService(importMappingItemsServiceKey);
    next();
    return;
  }

  if (service.dirtyState.isTouched()) {
    service.dirtyState.showDirtyDialog(
      () => {
        destroyService(importMappingItemsServiceKey);
        next();
      },
      undefined,
      next,
    );
  } else {
    destroyService(importMappingItemsServiceKey);

    next();
  }
});
</script>

<template>
  <ApplicationHeader>
    <template #breadcrumbs>
      <router-link :to="{ name: 'mappings' }">
        <Button
          class="custom-button-styles p-button-outlined p-button-sm"
          icon="mdi mdi-chevron-left"
          label="Import mappings"
        />
      </router-link>
    </template>
    <template #content>
      <div class="align-items-center flex gap-3 pb-4">
        <h3 v-if="!service.isLoadingParent" class="custom-h1-styles">
          {{ service.parent?.name }}
        </h3>
        <Skeleton v-else class="" width="30rem" height="28px" />
      </div>
    </template>
  </ApplicationHeader>

  <ConfirmDialog group="dirty-dialog" />
</template>
<style lang="scss" scoped>
.custom-button-styles {
  border-color: #dce5ef !important;
  color: black !important;
}
</style>
