<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import { inject } from 'vue';

const ts = inject(tsKey)!;
</script>
<template>
  <ConfirmDialog v-bind="{ ...$attrs }" class="dialog-size-small">
    <template #message>
      <div class="bg-white flex flex-column gap-3 my-3 surface-border">
        <p v-html="ts.tModule('deletedProducts.restoreVariantMessage')" />
        <Message severity="error" :closable="false" class="w-full">
          <template #icon>
            <i class="mdi mdi-information mr-3 text-3xl text-red-500"></i>
          </template>
          <p>
            {{ ts.tModule('deletedProducts.restoreVariantInfo') }}
          </p>
        </Message>
      </div>
    </template>
  </ConfirmDialog>
</template>
