import store from '@/core/store';
import { TableColumnInterface } from '@/platforms/supplier/modules/products/services/product-table.constants';
import * as cronParser from 'cron-parser';
import {
  CreateScheduledPipelineRequest,
  Product,
  ProductsRestService,
  ScheduledPipeline,
  ScheduledPipelinesRestService,
  UpdateScheduledPipelineRequest,
  User,
} from 'platform-unit2-api';
import { RouteLocationRaw, RouteParamsRaw } from 'vue-router';
import { TranslationService } from '../../translations/translation.service';
import { BaseViewService } from '../view/base-view.service';

export class ScheduledExportViewService extends BaseViewService<
  ScheduledPipelinesRestService,
  ScheduledPipeline,
  CreateScheduledPipelineRequest,
  UpdateScheduledPipelineRequest & { id: number }
> {
  public exportCron?: string;
  public currentUser?: User;

  public get scheduledPipelines() {
    return this._data;
  }
  constructor(ts: TranslationService) {
    super({
      Api: ScheduledPipelinesRestService,
      ts: ts,
      overviewRouteName: 'export-scheduled',
      createRouteName: '',
      updateRouteName: 'edit-scheduled-export',
      confirmPopUpGroup: 'export-scheduled',
    });

    this.currentUser = store.getters['users/currentUser'];
  }
  public createBody(): CreateScheduledPipelineRequest | undefined {
    throw new Error('Method not implemented.');
  }
  public updateBody(): (UpdateScheduledPipelineRequest & { id: number }) | undefined {
    if (this.partialObject?.id == null || !this.validated) {
      return;
    }

    const body: UpdateScheduledPipelineRequest & { id: number } = {
      id: this.partialObject.id,
      name: this.partialObject?.name,
      cron: this.exportCron ?? this.partialObject?.cron,
      module_id: this.partialObject?.module?.id,
      locale_id: this.partialObject?.locale?.id,
      is_paused: this.partialObject?.is_paused,
      update_variants: this.partialObject?.update_variants,
      client_id: this.partialObject?.client?.id,
      ignore_product_status: this.partialObject?.ignore_product_status,
    };

    return body;
  }
  public get validated(): boolean {
    return Boolean(this.partialObject.name);
  }

  /**
   * Method used to navigate to the products page
   * @param id
   */
  public async goToProducts(id?: number) {
    try {
      await this._router.push({
        name: 'products',
        query: { pipelineId: id } as RouteParamsRaw,
      } as RouteLocationRaw);
    } catch (error) {
      this._toastService.displayErrorToast(this._ts.tModule('tooltips.missingPipelineId'));
    }
  }

  public getNextRun(cron: string): string {
    return cronParser.parseExpression(cron).next().toISOString();
  }

  public restartScheduledPipeline(id: number) {
    this._restService
      .restartScheduledExport(id)
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.tModule('success.restarted_successfully'));
        this.refetch();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.tModule('errors.restarted_failed'));
      });
  }

  confirmScheduledPipelineDelete = (scheduledPipline: ScheduledPipeline) => {
    this._confirmService.confirmDelete({
      callback: () => this.deleteScheduledPipeline(scheduledPipline.id ?? 0),
      group: 'scheduled-export-row',
      message: this._ts.deleteConfirm(),
    });
  };

  public deleteScheduledPipeline(id: number) {
    this._restService
      .delete(id)
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.deleteSuccess());
        this.refetch();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.deleteFailed());
      });
  }

  public changePipelineStatus(scheduledPipeline: ScheduledPipeline, status: boolean) {
    this._restService
      .update(scheduledPipeline.id, {
        id: scheduledPipeline.id,
        name: scheduledPipeline.name,
        cron: scheduledPipeline.cron,
        module_id: scheduledPipeline.module?.id,
        locale_id: scheduledPipeline.locale?.id,
        is_paused: status,
        update_variants: scheduledPipeline.update_variants,
        client_id: scheduledPipeline.client?.id,
      })
      .then(() => {
        this._toastService.displaySuccessToast(
          status
            ? this._ts.tModule('success.paused_successfully')
            : this._ts.tModule('success.resumed_successfully'),
        );
        this.refetch();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.tModule('errors.update_failed'));
      });
  }

  public setCron(cron: string) {
    this.exportCron = cron;
  }

  public isCronValid() {
    const cron = this.partialObject.cron?.split(' ') ?? [];

    if (cron.filter((item: string) => item !== '').length !== 5) {
      return false;
    }

    const minutes = cron[0];

    if (cron[0] === '*') {
      return false;
    }

    if (minutes.includes('*/')) {
      if (parseInt(minutes.split('/')[1]) < 15 || minutes === '*/') {
        return false;
      }
    }

    if (minutes.includes(',')) {
      if (minutes.split(',').length > 5) {
        return false;
      }
    }

    if (minutes.includes('-')) {
      return false;
    }

    return true;
  }

  public isUpdateProductsModalVisible = false;
  public selectedProducts: Product[] = [];
  public async toggleUpdateProductsModal(scheduledPipeline?: ScheduledPipeline): Promise<void> {
    this.selectedProducts = [];

    if (scheduledPipeline != null) {
      this.current = scheduledPipeline;

      const productRest = new ProductsRestService();
      this.selectedProducts = (
        await productRest.getAll({}, { include_ids: scheduledPipeline?.product_ids })
      ).data;
    }

    this.isUpdateProductsModalVisible = !this.isUpdateProductsModalVisible;
  }

  public updateProductsTableColumns: TableColumnInterface[] = [
    {
      selected: true,
      field: 'gtin',
      header: this._ts.t('global.gtin'),
      sortable: true,
      filter: false,
      type: 'gtin',
      size: '12rem',
    },
    {
      selected: true,
      field: 'display_name',
      header: this._ts.t('supplier.products.product_name'),
      sortable: true,
      filter: false,
      type: 'display_name',
      size: '24rem',
    },
    {
      selected: true,
      field: 'brand_id',
      header: this._ts.t('supplier.brands.title'),
      sortable: true,
      filter: false,
      type: 'brand',
      size: '14rem',
    },
    {
      selected: true,
      field: 'variant_name',
      header: this._ts.t('supplier.products.variant_name'),
      sortable: true,
      filter: true,
      type: 'variant',
      size: '16rem',
    },
    {
      selected: true,
      field: 'datamodel.name',
      header: this._ts.t('supplier.datamodels.title'),
      sortable: true,
      filter: false,
      type: 'datamodel',
      size: '16rem',
    },
    {
      selected: true,
      field: 'updated_at',
      header: this._ts.t('global.updated_at'),
      sortable: true,
      filter: false,
      type: 'updated_at',
      size: '16rem',
    },
  ];

  public updateSelectedProducts(newProductsSelection: Product[]) {
    this.selectedProducts = newProductsSelection;
  }

  public async updateScheduledExportProducts() {
    try {
      if (this.current?.id != null) {
        await this._restService.updateScheduledExportProducts(this.current.id, {
          selected_products: this.selectedProducts.map((product: Product) => product.id),
        });

        this._toastService.displaySuccessToast(this._ts.tModule('success.updated_successfully'));
      }

      this.refetch();
    } catch (e) {
      this._toastService.displayErrorToast(this._ts.tModule('errors.update_failed'));
    } finally {
      this.toggleUpdateProductsModal();
    }
  }
}
