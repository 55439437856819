<script setup lang="ts">
//Services
import { TranslationService } from '@/general/services/translations/translation.service';

//Components
import { useService } from '@/general/composables/useService';
import { ExportMappingViewService } from '@/general/services/overview-service/modules/export-mapping/export-mapping-view.service';
import { Datamodel, Module } from 'platform-unit2-api';
import { exportMappingServiceKey } from '../services/keys/export-mapping.keys';

interface Props {
  visible: boolean;
  sourceDatamodel: Datamodel;
  sourceModule: Module;
}

/** Props */
const props = withDefaults(defineProps<Props>(), {
  visible: false,
});

const ts = new TranslationService('retailer', 'export_mappings');
const service = useService(exportMappingServiceKey, ExportMappingViewService);

const duplicateMapping = () => {
  if (
    service.selectedClientToDuplicate != null &&
    service.selectedModuleToDuplicate != null &&
    service.selectedDatamodelToDuplicate != null
  ) {
    service.duplicateExportMapping({
      workspace_id: service.selectedClientToDuplicate.id,
      module_id: service.selectedModuleToDuplicate.id,
      datamodel_id: service.selectedDatamodelToDuplicate.id,
      source_datamodel_id: props.sourceDatamodel.id,
      source_module_id: props.sourceModule.id,
    });
  }
};
</script>
<template>
  <Dialog :visible="visible" :closable="false" modal :style="{ width: '40vw' }">
    <template #header>
      <div>
        <h2 class="mb-2 text-800">
          {{ ts.tModule('duplicate_mappings.overwrite_title') }}
        </h2>
      </div>
    </template>

    <p class="text-600">
      {{ ts.tModule('duplicate_mappings.overwrite_description') }}
    </p>

    <template #footer>
      <div>
        <Button
          plain
          text
          :label="ts.tGlobal('cancel')"
          class="mr-3"
          @click="service.closeDuplicateWarningDialog()"
        />
        <Button
          :label="ts.tModule('duplicate_mappings.overwrite')"
          severity="danger"
          @click="() => duplicateMapping()"
        />
      </div>
    </template>
  </Dialog>
</template>
