import store from '@/core/store';
import { chain } from 'lodash';
import {
  ValidatedAttributeField,
  ValidationModel,
  ValidationModelsRestService,
  ValidationRule,
  ValidationRuleItemAttribute,
} from 'platform-unit2-api';
import { FieldErrorEnum } from 'platform-unit2-api/attribute-fields';
import { ValidationSet } from 'platform-unit2-api/validation-sets';
import { NavigationSidebarService } from '../navigation-sidebar/navigation-sidebar.service';
import { RefetchService } from '../overview-service/refetch.service';

export interface ValidationRulesGroupedByErrorAndAttributeName {
  [severity: string]: (ValidationRule & { linkedAttributes: ValidationRuleItemAttribute[] })[];
}

export interface ValidationRuleViewError {
  attributeId: number;
  localeId: number;
  message: string;
  severity: FieldErrorEnum;
}

export class ProductAttributesValidationService extends RefetchService {
  private _navigationSidebarService = NavigationSidebarService.getNavigationSidebarService();
  private validationModelsRestService = new ValidationModelsRestService();
  private _validationModels: ValidationModel[] = [];
  public selectedValidationModel?: ValidationModel;
  public filterByErrors = false;
  public filterByWarnings = false;
  public validationPanelVisible = false;
  public validationModelSelectDialogOpen = false;
  public loading = false;
  public selectedValidationPanelTab = '';

  public get validationSets() {
    return this.selectedValidationModel?.validation_sets ?? [];
  }

  public get validationRulesBySeverityAndAttributeName(): ValidationRulesGroupedByErrorAndAttributeName {
    const validationRulesBySeverityAndAttributeName = this._groupBySeverityAndAttributeName(
      this.validationSets,
    );

    if (this.filterByErrors) {
      delete validationRulesBySeverityAndAttributeName['Warning'];
    }

    if (this.filterByWarnings) {
      delete validationRulesBySeverityAndAttributeName['Error'];
    }

    return validationRulesBySeverityAndAttributeName;
  }

  public get validationModelsSelectOpenCondition() {
    return !this.loading && this.validationPanelVisible && this.validationModelSelectDialogOpen;
  }

  public get validationModels() {
    return this._validationModels;
  }

  public get selectedModule() {
    return this.selectedProduct?.module;
  }

  public get selectedProduct() {
    return store.getters['products/currentProduct'];
  }

  public get showFilters(): boolean {
    return (
      this.validationRulesBySeverityAndAttributeName['Error'] != null &&
      this.validationRulesBySeverityAndAttributeName['Warning'] != null
    );
  }

  public get errorsCount(): number {
    return (
      Object.keys(this._groupBySeverityAndAttributeName(this.validationSets)?.['Error'] ?? {})
        .length ?? 0
    );
  }
  public get warningsCount(): number {
    return (
      Object.keys(this._groupBySeverityAndAttributeName(this.validationSets)?.['Warning'] ?? {})
        .length ?? 0
    );
  }
  public get validationRulesCount(): number {
    return this.errorsCount + this.warningsCount;
  }

  toggleValidationPanel() {
    this.validationPanelVisible = !this.validationPanelVisible;
    this.validationModelSelectDialogOpen = false;

    if (this.validationPanelVisible) {
      this.fetchAllValidationModels();
      this._navigationSidebarService.setNavigationStatus(false);
    }
  }

  public async assignValidationModelToProduct(chosenValidationModelId: number | null) {
    if (this.selectedProduct?.id == null) {
      return;
    }

    // has to go through the store in order to set the product anew
    await store.dispatch('products/UPDATE_PRODUCT', {
      productId: this.selectedProduct.id,
      data: {
        validation_model_id: chosenValidationModelId,
      },
    });
  }

  async fetchValidationModelFromProduct() {
    if (this.selectedProduct?.validation_model?.id == null) {
      return;
    }

    const response = await this.validationModelsRestService.get(
      this.selectedProduct?.validation_model?.id,
    );

    // fetch all regardless because of other functionalities
    await this.fetchValidationModelFromVariants();

    this.selectedValidationModel = response;

    if (this.selectedProduct?.validation_model?.id !== response.id) {
      await this.assignValidationModelToProduct(response.id);
    }
  }

  async fetchValidationModelFromVariants() {
    const response = await this.validationModelsRestService.getAll(this._paginationParams, {
      module_id: this.selectedProduct?.module?.id,
    });
    this._validationModels = response.data;
    if (this._validationModels.length === 1 && this.selectedValidationModel == null) {
      this.selectedValidationModel = this._validationModels[0];
      this.assignValidationModelToProduct(this.selectedValidationModel.id);
    }
  }

  fetchAllValidationModels() {
    this.loading = true;
    if (
      this.selectedProduct?.id != null &&
      this.selectedProduct?.validation_model?.id != null &&
      this.selectedProduct?.module?.id === this.selectedProduct?.validation_model?.module?.id
    ) {
      this.fetchValidationModelFromProduct().finally(async () => {
        await this._storeRulesInStore();
        this.loading = false;
      });
      return;
    }

    if (this.selectedProduct?.module?.id == null) {
      this.loading = false;
      return;
    }

    this.fetchValidationModelFromVariants().finally(async () => {
      await this._storeRulesInStore();
      this.loading = false;
    });
  }

  toggleFilterByErrors(value: boolean) {
    this.filterByWarnings = false;
    this.filterByErrors = value;
  }

  toggleFilterByWarnings(value: boolean) {
    this.filterByErrors = false;
    this.filterByWarnings = value;
  }

  toggleShowAll() {
    this.toggleFilterByErrors(false);
    this.toggleFilterByWarnings(false);
  }

  resetSelectedValidationModel() {
    this.selectedValidationModel = undefined;
    this.validationModelSelectDialogOpen = true;
  }

  async onApplyClick(intermediateSelectedValidationModel?: ValidationModel) {
    this.selectedValidationModel = intermediateSelectedValidationModel;
    this.validationModelSelectDialogOpen = false;
    if (this.selectedProduct?.validation_model?.id !== intermediateSelectedValidationModel?.id) {
      this.loading = true;
      await this.assignValidationModelToProduct(intermediateSelectedValidationModel?.id ?? null);
      this.loading = false;
    }
  }

  // Function to group validation rules by severity using reduce
  private _groupBySeverityAndAttributeName(
    data: ValidationSet[],
  ): ValidationRulesGroupedByErrorAndAttributeName {
    let newData = chain(data)
      .flatMap((validationSet) => {
        return validationSet.validation_rules.map((validationRule) => {
          // Extract linked_attributes from rules where type === 'attributes'
          const linkedAttributes: ValidationRuleItemAttribute[] = validationRule.rule
            ?.flat()
            .filter((r) => r.type === 'attributes')
            .map((r) => r.attribute)
            .filter((r) => r != null) as ValidationRuleItemAttribute[];

          return {
            ...validationRule,
            linkedAttributes: linkedAttributes,
          };
        });
      })
      .groupBy('severity')
      .value();

    // Having errors on top instead of warnings
    newData = {
      Error: newData['Error'],
      Warning: newData['Warning'],
    };

    return newData;
  }

  private async _storeRulesInStore() {
    const rules = await this.getValidationErrors();
    store.dispatch('products/SET_VALIDATION_ERRORS', rules);
  }

  private _attributeFieldsErrors: ValidationRuleViewError[] = [];

  public get attributeFieldsErrors() {
    return this._attributeFieldsErrors ?? this.getValidationErrors();
  }
  // Returns the error messages for all the attributes that have errors and displays them in below the attribute fields
  public async getValidationErrors(): Promise<ValidationRuleViewError[]> {
    const res = await this.validationModelsRestService.validateProduct(this.selectedProduct.id);
    this._attributeFieldsErrors = res.map((validationError: ValidatedAttributeField) => ({
      attributeId: validationError?.attribute_id,
      localeId: validationError?.locale_id,
      message: `Validation ${validationError.validation_model_severity.toLowerCase()}: ${
        validationError.validation_model_name
      }`,
      severity: this._getFieldErrorEnumSeverity(
        validationError.validation_model_severity.toUpperCase(),
      ),
    }));

    return this._attributeFieldsErrors;
  }

  // Cast 'error' severity to FieldErrorEnum.VALIDATION_ERROR, so that it doesn't block saving the product
  private _getFieldErrorEnumSeverity(severity: string) {
    if (severity === 'ERROR') {
      return FieldErrorEnum.VALIDATION_ERROR;
    }

    return FieldErrorEnum[severity as keyof typeof FieldErrorEnum];
  }
}
