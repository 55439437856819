<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { ExportMappingDetailsViewService } from '@/general/services/overview-service/modules/export-mapping/export-mapping-details.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Attribute, AttributeTypeEnum } from 'platform-unit2-api';
import { DataTableRowReorderEvent } from 'primevue/datatable';
import useAttributeOption from 'utils/attribute-option';
import { onMounted } from 'vue';
import { exportMappingDetailsServiceKey } from '../services/keys/export-mapping-details.keys';

//Components
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import { onBeforeRouteLeave } from 'vue-router';

const ts = new TranslationService('supplier', 'export_mappings');
const service = useService(exportMappingDetailsServiceKey, ExportMappingDetailsViewService);

const { getFieldDefinitionOption } = useAttributeOption();

const getSources = (attribute?: Attribute) => {
  const type = attribute?.options
    ? getFieldDefinitionOption(attribute?.options, 'type', undefined) || AttributeTypeEnum.DEFAULT
    : AttributeTypeEnum.DEFAULT;

  return service.sourcesPerFieldType[type as AttributeTypeEnum];
};

onMounted(async () => {
  await service.fetchUnsupportedMappingItems();
});

onBeforeRouteLeave(() => {
  service.resetUnsupportedMappingItems();
});
</script>
<template>
  <div class="bg-white relative">
    <LoadingIndicator v-if="service.isLoadingDetailsPage" />
    <DataTable
      v-else
      :value="service.unsupportedMappingItems"
      edit-mode="row"
      data-key="id"
      class="min-h-full p-3"
      :loading="service.tableLoading || service.isSaving"
      responsive-layout="scroll"
      @row-reorder="(event: DataTableRowReorderEvent) => service.onRowReorder(event)"
    >
      <Column :row-reorder="true" header-style="width: 1rem" />
      <Column
        field="attribute"
        :header="ts.tModule('datamodel_attributes')"
        style="flex-grow: 1; flex-basis: 200px; max-width: 200px"
      >
        <template #body="slotProps">
          <Select
            v-if="!service.tableLoading"
            v-model="slotProps.data.attribute"
            :options="service.selectedMapping?.datamodel?.attributes"
            option-label="key"
            data-key="id"
            filter
            placeholder="Select a field definition"
            class="w-full"
          >
            <template #empty> {{ ts.notFoundWarning }} </template>
          </Select>
        </template>
        <template #footer>
          <div class="align-items-center flex gap-2">
            <Select
              v-if="!service.tableLoading"
              v-model="service.newMappingItem.attribute"
              :options="service.selectedMapping?.datamodel?.attributes"
              option-label="key"
              data-key="id"
              filter
              placeholder="Select a field definition"
              :show-clear="true"
              class="w-full"
            >
              <template #empty> {{ ts.notFoundWarning }} </template>
            </Select>
            <span
              v-tooltip.bottom="{
                value: ts.tModule('export_mapping_unsupported.copy_to_target'),
                class: 'text-sm',
              }"
              class="cursor-pointer"
              @click="service.copyToTargetField"
            >
              <i class="mdi mdi-swap-horizontal"></i>
            </span>
          </div>
        </template>
      </Column>

      <Column
        field="source"
        :header="ts.tModule('export_mapping_unsupported.source')"
        style="max-width: 200px"
      >
        <template #body="slotProps">
          <div class="flex w-full">
            <Select
              v-model="slotProps.data.source"
              option-value="value"
              option-label="label"
              data-key="value"
              :options="getSources(slotProps.data.attribute)"
              placeholder="Select type"
              class="w-full"
            />

            <Select
              v-if="slotProps.data.source === 'list_item'"
              v-model="slotProps.data.source_index"
              :options="service.getArray(slotProps.data.attribute)"
            />
          </div>
        </template>
        <template #footer>
          <div class="flex w-full">
            <Select
              v-model="service.newMappingItem.source"
              option-value="value"
              option-label="label"
              data-key="value"
              :options="getSources(service.newMappingItem.attribute ?? undefined)"
              placeholder="Select type"
              class="w-full"
            />
            <Select
              v-if="service.newMappingItem.source === 'list_item'"
              v-model="service.newMappingItem.source_index"
              :options="service.getArray(service.newMappingItem.attribute)"
              placeholder="Select an index"
            />
          </div>
        </template>
      </Column>

      <Column
        field="argument"
        :header="ts.tModule('export_mapping_unsupported.target')"
        style="flex-grow: 1; flex-basis: 200px; max-width: 200px"
      >
        <template #body="slotProps">
          <InputText v-model="slotProps.data.argument" class="w-full" />
        </template>
        <template #footer>
          <InputText v-model="service.newMappingItem.argument" class="w-full" />
        </template>
      </Column>

      <Column header-style="width: 2rem">
        <template #body="slotProps">
          <Button
            icon="mdi mdi-delete-outline"
            text
            severity="danger"
            @click="service.removeUnsupportedItem(slotProps.data.id)"
          />
        </template>
        <template #footer>
          <Button
            icon="mdi mdi-plus"
            text
            rounded
            :disabled="service.isSaving"
            @click="service.createUnsupportedMapping()"
          />
        </template>
      </Column>

      <template #empty>
        <Message severity="info" :closable="false"
          >{{ ts.tModule('export_mapping_unsupported.add_records') }}
        </Message>
      </template>
    </DataTable>

    <div class="bg-white border-100 border-top-1 bottom-0 p-3 right-0 sticky text-right w-full">
      <Button
        :disabled="service.isSaving"
        :label="ts.tGlobal('save')"
        :icon="service.isSaving ? 'mdi mdi-loading pi-spin' : ''"
        icon-pos="right"
        @click="service.saveUnsupportedMappingItems()"
      />
    </div>
  </div>
</template>
