<script setup lang="ts">
import AttachTaskModal from 'retailer/modules/products/components/actions/attach-task-modal.vue';
import RetailerBulkCategoryAttachModal from 'retailer/modules/products/components/actions/retailer-bulk-category-attach-modal.vue';
import AddTaskModal from 'retailer/modules/tasks/components/add-task-modal.vue';

import { TaskViewService } from '@/general/services/overview-service/modules/task-view.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { DeleteButton } from '@/general/ui/components/crud-buttons/ts/classes/delete-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import useCheckable from 'composables/checkable';
import { Product } from 'platform-unit2-api/products';
import { ScheduledPipeline } from 'platform-unit2-api/scheduled-pipelines';
import ExportProductsModalGeneric from 'retailer/modules/products/components/actions/export-products-modal-generic.vue';
import ExportProductsModal from 'retailer/modules/products/components/actions/export-products-modal.vue';
import ExportTypeModal from 'retailer/modules/products/components/actions/export-type-modal.vue';
import TransitionStateModal from 'retailer/modules/products/components/actions/mass-transition-state.vue';
import ProductsTable from 'retailer/modules/products/components/products-table.vue';
import { onMounted, provide, ref, watch } from 'vue';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { taskViewServiceKey } from '../../tasks/ts/keys/task-view-service.key';
/** Props */
const props = withDefaults(
  defineProps<{
    productIds?: number[];
    scheduledExportId?: number;
    scheduledExportName?: string;
    excludedProductsIds?: number[];
  }>(),
  {
    productIds: () => [] as number[],
    scheduledExportId: 0,
    scheduledExportName: '',
    excludedProductsIds: () => [] as number[],
  },
);

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'products');
const tsCategories = new TranslationService('retailer', 'categories');
/** Constants */
const store = useStore();
const router = useRouter();
const route = useRoute();
const { checkedRows, checkedIds } = useCheckable();
const loading = ref(false);
const loadingProductIds = ref<string[]>([]);

const exportActive = ref(false);
const exportTypeActive = ref(false);
const exportGenericActive = ref(false);
const attachTaskVisible = ref(false);
const retailerBulkAttachCategoryModelActive = ref(false);
const transitionStateModalActive = ref(false);
const refreshProducts = ref(false);
const productstable = ref();
const isGeneric = ref(false);

const exportChildRoutes = [
  {
    label: ts.tModule('export_modal.channel.title'),
    to: { name: 'export-channel' },
  },
  {
    label: ts.tModule('export_modal.settings.title'),
    to: { name: 'export-settings' },
  },
  {
    label: ts.tModule('export_modal.schedule.title'),
    to: { name: 'export-schedule' },
  },
  {
    label: ts.tModule('export_modal.contact.title'),
    to: { name: 'export-contact' },
  },
  {
    label: ts.tModule('export_modal.overview.title'),
    to: { name: 'export-info' },
  },
];

const exportChildRoutesGeneric = [
  {
    label: ts.tModule('export_modal.overview.title'),
    to: { name: 'export-generic' },
  },
  {
    label: ts.tModule('export_modal.overview.title'),
    to: { name: 'export-product-overview' },
  },
];

const showSidebar = ref(false);

const handleCreateProduct = async () => {
  router.push({ name: 'new-product' });
};

const hideDetails = () => {
  router.push({
    name: 'products',
  } as RouteLocationRaw);
};

const resetRefresh = () => {
  refreshProducts.value = false;
};

const deleteBulkDialogVisible = ref(false);

const hideDeleteBulkDialog = () => (deleteBulkDialogVisible.value = false);

const bulkDeleteProductsHandler = async () => {
  loadingProductIds.value.push(...(checkedIds.value as string[]));
  try {
    await store.dispatch('products/BULK_ACTION_DELETE', checkedIds.value as string[]);
    deleteBulkDialogVisible.value = false;
    refreshProducts.value = true;
    toastService.displaySuccessToast(ts.deleteSuccess());
    checkedRows.value = [];
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  }

  loadingProductIds.value = loadingProductIds.value.filter(
    (uuid) => !checkedIds.value.includes(uuid),
  );
};

const exportVisible = async (value: boolean) => {
  exportActive.value = value;
  await router.push({ name: 'export-channel' });
  value ? await router.push({ name: 'export-channel' } as RouteLocationRaw) : reRoute();
};

const exportGenericVisible = async (value: boolean) => {
  value ? await router.push({ name: 'export-generic' } as RouteLocationRaw) : reRoute();
  exportGenericActive.value = value;
};

const exportTypeVisible = (value: boolean) => {
  exportTypeActive.value = value;

  if (!value) reRoute();
};

const reRoute = async () => {
  await router.push({ name: 'products' } as RouteLocationRaw);
};

const showExportModel = async (value: boolean) => {
  if (!value) {
    await router.push({ name: 'export-channel' });
    await exportVisible(true);
  } else {
    await exportGenericVisible(true);
  }

  exportTypeActive.value = false;
};

const toTypeSelection = async (value: boolean) => {
  isGeneric.value = value;
  value ? (exportGenericActive.value = false) : (exportActive.value = false);

  await router.push({ name: 'products' } as RouteLocationRaw);
  exportTypeVisible(true);
};

const retailerBulkAttachCategoryModelVisible = (value: boolean) =>
  (retailerBulkAttachCategoryModelActive.value = value);

const transitionStateModalVisible = (value: boolean) => (transitionStateModalActive.value = value);

const attachToTaskVisible = (value: boolean) => (attachTaskVisible.value = value);

const addTaskModalVisible = () => {
  router.push({ name: 'products-new-task' } as RouteLocationRaw);
};

const addProductsToScheduledExport = async () => {
  const scheduledExport: ScheduledPipeline = await store.dispatch(
    'export/FIND_SCHEDULED_EXPORT',
    props.scheduledExportId,
  );
  scheduledExport.product_ids = (scheduledExport.product_ids ?? []).concat(checkedIds.value);
  const updateScheduledExport = await store.dispatch(
    'export/UPDATE_SCHEDULED_EXPORT',
    scheduledExport,
  );
  Promise.all([scheduledExport, updateScheduledExport]).then(() => {
    router.push({ name: 'export-scheduled' } as RouteLocationRaw);
  });
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: hideDeleteBulkDialog,
});

const deleteButton = new DeleteButton({
  label: ts.tGlobal('delete'),
  position: CrudButtonPosition.RIGHT,
  onClick: bulkDeleteProductsHandler,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, deleteButton],
});

const retailerItems = ref([
  {
    label: 'Actions',
    disabled: () => !checkedIds.value.length,
    items: [
      {
        label: 'Add task',
        icon: 'mdi mdi-clipboard-plus-outline',
        command: () => addTaskModalVisible(),
      },
      {
        label: 'Attach to existing task',
        icon: 'mdi mdi-arrow-decision',
        command: () => attachToTaskVisible(true),
      },
      {
        label: tsCategories.tModule('bulkAttachTitle'),
        icon: 'mdi mdi-clipboard-plus-outline',
        command: () => retailerBulkAttachCategoryModelVisible(true),
      },
    ],
  },
  {
    label: ts.tModule('actions.transition_state'),
    icon: 'mdi mdi-send-check-outline',
    command: () => transitionStateModalVisible(true),
    disabled: () => (!checkedIds.value.length ? true : false),
  },
  {
    label: ts.tModule('actions.export'),
    icon: 'mdi mdi-export',
    command: () => exportTypeVisible(true),
    disabled: () => (!checkedIds.value.length ? true : false),
  },
]);

watch(
  () => route,
  () => {
    if (route.name === 'new-product') {
      showSidebar.value = true;
    }

    if (route.name === 'products') {
      showSidebar.value = false;
    }
  },
  {
    deep: true,
  },
);

onMounted(async () => {
  if (route.name === 'new-product') {
    showSidebar.value = true;
  }
});

const updateCheckedRows = (rows: Product[]) => {
  checkedRows.value = rows;
};

const taskService = ref(new TaskViewService('products'));
provide(taskViewServiceKey, taskService.value as TaskViewService);
</script>
<template>
  <section class="flex flex-column h-full px-4">
    <!-- #region: Modals -->

    <export-products-modal
      v-if="exportActive"
      :is-active="exportActive"
      :ids="checkedIds"
      :checked-rows="checkedRows"
      :child-routes="exportChildRoutes"
      @hide="exportVisible(false)"
      @to-type-selection="toTypeSelection"
    />
    <export-products-modal-generic
      v-if="exportGenericActive"
      :is-active="exportGenericActive"
      :ids="checkedIds"
      :checked-rows="checkedRows"
      :child-routes-generic="exportChildRoutesGeneric"
      @hide="exportGenericVisible(false)"
      @to-type-selection="toTypeSelection"
    />
    <export-type-modal
      v-if="exportTypeActive"
      :is-active="exportTypeActive"
      :model-value="isGeneric"
      @update:model-value="showExportModel"
      @hide="exportTypeVisible(false)"
    />

    <AddTaskModal :ids="checkedIds" />
    <transition-state-modal
      v-if="transitionStateModalActive"
      :is-active="transitionStateModalActive"
      :ids="checkedIds"
      @hide="transitionStateModalActive = false"
      @refresh="refreshProducts = true"
    />
    <attach-task-modal
      :is-active="attachTaskVisible"
      :ids="checkedIds"
      @hide="attachToTaskVisible(false)"
    />

    <retailer-bulk-category-attach-modal
      :is-active="retailerBulkAttachCategoryModelActive"
      :ids="checkedIds"
      @hide="retailerBulkAttachCategoryModelVisible(false)"
    />
    <!-- #endregion -->
    <Menubar
      v-if="!scheduledExportId"
      :model="retailerItems"
      class="justify-content-end mb-3 mt-3"
    />

    <!-- if it's to add products to scheduled export -->
    <div v-if="scheduledExportId" class="flex justify-content-between">
      <Message severity="info" :closable="false">
        {{ ts.tModule('adding_to_scheduled_export') }} "{{ scheduledExportName }}"
      </Message>
      <Button label="Add the products" class="m-3" @click="addProductsToScheduledExport" />
    </div>

    <!-- Product List Table  -->
    <products-table
      ref="productstable"
      :refresh-products="refreshProducts"
      :load-data="loading"
      @update:checked-rows="updateCheckedRows"
      @reset-refresh="resetRefresh"
      @create-product="handleCreateProduct"
    />

    <BaseDialog
      :visible="deleteBulkDialogVisible"
      :title="ts.tGlobal('delete')"
      :buttons-options="buttonsOptions"
      size="small"
      @update:visible="hideDeleteBulkDialog"
    >
      <div class="align-items-center flex">
        <i class="mdi mdi-information-outline mr-2 text-3xl" />
        <p>
          {{
            ts
              .tModule('confirm_delete', {
                params: {
                  amount: checkedIds.length,
                },
              })
              .toString()
          }}
        </p>
      </div>
    </BaseDialog>

    <Drawer
      v-model:visible="showSidebar"
      class="p-drawer-md"
      :dismissable="false"
      position="right"
      :pt="{
        header: 'align-self-end',
      }"
      @hide="hideDetails"
    >
      <router-view @hide="hideDetails" @refresh="refreshProducts = true" />
    </Drawer>
    <ConfirmPopup group="products" />
  </section>
</template>
