<script setup lang="ts">
import { useService } from '@/general/composables/useService/useService';
import { tsKey } from '@/general/services/translations/translation.key';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { importMappinsAdvancedItemsServiceKey } from 'supplier/modules/import-mapping-items/ts/keys/import-mapping-items.keys';
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import { SupplierImportMappingItemsAdvancedViewService } from '../ts/services/supplier-import-mapping-advanced-items-view.service';

const service = useService(
  importMappinsAdvancedItemsServiceKey,
  SupplierImportMappingItemsAdvancedViewService,
)!;

const ts = inject(tsKey)!;
const router = useRouter();
</script>
<template>
  <div class="align-items-start flex flex-column gap-2 mb-3">
    <div class="align-items-center flex gap-2">
      <h3>{{ ts?.tModule('map_the_attributes') }}</h3>
      <Badge :value="service.amountOfSavedItems + '/' + service.amountOfAttributes" />
    </div>
    <p>{{ ts?.tModule('map_the_attributes_subtitles') }}</p>
  </div>

  <div v-if="service.mappingTree.length != 0">
    <TreeTable
      resizable-columns
      :expanded-keys="service.expandedKeys"
      :value="service.mappingTree"
      class="text-base"
    >
      <Column
        field="argument"
        expander
        :pt="{
          headerCell: {
            class: 'font-bold surface-50 p-4 flex align-items-center',
          },
        }"
        class="border-1"
      >
        <template #header>
          <div
            v-tooltip.bottom="{
              value: !service.expanded ? ts.tModule('expandAll') : ts.tModule('collapseAll'),
            }"
            class="align-items-center align-justify-content column-hovered flex pr-3"
            @click="service.toggleExpansion()"
          >
            <Button
              :icon="!service.expanded ? 'pi pi-angle-right' : 'pi pi-angle-down'"
              rounded
              text
              style="font-size: 8rem!"
              class="font-normal mr-2 text-gray-600"
            />
            <p>{{ ts?.tModule('import_source') }}</p>
          </div>
        </template>

        <template #body="{ node }">
          <InputText
            v-if="node.data.type != 'GROUP_FIELD'"
            v-model="node.data.argument"
            style="width: 85%"
            class="border-0 py-2"
            :placeholder="ts.tModule('sourceArgument')"
            @update:model-value="
              (value?: string) => value && service.updateAdvancedMappingItem(node.key, value)
            "
          />
          <InputText
            v-else
            style="width: 85%"
            class="border-0 py-2"
            :placeholder="node.data.attribute"
            disabled
          /> </template
      ></Column>
      <Column
        field="attribute"
        :header="ts?.tModule('datamodel_attributes')"
        :pt="{
          headerCell: {
            class: 'font-bold p-4',
          },
        }"
        class="border-1 surface-50"
      >
        <template #body="{ node }">
          <div>{{ node.data.attribute }}</div>
        </template></Column
      >
    </TreeTable>
  </div>
  <div v-else>
    <EmptyState
      :translation-service="ts"
      :empty-state-title="ts.tModule('noSearchResults')"
      :empty-state-subtitle="ts.tModule('noSearchResultsSubtitle')"
      :icon-name="'imports'"
      :button-label="ts.tModule('emptyStateButton')"
      @clicked="router.push({ name: 'datamodels' })"
    />
  </div>

  <ConfirmDialog group="delete-dialog" />
</template>
<style lang="css" scoped>
.column-hovered:hover {
  background: rgba(0, 137, 215, 0.04);
  border-color: transparent;
  cursor: pointer;
}
</style>
