<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import GtinSearch from '@/general/ui/components/products-table/gtin-search.vue';
import ProductsTableGeneric from '@/general/ui/components/products-table/products-table-generic.vue';
import { Product } from 'platform-unit2-api';
import { inject, ref } from 'vue';
import { scheduledExportServiceKey } from '../ts/keys/exports.keys';

const ts = inject(tsKey)!;
const scheduledExportService = inject(scheduledExportServiceKey)!;

const cancelButton = new CancelButton({
  label: ts.value.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => scheduledExportService.value.toggleUpdateProductsModal(),
});

const createButton = new CreateButton({
  label: ts.value.tGlobal('update'),
  position: CrudButtonPosition.RIGHT,
  type: 'submit',
  onClick: async () => await scheduledExportService.value.updateScheduledExportProducts(),
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, createButton],
});
</script>
<template>
  <BaseDialog
    v-if="scheduledExportService != null"
    :visible="scheduledExportService.isUpdateProductsModalVisible"
    :title="ts.tModule(`export_scheduled.updateProducts.title`)"
    :subtitle="ts.tModule(`export_scheduled.updateProducts.subtitle`)"
    :buttons-options="buttonsOptions"
    size="large"
    @update:visible="scheduledExportService.toggleUpdateProductsModal()"
  >
    <ProductsTableGeneric
      :selected-products="scheduledExportService.selectedProducts"
      :table-columns="scheduledExportService.updateProductsTableColumns"
      @update-products-list="(value: Product[]) => scheduledExportService.updateSelectedProducts(value)"
    >
      <template #header="{ tableService }">
        <div class="align-items-center flex font-normal gap-3">
          <InputText
            v-model="tableService.query"
            class="border-100 w-9"
            :placeholder="ts.tGlobal('search')"
          />
          <GtinSearch
            button-class="w-3"
            @gtin-search-values="(value: string[]) => tableService.applyGTINFilter(value)"
          />
        </div>
      </template>
    </ProductsTableGeneric>
  </BaseDialog>
</template>
