<script setup lang="ts">
import { inject } from 'vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';

const service = inject(datamodelAttributeServiceKey)!;
const ts = service.ts;
</script>

<template>
  <Message v-if="service.removeWarning" class="text-sm" @close="service.removeWarning = false">{{
    ts.tModule('warning_removing_attributes')
  }}</Message>
</template>
