<script setup lang="ts">
import { GroupFieldService } from '@/general/services/attribute-fields/field-services/group-field.service';
import { AdvancedFieldPanel } from '@/general/services/attribute-fields/interfaces/advanced-field-tab.interface';
import { TranslationService } from '@/general/services/translations/translation.service';
import AttributeFieldsRenderer from '@/general/ui/components/attribute-fields/attribute-fields-renderer.vue';
import { uniqueId } from 'lodash';
import { AttributeField } from 'platform-unit2-api/attribute-fields';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import { SortableEvent } from 'sortablejs';
import Flag from 'ui/components/attribute-fields/flag.vue';
import { v4 as uuidv4 } from 'uuid';
import { computed, inject, provide, ref, unref } from 'vue';
import { useStore } from 'vuex';
import { lockableKey } from '../inject.keys';
import GroupFieldActionButton from './group-field-action-button.vue';
import GroupFieldDnd from './group-field-dnd.vue';
/** Props */
interface Props {
  attributeField: AttributeField<null>; // Group field does not have a value
}
const props = defineProps<Props>();
const confirmGroup = ref(uuidv4());

const store = useStore();

const isInMissingFieldMode = computed(() => {
  return store.getters['products/showMissingFields'];
});

provide(lockableKey, false);

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;
const service = ref(
  new GroupFieldService(
    props.attributeField,
    unref(productAttributeFieldsService),
    confirmGroup.value,
  ),
);

const ts = new TranslationService('general', 'components');
</script>
<template>
  <section>
    <!-- children are different locales of the group field when the group field attribute is not global -->
    <div
      v-for="(locale, index) in service.getLocalesToRender(
        productAttributeFieldsService.chosenLocaleIds,
      )"
      :key="uniqueId(attributeField.attribute.id.toString() + index.toString())"
    >
      <GroupFieldDnd
        :show-handle="
          Boolean(attributeField.attribute.options.multiple) &&
          Boolean(attributeField.attribute.options.collapsible)
        "
        :list-items="service.panels.get(locale?.id) ?? []"
        @on-drag-end-callback="(_: SortableEvent, items: AdvancedFieldPanel[]) => service.changeTheIndexInGroupMap(locale?.id, items)"
      >
        <template #default="{ item }">
          <Flag :locale="locale" :global="attributeField.attribute.global_attribute" />
          <Panel
            v-if="attributeField.attribute?.options?.collapsible"
            toggleable
            class="w-full"
            :pt="{
              root: {
                class: 'border-noround-left',
              },
              header: {
                style: { 'flex-direction': 'row-reverse' },
              },
              content: {
                class: 'border-noround-left',
              },
            }"
          >
            <template #toggleicon="{ collapsed }">
              <i :class="collapsed ? 'mdi mdi-chevron-right' : 'mdi mdi-chevron-down'" />
            </template>
            <template #header>
              <div class="align-items-center flex justify-content-between w-full">
                <p class="font-bold">
                  {{ service.getAccordionValue(item.attributes, locale?.id) }}
                </p>
                <GroupFieldActionButton
                  :group-service="(service as GroupFieldService)"
                  :item="item"
                  :locale="locale"
                  :disabled="isInMissingFieldMode"
                  :relative="false"
                />
              </div>
            </template>
            <AttributeFieldsRenderer :attribute-fields="item.attributes" />
          </Panel>

          <!-- Normal Group Field -->
          <Card v-else class="custom-group-card relative text-left w-full">
            <template #content>
              <div class="grid">
                <AttributeFieldsRenderer :attribute-fields="item.attributes" />
              </div>
              <GroupFieldActionButton
                :group-service="(service as GroupFieldService)"
                :item="item"
                :locale="locale"
                :disabled="isInMissingFieldMode"
                :relative="true"
              />
            </template>
          </Card>
        </template>
      </GroupFieldDnd>
      <div
        v-if="
          attributeField.attribute.options.multiple &&
          (service.panels.get(locale?.id) ?? []).length > 0
        "
        v-tooltip="{
          value: ts.tModule('groupField.createPresetDisabled'),
          disabled: !isInMissingFieldMode,
        }"
        class="fit-content"
      >
        <Button
          icon="mdi mdi-plus"
          :label="
            ts.tModule('attributeFields.addGroup', {
              params: { attributeName: attributeField.attribute.key },
            })
          "
          text
          :disabled="service.isLoadingPreset || isInMissingFieldMode"
          :loading="service.isLoadingPreset"
          @click="service?.addNewPreset(locale?.id)"
        />
      </div>
    </div>
    <ConfirmDialog :group="confirmGroup" />
  </section>
</template>

<style scoped lang="scss">
.custom-group-card {
  border-radius: 0 var(--inputBorderRadius) var(--inputBorderRadius) 0 !important;
}
.fit-content {
  width: fit-content !important;
}
</style>
