<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/vue3';
import * as cronParser from 'cron-parser';
import { Action } from 'platform-unit2-api/actions';
import { DashboardRestService } from 'platform-unit2-api/dashboard';
import { ref } from 'vue';
import { RouteLocationRaw, RouteParamsRaw, useRouter } from 'vue-router';

/** Services */
const ts = new TranslationService('supplier', 'dashboard');
const dashboardApi = new DashboardRestService();

/** Constants */
const router = useRouter();
const loading = ref(true);

const options: CalendarOptions | any = {
  plugins: [dayGridPlugin],
  initialDate: new Date().toISOString(),
  initialView: 'dayGridMonth',
  buttonIcons: {
    prev: ' mdi mdi-chevron-left',
    next: ' mdi mdi-chevron-right',
  },
  events: async (fetchInfo: any, successCallback: ([]) => void, failureCallback: () => void) => {
    const events = ref<unknown[]>([]);
    loading.value = true;

    const params = {
      startDate: fetchInfo.start,
      endDate: fetchInfo.end,
    };

    try {
      const products = await dashboardApi.getDashboardProducts(params.startDate, params.endDate);
      const tasks = await dashboardApi.getDashboardTasks(params.startDate, params.endDate);
      const pipelines = await dashboardApi.getPipelineData();

      events.value = [];

      pipelines.forEach((item) => {
        const startDate =
          fetchInfo.start.getTime() > new Date().getTime() ? fetchInfo.start : new Date();
        const cron = cronParser.parseExpression(item.cron, { currentDate: startDate });
        events.value.push({
          title: ts.tModule('calendar.scheduled', {
            params: {
              name: item.name,
              type: item.type,
            },
          }),
          daysOfWeek: cron.fields.dayOfWeek,
          startRecur: startDate.toISOString(),
          endRecur: fetchInfo.end.getTime(),
        });
      });

      events.value = events.value.concat(
        products.created.map((item: { count: number; date: string; ids: number[] }, i: number) => ({
          id: i,
          title: ts.tModule('calendar.products_added', { params: { count: item.count } }),
          ids: item.ids,
          start: item.date,
          click: () => {
            navigateToProducts(item.date, 'created');
          },
          backgroundColor: 'green',
          borderColor: 'green',
        })),
      );

      events.value = events.value.concat(
        products.updated.map((item: { count: number; date: string; ids: number[] }, i: number) => ({
          id: i,
          title: ts.tModule('calendar.products_edited', { params: { count: item.count } }),
          ids: item.ids,
          start: item.date,
          click: () => {
            navigateToProducts(item.date, 'updated');
          },
          backgroundColor: '#007aee',
          borderColor: '#007aee',
        })),
      );

      events.value = events.value.concat(
        products.deleted.map(
          (item: { count: number; date: string; ids: number[] }, i: number): any => ({
            id: i,
            title: ts.tModule('calendar.products_deleted', { params: { count: item.count } }),
            ids: item.ids,
            start: item.date,
            backgroundColor: 'red',
            borderColor: 'red',
          }),
        ),
      );

      events.value = events.value.concat(
        tasks.map((item: Action, i: number): any => ({
          id: i,
          title: item.title,
          start: item.date,
          click: () => {
            router.push({
              name: 'update-task',
              params: { id: item.id.toString() } as RouteParamsRaw,
            } as RouteLocationRaw);
          },
          backgroundColor: '#007aee',
        })),
      );

      successCallback(events.value);
      loading.value = false;
    } catch (e) {
      failureCallback();
    } finally {
      loading.value = false;
    }
  },
  headerToolbar: {
    left: 'prev,next',
    center: 'title',
    right: 'today',
  },
  selectable: true,
  eventClick: (info: any) => {
    if (!info.event.extendedProps.click) return;

    info.event.extendedProps.click(info.event.extendedProps);
  },
};

const navigateToProducts = (date: string, type: string) => {
  router.push({
    name: 'products',
    query: { date: date, type: type } as RouteParamsRaw,
  } as RouteLocationRaw);
};
</script>
<template>
  <section class="pt-2 px-4">
    <Message severity="info" :closable="true">
      {{ ts.tModule('calendar.info') }}
    </Message>
    <div style="min-height: 50vh" class="border-round pb-5 pt-2">
      <full-calendar :loading="loading" :options="options"></full-calendar>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.p-breadcrumb {
  background: none;
  border: 0;
  padding: 0;
}

.pup-app .fc .fc-view-harness .fc-event-main {
  background: inherit;
  border: 1px solid transparent;
}
.p-breadcrumb {
  background: none;
  border: 0;
  padding: 0;
}

.p-breadcrumb :deep(.pi-chevron-right) {
  color: #fff;
  padding: 0 3px 0 3px;
  font-size: smaller;
}
.p-breadcrumb :deep(.p-menuitem-link .p-menuitem-text) {
  color: #fff;
}
.p-breadcrumb :deep(.p-menuitem-link .p-menuitem-icon) {
  color: #fff;
}

.fc :deep(.fc-button-primary) {
  background-color: #0089d7;
  border-color: #0089d7;
}
</style>
