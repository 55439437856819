<script setup lang="ts">
//components
import { MultipleImageTextClass } from '@/api/v1/content-story/ts/classes/multiple-image-text.class';
import { TranslationService } from '@/general/services/translations/translation.service';
import { inject } from 'vue';
import { storyBuilderServiceKey } from '../../../ts/helpers/story-manager-keys';
import BaseContentComponent from '../content-components/base-content-component.vue';
import ContentHeadline from '../content-components/content-headline.vue';
import ContentImage from '../content-components/content-image.vue';
import ContentTextarea from '../content-components/content-textarea.vue';

interface Props {
  uuid: string;
}

const props = defineProps<Props>();

// inject / provide
const storyService = inject(storyBuilderServiceKey);
const ts = new TranslationService('supplier', 'storyManager');

// const / refs
const storyModule = storyService?.value.getModule<MultipleImageTextClass>(props.uuid);
</script>

<template>
  <BaseContentComponent :uuid="uuid">
    <template #content>
      <div class="flex flex-column gap-3 h-full">
        <div class="flex gap-2 justify-content-between main-area w-full">
          <div class="highlight-image ml-4 mt-5">
            <Image
              :image-class="
                storyModule?.getHighlightedBlock()?.block?.image?.uploadDestinationId
                  ? 'h-full w-full flex'
                  : 'w-10rem h-10rem flex'
              "
              class="align-items-center flex h-full justify-content-center w-full"
              alt="highlight"
              :src="
                storyModule?.getHighlightedBlock()?.block?.image?.uploadDestinationId ??
                '/src/assets/empty-state/empty-upload-library-state.svg'
              "
            />
            <ContentHeadline
              :custom-place-holder="ts.tModule('storyBuilder.customHeadline.imageKeyWords')"
              :custom-class="'mt-3'"
              :headline="storyModule?.getAltText(storyModule?.highlightedBlockIndex)"
              @headline-value="storyModule?.setAltText($event, storyModule?.highlightedBlockIndex)"
            />
          </div>

          <div class="flex flex-column w-8">
            <ContentHeadline
              :custom-class="'mb-3 mt-5'"
              :headline="
                (storyModule?.data.blocks ?? [])[storyModule?.highlightedBlockIndex ?? 0].block
                  ?.headline?.value
              "
              @headline-value="
                ($event) => storyModule?.setHeadline(storyModule.highlightedBlockIndex, $event)
              "
            />
            <ContentTextarea
              :additional-container-style="'76%'"
              :textbox="
                (storyModule?.data.blocks ?? [])[storyModule?.highlightedBlockIndex ?? 0].block
                  ?.body?.value
              "
              @html-text-area="
                ($event) => storyModule?.setBody(storyModule.highlightedBlockIndex, $event)
              "
            />
          </div>
        </div>
        <div class="flex gap-2 h-4 justify-content-between secondary-area">
          <div class="flex flex-column w-3" @click="storyModule?.setHighlightedBlock(0)">
            <ContentImage
              :custom-library-button-label="ts.tGlobal('library')"
              :minimal-image-size="storyModule?.minimumImageSize"
              :accepted-file-types="storyModule?.acceptedFileTypes"
              :upload-url="storyModule?.getUploadUrl(0)"
              :is-upload-set="storyModule?.hasUploadSet(0)"
              @uploaded-image="storyModule?.setUploadData($event, 0)"
            />
            <ContentHeadline
              :custom-place-holder="ts.tModule('storyBuilder.captionHeadline')"
              :headline="storyModule?.getCaption(0)"
              @headline-value="($event) => storyModule?.setCaption(0, $event)"
            />
          </div>
          <div class="flex flex-column w-3" @click="storyModule?.setHighlightedBlock(1)">
            <ContentImage
              :custom-library-button-label="ts.tGlobal('library')"
              :minimal-image-size="storyModule?.minimumImageSize"
              :accepted-file-types="storyModule?.acceptedFileTypes"
              :upload-url="storyModule?.getUploadUrl(1)"
              :is-upload-set="storyModule?.hasUploadSet(1)"
              @uploaded-image="storyModule?.setUploadData($event, 1)"
            />
            <ContentHeadline
              :custom-place-holder="ts.tModule('storyBuilder.captionHeadline')"
              :headline="storyModule?.getCaption(1)"
              @headline-value="($event) => storyModule?.setCaption(1, $event)"
            />
          </div>
          <div class="flex flex-column w-3" @click="storyModule?.setHighlightedBlock(2)">
            <ContentImage
              :custom-library-button-label="ts.tGlobal('library')"
              :minimal-image-size="storyModule?.minimumImageSize"
              :accepted-file-types="storyModule?.acceptedFileTypes"
              :upload-url="storyModule?.getUploadUrl(2)"
              :is-upload-set="storyModule?.hasUploadSet(2)"
              @uploaded-image="storyModule?.setUploadData($event, 2)"
            />
            <ContentHeadline
              :custom-place-holder="ts.tModule('storyBuilder.captionHeadline')"
              :headline="storyModule?.getCaption(2)"
              @headline-value="($event) => storyModule?.setCaption(2, $event)"
            />
          </div>
          <div class="flex flex-column w-3" @click="storyModule?.setHighlightedBlock(3)">
            <ContentImage
              :custom-library-button-label="ts.tGlobal('library')"
              :minimal-image-size="storyModule?.minimumImageSize"
              :accepted-file-types="storyModule?.acceptedFileTypes"
              :upload-url="storyModule?.getUploadUrl(3)"
              :is-upload-set="storyModule?.hasUploadSet(3)"
              @uploaded-image="storyModule?.setUploadData($event, 3)"
            />
            <ContentHeadline
              :custom-place-holder="ts.tModule('storyBuilder.captionHeadline')"
              :headline="storyModule?.getCaption(3)"
              @headline-value="($event) => storyModule?.setCaption(3, $event)"
            />
          </div>
        </div>
      </div>
    </template>
  </BaseContentComponent>
</template>

<style lang="scss" scoped>
.highlight-image {
  border: 1px dashed #cad9e7;
  border-radius: 6px;
  aspect-ratio: 1/1;
  height: 82%;
}
.main-area {
  height: 50%;
}

.secondary-area {
  height: 50%;
}
</style>
