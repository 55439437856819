import { ActionTree } from 'vuex';
import * as Sentry from '@sentry/vue';
import { User as SentryUser } from '@sentry/vue';
import useStorage from 'utils/storage';
import { UserState } from '../user.types';

import { UpdateUserRequest, User, UserRestService } from 'platform-unit2-api/users';
import { HeapService } from '@/core/app/ts/services/heap.service';

const userApi = new UserRestService();

export const actions: ActionTree<UserState, {}> = {
  async GET_CURRENT_USER_DETAILS({ commit }) {
    const user = await userApi.getCurrentUser(true);

    if (user) {
      Sentry.configureScope((scope) => {
        if (!user.id) return;
        scope.setTag('user-roles', user.roles.join(', '));
        const sentryUser: SentryUser = {
          id: user.id.toString(),
          email: user.email,
          username: user.name,
        };
        scope.setUser(sentryUser);
      });

      const isProduction = location.hostname === 'retail.productsup.com';

      if (isProduction) {
        // @ts-ignore
        window.intercomSettings = {
          app_id: 'r57gyn68',
          name: user.name,
          email: user.email,
          user_hash: user.user_hash,
          hide_default_launcher: true,
        };

        HeapService.identify(user.user_hash ?? user.id.toString());
        HeapService.addUserProperties({
          Name: user.name,
          Email: user.email,
          Workspace: user.workspace?.name,
          Organisation: user.organisation?.name,
          CreatedAt: user.created_at,
          UpdatedAt: user.updated_at,
        });
      }

      commit('updateUser', user);
    }
  },

  async UPDATE_USER(
    { getters, commit },
    { id, updatedUser }: { id: number; updatedUser: UpdateUserRequest },
  ): Promise<void> {
    if (updatedUser?.workspace_id != null) {
      await userApi.changeWorkspaceTrespassKeycloak(id, updatedUser.workspace_id);
    }

    const user = (await userApi.update(id, updatedUser)) as User;

    if (id === getters['currentUser'].id) {
      commit('updateUser', Object.assign(getters['currentUser'], user));
    }

    const storage = useStorage();

    storage.setStorageItem('workspace', {
      admin: user.admin_mode,
      type: user.workspace?.workspace_type?.type,
    });
  },

  async UPDATE_WORKSPACE(
    { getters, commit },
    { userId, workspaceId }: { userId: number; workspaceId: number },
  ): Promise<void> {
    if (userId != null && workspaceId != null) {
      await userApi.changeWorkspaceTrespassKeycloak(userId, workspaceId);
    }

    const user = await userApi.getCurrentUser(true);

    if (userId === getters['currentUser'].id) {
      commit('updateUser', user);
    }

    const storage = useStorage();

    storage.setStorageItem('workspace', {
      admin: user?.admin_mode,
      type: user?.workspace?.workspace_type?.type,
    });
  },
};
