import { Platform } from '@/core/router/route.factory';
import store from '@/core/store';
import { isPositive } from '@/general/utils/isPositive';
import { FilterMatchMode } from '@primevue/core/api';
import { cloneDeep } from 'lodash';
import {
  Client,
  ClientTypeEnum,
  CreateExportMappingRequest,
  Datamodel,
  DuplicateExportMappingRequest,
  ExportMapping,
  ExportMappingFilters,
  ExportMappingsRestService,
  Module,
  UpdateExportMappingRequest,
  User,
} from 'platform-unit2-api';
import { DataTableFilterEvent, DataTableFilterMetaData } from 'primevue/datatable';
import { TranslationService } from '../../../translations/translation.service';
import { BaseViewService } from '../../view/base-view.service';

export class ExportMappingViewService extends BaseViewService<
  ExportMappingsRestService,
  ExportMapping,
  CreateExportMappingRequest,
  UpdateExportMappingRequest,
  ExportMappingFilters
> {
  public duplicateDialog = false;
  public currentUser?: User;
  public filter = '';
  public filters = {
    'datamodel.name': { value: null, matchMode: FilterMatchMode.EQUALS },
    'module.name': { value: null, matchMode: FilterMatchMode.EQUALS },
  };
  public selectedOption: { label: string; value: string } = {
    label: 'Supported',
    value: 'supported',
  };
  public dropdownOptions = [
    { label: 'Supported', value: 'supported' },
    { label: 'Unsupported', value: 'unsupported' },
  ];
  constructor(ts: TranslationService) {
    const currentUser: User | undefined = store.getters['users/currentUser'];
    let currentSpace: ClientTypeEnum =
      currentUser?.workspace?.workspace_type?.type ?? ClientTypeEnum.SUPPLIER;
    if (currentSpace.toString().toLowerCase() === 'dms') {
      currentSpace = ClientTypeEnum.SUPPLIER;
    }

    super({
      Api: ExportMappingsRestService,
      ts: ts ?? new TranslationService(currentSpace.toLowerCase() as Platform, 'export_mappings'),
      overviewRouteName: 'export-mappings',
      createRouteName: 'new-mapping',
      updateRouteName: '',
      confirmPopUpGroup: 'export_mappings',
    });

    this.currentUser = currentUser;
    this.refetch = this.fetchAll;
  }

  /**
   * Get export supported or unsupported export mappings, based on dropdown value.
   */
  public get exportMappings(): ExportMapping[] {
    return this._data;
  }

  public createBody() {
    if (!this.validated) {
      return;
    }

    const body: CreateExportMappingRequest = {
      datamodel_id: this.partialObject.datamodel!.id,
      module_id: this.partialObject.module!.id,
      owner_id:
        this.selectedOption.value === 'supported' ? this.currentUser?.workspace?.id : undefined,
    };

    return body;
  }

  protected _create(createBody?: CreateExportMappingRequest): void {
    this._isLoadingCrudComponent = true;

    const body = createBody ?? this.createBody();

    if (body != null && this.validated) {
      this._restService
        .post(body)
        .then(() => {
          this._toastService.displaySuccessToast(this._ts.createSuccess());
          this._reloadPage();
        })
        .catch((e) => {
          this._formValidationService.handleError(e, () => {
            this._toastService.displayErrorToast(e.response.data.message);
          });
        })
        .finally(() => {
          this._isLoadingCrudComponent = false;
        });
    } else {
      this._isLoadingCrudComponent = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public updateBody(body?: UpdateExportMappingRequest): UpdateExportMappingRequest {
    throw new Error('Method not implemented.');
  }

  public get validated(): boolean {
    if (this.partialObject.datamodel?.id && this.partialObject.module?.id) {
      return true;
    }

    return false;
  }

  //#region Duplicate functions
  /**
   * Open a dialog to duplicate a mapping.
   * @param mapping mapping to duplicate
   */
  public openDuplicateDialog(mapping: ExportMapping): void {
    this._currentObject = cloneDeep(mapping);
    this.duplicateDialog = true;
  }

  /**
   * Close the duplicate dialog.
   */
  public closeDuplicateDialog(): void {
    this._currentObject = undefined;
    this.duplicateDialog = false;
  }

  public navigateToMappingDetails(mapping: ExportMapping): void {
    if (mapping.owner == null) {
      this._router.push({
        name: 'unsupported-export-mapping',
        params: { id: mapping.id.toString() },
      });
    } else {
      this._router.push({
        name: 'supported-export-mapping',
        params: { id: mapping.id.toString() },
      });
    }
  }

  // Remove getter method from the function
  public openCrudComponent(object?: ExportMapping, redirect = true): void {
    this.crudComponent = true;
    this._formValidationService.resetErrors();

    //Router doesn't need to be called because user is already on update route
    if (object == null && this._route.params?.id != null && isPositive(this._route.params.id)) {
      return;
    }

    //set the current object
    this.current = object;

    //Route to child component to store the id in the url
    if (redirect) {
      if (this.current != null && this.current?.id != null) {
        this._router.push({ name: this._updateRouteName, params: { id: this.current.id } });
      } else {
        this._router.push({ name: this._createRouteName });
      }
    }
  }

  public applyFilter(event: DataTableFilterEvent) {
    this.filterParams = {
      ...this.filterParams,
      datamodel_id: (event.filters['datamodel.name'] as DataTableFilterMetaData).value?.id,
      module_id: (event.filters['module.name'] as DataTableFilterMetaData).value?.id,
    };

    this.refetch();
  }

  // Duppliacte a mapping

  public selectedClientToDuplicate?: Client;
  public selectedDatamodelToDuplicate?: Datamodel;
  public selectedModuleToDuplicate?: Module;
  public duplicateWarningDialogVisible = false;

  public async checkIfMappingExists(mapping: DuplicateExportMappingRequest): Promise<void> {
    if (
      mapping.datamodel_id === mapping.source_datamodel_id &&
      mapping.module_id === mapping.source_module_id
    ) {
      this._toastService.displayErrorToast(
        this._ts.tModule('duplicate_mappings.duplication_error_same_destination'),
      );
      return;
    }

    await this._restService
      .doesExportMappingExist({
        module_id: mapping.module_id,
        datamodel_id: mapping.datamodel_id,
      })
      .then((res) => {
        res ? (this.duplicateWarningDialogVisible = true) : this.duplicateExportMapping(mapping);
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.duplicateFailed());
      });
  }

  public duplicateExportMapping(mapping: DuplicateExportMappingRequest) {
    this._restService
      .duplicate(mapping)
      .then(() => {
        this.closeDuplicateDialog();
        this.refetch();
        this._toastService.displaySuccessToast(this._ts.duplicateSuccess());
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.duplicateFailed());
      });
  }

  public isDuplicateButtonDisabled(): boolean {
    return (
      !this.selectedClientToDuplicate ||
      !this.selectedDatamodelToDuplicate ||
      !this.selectedModuleToDuplicate
    );
  }

  public closeDuplicateWarningDialog(): void {
    this.duplicateWarningDialogVisible = false;
  }
}
