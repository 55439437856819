import { RouteRecordRaw } from 'vue-router';
import WorkspaceSelector from './workspace-selector.vue';

const workspaceSelectorRoutes: RouteRecordRaw[] = [
  {
    path: '/workspaces',
    name: 'workspaces',
    component: WorkspaceSelector,
    meta: {
      title: 'Productsup Platform - Workspaces',
    },
  },
];

export default workspaceSelectorRoutes;
