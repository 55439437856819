<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import EmptyState from '@/general/ui/components/empty-state.vue';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import CategorySelect from '@/general/ui/components/selects/category-select.vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import StatusSelect from '@/general/ui/components/selects/product-status-select.vue';
import VariantSelect from '@/general/ui/components/selects/variant-select.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import Upload from '@/general/ui/components/upload.vue';
import { formatDate } from '@/general/utils/format-date';
import ProductTableDateFilter from '@/platforms/supplier/modules/products/components/table/product-table-date-filter.vue';
import { TableColumnInterface } from '@/platforms/supplier/modules/products/services/product-table.constants';
import { ActionStatusSeverityEnum, Product, ProductUpload } from 'platform-unit2-api';
import { DataTableFilterEvent } from 'primevue/datatable';
import { onMounted, ref } from 'vue';
import BrandSelect from '../selects/brand-select.vue';
import ClientSelect from '../selects/client-select.vue';
import DatamodelSelect from '../selects/datamodel-select.vue';
import { ProductsTableGenericService } from './products-table-generic.service';

interface Props {
  selectedProducts: Product[];
  tableColumns: TableColumnInterface[];
}

const props = defineProps<Props>();
const emit = defineEmits(['update-products-list']);

const service = ref(new ProductsTableGenericService(props.tableColumns, props.selectedProducts));

onMounted(() => {
  service.value.getProducts();
});
</script>
<template>
  <DataTable
    v-model:selection="service.checkedProductRows"
    v-model:filters="service.productFilters"
    :global-filter-fields="[
      'variant_name',
      'gtin',
      'owner_id',
      'datamodel.name',
      'brand_id',
      'product_status_id',
      'gtins',
      'module_id',
      'created_at',
    ]"
    size="small"
    :rows="service.limit"
    :total-records="service.total"
    :first="service.first"
    show-gridlines
    :value="service.products"
    :loading="service.loading"
    filter-display="menu"
    data-key="id"
    scrollable
    scroll-height="flex"
    removable-sort
    lazy
    :pt="{
      header: {
        style: 'border: 0; background: inherit; padding-left:0; padding-right:0',
      },
    }"
    :paginator="service.limit ? (service.total ?? 0) > service.limit : false"
    @page="(event) => service.onPageChange(event.page)"
    @sort="(event) => service.onSortChange(event)"
    @filter="(event: DataTableFilterEvent) => service.applyFilter(event)"
  >
    <template #empty>
      <EmptyState
        v-if="!service.loading"
        style="max-width: 80vw"
        class="pt-5"
        :translation-service="(service.ts as TranslationService)"
        :icon-name="'products'"
        :button-visible="false"
      />
    </template>
    <!-- Header of the table used to display search, column filter, filter removals,
    next iteration also bulk actions and export -->
    <template #header> <slot name="header" :table-service="service"> </slot> </template>

    <!-- First two column, These are frozen. Shows the checkbox to select the product and the thumbnail.
    These are both the header and the body,-->
    <!-- Checkbox column-->
    <Column frozen style="min-width: 4rem; flex-grow: 0" class="z-1">
      <template #header>
        <div class="flex justify-content-center w-full">
          <Checkbox v-model="service.isAllChecked" :binary="true" />
        </div>
      </template>
      <template #body="slotProps">
        <div id="select-checkbox" class="flex justify-content-center w-full">
          <Checkbox
            v-model="service.checkedProductRows"
            name="products"
            :value="slotProps.data"
            @change="emit('update-products-list', service.checkedProductRows)"
          />
        </div>
      </template>
    </Column>
    <!-- thumbnail column-->
    <Column
      frozen
      style="flex-grow: 1"
      class="z-1"
      :style="'border-right: 1px solid #dfe7ef; box-shadow: 0 0 15px rgba(90, 113, 135, 0.28)!important; clip-path: inset(0px -15px 0px 0px);'"
    >
      <template #body="slotProps">
        <Upload
          preview
          :upload="slotProps.data.thumbnail"
          additional-container-style="width: 48px !important;"
        />
      </template>
    </Column>

    <!-- Dynamically generated columns based on the columns selected.
    the given templates are for the #body and the #filterModel-->

    <Column
      v-for="(col, index) of service.tableColumns"
      :key="col.field + '_' + index"
      :field="col.field"
      :header="col.header"
      :sortable="col.sortable"
      :show-filter-match-modes="false"
      style="cursor: pointer"
      :style="
        col.type === 'gtin' ? 'border-left: 0px; min-width: ' + col.size : 'min-width: ' + col.size
      "
      :class="[col.type === 'images_included' ? 'flex-grow-1' : '', col.type]"
    >
      <template #body="{ data }">
        <template v-if="col.type === 'gtin'"> {{ data.gtin ? data.gtin : '' }} </template>
        <template v-if="col.type === 'display_name'">
          {{ data.display_name }}
        </template>

        <template v-if="col.type === 'variant'">
          {{ data.variant_name ? data.variant_name : 'Master Data' }}
        </template>

        <template v-if="col.type === 'shared_retailer' && data.variant_name != null">
          <Checkbox v-model="data.public" :binary="true" :disabled="true" class="ml-2" />
        </template>

        <template v-if="col.type === 'owner'">
          {{ data.owner.name }}
        </template>

        <template v-if="col.type === 'category'">
          {{ data.category ? data.category.name : '' }}
        </template>

        <template v-if="col.type === 'datamodel'">
          {{ data.datamodel ? data.datamodel.name : '' }}
        </template>

        <template v-if="col.type === 'brand'">
          {{ data.brand ? data.brand.name : '' }}
        </template>

        <template v-if="col.type === 'module'">
          {{ data.module ? data.module.name : '' }}
        </template>

        <template v-if="col.type === 'completeness'">
          <ProgressBar :progress="Math.round(data.completeness) ?? 0" width="w-10rem" />
        </template>

        <template v-if="col.type === 'updated_at'">
          {{ formatDate(data.updated_at) }}
        </template>
        <template v-if="col.type === 'created_at'">
          {{ formatDate(data.created_at) }}
        </template>
        <template v-if="col.type === 'status'">
          <StatusChip
            :label="data.status?.label"
            :severity="ActionStatusSeverityEnum[data.status?.label?.toUpperCase() as keyof typeof ActionStatusSeverityEnum]"
            label-only
          />
        </template>
        <template v-if="col.type === 'images_included'">
          <div class="flex flex-row gap-1">
            <div
              v-for="item in data.images_included.sort((a: ProductUpload, b: ProductUpload) => a.order - b.order)"
              :key="item.id"
              style="width: 4rem !important; padding-right: 4px"
            >
              <Upload
                v-if="item.public_thumbnail_url"
                preview
                :upload="item"
                additional-container-style="width: 4rem !important;"
              />

              <SvgIcon
                v-else
                :filename="item.public_url"
                custom-style="width: 4rem !important"
                class="flex-shrink-0"
              />
            </div>
          </div>
        </template>
      </template>

      <template
        v-if="
          col.type === 'variant' ||
          col.type === 'owner' ||
          col.type === 'category' ||
          col.type === 'datamodel' ||
          col.type === 'brand' ||
          col.type === 'module' ||
          col.type === 'status' ||
          col.type === 'created_at'
        "
        #filter="{ filterModel }"
      >
        <template v-if="col.type === 'variant'">
          <VariantSelect
            v-model="filterModel.value"
            class="filter-style"
            display="comma"
            multiselect
            hide-label
          />
        </template>

        <template v-if="col.type === 'owner'">
          <ClientSelect
            v-model="filterModel.value"
            class="filter-style"
            multiselect
            only-used-by-products
            display="comma"
            hide-label
          />
        </template>

        <template v-if="col.type === 'datamodel'">
          <div class="filter-style">
            <DatamodelSelect
              v-model="filterModel.value"
              :only-used-by-products="true"
              display="comma"
              hide-label
              multiselect
            />
          </div>
        </template>

        <template v-if="col.type === 'category'">
          <CategorySelect
            v-model="filterModel.value"
            class="filter-style"
            display="comma"
            multiselect
            hide-label
          />
        </template>

        <template v-if="col.type === 'brand'">
          <BrandSelect
            v-model="filterModel.value"
            multiselect
            class="filter-style"
            only-used-by-products
            hide-label
            display="comma"
          />
        </template>

        <template v-if="col.type === 'module'">
          <ModuleSelect
            v-model="filterModel.value"
            class="filter-style"
            :only-used-by-products="true"
            display="comma"
            multiselect
            hide-label
          />
        </template>

        <template v-if="col.type === 'status'">
          <StatusSelect
            v-model="filterModel.value"
            class="filter-style"
            display="comma"
            multiselect
            hide-label
          />
        </template>

        <template v-if="col.type === 'created_at'">
          <ProductTableDateFilter
            :filter-model="filterModel"
            @update:model-value="($event: null[]|string[]) => filterModel.value = $event"
          />
        </template>
      </template>
    </Column>
  </DataTable>
</template>
