<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import { TranslationService } from '@/general/services/translations/translation.service';
import Overview from '@/general/ui/views/over-view/over-view.vue';
import { provide, ref, Ref } from 'vue';
import DeletedProductsTable from '../components/deleted-products/deleted-products-table.vue';
import RestoreMasterDataConfirmDialog from '../components/deleted-products/restore-master-data-confirm-dialog.vue';
import RestoreVariantConfirmDialog from '../components/deleted-products/restore-variant-confirm-dialog.vue';
import { DeletedProductsService } from '../services/deleted-products.service';
import { deletedProductsServiceKey } from '../ts/keys/products.keys';

const ts = ref(new TranslationService('supplier', 'products'));
const service = ref(new DeletedProductsService(ts.value as TranslationService));

provide(deletedProductsServiceKey, service as Ref<DeletedProductsService>);
provide(tsKey, ts as Ref<TranslationService>);
</script>

<template>
  <Overview platform="supplier" module-name="deletedProducts" :service="service">
    <template #content>
      <Message class="m-1 mb-3" severity="warn" :closable="false">
        <p>{{ ts.tModule('deletedProducts.deleteAfterDays') }}</p>
      </Message>
      <DeletedProductsTable />
    </template>
    <template #crudComponent> </template>
    <template #others>
      <RestoreVariantConfirmDialog group="restoreVariant" />
      <RestoreMasterDataConfirmDialog group="restoreMasterData" />
    </template>
  </Overview>
</template>
