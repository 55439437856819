import { Platform } from '@/core/router/route.factory';
import store from '@/core/store';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import {
  Attribute,
  AttributeTypeEnum,
  ClientCompact,
  ClientTypeEnum,
  CreateMappingItemRequest,
  DatamodelAttributeConfig,
  DatamodelsRestService,
  ExportMapping,
  ExportMappingsRestService,
  Locale,
  MappingItem,
  MappingItemRestService,
  MappingSourceType,
  TemplateEndpoint,
  TemplatesRestService,
  UpdateMappingItemRequest,
  User,
} from 'platform-unit2-api';
import { DataTableRowReorderEvent } from 'primevue/datatable';
import { useRoute } from 'vue-router';

export class ExportMappingDetailsViewService {
  // Services
  private _ts = new TranslationService('supplier', 'export_mappings');
  private _toastService: ToastService;
  protected _route: ReturnType<typeof useRoute>;
  protected _restService: ExportMappingsRestService;
  private _datamodelApiService = new DatamodelsRestService();
  private _mappingItemRestService = new MappingItemRestService();
  private _templatesRestService = new TemplatesRestService();
  private used = false;

  public isLoadingDetailsPage = false;
  public selectedMapping?: ExportMapping = undefined;
  public attributeConfigs: DatamodelAttributeConfig[] = [];
  public mappingItemsOwners: ClientCompact[] = [];
  public endpoints: TemplateEndpoint[] = [];
  public selectedEndpointId?: number;
  public selectedLocale: Locale[] = [];
  public selectedSource: { value: MappingSourceType }[] = [];
  public editableMappingChipItems: any[] = [];

  public currentUser?: User;

  constructor() {
    const currentUser: User | undefined = store.getters['users/currentUser'];
    let currentSpace: ClientTypeEnum =
      currentUser?.workspace?.workspace_type?.type ?? ClientTypeEnum.SUPPLIER;
    if (currentSpace.toString().toLowerCase() === 'dms') {
      currentSpace = ClientTypeEnum.SUPPLIER;
    }

    this._ts = new TranslationService(currentSpace.toLowerCase() as Platform, 'export_mappings');
    this._toastService = ToastService.getInstance();
    this.currentUser = currentUser;
    this._route = useRoute();
    this._restService = new ExportMappingsRestService();
  }

  // Export mapping details page

  // Get selected mapping
  public async fetchSelectedMapping() {
    // Fetch by id
    if (this._route.params.id == null) return;
    try {
      await this._restService.get(Number(this._route.params.id)).then(async (res) => {
        this.selectedMapping = res;
      });
    } catch (err) {
      this._toastService.displayErrorToast(this._ts.loadFailed(this._ts.tGlobal('data')));
    }
  }

  //    Supported mapping items

  // Fetch all attribute configs (attributes connected to the datamodel) (that info is not present in the datamodel)
  public async fetchAttributeConfigs() {
    if (this.selectedMapping?.datamodel?.id) {
      this.attributeConfigs = await this._datamodelApiService.getDatamodelAttributeConfig(
        this.selectedMapping?.datamodel.id,
      );
    }
  }

  public getOwner(): ClientCompact | undefined {
    return this.currentUser?.workspace;
  }

  async getTemplateEndpoint() {
    this.endpoints = [];
    if (!this.selectedMapping?.module?.template_id) return;
    try {
      this.endpoints = (
        await this._templatesRestService.getTemplateEndpoints(
          this.selectedMapping.module?.template_id,
        )
      ).data;
    } catch (err) {
      this._toastService.displayErrorToast(this._ts.loadFailed(this._ts.tGlobal('data')));
    }
  }

  public async fetchAll() {
    this.isLoadingDetailsPage = true;
    await this.fetchSelectedMapping()
      .then(async () => {
        await this.fetchAttributeConfigs();
        await this.getTemplateEndpoint().then(() => {
          this.loadData();
        });
      })
      .finally(() => {
        this.isLoadingDetailsPage = false;
      });
  }

  public sortMapping(data: any[], sortBy: string) {
    return data
      ? data.sort((a, b) => (a[sortBy] < b[sortBy] ? -1 : a[sortBy] > b[sortBy] ? 1 : 0))
      : data;
  }

  public isOriginalKeyValid(id: number) {
    return this.attributeConfigs?.find((x) => x.attribute_id === id)?.name ?? undefined;
  }

  public defaultMappingSource = [
    {
      value: MappingSourceType.VALUE,
      label: this._ts.tModule('field_sources.take_value'),
    },
    {
      value: MappingSourceType.LOCALE,
      label: this._ts.tModule('field_sources.take_locale'),
    },
  ];

  public sourcesPerFieldType: Record<
    AttributeTypeEnum,
    Array<{ value: MappingSourceType; label: string }>
  > = {
    DEFAULT: [...this.defaultMappingSource],
    GTIN_FIELD: [...this.defaultMappingSource],
    TEXT_AREA_FIELD: [...this.defaultMappingSource],
    KOAG_FIELD: [...this.defaultMappingSource],
    CATEGORY_FIELD: [...this.defaultMappingSource],
    LIST_FIELD: [...this.defaultMappingSource],
    LIST_GROUP: [...this.defaultMappingSource],
    TAB_FIELD: [...this.defaultMappingSource],
    GROUP_FIELD: [...this.defaultMappingSource],
    RICH_TEXT_FIELD: [
      {
        value: MappingSourceType.VALUE,
        label: this._ts.tModule('field_sources.take_html'),
      },
      {
        value: MappingSourceType.TEXT,
        label: this._ts.tModule('field_sources.take_text'),
      },
      {
        value: MappingSourceType.LOCALE,
        label: this._ts.tModule('field_sources.take_locale'),
      },
    ],
    SWITCH_FIELD: [...this.defaultMappingSource],
    NUMBER_FIELD: [...this.defaultMappingSource],
    DATE_TIME_FIELD: [...this.defaultMappingSource],
    BRAND_FIELD: [...this.defaultMappingSource],
    TAG_FIELD: [...this.defaultMappingSource],
    CHOICE_FIELD: [...this.defaultMappingSource],
    MULTIPLE_CHOICE_FIELD: [...this.defaultMappingSource],
    FINANCIAL_FIELD: [
      ...this.defaultMappingSource,
      {
        value: MappingSourceType.DECIMAL,
        label: this._ts.tModule('field_sources.take_number'),
      },
      {
        value: MappingSourceType.UNIT,
        label: this._ts.tModule('field_sources.take_unit'),
      },
    ],
    KEY_VALUE_FIELD: [
      ...this.defaultMappingSource,
      {
        value: MappingSourceType.KEY,
        label: this._ts.tModule('field_sources.take_key'),
      },
    ],
    INPUT_SELECT_FIELD: [
      ...this.defaultMappingSource,
      {
        value: MappingSourceType.DECIMAL,
        label: this._ts.tModule('field_sources.take_number'),
      },
      {
        value: MappingSourceType.UNIT,
        label: this._ts.tModule('field_sources.take_unit'),
      },
    ],
  };

  public async addAttribute(attribute: Attribute) {
    if (this.selectedEndpointId == null) {
      return;
    }

    const index = this.selectedEndpointId;

    if (
      this.editableMappingChipItems[index] != null &&
      this.editableMappingChipItems[index].length > 0
    ) {
      return;
    }

    if (this.editableMappingChipItems[index] == null) {
      this.editableMappingChipItems[index] = [];
    }

    this.editableMappingChipItems[index].forEach((item: any) => {
      if (item.attribute.id == attribute.id) this.used = true;
      else this.used = false;
    });

    if (!this.used) {
      const mappingItem = await this.saveAttributes(attribute, index);

      if (mappingItem) {
        this.editableMappingChipItems[index].push(mappingItem);
      }
    } else {
      this._toastService.displayErrorToast(
        this._ts.tModule('export_mapping_supported.attribute_exists'),
      );
    }
  }

  public async saveAttributes(attribute: Attribute, index: number): Promise<MappingItem | void> {
    try {
      const mapping: CreateMappingItemRequest = {
        module_id: this.selectedMapping?.module?.id ?? 0,
        datamodel_id: this.selectedMapping?.datamodel?.id ?? 0,
        attribute_id: attribute.id ?? 0,
        template_endpoint_id: index,
        source: this.selectedSource[attribute?.id].value,
        owner_id: this.getOwner()?.id ?? 0,
        source_locale_id: attribute && this.selectedLocale[attribute.id]?.id,
        argument: '',
      };

      const mappingItem = await this._mappingItemRestService.post(mapping);
      this._toastService.displaySuccessToast(this._ts.createSuccess());

      return mappingItem;
    } catch (err) {
      this._toastService.displayErrorToast(
        this._ts.tModule('export_mapping_supported.adding_failed'),
      );
    }
  }

  public async removeMappingItem(mappingItem: MappingItem) {
    try {
      await this._mappingItemRestService.delete(mappingItem.id);
    } catch (err) {
      this._toastService.displayErrorToast(this._ts.deleteFailed());
    }
  }

  public removeAll(id: number) {
    this.editableMappingChipItems[id].forEach((item: MappingItem) => {
      this.removeMappingItem(item);
    });

    this.editableMappingChipItems[id] = [];
  }

  public loadData() {
    this.editableMappingChipItems = [];
    this.selectedMapping?.mapping_items.forEach((el: MappingItem) => {
      if (!this.editableMappingChipItems[el.template_endpoint_id ?? 0]) {
        this.editableMappingChipItems[el.template_endpoint_id ?? 0] = [];
      }

      this.editableMappingChipItems[el.template_endpoint_id ?? 0].push(el);
    });
  }

  public resetSupportedMappingItems() {
    this.selectedEndpointId = undefined;
    this.selectedMapping = undefined;
    this.editableMappingChipItems = [];
  }

  //    Unsupported mapping items
  public tableLoading = false;
  public isSaving = false;
  public unsupportedMappingItems: MappingItem[] = [];
  public newMappingItem: Partial<Omit<MappingItem, 'id'>> = {
    source: MappingSourceType.VALUE,
  };

  public async fetchUnsupportedMappingItems() {
    this.isLoadingDetailsPage = true;
    this.fetchSelectedMapping()
      .then(() => {
        this.getUnsupportedMappingItems();
      })
      .finally(() => {
        this.isLoadingDetailsPage = false;
      });
  }

  public getUnsupportedMappingItems() {
    this.unsupportedMappingItems =
      this.selectedMapping?.mapping_items
        .filter((mappingItem: MappingItem) => mappingItem !== null)
        .filter((mappingItem: MappingItem) => mappingItem.template_endpoint_id === null)
        .map((mappingItem: MappingItem) => ({
          ...mappingItem,
          source_index: mappingItem.source_index! + 1,
        })) ?? [];

    this.unsupportedMappingItems = this.sortMapping(this.unsupportedMappingItems, 'order');
  }

  public async onRowReorder(event: DataTableRowReorderEvent): Promise<void> {
    this.tableLoading = true;

    this.unsupportedMappingItems = event.value;

    const data = {
      order: event.dropIndex,
    };

    if (!this.selectedMapping?.mapping_items[0]?.mapping_id) {
      return;
    }

    await this._restService.orderMappingItem(
      this.selectedMapping?.mapping_items[0]?.mapping_id,
      this.unsupportedMappingItems[event.dropIndex].id,
      data,
    );

    this.tableLoading = false;
  }

  public copyToTargetField(): void {
    if (!this.newMappingItem.attribute) {
      return;
    } else {
      this.newMappingItem.argument = this.newMappingItem.attribute.key;
    }
  }

  public getArray(attribute?: Attribute): Array<number> {
    if (!attribute) return [];
    return Array.from(Array(parseInt(attribute.options?.maxLength ?? '0')).keys(), (n) => n + 1);
  }

  public async removeUnsupportedItem(mappingItemId: number): Promise<void> {
    this.tableLoading = true;
    try {
      await this._mappingItemRestService.delete(mappingItemId);
      this._toastService.displaySuccessToast(this._ts.deleteSuccess());
    } catch (err) {
      this._toastService.displayErrorToast(this._ts.deleteFailed());
    } finally {
      this.tableLoading = false;
      this.fetchUnsupportedMappingItems();
    }
  }

  public async createUnsupportedMapping(): Promise<void> {
    if (
      !this.newMappingItem.attribute ||
      !this.newMappingItem.source ||
      !this.newMappingItem.argument
    ) {
      this._toastService.displayErrorToast(this._ts.tModule('missing_fields'));
      return;
    }

    try {
      this.isSaving = true;

      if (this.selectedMapping?.module?.id && this.selectedMapping.datamodel?.id) {
        const mappingItem: CreateMappingItemRequest = {
          module_id: Number(this.selectedMapping?.module?.id),
          datamodel_id: this.selectedMapping.datamodel?.id,
          argument: this.newMappingItem?.argument,
          attribute_id: this.newMappingItem.attribute!.id,
          source: this.newMappingItem.source,
          source_index: this.newMappingItem.source_index! - 1,
          template_endpoint_id: null,
          owner_id: null,
        };
        await this._mappingItemRestService.post(mappingItem);
      }

      this._toastService.displaySuccessToast(this._ts.createSuccess());
    } catch (err) {
      this._toastService.displayErrorToast(this._ts.createFailed());
    } finally {
      this.newMappingItem = {
        source: MappingSourceType.VALUE,
      };
      this.isSaving = false;
      this.fetchUnsupportedMappingItems();
    }
  }

  public async saveUnsupportedMappingItems(): Promise<void> {
    try {
      this.isSaving = true;

      const mappingItemsToUpdate: UpdateMappingItemRequest[] = this.unsupportedMappingItems.map(
        (mappingItem: MappingItem) => {
          return {
            id: mappingItem.id,
            mapping_id: mappingItem.mapping_id,
            argument: mappingItem.argument,
            attribute_id: mappingItem.attribute?.id,
            source: mappingItem.source,
            source_index: mappingItem.source_index! - 1,
            owner_id: null,
          } as UpdateMappingItemRequest;
        },
      );

      const promises = mappingItemsToUpdate.map((element) => {
        return this._mappingItemRestService.update(element.id, element);
      });

      await Promise.all(promises);
      this._toastService.displaySuccessToast(this._ts.updateSuccess());
    } catch (err) {
      this._toastService.displayErrorToast(this._ts.updateFailed());
    } finally {
      this.isSaving = false;
      this.fetchUnsupportedMappingItems();
    }
  }

  public resetUnsupportedMappingItems() {
    this.selectedMapping = undefined;
    this.unsupportedMappingItems = [];
    this.newMappingItem = {
      source: MappingSourceType.VALUE,
    };
  }
}
