<script setup lang="ts">
import { productAttributeValidationServiceKey } from '@/general/services/attribute-fields/service-keys';
import { TranslationService } from '@/general/services/translations/translation.service';
import { inject } from 'vue';
import ValidationError from './components/validation-error.vue';

const ts = new TranslationService('supplier', 'products');

const productAttributesValidationService = inject(productAttributeValidationServiceKey)!;
</script>

<template>
  <div>
    <div class="align-items-center flex mb-4 subtitle text-gray-600">
      {{ ts.tModule('validationModels.applied') }}:
      <b class="pup-ml-2"
        >"{{ productAttributesValidationService.selectedValidationModel?.name }}"</b
      >
      <Button
        v-if="productAttributesValidationService.validationModels.length > 1"
        icon="mdi mdi-pencil-outline"
        class="ml-1"
        text
        @click="productAttributesValidationService.resetSelectedValidationModel()"
      />
    </div>
    <div
      :class="{
        'flex flex-column justify-content-center w-full h-full':
          productAttributesValidationService.validationRulesCount === 0,
      }"
    >
      <div
        v-if="productAttributesValidationService.validationRulesCount === 0"
        class="flex flex-column justify-content-center pup-pt-10"
      >
        <img
          src="/src/assets/empty-state/no_errors_and_warnings_validation_models.svg"
          class="align-self-center pup-mb-7 w-5rem"
        />
        <div class="text-center text-xl">{{ ts.tModule('validationModels.noErrorsFound') }}</div>
      </div>
      <div v-else>
        <!-- Filtering options -->
        <div class="align-items-center flex info justify-content-between pup-mb-2">
          <div class="text-xl title">
            <b>{{ ts.tModule('validationModels.errorsAndSolutions') }}</b>
          </div>
          <div class="align-items-center flex">
            <Button
              :label="productAttributesValidationService.errorsCount.toString()"
              icon="mdi mdi-alert-circle"
              class="text-red-500"
              :text="!productAttributesValidationService.filterByErrors"
              :severity="
                productAttributesValidationService.filterByErrors ? 'secondary' : 'primary'
              "
              @click="productAttributesValidationService.toggleFilterByErrors(true)"
            />
            <Button
              :label="productAttributesValidationService.warningsCount.toString()"
              icon="mdi mdi-alert"
              class="pup-ml-2 text-orange-400"
              :text="!productAttributesValidationService.filterByWarnings"
              :severity="
                productAttributesValidationService.filterByWarnings ? 'secondary' : 'primary'
              "
              @click="productAttributesValidationService.toggleFilterByWarnings(true)"
            />
            <Button
              :label="
                ts.tModule('validationModels.showAll', {
                  params: { count: productAttributesValidationService.validationRulesCount },
                })
              "
              class="pup-ml-2"
              :text="
                productAttributesValidationService.filterByWarnings ||
                productAttributesValidationService.filterByErrors
              "
              :severity="
                productAttributesValidationService.filterByWarnings ||
                productAttributesValidationService.filterByErrors
                  ? 'primary'
                  : 'secondary'
              "
              @click="productAttributesValidationService.toggleShowAll()"
            />
          </div>
        </div>
        <div
          v-for="severity in Object.keys(
            productAttributesValidationService.validationRulesBySeverityAndAttributeName,
          )"
          :key="severity"
        >
          <div
            v-if="
              productAttributesValidationService.validationRulesBySeverityAndAttributeName[
                severity
              ] != null
            "
          >
            <div
              :class="`align-items-center bg-${
                severity === 'Error' ? 'red' : 'orange'
              }-100 flex pup-p-2 pup-my-1`"
            >
              <i
                :class="`mdi mdi-alert-circle pup-mr-2 text-${
                  severity === 'Error' ? 'red' : 'orange'
                }-500 text-xl`"
              ></i>
              <span class="font-bold">{{ severity }}</span>
            </div>
            <ValidationError
              :severity="severity"
              :validation-rules="
                productAttributesValidationService.validationRulesBySeverityAndAttributeName[
                  severity
                ]
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
