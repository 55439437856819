<script setup lang="ts">
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Thumbnail, ThumbnailsRestService } from 'platform-unit2-api/thumbnails';
import Upload from 'ui/components/upload.vue';
import UserAvatar from 'ui/components/user-avatar.vue';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

// Props
interface Props {
  isAvatar?: boolean;
  username?: string;
  thumbnail?: Thumbnail;
}

const props = withDefaults(defineProps<Props>(), {
  username: '',
  thumbnail: undefined,
});

//  Emits
const emit = defineEmits<{
  (e: 'uploaded', thumbnailValue?: Thumbnail): void;
  (e: 'uploading'): void;
}>();

// Composables
const store = useStore();

//services
const ts = new TranslationService('general', 'components');
const toastService = ToastService.getInstance();
const confirmService = new ConfirmService();
const thumbnailApi = new ThumbnailsRestService();

// Constants
const uploader = ref();
const isUploading = ref(false);
const thumbnailValue = ref<Thumbnail>();

const uploadThumbnail = async (event: any) => {
  emit('uploading');
  const allowedTypes: String[] = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/tiff',
    'image/tif',
  ];
  try {
    if (!allowedTypes.includes(event.files[0].type)) {
      throw 'file type is now allowed';
    }

    const result = await store.dispatch('thumbnails/STORE_THUMBNAIL', event.files[0]);
    uploader.value.files = [];

    thumbnailValue.value = await thumbnailApi.get(result.id);

    setTimeout(() => {
      isUploading.value = false;
    }, 1000);
    toastService.displaySuccessToast(ts.uploadSuccess(ts.tGlobal('avatar')));
  } catch (err) {
    toastService.displayErrorToast(
      ts.tGlobal('incorrectFileType', {
        params: {
          allowedTypes: allowedTypes.join(', '),
        },
      }),
    );
  } finally {
    emit('uploaded', thumbnailValue.value);
  }
};

const confirmThumbnailDelete = (event: MouseEvent) => {
  confirmService.confirmDelete({
    event: event,
    group: 'thumbnail',
    message: ts.deleteConfirm(),
    callback: () => deleteThumbnail(),
  });
};

const deleteThumbnail = async () => {
  thumbnailValue.value = undefined;
  toastService.displaySuccessToast(ts.deleteSuccess(ts.tGlobal('avatar')));
  emit('uploaded', thumbnailValue.value);
};

// Lifecycle hooks
onMounted(() => {
  thumbnailValue.value = props.thumbnail;
  isUploading.value = false;
});
</script>

<template>
  <div class="align-items-center flex">
    <UserAvatar
      v-if="isAvatar && !isUploading"
      :url="thumbnailValue?.public_url"
      :username="username"
      size="large"
    />
    <Upload
      v-else
      :is-uploading="isUploading"
      :upload="thumbnailValue"
      class="upload-image w-5rem"
    />
    <FileUpload
      ref="uploader"
      mode="basic"
      :custom-upload="true"
      auto
      :choose-label="ts.tModule('thumbnailUploader.upload')"
      class="ml-3"
      @uploader="uploadThumbnail"
    />

    <Button
      icon="mdi mdi-delete-outline"
      severity="danger"
      text
      :label="ts.tModule('thumbnailUploader.remove')"
      :disabled="!thumbnailValue"
      @click="(event: MouseEvent) => confirmThumbnailDelete(event)"
    />
    <ConfirmPopup group="thumbnail" />
  </div>
</template>

<style scoped lang="scss">
.upload-image {
  height: 3.4rem;
  width: 3.4rem;
}
</style>
