<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import EmptyState from '@/general/ui/components/empty-state.vue';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import TableSkeleton from '@/general/ui/components/skeletons/table-skeleton.vue';
import Upload from '@/general/ui/components/upload.vue';
import { Brand } from 'platform-unit2-api/brands';
import { DashboardRestService } from 'platform-unit2-api/dashboard';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'dashboard');
const dashboardApi = new DashboardRestService();

/** Constants */
const loading = ref(true);
const router = useRouter();
const brands = ref<Brand[]>([]);

const startDataFetching = async (): Promise<void> => {
  loading.value = true;

  try {
    brands.value = (await dashboardApi.getDashboardBrands()).brands;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

/** Lifecycle Methods */
onMounted(async () => {
  await startDataFetching();
});
</script>
<template>
  <Card v-if="loading">
    <template #content>
      <h4 class="mb-4 text-left">{{ ts.tModule('user_dashboard.brandCompleteness.title') }}</h4>
      <TableSkeleton />
    </template>
  </Card>
  <Card v-else>
    <template #content>
      <h4 class="mb-4 text-left">{{ ts.tModule('user_dashboard.brandCompleteness.title') }}</h4>
      <div v-if="!brands.length">
        <EmptyState
          :translation-service="ts"
          :icon-name="'master-data'"
          :empty-state-title="ts.tModule('emptyState.brands.title')"
          :empty-state-subtitle="ts.tModule('emptyState.brands.subtitle')"
          :button-label="ts.tModule('emptyState.brands.button')"
          :button-icon="'mdi mdi-plus'"
          @clicked="router.push({ name: 'new-products' })"
        />
      </div>
      <div v-else class="h-30rem overflow-y-scroll">
        <div
          v-for="data in brands"
          :key="data.id"
          class="align-items-center cursor-pointer flex flex-row hover:surface-50 mb-3 p-3 w-11"
          @click="
            $router.push({
              name: 'brand-products-incomplete',
              params: { id: data.id.toString() },
            })
          "
        >
          <upload :upload="data.thumbnail" class="mr-3 w-6rem" />
          <div class="text-left w-full">
            {{ data.name }}
            <ProgressBar
              :progress="data.completeness ? Math.round(data.completeness) : 0"
              class="pup-mt-2"
            />
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>
<style scoped lang="scss">
.table-wrapper {
  position: relative;
  min-height: 200px;
}
.ui-datatable tr.ui-datatable-even:hover,
.ui-datatable tr.ui-datatable-odd:hover {
  background: #78bcff;
}
.p-progressbar {
  flex-grow: 1;
  height: 0.5rem;
}
</style>
