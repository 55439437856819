import { FormValidationService } from './../../../../../general/services/form-validation/form-validation.service';
import store from '@/core/store';
import { DirtyStateService } from '@/general/services/dirty-state/dirty-state.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Locale, Module } from 'platform-unit2-api';
import { ClientsRestService, Client, UpdateClientRequest } from 'platform-unit2-api/clients';

export class WorkspaceSettingsService {
  public ts: TranslationService;
  public dirtyStateService: DirtyStateService<Client>;
  public toastService: ToastService;
  public formValidationService: FormValidationService;
  public workspace?: Client = undefined;
  public isLoading = false;
  public isSaving = false;

  private _clientRestService: ClientsRestService;

  public constructor() {
    this._clientRestService = new ClientsRestService();
    this.dirtyStateService = new DirtyStateService<Client>();
    this.toastService = ToastService.getInstance();
    this.formValidationService = new FormValidationService();
    this.ts = new TranslationService('supplier', 'workspace-settings');
  }

  public async fetchData() {
    this.isLoading = true;
    if (store.getters['users/currentUser']?.workspace?.id) {
      this.workspace = await this._clientRestService.get(
        store.getters['users/currentUser'].workspace.id,
      );
      this.dirtyStateService.changeInitialData(this.workspace);
      this.dirtyStateService.changeDataToCompare(this.workspace);
    }

    this.isLoading = false;
  }

  public async saveWorkspace() {
    this.formValidationService.resetErrors();
    try {
      this.isSaving = true;
      if (!this.workspace) {
        return;
      }

      const client: UpdateClientRequest = {
        id: this.workspace.id,
        name: this.workspace.name ?? '',
        general_contact_id: this.workspace.general_contact
          ? this.workspace.general_contact.id
          : undefined,
        financial_contact_id: this.workspace.financial_contact
          ? this.workspace.financial_contact.id
          : undefined,
        thumbnail_id: this.workspace.thumbnail?.id ?? null,
        locale_ids: this.workspace.locales?.map((locale: Locale) => locale.id) ?? [],
        module_ids: this.workspace.modules?.map((module: Module) => module.id) ?? [],
        organisation_id: this.workspace.organisation_id ?? 0,
      };

      await this._clientRestService.update(this.workspace!.id, client);

      this.toastService.displaySuccessToast(this.ts.updateSuccess());
    } catch (err) {
      this.formValidationService.handleError(err, () => {
        this.toastService.displayErrorToast(this.ts.updateFailed());
      });
    } finally {
      this.isSaving = false;
      this.dirtyStateService.changeInitialData(this.workspace!);
    }
  }
}
