<script lang="ts" setup>
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { assetViewServiceKey } from '../../keys/asset-view-service.key';

const service = useService(assetViewServiceKey, AssetViewService);

// Services
const ts = new TranslationService('supplier', 'media');
</script>

<template>
  <div v-if="service != null" class="pup-m-4 w-full">
    <p class="font-bold mb-3">
      {{
        ts.tModule('import_assets.match_description', {
          params: {
            amountUploads: service.importAssetsModal.partialObject.upload_length ?? 0,
            amountProducts: service.importAssetsModal.partialObject.product_upload_length ?? 0,
          },
        })
      }}
    </p>

    <div class="field-checkbox">
      <Checkbox
        v-model="service.importAssetsModal.partialObject.overwrite"
        input-id="settings-view-overwrite"
        :binary="true"
      />
      <label
        v-tooltip.right="{ value: ts.tModule('import_assets.overwrite_tooltip'), class: 'text-sm' }"
        for="settings-view-overwrite"
        >{{ ts.tModule('import_assets.existing_files') }}</label
      >
    </div>

    <div class="field-checkbox">
      <Checkbox
        v-model="service.importAssetsModal.partialObject.update_variants"
        :binary="true"
        input-id="settings-view-variant"
      />
      <label
        v-tooltip.right="{
          value: ts.tModule('import_assets.add_to_variants.tooltip'),
          class: 'text-sm',
        }"
        for="settings-view-variant"
        >{{ ts.tModule('import_assets.add_to_variants.title') }}</label
      >
    </div>
  </div>
</template>
