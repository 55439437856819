<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import OverViewDialog from '@/general/ui/components/over-view/over-view-dialog.vue';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import { inject } from 'vue';
import { useSupplierImportMappingService } from '../../ts/useImportMappingService';

const ts = inject(tsKey)!;
const service = useSupplierImportMappingService();
</script>

<template>
  <OverViewDialog :service="service" size="medium">
    <template #form="{ data, isCreating }">
      <p class="mb-3">{{ ts.tModule('select_a_module') }}</p>
      <div class="flex flex-column gap-2 mb-3">
        <label>{{ ts.tGlobal('name') }}</label>
        <InputText
          v-model="data.name"
          class="w-full"
          :class="{ 'p-invalid': service.hasError('name') }"
        />
        <small
          v-if="service.hasError('name')"
          :class="{ 'p-error block': service.hasError('name') }"
          class="hidden"
          >{{ service.fieldErrorMessage('name').toString() }}</small
        >
      </div>
      <DatamodelSelect v-if="isCreating" v-model="data.datamodel" />
    </template>
    <template #skeleton>
      <div>
        <Skeleton height="2rem" width="25rem" class="mb-5" />
        <div class="mb-5">
          <Skeleton width="10rem" class="mb-2" />
          <Skeleton height="2.25rem" class="p-mb-2" />
        </div>
      </div>
    </template>
  </OverViewDialog>
</template>
