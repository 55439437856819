<script setup lang="ts">
import { inject } from 'vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';
import ODMs from './odms.vue';
import UserDatamodel from './user-datamodel.vue';

const service = inject(datamodelAttributeServiceKey)!;
</script>

<template>
  <div v-if="!service.loadingUpdateDatamodelDialog" class="flex" :style="{ height: '92%' }">
    <!-- Predefined datamodel -->
    <ODMs />

    <!-- Divider -->
    <div :style="{ width: '6%' }">
      <Divider
        :pt="{
          content: {
            style: { height: '40px', width: '40px' },
            class: 'border-circle flex justify-content-center align-items-center',
          },
        }"
        layout="vertical"
      >
        <i class="align-content-center flex mdi mdi-arrow-right-thin text-3xl text-gray-600"></i
      ></Divider>
    </div>

    <!--   User's datamodel -->
    <UserDatamodel />
  </div>
</template>
