import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Import Mapping Item',
  subTitle: 'Here you can create a new Import Mapping Item.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Import Mapping Item',
};

const update: TranslationObjectAction = {
  title: 'Edit Import Mapping Item',
  subTitle: 'Here you can edit a Import Mapping Item.',
};

const translationObject: TranslationObject = {
  title: 'Import Mapping Item | Import Mapping Items',
  placeholder: 'Select Import Mapping Item | Select Import Mapping Items',

  emptyStateSubtitle:
    'Start by creating your first data model with attributes to create your first import mapping item.',
  emptyStateButton: 'Go to datamodels',

  noSearchResults: 'No import mapping items found',
  noSearchResultsSubtitle:
    "Can't find the attribute you are looking for? Make sure it exists in the data model.",

  create: create,
  delete: deleteTranslation,
  update: update,

  syncSuccess: 'Import mapping items were successfully synced',

  emptyStateTitle: 'No import mapping items found',
  emptyStateSubTitle: 'Start by creating your first import mapping item.',
  newImportMappingSubtitle:
    'Pick a suitable name then the data model for which you want to create the import mapping item.',
  modals: {
    delete_mapping: 'Are you sure you want to delete this import mapping item?',
  },
  import_source: 'Import source',
  datamodel_attributes: 'Target data model attribute',
  map_the_attributes: 'Map the attributes',
  map_the_attributes_subtitles: 'Map the attributes from your excel to the data model attributes.',
  originalAttributeName: 'Attribute name',
  sourceArgument: 'Source argument...',
  expandAll: 'Expand all',
  collapseAll: 'Collapse all',
  autoMap: 'Automap',
  modalTitle: 'Automap attributes',
  headerRow: 'Enter the row number containing the headers',
  applySuggestionTo: 'Apply automapping to',
  emptyAttributes: 'Empty attributes',
  allAttributes: 'All attributes',
  selectFile: 'Upload import file',
};

const translation: Translation = {
  importMappingItems: translationObject,
};

export default translation;
