<script setup lang="ts">
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import UserSelect from '@/general/ui/components/selects/user-select.vue';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import ThumbnailUploader from '@/general/ui/components/thumbnail-uploader.vue';
import { onMounted, ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { WorkspaceSettingsService } from '../services/workspaces-settings.service';

const service = ref(new WorkspaceSettingsService());

onBeforeRouteLeave((_, __, next) => {
  service.value.dirtyStateService.showDirtyDialog(next, undefined, next);
});

onMounted(() => service.value.fetchData());
</script>

<template>
  <section>
    <LoadingIndicator v-if="service.isLoading" />
    <section v-else class="m-4">
      <Card v-if="service.workspace" class="text-left">
        <template #content>
          <section class="flex flex-column gap-3">
            <div class="flex flex-column gap-2">
              <label for="logo">{{ service.ts.tModule('logo') }}</label>
              <div class="align-items-center flex formgroup-inline">
                <ThumbnailUploader
                  :is-avatar="service.workspace.thumbnail == null"
                  :username="service.workspace.name"
                  :thumbnail="service.workspace.thumbnail"
                  @uploaded="(value) => (service.workspace!.thumbnail = value)"
                />
              </div>
            </div>

            <div class="flex flex-column gap-2">
              <label for="name">{{ service.ts.tModule('name') }}</label>
              <InputText
                v-model="service.workspace.name"
                class="w-full"
                :class="{ 'p-invalid': service.formValidationService.hasError('name') }"
                type="text"
                :disabled="true"
              />
              <small
                v-if="service.formValidationService.hasError('name')"
                :class="{ 'p-error block': service.formValidationService.hasError('name') }"
                class="hidden"
                >{{ service.formValidationService.fieldErrorMessage('name').toString() }}</small
              >
            </div>
            <div class="flex flex-column gap-2">
              <label for="email">{{ service.ts.tModule('generalContact') }}</label>
              <UserSelect
                v-model="service.workspace.general_contact"
                :class="{
                  'p-invalid': service.formValidationService.hasError('general_contact_id'),
                }"
                hide-label
              />
              <small
                v-if="service.formValidationService.hasError('general_contact_id')"
                :class="{
                  'p-error block': service.formValidationService.hasError('general_contact_id'),
                }"
                class="hidden"
                >{{
                  service.formValidationService.fieldErrorMessage('general_contact_id').toString()
                }}</small
              >
            </div>

            <div class="flex flex-column gap-2">
              <label for="email">{{ service.ts.tModule('financialContact') }}</label>
              <UserSelect
                v-model="service.workspace.financial_contact"
                :class="{
                  'p-invalid': service.formValidationService.hasError('financial_contact_id'),
                }"
                hide-label
              />
              <small
                v-if="service.formValidationService.hasError('financial_contact_id')"
                :class="{
                  'p-error block': service.formValidationService.hasError('financial_contact_id'),
                }"
                class="hidden"
                >{{
                  service.formValidationService.fieldErrorMessage('financial_contact_id').toString()
                }}</small
              >
            </div>
            <div class="flex flex-column gap-2">
              <ModuleSelect
                v-model="service.workspace.modules"
                :class="{ 'p-invalid': service.formValidationService.hasError('module_ids') }"
                :disabled="true"
                multiselect
              />
              <small
                v-if="service.formValidationService.hasError('module_ids')"
                :class="{ 'p-error block': service.formValidationService.hasError('module_ids') }"
                class="hidden"
                >{{
                  service.formValidationService.fieldErrorMessage('module_ids').toString()
                }}</small
              >
            </div>
            <div class="flex flex-column gap-2">
              <label>{{ service.ts.tGlobal('languages') }}</label>
              <LocaleSelect
                v-model="service.workspace.locales"
                multiselect
                hide-label
                @fetch-finished="service.dirtyStateService.changeInitialData(service.workspace)"
              />
            </div>
          </section>
        </template>
      </Card>
      <Button
        :disabled="service.isSaving || !service.dirtyStateService.isTouched()"
        :label="service.ts.tModule('save')"
        :icon="service.isSaving ? 'mdi mdi-loading pi-spin' : ''"
        icon-pos="right"
        class="mt-4"
        @click="service.saveWorkspace()"
      />
    </section>
    <ConfirmDialog group="dirty-dialog" />
  </section>
</template>
