<script setup lang="ts">
import { modulesServiceKey } from 'supplier/modules/modules/ts/keys/modules.keys';
import { inject } from 'vue';

const service = inject(modulesServiceKey)!;
</script>
<template>
  <div v-for="(_, dynamicKey) in service.credentialValues.dynamic_settings" :key="dynamicKey">
    <label
      v-if="service.findFieldByFieldName(dynamicKey.toString())?.type != 'button'"
      class="block mb-1"
      >{{ service.getFieldLabel(dynamicKey.toString()) }}
      <i
        v-tooltip.top="service.findFieldByFieldName(dynamicKey.toString())?.hint ?? ''"
        class="mdi mdi-help-circle-outline"
      />
    </label>
    <div
      v-if="service.findFieldByFieldName(dynamicKey.toString())?.type == 'boolean'"
      class="flex mb-3 p-input-icon-right"
    >
      <ToggleSwitch
        v-model="service.credentialValues.dynamic_settings[dynamicKey.toString()]"
        class="mt-1"
      />
    </div>
    <div
      v-else-if="service.findFieldByFieldName(dynamicKey.toString())?.type == 'select'"
      class="flex mb-3 p-input-icon-right"
    >
      <Select
        v-model="service.credentialValues.dynamic_settings[dynamicKey.toString()]"
        class="w-full"
        :options="service.findFieldByFieldName(dynamicKey.toString())?.options"
        option-label="label"
        option-value="value"
      />
    </div>
    <div
      v-else-if="service.findFieldByFieldName(dynamicKey.toString())?.type == 'button'"
      class="flex mb-3 p-input-icon-right"
    >
      <Button
        v-if="service.findFieldByFieldName(dynamicKey.toString())?.integration === 'amazon'"
        class="amazon p-0"
        aria-label="Amazon"
        @click="service.goToEndpoint(dynamicKey.toString())"
      >
        <i class="pi pi-amazon px-2" />
        <span class="px-3">{{ dynamicKey }}</span>
      </Button>
      <Button
        v-else
        :label="dynamicKey.toString()"
        @click="service.goToEndpoint(dynamicKey.toString())"
      />
    </div>

    <span v-else class="flex p-input-icon-right">
      <InputText
        v-if="service.isEncrypted(dynamicKey.toString()) == undefined"
        v-model="service.credentialValues.dynamic_settings[dynamicKey.toString()]"
        type="text"
        :placeholder="
          service.ts.tModule('credential_edit.new', {
            params: { field: service.getFieldLabel(dynamicKey.toString()) },
          })
        "
        class="mb-3 w-full"
      />
      <Password
        v-if="service.isEncrypted(dynamicKey.toString()) != undefined"
        v-model="service.credentialValues.dynamic_settings[dynamicKey.toString()]"
        toggle-mask
        :feedback="false"
        :placeholder="
          service.ts.tModule('credential_edit.new', {
            params: { field: service.getFieldLabel(dynamicKey.toString()) },
          })
        "
        class="mb-3 w-full"
      />
    </span>
  </div>
</template>
<style lang="scss" scoped>
.p-button.amazon {
  background: var(--yellow-500) !important;
  background-size: 200% 100% !important;
  color: #000;
  border-color: var(--yellow-500);
  line-height: 2rem !important;
}
.p-button.amazon:hover {
  background: var(--yellow-300) !important;

  border-color: var(--yellow-500) !important;
  color: #000 !important;
}

.p-button.amazon:focus {
  box-shadow: 0 0 0 1px var(--yellow-200) !important;
}

// :pt pcInput prop is not working in primevue
.p-password :deep(.p-password-input) {
  width: 100%;
}
</style>
