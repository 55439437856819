r
<script setup lang="ts">
import { StandardSingleSideImageClass } from '@/api/v1/content-story/ts/classes/single-side-image.class';
import { ImagePosition } from '@/api/v1/content-story/ts/enums/image-position.enum';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Upload as UploadFile } from 'platform-unit2-api/uploads';
import { inject } from 'vue';
import { storyBuilderServiceKey } from '../../../ts/helpers/story-manager-keys';
import BaseContentComponent from '../content-components/base-content-component.vue';
import ContentHeadline from '../content-components/content-headline.vue';
import ContentImage from '../content-components/content-image.vue';
import ContentTextarea from '../content-components/content-textarea.vue';
/** Props */
interface Props {
  uuid: string;
}

const props = defineProps<Props>();

// inject / provide
const storyService = inject(storyBuilderServiceKey);

// const / refs
const storyModule = storyService?.value.getModule<StandardSingleSideImageClass>(props.uuid);
const ts = new TranslationService('supplier', 'storyManager');
</script>

<template>
  <BaseContentComponent custom-height="50%" :uuid="uuid">
    <template #content>
      <div class="flex gap-3 h-full justify-content-around p-3">
        <div v-if="storyModule?.data.imagePositionType === 'LEFT'" class="flex flex-column w-3">
          <KeepAlive>
            <ContentImage
              :is-upload-set="storyModule?.hasUploadSet()"
              :upload-url="storyModule?.getUploadUrl()"
              :minimal-image-size="storyModule?.minimumImageSize"
              :accepted-file-types="storyModule?.acceptedFileTypes"
              @uploaded-image="(event: UploadFile) => storyModule?.setUploadData(event)"
            />
          </KeepAlive>
          <ContentHeadline
            :custom-place-holder="ts.tModule('storyBuilder.customHeadline.imageKeyWords')"
            :custom-class="'mt-3'"
            :headline="storyModule?.getAltText()"
            @headline-value="storyModule?.setAltText($event)"
          />
          <Button
            plain
            text
            rounded
            icon="mdi mdi-chevron-right"
            icon-pos="right"
            label="right"
            @click="storyModule?.setImagePosition(ImagePosition.RIGHT)"
          />
        </div>
        <div class="flex flex-column w-9">
          <ContentHeadline
            :headline="storyModule?.data.block.headline?.value"
            @headline-value="storyModule?.setHeadline($event)"
          />
          <ContentTextarea
            :additional-container-style="'80%'"
            :textbox="storyModule?.data.block.body?.value"
            @html-text-area="storyModule?.setTextArea($event)"
          />
        </div>
        <div v-if="storyModule?.data.imagePositionType === 'RIGHT'" class="flex flex-column w-3">
          <KeepAlive>
            <ContentImage
              :is-upload-set="storyModule?.hasUploadSet()"
              :upload-url="storyModule?.getUploadUrl()"
              :minimal-image-size="storyModule?.minimumImageSize"
              :accepted-file-types="storyModule?.acceptedFileTypes"
              @uploaded-image="(event: UploadFile) => storyModule?.setUploadData(event)"
            />
          </KeepAlive>
          <ContentHeadline
            :custom-place-holder="ts.tModule('storyBuilder.customHeadline.imageKeyWords')"
            :custom-class="'mt-3'"
            :headline="storyModule?.getAltText()"
            @headline-value="storyModule?.setAltText($event)"
          />
          <Button
            plain
            text
            rounded
            icon="mdi mdi-chevron-left"
            label="left"
            @click="storyModule?.setImagePosition(ImagePosition.LEFT)"
          />
        </div>
      </div>
    </template>
  </BaseContentComponent>
</template>

<style lang="scss" scoped></style>
