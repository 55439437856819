<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import { inject } from 'vue';
import { deletedProductsServiceKey } from '../../ts/keys/products.keys';

const ts = inject(tsKey)!;
const service = inject(deletedProductsServiceKey)!;
</script>
<template>
  <ConfirmDialog v-bind="{ ...$attrs }" class="dialog-size-small">
    <template #message>
      <div class="bg-white flex flex-column gap-5 my-3 surface-border">
        <p v-html="ts.tModule('deletedProducts.restoreMasterDataMessage')" />
        <div class="align-items-center flex">
          <Checkbox
            v-model="service.restoreVariantsCheckbox"
            binary
            input-id="restore_variants"
            class="mr-3"
          />
          <label for="restore_variants">{{
            ts.tModule('deletedProducts.restoreMasterDataInfo')
          }}</label>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>
