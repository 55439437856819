<script setup lang="ts">
import DragAndDrop from '@/general/ui/components/drag-and-drop.vue';

import { inject } from 'vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';
import DatamodelEmptyState from './datamodel-empty-state.vue';
import UserDatamodelActions from './user-datamodel-actions.vue';
import UserDatamodelAttributeBulkDelete from './user-datamodel-attribute-bulk-delete.vue';
import UserDatamodelAttributeEmptyState from './user-datamodel-attribute-empty-state.vue';
import UserDatamodelAttribute from './user-datamodel-attribute.vue';
import UserDatamodelChosenDatamodelHeader from './user-datamodel-chosen-datamodel-header.vue';
import UserDatamodelRemoveMessage from './user-datamodel-remove-message.vue';

const service = inject(datamodelAttributeServiceKey)!;
const ts = service.ts;
</script>

<template>
  <div class="flex flex-column mt-3" :style="{ width: '47%' }">
    <span class="font-bold text-lg">{{ ts.tModule('datamodel_new.title') }}</span>
    <div class="flex flex-column flex-grow-1 my-2" :style="{ minHeight: '100px' }">
      <!-- Datamodel name -->
      <UserDatamodelActions />

      <!-- divider -->
      <Divider />
      <UserDatamodelRemoveMessage />
      <!-- Attributes -->
      <UserDatamodelAttributeBulkDelete />
      <div class="flex flex-grow-1 overflow-y-auto w-full" :style="{ 'margin-top': '10px' }">
        <div class="flex h-full place-items-center text-center w-full">
          <!-- No attributes added selected -->
          <UserDatamodelAttributeEmptyState
            v-if="service.userAttributes && service.userAttributes.length === 0"
          />

          <!-- No attributes found in search query -->
          <DatamodelEmptyState
            v-else-if="service.getOnlyUsedDatemodels().length === 0"
            icon="mdi mdi-playlist-plus"
            :header="ts.tModule('datamodel_attributes.no_attributes_found')"
            :subheader="ts.tModule('datamodel_attributes.no_attributes_found_description')"
          />
          <!-- Show the attributes -->

          <ScrollPanel v-else class="user-datamodel-panel w-full">
            <div
              v-for="(dm, index) of service.getOnlyUsedDatemodels()"
              :key="dm.id + index"
              class="font-bold mb-3 text-left w-full"
            >
              <UserDatamodelChosenDatamodelHeader :datamodel="dm" />

              <DragAndDrop
                :list-items="service.getDatamodelAttributes(dm)"
                item-classes="flex justify-content-between align-items-center border-1 border-gray-100 w-full h-full p-2 pl-3 my-2 background-user-attribute"
                :sortable-options="{
                  ghostClass: 'active-attribute',
                  direction: 'vertical',
                  disabled: service.searchKey !== '',
                }"
              >
                <template #default="{ item }">
                  <UserDatamodelAttribute
                    :key="dm.id + item.id"
                    :attribute="item"
                    :datamodel="service.datamodel"
                    :override="service.overrides.get(item.id)"
                    @remove="service.removeAttribute(item)"
                    @created="(config) => service.overrides.set(item.id, config)"
                    @deleted="() => service.overrides.delete(item.id)"
                  />
                </template>
              </DragAndDrop>
            </div>
          </ScrollPanel>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.background-user-attribute {
  background-color: white;
}
.user-datamodel-panel .active-attribute {
  position: relative;
  animation-name: fadeIn;
  animation-duration: 1s;
  @keyframes fadeIn {
    from {
      background-color: green !important;
      opacity: 0;
    }
    to {
      background-color: white !important;
      opacity: 1;
    }
  }
}
</style>
