<script setup lang="ts">
import { productAttributeValidationServiceKey } from '@/general/services/attribute-fields/service-keys';
import { TranslationService } from '@/general/services/translations/translation.service';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import VariantValidationModelSelect from '@/general/ui/components/selects/variant-validation-model-select.vue';
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import { ValidationModel } from 'platform-unit2-api/validation-models';
import ValidationEmptyState from 'supplier/modules/products/components/validation-rules/product-details-validation-empty-state.vue';
import ProductDetailsValidationList from 'supplier/modules/products/components/validation-rules/product-details-validation-list.vue';
import ValidationModelsEmptyState from 'supplier/modules/products/components/validation-rules/validation-models-empty-state.vue';
import { inject, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const ts = new TranslationService('supplier', 'products');
const intermediateSelectedValidationModel = ref<ValidationModel | undefined>();
const route = useRoute();

const productAttributesValidationService = inject(productAttributeValidationServiceKey)!;
const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => productAttributesValidationService.value.toggleValidationPanel(),
});

const applyButton = new CreateButton({
  label: ts.tGlobal('apply'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => intermediateSelectedValidationModel.value != null,
  onClick: async () => {
    await productAttributesValidationService.value.onApplyClick(
      intermediateSelectedValidationModel.value,
    );

    intermediateSelectedValidationModel.value = undefined;
  },
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, applyButton],
});

watch(
  () => route.params,
  () => {
    productAttributesValidationService.value.validationPanelVisible = false;
    productAttributesValidationService.value.selectedValidationModel = undefined;
  },
);
</script>
<template>
  <div
    class="container"
    :class="{
      open: productAttributesValidationService.validationPanelVisible,
    }"
  >
    <div class="overflow-y-scroll wrapper">
      <SidebarSkeleton v-if="productAttributesValidationService.loading" class="m-4" />
      <div v-else class="h-full p-4">
        <!-- Header -->
        <div class="flex header justify-content-between">
          <h3 class="my-3">
            <strong>{{ ts.tModule('validations') }}</strong>
          </h3>

          <Button
            text
            rounded
            icon="mdi mdi-close"
            class="text-gray-800 text-xl"
            @click="productAttributesValidationService.toggleValidationPanel()"
          />
        </div>
        <ValidationModelsEmptyState
          v-if="productAttributesValidationService.validationModels.length == 0"
          :ts="ts"
        />
        <ValidationEmptyState
          v-else-if="
            productAttributesValidationService.validationModels.length > 0 &&
            productAttributesValidationService.selectedValidationModel == null
          "
          :ts="ts"
        />
        <ProductDetailsValidationList v-else />
      </div>
    </div>
    <BaseDialog
      :title="ts.tModule('validationModels.dialogTitle')"
      :subtitle="
        ts.tModule('validationModels.dialogSubtitle', {
          params: {
            moduleName: productAttributesValidationService.selectedModule?.name ?? 'Master Data',
          },
        })
      "
      :visible="productAttributesValidationService.validationModelsSelectOpenCondition"
      show-footer
      :buttons-options="buttonsOptions"
      @update:visible="productAttributesValidationService.toggleValidationPanel()"
    >
      <VariantValidationModelSelect
        v-model="intermediateSelectedValidationModel"
        :module-id="productAttributesValidationService.selectedModule?.id"
      />
    </BaseDialog>
  </div>
</template>
<style scoped>
.container {
  transition: all 0.2s;
  width: 0;

  &.open {
    width: 500px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 500px;
  background-color: #ffffff;
  box-shadow: 0 0 10rgb (231, 58, 58) gba(0, 0, 0, 0.1);
}
</style>
