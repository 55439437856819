<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { isPositive } from '@/general/utils/isPositive';
import { ActionRestService } from 'platform-unit2-api/actions';
import { Comment, CommentsRestService, CreateCommentRequest } from 'platform-unit2-api/comments';
import SingleComment from 'retailer/modules/comments/components/single-comment.vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

/** Props */
export interface Props {
  taskId?: number;
  comments?: Comment[];
  hasTitle: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  comments: () => [],
  hasTitle: true,
  taskId: undefined,
});

/** Emits */
const emits = defineEmits<{ (e: 'refresh'): void }>();

/** Composables */
const route = useRoute();

/** Services */
const ts = new TranslationService('retailer', 'comments');
const toastService = ToastService.getInstance();
const commentApi = new CommentsRestService();
const actionApi = new ActionRestService();

/** Constants */
const loading = ref(true);
const reply = ref('');
const searchedComments = ref<Comment[]>([]);
const total = computed(() => searchedComments.value.length);

const loadComments = async (): Promise<void> => {
  loading.value = true;
  try {
    if (props.taskId) {
      searchedComments.value = await actionApi.getTaskComments(props.taskId);
    }
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

const submitComment = async () => {
  try {
    if (isPositive(route.params.id)) {
      const createComment: CreateCommentRequest = {
        action_id: Number(route.params.id),
        comment: reply.value,
      };

      await commentApi.post(createComment);

      toastService.displaySuccessToast(ts.createSuccess());
      reply.value = '';
      await loadComments();
      emits('refresh');
    }
  } catch (err) {
    toastService.displayErrorToast(ts.createFailed());
  }
};

/** Lifecycle */
onMounted(() => {
  if (props.comments.length) {
    searchedComments.value = props.comments;
  } else {
    loadComments();
  }
});
</script>
<template>
  <Card class="border-1 border-100 shadow-none w-full">
    <template v-if="hasTitle" #title>{{ ts.titlePlural }} </template>
    <template #content>
      <div v-if="loading">
        <Skeleton height="2.5rem" width="auto" class="mb-4" style="margin-left: 20rem" />
        <Skeleton height="2.5rem" width="auto" class="mb-4" style="margin-right: 20rem" />
        <Skeleton height="2.5rem" width="auto" class="mb-4" style="margin-right: 20rem" />
        <Skeleton height="2.5rem" width="auto" class="mb-4" style="margin-left: 20rem" />
        <Skeleton height="2.5rem" width="auto" class="mb-4" style="margin-right: 20rem" />
      </div>

      <div v-else-if="!(total || loading)">
        <Message severity="info" :closable="false">
          {{ ts.tModule('empty') }}
        </Message>
      </div>
      <div v-else>
        <ScrollPanel style="width: 100%; height: 25rem">
          <SingleComment v-for="comment in searchedComments" :key="comment.id" :comment="comment" />
        </ScrollPanel>
      </div>
    </template>

    <template #footer>
      <!-- FIX ME: Create a seperate component for this -->
      <div class="flex flex-column ml-2">
        <Textarea
          v-model="reply"
          type="text"
          rows="3"
          :placeholder="ts.tModule('commentInput.placeholder')"
        />
        <div class="mt-2 text-left">
          <Button
            class="border-round-sm p-button-outlined"
            :label="ts.tModule('commentInput.suffix')"
            :disabled="reply.length < 3"
            @click.stop.prevent="submitComment"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<style lang="scss" scoped>
.p-scrollpanel :deep(.p-scrollpanel-wrapper) {
  z-index: 0;
}
</style>
