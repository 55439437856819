<script setup lang="ts">
// import { tsKey } from '@/general/services/translations/translation.key';
import { TranslationService } from '@/general/services/translations/translation.service';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { formatDate } from '@/general/utils/format-date';
import { PromptGroup } from 'platform-unit2-api/prompts';
import { inject, ref } from 'vue';
import { promptServiceKey } from '../../ts/keys/supplier-ai.keys';

// const ts = inject(tsKey);
const service = inject(promptServiceKey)!;
const ts = new TranslationService('supplier', 'ai-enhancements');
const expandedRows = ref([]);
</script>

<template>
  <DataTable
    v-model:expanded-rows="expandedRows"
    class="mb-5"
    :value="service?.prompts"
    :loading="service?.isLoadingOverView"
  >
    <Column :expander="true" header-style="width: 3rem" />

    <Column field="prompt" :header="'prompt'"> </Column>

    <template #expansion="slotProps: { data: PromptGroup }">
      <div v-if="slotProps.data.data.find((res) => res.type === 'chat')" class="mb-3">
        <h4>({{ slotProps.data.data.length }}) Results:</h4>
        <div v-for="({ chat, id }, index) in service.getChatPrompts(slotProps.data)" :key="id">
          <h5>{{ index + 1 }}. Session {{ formatDate(chat.created_at) }} :</h5>
          <ul class="ml-5">
            <li
              v-for="{ message, response, id: messageId } in chat.messages"
              :key="messageId"
              class="py-1"
            >
              <p>
                <label class="font-bold"> You: </label>
                {{ message }}
              </p>
              <p class="ml-2 mt-2"><label class="font-bold">AI: </label>: {{ response }}</p>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="slotProps.data.data.find((res) => res.type === 'generatedPhotos')" class="mb-3">
        <h4 class="mb-3">Image's</h4>
        <div v-for="({ uploads, id }, index) in service.getUploadPrompts(slotProps.data)" :key="id">
          <label class="font-bold"
            >{{ index + 1 }} Generated images
            {{ formatDate(new Date(uploads[0].created_at)) }}:</label
          >
          <div class="flex">
            <Image
              v-for="{ id: upload_id, public_url, filename } in uploads"
              :key="upload_id"
              image-class="w-10rem h-10rem  mr-3 mt-3"
              :alt="filename"
              :src="public_url"
              preview
            />
          </div>
        </div>
      </div>

      <!-- endregion-->
    </template>
    <!-- empty table message-->
    <template #empty>
      <EmptyState
        :translation-service="ts"
        :empty-state-title="'AI generation prompts'"
        :empty-state-subtitle="'No prompts found, start using the AI generation.'"
        :button-visible="false"
        :icon-name="'master-data'"
      ></EmptyState>
    </template>
  </DataTable>
</template>

<style scoped>
ul {
  list-style-type: decimal;
}
</style>
