<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import CategorySelect from '@/general/ui/components/selects/category-select.vue';
import { Category, CategoryRestService } from 'platform-unit2-api/categories';
import { ref, watch } from 'vue';

/** Props */
const props = defineProps<{
  ids: Array<number>;
  isActive: boolean;
}>();

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'products');
const tsCategories = new TranslationService('retailer', 'categories');
const toastService = ToastService.getInstance();
const categoryAPI = new CategoryRestService();

/** Consts */
const selectedCategory = ref<Category>();

const update = async () => {
  try {
    if (selectedCategory.value != null) {
      buttonsOptions.value.saving = true;
      await categoryAPI.bulkAttachCategory(props.ids, [selectedCategory.value?.id]);
      toastService.displaySuccessToast(ts.updateSuccess());
    }
  } catch (err) {
    toastService.displayErrorToast(ts.tGlobal('unknown'));
  } finally {
    buttonsOptions.value.saving = false;
    emit('hide');
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const attachButton = new CreateButton({
  label: ts.tGlobal('attach'),
  position: CrudButtonPosition.RIGHT,
  onClick: update,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, attachButton],
});

watch(
  () => props.isActive,
  () => {
    selectedCategory.value = {} as Category;
  },
);
</script>
<template>
  <BaseDialog
    :title="tsCategories.tModule('bulkAttachTitle')"
    :visible="isActive"
    :buttons-options="buttonsOptions"
    class="name-dialog"
    size="medium"
    @update:visible="emit('hide')"
  >
    <Message severity="info" :closable="false" class="my-5">
      {{ tsCategories.tModule('bulkAttachDescription') }}
    </Message>
    <!-- #region: Category-product field -->
    <div>
      <div class="flex justify-content-center">
        <CategorySelect v-model="selectedCategory" />
      </div>
    </div>
    <!-- #endregion -->
  </BaseDialog>
</template>
