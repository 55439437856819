<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { SupplierImportMappingItemsViewService } from '../ts/services/supplier-import-mapping-items-view.service';
import { ref } from 'vue';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { FileUploadUploaderEvent } from 'primevue/fileupload';
import { useService } from '@/general/composables/useService';
import { importMappingItemsServiceKey } from 'supplier/modules/import-mapping-items/ts/keys/import-mapping-items.keys';

const service = useService(importMappingItemsServiceKey, SupplierImportMappingItemsViewService)!;

const ts = new TranslationService('supplier', 'importMappingItems');

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => service.toggleAutoMapModal(),
});

const autoMapButton = new CreateButton({
  label: ts.tModule('autoMap'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    fileUpload.value?.upload();
  },
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, autoMapButton],
});

const fileUpload = ref();
</script>
<template>
  <BaseDialog
    secondary
    :visible="service.autoMapModalVisible"
    :title="ts.tModule('modalTitle')"
    :buttons-options="buttonsOptions"
    @update:visible="service.toggleAutoMapModal()"
  >
    <!-- Import file -->
    <div class="align-items-center field mb-4">
      <label class="block mb-2">{{ ts.tModule('selectFile') }}</label>

      <div class="flex">
        <FileUpload
          ref="fileUpload"
          class="flex flex-nowrap"
          custom-upload
          mode="basic"
          :choose-label="ts.tGlobal('upload')"
          :pt="{
            chooseButton: {
              class: 'hidden',
            },
          }"
          @uploader="(e: FileUploadUploaderEvent) => service.startAutoMap(e)"
        />
      </div>
    </div>

    <!-- Header row-->
    <div class="align-items-center field mb-4">
      <label class="block mb-2">{{ ts.tModule('headerRow') }}</label>

      <InputNumber v-model="service.headerRow" show-buttons />
    </div>

    <!-- suggestion target -->
    <div class="field mb-4">
      <label class="mb-1">{{ ts.tModule('applySuggestionTo') }}</label>

      <div class="flex py-2">
        <div class="flex gap-2 items-center mr-4">
          <RadioButton v-model="service.suggestionTarget" name="empty" value="empty" />
          <label for="empty">{{ ts.tModule('emptyAttributes') }}</label>
        </div>
        <div class="flex gap-2 items-center">
          <RadioButton v-model="service.suggestionTarget" name="all" value="all" />
          <label for="all">{{ ts.tModule('allAttributes') }}</label>
        </div>
      </div>
    </div>
  </BaseDialog>
</template>
