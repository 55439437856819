import { RefetchService } from '@/general/services/overview-service/refetch.service';
import { useRoute, useRouter } from 'vue-router';
import {
  ImportMapping,
  ImportMappingItem,
  ImportMappingItemResponse,
  UpdateImportMappingItemRequest,
  ImportMappingsRestService,
  ImportMappingItemRestService,
  ImportMappingAutoMapItem,
} from 'platform-unit2-api/import-mappings';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { DirtyStateService } from '@/general/services/dirty-state/dirty-state.service';
import { FormValidationService } from '@/general/services/form-validation/form-validation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { OverviewBase } from '../../interfaces/overview-base.interface';
import { FileUploadUploaderEvent } from 'primevue/fileupload';

export class ImportMappingItemsViewService extends RefetchService implements OverviewBase {
  public parent?: ImportMapping;
  public dirtyState: DirtyStateService<ImportMappingItem[]>;
  public isLoadingParent = true;
  public deleted = false;

  protected _route = useRoute();
  protected _router = useRouter();
  protected _mappingItemRestService = new ImportMappingItemRestService();
  protected _ts: TranslationService;
  protected _importMappingItems: ImportMappingItem[] = [];
  protected _editingMappingItems: ImportMappingItem[] = [];
  protected _formValidationService: FormValidationService;
  protected _isLoadingOverview = true;
  protected _isInitialized = false;
  protected _toastService: ToastService;
  protected _confirmService: ConfirmService;
  protected _importMappingRestService: ImportMappingsRestService;

  public get isInitialized(): boolean {
    return this._isInitialized;
  }

  public get isLoadingCrudComponent(): boolean {
    return false;
  }

  public get isLoadingOverView(): boolean {
    return this._isLoadingOverview;
  }

  public hasError = (field: string): boolean => this._formValidationService.hasError(field);

  public updateMappingItem(mappingItem: ImportMappingItem, value: string) {
    mappingItem.argument = value === '' ? null : value;
    //find if it exists update it or add it
    const existingItem = this._editingMappingItems.find(
      (item) => item.attribute.id === mappingItem.attribute.id,
    );

    if (existingItem) {
      existingItem.argument = mappingItem.argument;
    } else {
      this._editingMappingItems.push(mappingItem);
    }
  }

  /**
   * Get mapping items, this.data is assigned to mappings for readabilty.
   */
  public get mappingItems(): (ImportMappingItem & {
    autoMapSuggestion?: boolean;
  })[] {
    return this._importMappingItems.map((item) => {
      const editedItem = this._editingMappingItems.find(
        (i) => i.attribute.id === item.attribute.id,
      );

      if (editedItem) {
        const editedItemFromInitialState = this.dirtyState.initialData.find(
          (i) => i.attribute.id === item.attribute.id,
        );

        if (editedItemFromInitialState?.argument === editedItem.argument) {
          this._editingMappingItems = this._editingMappingItems.filter(
            (i) => i.attribute.id !== editedItem.attribute.id,
          );
          return item;
        }

        return editedItem;
      }

      return item;
    });
  }

  public set mappingItems(value: ImportMappingItem[]) {
    this._importMappingItems = value.sort((a, b) => a.attribute.order! - b.attribute.order!);
  }

  public get isDirty(): boolean {
    return this._editingMappingItems.length > 0;
  }

  public amountOfAttributes = 0;
  public amountOfSavedItems = 0;

  constructor(ts: TranslationService) {
    super();
    this._ts = ts;

    this.dirtyState = new DirtyStateService<ImportMappingItem[]>();
    this._formValidationService = new FormValidationService();
    this._toastService = ToastService.getInstance();
    this._confirmService = new ConfirmService();
    this._importMappingRestService = new ImportMappingsRestService();

    this.refetch = this.fetchAll;
  }

  protected _resetDirtyState(force = false) {
    if (
      this.query == null ||
      (this.query === '' && this._editingMappingItems.length === 0) ||
      force
    ) {
      this.dirtyState.resetData(this.mappingItems);
    }

    const newDataToCompare =
      this.dirtyState.dataToCompare?.map((item) => {
        if (this.mappingItems.map((ei) => ei.attribute.id).includes(item.attribute.id)) {
          return this.mappingItems.find(
            (ei) => ei.attribute.id === item.attribute.id,
          ) as ImportMappingItem;
        }

        return item;
      }) ?? [];

    this.dirtyState.changeDataToCompare(newDataToCompare);
  }

  protected handleResponse = (response: ImportMappingItemResponse) => {
    this.mappingItems = response.data;
    this.amountOfAttributes = response.amount_of_attributes;
    this.amountOfSavedItems = response.amount_of_saved_items;
  };

  public fetchAll(): void {
    this._isLoadingOverview = true;

    this._mappingItemRestService
      .getImportMappingItems(+this._route.params['id'], this.query)
      .then((result) => {
        this.handleResponse(result);

        this._resetDirtyState();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
        this._importMappingItems = [];
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this._isInitialized = true;
      });
  }

  public resolveCrudComponent(): void {
    if (!isNaN(+this._route.params['id'])) {
      this.isLoadingParent = true;
      this._importMappingRestService
        .get(Number(this._route.params['id']))
        .then((res) => {
          this.parent = res;
        })
        .catch(() => {
          this._toastService.displayErrorToast(this._ts.loadFailed());
          this.parent = undefined;
        })
        .finally(() => {
          this.isLoadingParent = false;
        });
    }
  }

  protected _parseMappingItemToRequest(item: ImportMappingItem): UpdateImportMappingItemRequest {
    return {
      id: item.id,
      argument: !item.argument || item.argument === '' ? null : item.argument,
      attribute: {
        id: item.attribute.id,
      },
    };
  }

  protected _parseMappingItemsToSyncRequest() {
    return this._editingMappingItems.map(this._parseMappingItemToRequest);
  }

  public syncImportMappings() {
    this._isLoadingOverview = true;

    const request = this._parseMappingItemsToSyncRequest();

    this._mappingItemRestService
      .sync(Number(this._route.params['id']), request)
      .then((result) => {
        this.handleResponse(result);

        this._editingMappingItems = [];

        this.query = '';
        this._resetDirtyState();

        this._toastService.displaySuccessToast(this._ts.tModule('syncSuccess'));
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this.mappingResult = undefined;
      });
  }

  public delete() {
    this._confirmService.confirmDelete({
      callback: () => {
        this._isLoadingOverview = true;

        this._importMappingRestService
          .delete(+this._route.params['id'])
          .then(() => {
            this.deleted = true;
            this._router.push({ name: 'import-mappings' });
            this._toastService.displaySuccessToast(this._ts.deleteSuccess());
          })
          .catch(() => {
            this._toastService.displayErrorToast(this._ts.deleteFailed());
          })
          .finally(() => {
            this._isLoadingOverview = false;
          });
      },
      group: 'delete-dialog',
    });
  }

  //#region AutoMap

  public autoMapModalVisible = false;
  public headerRow = undefined;
  public mappingResult?: ImportMappingAutoMapItem[];
  public mappingItemsWithAutoMapSuggestion: (ImportMappingItem & {
    autoMapSuggestion?: boolean;
  })[] = [];

  public suggestionTarget = 'empty'; //empty or all for applying the automap suggestion to empty attributes or all attributes

  public toggleAutoMapModal() {
    this.autoMapModalVisible = !this.autoMapModalVisible;
  }

  /**
   *
   * @param event FileUploadUploaderEvent
   * Start the automap process
   * The file will be uploaded and the automap suggestion will be fetched.
   */
  public async startAutoMap(event: FileUploadUploaderEvent) {
    try {
      const file = (event.files as File[])[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('header_row', (this.headerRow ?? 1).toString());
      formData.append('mapping_id', this._route.params['id'].toString());

      this.mappingResult = await this._mappingItemRestService.autoMap(formData);
      this.handleMappingResponse();
      this._resetDirtyState();
    } catch {
      this._toastService.displayErrorToast(this._ts.loadFailed());
    } finally {
      this._isLoadingOverview = false;
      this._isInitialized = true;
      this.toggleAutoMapModal();
    }
  }

  /**
   * Handle the mapping response
   * If the mapping result is not empty, the mapping items will be extended with the automap suggestion.
   */
  protected handleMappingResponse() {
    if (this.mappingResult) {
      //create a map of the result
      const mappedResult: Map<string, string> = this.mappingResult.reduce((map, item) => {
        map.set(item.attribute, item.header);
        return map;
      }, new Map<string, string>());

      //extend the mapping items with the automap suggestion if it exists and update the mapping items
      this.mappingItems = this.mappingItems.map((item) => {
        const extendedItem = { ...item };
        if (
          (this.suggestionTarget === 'empty' && !item.argument) ||
          this.suggestionTarget === 'all'
        ) {
          if (mappedResult.has(item.attribute.key)) {
            extendedItem.argument = mappedResult.get(item.attribute.key)!;
            extendedItem.autoMapSuggestion = true;
            this.updateMappingItem(extendedItem, extendedItem.argument);
          }
        }

        return extendedItem;
      });
    }
  }

  //#endregion
}
