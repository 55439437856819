<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import AttributeSelect from '@/general/ui/components/selects/attribute-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import { Attribute, AttributeTypeEnum } from 'platform-unit2-api/attributes';
import { Locale } from 'platform-unit2-api/locales';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';

/** Props */
interface Props {
  ids: number[];
  isActive: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  ids: () => [],
});

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');

/** Consts */
const store = useStore();
const attribute = ref<Attribute>();
const selectedLocale = ref<Locale>();

onMounted(() => {
  if (store.getters['users/currentUser'] && store.getters['users/currentUser'].locale) {
    selectedLocale.value = store.getters['users/currentUser'].locale;
  }
});

const emptyFieldDefinition = () => {
  attribute.value = undefined;
};

const sync = async () => {
  if (!attribute.value) {
    return;
  }

  try {
    buttonsOptions.value.saving = true;
    await store.dispatch('products/BULK_ACTION_MASS_SYNC_DISPLAY_NAME', {
      ids: props.ids,
      locale_id: selectedLocale.value?.id,
      productDefinitionId: attribute.value?.id,
    });
    toastService.displaySuccessToast(
      ts.tModule('mass_sync_display_name.success', {
        params: {
          field: attribute.value.key,
          amount: props.ids.length,
        },
      }),
    );
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('mass_sync_display_name.error'));
  } finally {
    buttonsOptions.value.saving = false;
    emit('hide');
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const syncButton = new CreateButton({
  label: ts.tGlobal('sync'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !!attribute.value,
  onClick: sync,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, syncButton],
});
</script>
<template>
  <BaseDialog
    :title="ts.tModule('mass_sync_display_name.title')"
    :visible="isActive"
    :buttons-options="buttonsOptions"
    size="medium"
    @update:visible="$emit('hide')"
  >
    <div class="field mb-3">
      <label class="font-bold">{{ ts.tGlobal('select_language') }}</label>
      <LocaleSelect v-model="selectedLocale" hide-label />
    </div>

    <div v-if="!attribute" class="field mb-3">
      <AttributeSelect
        v-model="attribute"
        show-type
        :allowed-types-to-show="[AttributeTypeEnum.DEFAULT]"
      />
    </div>
    <div v-else class="field mb-3">
      <div class="align-items-center flex justify-content-between">
        <p>
          {{
            ts.tModule('mass_sync_display_name.updating_notification', {
              params: {
                field: attribute.key,
                amount: ids.length,
              },
            })
          }}
        </p>
        <Button
          class="p-button-outlined p-button-sm"
          :label="ts.tModule('mass_sync_display_name.change_field')"
          @click="emptyFieldDefinition"
        />
      </div>
    </div>
  </BaseDialog>
</template>
