<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import EmptyState from '@/general/ui/components/empty-state.vue';
import TaskStatusSelect from '@/general/ui/components/selects/task-status-select.vue';
import TaskTypeSelect from '@/general/ui/components/selects/task-type-select.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import UserAvatarGroup from '@/general/ui/components/user-avatar-group.vue';
import { formatDate } from '@/general/utils/format-date';
import { ActionStatusSeverityEnum } from 'platform-unit2-api/action-statuses';
import { DataTableFilterEvent } from 'primevue/datatable';
import { inject } from 'vue';
import { taskViewServiceKey } from '../ts/keys/task-view-service.key';

const taskService = inject(taskViewServiceKey)!;
const ts = new TranslationService('supplier', 'tasks');
</script>
<template>
  <DataTable
    v-if="taskService != null && ts != null"
    v-model:filters="taskService.filters"
    removable-sort
    row-hover
    scrollable
    lazy
    scroll-height="flex"
    :paginator="taskService.limit ? (taskService.total ?? 0) > taskService.limit : false"
    :loading="taskService.isLoadingOverView"
    :rows="taskService.limit"
    :total-records="taskService.total"
    :first="taskService.first"
    :global-filter-fields="['status.label', 'action_type.name', 'users']"
    filter-display="menu"
    :value="taskService.tasks"
    @page="taskService.onPageChange($event.page)"
    @sort="taskService.onSortChange($event)"
    @row-click="taskService?.openCrudComponent($event.data, true)"
    @filter="(event: DataTableFilterEvent) => taskService.applyFilter(event)"
  >
    <template #empty>
      <EmptyState
        :translation-service="ts"
        :button-visible="true"
        :button-icon="'mdi mdi-plus'"
        :icon-name="'tasks'"
        @clicked="taskService.openCrudComponent()"
      />
    </template>
    <Column field="title" :header="ts.tModule('tableHeaders.taskName')" />
    <Column field="owner.name" :header="ts.tModule('tableHeaders.assignedBy')" />
    <Column
      field="action_type.name"
      :header="ts.tModule('tableHeaders.taskType')"
      :show-filter-match-modes="false"
    >
      <template #filter="{ filterModel }">
        <TaskTypeSelect v-model="filterModel.value" multiselect class="filter-style" hide-label />
      </template>
    </Column>

    <Column
      field="users"
      :header="ts.tModule('tableHeaders.assignee')"
      :show-filter-match-modes="false"
    >
      <template #body="slotProps">
        <UserAvatarGroup v-if="slotProps.data.users.length > 0" :users="slotProps.data.users" />
        <span v-if="slotProps.data.assigned_to_workspace">{{
          taskService.currentUser?.workspace?.name
        }}</span>
      </template>
      <template #filter="{ filterModel }">
        <MultiSelect
          v-model="filterModel.value"
          :options="taskService.assignees"
          option-label="label"
          option-group-label="label"
          option-group-children="items"
          placeholder="Select Assignees"
          class="filter-style"
        >
          <template #optiongroup="slotProps">
            <div class="align-items-center flex">
              <div>{{ slotProps.option.label }}</div>
            </div>
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column
      :header="ts.tModule('tableHeaders.status')"
      field="status.label"
      :show-filter-match-modes="false"
    >
      <template #body="slotProps">
        <StatusChip
          :label="slotProps.data.status.label"
          :severity="ActionStatusSeverityEnum[slotProps.data.status.label.toUpperCase() as keyof typeof ActionStatusSeverityEnum]"
          label-only
        />
      </template>

      <template #filter="{ filterModel }">
        <TaskStatusSelect
          v-model="filterModel.value"
          class="filter-style"
          display="comma"
          multiselect
          hide-label
        />
      </template>
    </Column>
    <Column field="date" :header="ts.tModule('tableHeaders.dueDate')" sortable>
      <template #body="slotProps">
        <div :class="taskService.styleDate(slotProps.data.date, slotProps.data.completed_at)">
          {{ formatDate(slotProps.data.date) }}
        </div>
      </template>
    </Column>
  </DataTable>
</template>
<style lang="scss" scoped>
.filter-style {
  max-width: 150px;
}
</style>
