<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import StatusChip from '@/general/ui/components/status-chip.vue';
import Upload from '@/general/ui/components/upload.vue';
import { formatDate } from '@/general/utils/format-date';
import { ActionStatusSeverityEnum } from 'platform-unit2-api/action-statuses';
import { ExportParameters, Pipeline, PipelinesRestService } from 'platform-unit2-api/pipelines';
import ExportLog from 'retailer/modules/export/components/export-log.vue';
import ExportVerticalList from 'retailer/modules/export/components/export-vertical-list.vue';
import ExportService from 'retailer/modules/export/services/export.service';
import { TranslationService } from 'services/translations/translation.service';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
  pipelineId: number | undefined;
}

/** Initializing services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'exports');
const route = useRoute();
const exportService = new ExportService();
const pipelineApi = new PipelinesRestService();

/** Constants */
const loading = ref<boolean>(false);
const showSidebar = ref<boolean>(false);
const pipeline = ref<Pipeline>();
const props = defineProps<Props>();
let timer!: ReturnType<typeof setInterval>;

/**
 * Loads currently selected pipeline
 * @param showLoadingIcon
 */
const loadAsyncData = async (showLoadingIcon = true): Promise<void> => {
  loading.value = showLoadingIcon;
  try {
    if (props.pipelineId === undefined) {
      return;
    }

    pipeline.value = await pipelineApi.get(props.pipelineId);
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  }

  loading.value = false;
};

/** Lifecycle */
watch(
  () => route,
  () => {
    if (route.name === 'restart-export') {
      showSidebar.value = true;
    }

    if (route.name === 'export') {
      showSidebar.value = false;
    }
  },
  {
    deep: true,
  },
);

onMounted(async () => {
  timer = setInterval(loadAsyncData.bind(this, false), 5000);
  await loadAsyncData();
});

onUnmounted(() => {
  clearInterval(timer);
});
</script>

<template>
  <div class="details-retailer-actions">
    <div class="retailer">
      <upload class="w-5rem" :upload="pipeline?.retailer?.thumbnail" />
      <span class="name">
        {{
          exportService.formatName(pipeline?.module?.name ?? ts.tModule('details_modal.unknown'))
        }}
      </span>
    </div>
  </div>
  <div class="details-info">
    <div class="info">
      <div v-if="pipeline" class="data">
        <div class="item">
          <span class="title">{{ ts.tModule('details_modal.date') }}:</span>
          <span class="text">{{ formatDate(new Date(pipeline?.created_at)) }}</span>
        </div>
        <div class="item">
          <span class="title">{{ ts.tModule('details_modal.status') }}:</span>
          <StatusChip
            v-if="pipeline?.current_status"
            :label="exportService.processStatus(Number(pipeline?.current_status))"
            :severity="ActionStatusSeverityEnum[exportService.processStatus(Number(pipeline?.current_status)) as keyof typeof ActionStatusSeverityEnum]"
          />
        </div>
        <div class="item">
          <span class="title">{{ ts.tModule('details_modal.errors') }}:</span>
          <span class="text">
            <span class="table-column_errors">
              <span class="text">{{ pipeline?.error_count }}</span>
              <img v-if="pipeline?.error_count" src="@/assets/icons/error-icon.svg" />
            </span>
          </span>
        </div>
      </div>
      <div v-if="!pipeline" class="loader p-8">
        <LoadingIndicator />
      </div>
    </div>
    <div class="info">
      <div v-if="pipeline" class="data">
        <div class="item">
          <span class="title">{{ ts.tModule('details_modal.products') }}:</span>
          <span class="text">{{
            (pipeline?.payload?.parameters as ExportParameters)?.product_ids?.length ?? 0
          }}</span>
        </div>
        <div class="item">
          <span class="title">{{ ts.tModule('details_modal.exported_by') }}:</span>
          <span class="text">{{ pipeline?.user?.name }}</span>
        </div>
        <div class="item">
          <span class="title">{{ ts.tModule('details_modal.exportId') }}:</span>
          <span class="text">{{ pipeline?.id }}</span>
        </div>
      </div>
      <div v-if="!pipeline" class="loader p-8">
        <LoadingIndicator />
      </div>
    </div>
    <div class="info">
      <div v-if="pipeline" class="data">
        <div class="item">
          <span class="title">{{ ts.tModule('details_modal.channel') }}:</span>
          <span class="text">{{ pipeline?.module?.name }}</span>
        </div>
        <div class="item">
          <span class="title">{{ ts.tModule('details_modal.locale') }}:</span>
          <span class="text">{{ pipeline?.locale?.value }}</span>
        </div>
        <div class="item">
          <span class="title">{{ ts.tModule('details_modal.mailed_to') }}:</span>
          <template
            v-for="email in (pipeline?.payload?.parameters as ExportParameters).mail_to"
            :key="email"
          >
            {{ email }},
            <br />
          </template>
        </div>
      </div>
      <div v-if="!pipeline" class="loader p-8">
        <LoadingIndicator />
      </div>
    </div>
    <div class="info">
      <div v-if="pipeline" class="data">
        <div v-if="(pipeline?.payload?.parameters as ExportParameters)?.include_data" class="item">
          <span class="title"><i class="pi pi-check" /></span>
          <span class="text">{{ ts.tModule('details_modal.data_included') }}</span>
        </div>
        <div
          v-if="(pipeline?.payload?.parameters as ExportParameters)?.include_assets"
          class="item"
        >
          <span class="title"><i class="pi pi-check" /> </span>
          <span class="text">{{ ts.tModule('details_modal.assets_included') }}</span>
        </div>
        <div
          v-if="(pipeline?.payload?.parameters as ExportParameters)?.ignore_product_status"
          class="item"
        >
          <span class="title"><i class="pi pi-check" /> </span>
          <span class="text">{{ ts.tModule('details_modal.ignore_product_status') }}</span>
        </div>
      </div>
      <div v-if="!pipeline" class="loader p-8">
        <LoadingIndicator />
      </div>
    </div>
  </div>

  <span class="log-count">
    {{ ts.tModule('details_modal.export_log') }}
  </span>
  <div class="tab-view">
    <Tabs value="export-statuses">
      <TabList>
        <Tab value="export-statuses">{{ ts.tModule('details_modal.tab_pipeline_statuses') }}</Tab>
        <Tab value="export-errors">{{
          ts.tModule('details_modal.tab_product_errors') +
          ' (' +
          (pipeline?.error_count ?? '-') +
          ')'
        }}</Tab>
      </TabList>
      <TabPanel value="export-statuses">
        <div v-if="!pipeline?.status" class="loader p-8">
          <LoadingIndicator />
        </div>
        <ExportVerticalList :pipeline="pipeline" />
      </TabPanel>
      <TabPanel value="export-errors">
        <ExportLog :pipeline="pipeline" :pipeline-id="props.pipelineId" />
      </TabPanel>
    </Tabs>
  </div>
</template>

<style lang="scss" scoped>
:deep .p-dialog .p-dialog-content {
  overflow: hidden !important;
}

:deep .pi-sort-alt:before {
  font-weight: bold;
  color: var(--greyscale-black-600);
}

.table-column_gtin {
  padding-left: 12px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
}

:deep .p-column-title {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: var(--greyscale-black-600) !important;
}

.details-retailer-actions {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;

  .retailer {
    display: flex;
    align-items: center;

    :deep img {
      height: 32px;
      width: 32px;
      border: 1px solid lightgray;
      border-radius: 30px !important;
    }

    .name {
      padding-left: 16px;

      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
    }
  }

  .actions {
    :deep .p-dropdown {
      border: 1px solid #cad9e7;
      border-radius: 3px;
    }

    :deep .p-dropdown-label {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #172533;
      padding-right: 0;
    }

    :deep .p-dropdown-trigger {
      color: #172533;
    }
  }
}

.details-info {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid var(--greyscale-black-300);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 40px;

  .info {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-left: 16px;
    padding-right: 16px;
    border-left: 1px solid var(--greyscale-black-300);

    &:first-child {
      padding-left: 0;
      border-left: unset;
    }

    &:last-child {
      padding-right: 0;
    }

    &:nth-child(1) {
      .title {
        width: 60px;
      }
    }

    &:nth-child(2) {
      .title {
        width: 90px;
      }
    }

    &:nth-child(3) {
      .title {
        width: 70px;
      }
    }
  }

  .item {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 12px;

    &:last-child {
      padding-bottom: 0;
    }

    .title {
      i {
        padding-right: 8px;
      }
    }

    .table-column_errors {
      display: flex;
      justify-content: flex-start;
    }
  }
}

.log-count {
  display: block;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 12px;

  .count {
    font-weight: 400;
  }
}
</style>
