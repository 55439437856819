<script setup lang="ts">
import { formatDate } from '@/general/utils/format-date';
import { ImportPipeline } from 'platform-unit2-api';
import { PipelineStatus } from 'platform-unit2-api/pipelines';
import ImportPipelineStatus from './import-pipeline-statuses.vue';

interface Props {
  pipeline: ImportPipeline | undefined;
}

const props = defineProps<Props>();

const mapStatuses = (singlePipeline: ImportPipeline) => {
  return singlePipeline?.statuses!.map((item: PipelineStatus) => item).reverse();
};
</script>

<template>
  <div v-if="props.pipeline && props.pipeline?.statuses" class="align-items-first flex mt-4 w-full">
    <Timeline :value="mapStatuses(props.pipeline)" class="relative" layout="vertical" align="left">
      <template #marker="prop">
        <Badge
          :severity="prop.item.level === 'error' ? 'danger' : prop.item.level"
          class="align-items-center border-circle flex h-1rem shadow-1 z-1"
          :pt="{
            root: () => ({
              style: { 'min-width': '1rem' },
            }),
          }"
        />
      </template>
      <template #opposite="prop">
        <small class="p-text-secondary vertical-status">
          {{ formatDate(prop.item.occurred_at) }}
        </small>
      </template>
      <template #content="prop">
        <ImportPipelineStatus :pipeline-status="prop.item" />
      </template>
    </Timeline>
  </div>
</template>

<style lang="scss" scoped>
:deep .p-timeline-event-opposite {
  flex: 0.2;
}

:deep .vertical-status,
:deep .p-timeline-event-content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 16px;
}
</style>
