<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import TaskStatusSelect from '@/general/ui/components/selects/task-status-select.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import Upload from '@/general/ui/components/upload.vue';
import { formatDate } from '@/general/utils/format-date';
import CommentSection from '@/platforms/retailer/modules/comments/components/comment-section.vue';
import { ActionStatusSeverityEnum } from 'platform-unit2-api/action-statuses';
import { Product } from 'platform-unit2-api/products';
import { ListboxChangeEvent } from 'primevue/listbox';
import { inject } from 'vue';
import { taskViewServiceKey } from '../ts/keys/task-view-service.key';

const service = inject(taskViewServiceKey)!;
const ts = new TranslationService('supplier', 'tasks');
</script>
<template>
  <div>
    <StatusChip
      class="pup-mb-2 pup-py-2"
      :label="service.partialObject.status?.label"
      :severity="ActionStatusSeverityEnum[service.partialObject.status?.label?.toUpperCase() as keyof typeof ActionStatusSeverityEnum]"
      label-only
    />
    <h4 class="font-bold mb-5">
      {{ service.partialObject.title }}
    </h4>
  </div>
  <div class="mb-3">
    <label>{{ ts.tModule('tableHeaders.assignee') }}</label>
    <InputText
      :value="
        service.partialObject.assigned_to_workspace
          ? service.currentUser?.workspace?.name
          : service.partialObject.users?.map((user) => user.name).join(', ')
      "
      disabled
      class="w-full"
      type="text"
    />
  </div>
  <div class="field mb-3">
    <label for="date">{{ ts.tModule('tableHeaders.dueDate') }}</label>
    <InputText
      id="date"
      type="text"
      :value="service.partialObject.date ? formatDate(new Date(service.partialObject.date)) : ''"
      disabled
      class="w-full"
    />
  </div>
  <div class="field mb-3">
    <label for="description">{{ ts.tGlobal('description') }}</label>
    <Textarea
      id="description"
      v-model="service.partialObject.description"
      disabled
      class="w-full"
      type="text"
      :auto-rezie="true"
    />
  </div>
  <div class="field mb-3">
    <label for="created_by">{{ ts.tModule('tableHeaders.assignedBy') }}</label>
    <InputText
      v-if="service.partialObject.owner"
      id="created_by"
      v-model="service.partialObject.owner.name"
      disabled
      class="w-full"
      type="text"
    />
  </div>
  <div class="field mb-3">
    <TaskStatusSelect v-model="service.partialObject.status" :show-clear="false" />
  </div>
  <div v-if="service.partialObject.products?.length ?? 0 > 0" class="field mb-3">
    <label for="products">{{ ts.tGlobal('products', { choice: 2 }) }}</label>
    <Listbox
      id="products"
      v-model="service.selectedProduct"
      option-label="display_name"
      :options="service.partialObject.products"
      list-style="max-height:200px"
      @change="
            (event: ListboxChangeEvent) => $router.push({ name: 'product-details', params: { id: event.value.id } })
          "
    >
      <template #option="slotProps: { option: Product }">
        <div class="align-items-center flex">
          <Upload
            class="w-auto"
            :additional-container-style="'margin-right: 1rem;'"
            :additional-image-style="'width:32px !important;'"
            :upload="slotProps.option.thumbnail"
          />
          <div class="mx-2">
            {{
              slotProps.option.gtin != '' && slotProps.option.gtin != null
                ? slotProps.option.gtin
                : ts.tModule('noGtin')
            }}
          </div>
          <div
            v-if="slotProps.option.display_name"
            v-tooltip.bottom="slotProps.option.display_name"
            class="white-space-nowrap"
            :style="'text-overflow: ellipsis !important; overflow: hidden;'"
          >
            {{ ' | ' + slotProps.option.display_name }}
          </div>
        </div>
      </template>
    </Listbox>
  </div>
  <div class="field mb-3">
    <label for="chat">{{ ts.tGlobal('chat') }}</label>
    <CommentSection id="chat" :has-title="false" :task-id="service.partialObject.id" />
  </div>
</template>
