<script setup lang="ts">
//services
import { TranslationService } from '@/general/services/translations/translation.service';

/** Props */
export interface Props {
  headline?: string;
  customClass?: string;
  customPlaceHolder?: string;
  disabled?: boolean;
}

withDefaults(defineProps<Props>(), {
  headline: undefined,
  customClass: 'my-3',
  disabled: false,
  customPlaceHolder: undefined,
});

const ts = new TranslationService('supplier', 'storyManager');

/** Emits */
const emit = defineEmits<{
  (e: 'headline-value', text: string | undefined): void;
}>();

/** Lifecycle */
</script>

<template>
  <InputText
    :disabled="disabled"
    type="text"
    class="w-full"
    :class="customClass"
    :placeholder="customPlaceHolder ?? ts.tModule('storyBuilder.threeImageAndText.headline')"
    :model-value="headline"
    @update:model-value="emit('headline-value', $event)"
  />
</template>

<style lang="scss" scoped></style>
