<script setup lang="ts">
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Product, ProductsRestService } from 'platform-unit2-api/products';
import { onMounted, ref } from 'vue';

/** Props */
export interface Props {
  productIds: number[];
}

const props = withDefaults(defineProps<Props>(), {
  productIds: () => [],
});

/** Emits */
const emit = defineEmits<{
  (e: 'removeProduct', productIds: number[]): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'exports');
const confirmService = new ConfirmService();
const productsApi = new ProductsRestService();

/** Constants */
const products = ref<Product[]>([]);
const loading = ref<boolean>(false);
const selectedProductToDelete = ref<number>();

const loadAsyncData = () => {
  loading.value = true;
  const getProducts = props.productIds.map(async (productId) => {
    try {
      const product = await productsApi.get(productId);
      products.value.push(product);
    } catch (err: any) {
      throw err;
    }
  });

  Promise.all(getProducts)
    .then(() => {
      loading.value = false;
    })
    .catch((error: any) => console.log(error));
};

const confirmProductDelete = (product: Product) => {
  confirmService.confirmDelete({
    event: event as PointerEvent,
    callback: () => deleteProduct(product.id),
    group: 'product-list',
    message: ts.deleteConfirm(),
  });
};

const removeFromProducts = (id: number) => {
  products.value = products.value.filter((product) => product.id !== id);
};

const deleteProduct = (productIdToRemove: number) => {
  selectedProductToDelete.value = productIdToRemove;
  const newProductIds = props.productIds.filter((productId) => productId !== productIdToRemove);
  emit('removeProduct', newProductIds);
  setTimeout(removeFromProducts, 1000, productIdToRemove);
};

const rowClass = (data: Product) => {
  return selectedProductToDelete.value === data.id ? 'remove product' : 'product';
};

/** Lyfecycle */
onMounted(() => {
  if (props.productIds) {
    loadAsyncData();
  }
});
</script>

<template>
  <div class="p-2 w-full">
    <Message v-if="!loading && !products.length" severity="info" :closable="false">
      {{ ts.getEmpty }}
    </Message>

    <div v-else>
      <DataTable
        :value="products"
        :row-class="rowClass"
        :loading="loading"
        :paginator="true"
        :rows="5"
        paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows-per-page-options="[5, 15, 30]"
        responsive-layout="scroll"
        current-page-report-template="Showing {first} to {last} of {totalRecords}"
      >
        <Column field="gtin" :header="ts.tModule('table_header.gtin')"></Column>
        <Column field="display_name" :header="ts.tModule('table_header.name')"></Column>
        <Column field="category.name" :header="ts.tModule('table_header.category')"></Column>
        <Column field="brand.name" :header="ts.tModule('table_header.brand')"></Column>
        <Column field="color" header="">
          <template #body="slotProps">
            <Button
              v-tooltip.bottom="ts.tModule('tooltips.delete')"
              icon="mdi mdi-delete-outline"
              text
              severity="danger"
              class="text-lg"
              @click="confirmProductDelete(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <ConfirmPopup group="product-list" />
  </div>
</template>
