<script setup lang="ts"></script>

<template>
  <div>
    <div class="mb-5">
      <Skeleton height="0.7rem" width="10rem" class="mb-2" />
      <Skeleton height="1rem" class="mb-2" />
      <Skeleton height="1rem" />
    </div>
    <div class="mb-5">
      <Skeleton height="0.7rem" width="10rem" class="mb-2" />
      <Skeleton height="1rem" class="mb-2" />
      <Skeleton height="1rem" />
    </div>
    <div class="mb-5">
      <Skeleton height="0.7rem" width="10rem" class="mb-2" />
      <Skeleton height="1rem" class="mb-2" />
      <Skeleton height="1rem" />
    </div>
    <div class="mb-5">
      <Skeleton height="0.7rem" width="10rem" class="mb-2" />
      <Skeleton height="1rem" class="mb-2" />
      <Skeleton height="1rem" />
    </div>
  </div>
</template>
