<script setup lang="ts">
//components
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import UploadBox from '@/general/ui/components/upload-box.vue';
import MediaPicker from 'supplier/modules/media/components/media-picker.vue';

//types
import { Upload } from 'platform-unit2-api/uploads';

//vue
import { computed, ref, watch } from 'vue';
import { EmptyStateUploadOrMediaPickerService } from './empty-state-upload-or-media-picker.vue.service';

/** Props */
export interface Props {
  acceptedFileTypes: string;
  showTitle?: boolean;
  showSubTitle?: boolean;
  customTitle?: string;
  customSubTitle?: string;
  customUploadLabel?: string;
  customSvgUrl?: string;
  customLibraryButtonLabel?: string;
  isAbleToPickMultipleImages?: boolean;
  minimalImageSize?: `${string} : ${string}`;
  openMediaPicker?: boolean;
}

const prop = withDefaults(defineProps<Props>(), {
  showTitle: true,
  showSubTitle: true,
  customTitle: undefined,
  customSubTitle: undefined,
  customUploadLabel: undefined,
  customSvgUrl: undefined,
  customLibraryButtonLabel: undefined,
  isAbleToPickMultipleImages: false,
  minimalImageSize: undefined,
  openMediaPicker: false,
});

const emit = defineEmits<{
  (e: 'choose-multiple-files', images: Upload[] | Upload): void;
  (e: 'chosen-file', image: Upload | undefined): void;
  (e: 'reset-media-picker-state', state: boolean): void;
}>();

const emitChosenMultipleFiles = (event: Upload[] | Upload) => {
  emit('choose-multiple-files', event);
};

const emitChosenFile = (event?: Upload) => {
  emit('chosen-file', event);
  emit('reset-media-picker-state', false);
};

const mediaPickerState = ref(false);

const emptyUploadOrMediaPickerService = new EmptyStateUploadOrMediaPickerService(
  prop.isAbleToPickMultipleImages,
  emitChosenMultipleFiles,
  emitChosenFile,
);

const iconPath = computed(() => {
  return prop.customSvgUrl
    ? prop.customSvgUrl
    : '/src/assets/empty-state/empty-upload-library-state.svg';
});

const showIconOrLoading = (icon: string, loading: boolean) => {
  return computed(() => {
    return loading ? 'mdi mdi-loading pi-spin' : icon;
  }).value;
};

watch(
  () => prop.openMediaPicker,
  () => {
    mediaPickerState.value = prop.openMediaPicker;
  },
);
</script>

<template>
  <div id="drop-zone" class="border-empty-state h-full w-full" data-testid="empty-upload-picker">
    <UploadBox
      :multiple="isAbleToPickMultipleImages"
      :remove-header="true"
      :dropbox-full-height="true"
      :accept="acceptedFileTypes"
      @input="emptyUploadOrMediaPickerService.handleUploadAssets"
    >
      <template #custom-content="{ openFileSelect, loading }">
        <div class="flex flex-column h-full w-full">
          <div class="flex h-3rem justify-content-end">
            <p
              :style="{ display: minimalImageSize ? 'flex' : 'none' }"
              data-testid="minimal-image-size"
            >
              {{ minimalImageSize }}
            </p>
          </div>

          <div
            class="align-content-center align-items-center flex flex-column h-full justify-content-center"
          >
            <div class="mb-3">
              <SvgIcon :iconpath="iconPath" custom-style="width: 6rem" />
            </div>
            <h3
              class="mb-2"
              :style="{ display: showTitle ? 'block' : 'none' }"
              data-testid="empty-upload-picker-title"
            >
              {{
                customTitle ??
                emptyUploadOrMediaPickerService.ts.tModule('emptyUploadOrMediaPicker.title')
              }}
            </h3>
            <p
              class="mb-5 text-center w-5"
              :style="{ display: showSubTitle ? 'block' : 'none' }"
              data-testid="empty-upload-picker-subtitle"
            >
              {{
                customSubTitle ??
                emptyUploadOrMediaPickerService.ts.tModule('emptyUploadOrMediaPicker.subTitle')
              }}
            </p>
            <div class="flex">
              <Button
                size="small"
                data-testid="empty-upload-picker-upload-label"
                :icon="showIconOrLoading('mdi mdi-tray-arrow-up', loading)"
                :disabled="loading"
                :label="
                  customUploadLabel ??
                  emptyUploadOrMediaPickerService.ts.tModule(
                    'emptyUploadOrMediaPicker.uploadAssets',
                  )
                "
                class="hover:bg-black-alpha-10 hover:border-0 hover:text-900 mr-3 p-button-outlined text-sm"
                @click="openFileSelect()"
              />

              <Button
                size="small"
                data-testid="empty-upload-picker-picker-label"
                :icon="showIconOrLoading('mdi mdi-plus', loading)"
                :disabled="loading"
                :label="
                  customLibraryButtonLabel ??
                  emptyUploadOrMediaPickerService.ts.tModule(
                    'emptyUploadOrMediaPicker.addFromLibrary',
                  )
                "
                :pt="{
                  label: () => ({
                    style: { 'text-wrap': 'nowrap' },
                  }),
                }"
                class="hover:bg-black-alpha-10 hover:border-0 hover:text-900 p-button-outlined text-sm"
                @click="mediaPickerState = true"
              />
            </div>
          </div>
        </div>
      </template>
    </UploadBox>
  </div>
  <MediaPicker
    v-model:visible="mediaPickerState"
    :accept-files="acceptedFileTypes"
    :selected-ids="[]"
    :multiple="isAbleToPickMultipleImages"
    @choose-multiple-images="(event: Upload[]) => emptyUploadOrMediaPickerService.emitSelectedImages(event)"
    @hide="(mediaPickerState = false), emit('reset-media-picker-state', false)"
  />
</template>
<style lang="scss" scoped>
.border-empty-state {
  border: 1px dashed #cad9e7;
  border-radius: 6px;
}
</style>
