<script setup lang="ts">
import { inject } from 'vue';
import { generateImageServiceKey } from '../../ts/keys/supplier-ai.keys';

const service = inject(generateImageServiceKey)!;
</script>
<template>
  <div class="mb-2">
    <div class="font-bold mb-4 text-3xl">Generate images</div>
    <div class="align-items-center flex h-5rem justify-content-between w-full">
      <div class="field flex flex-column mb-3 mr-3 w-5">
        <label for="prompt">Your request: <span class="font-bold text-red-500">&ast;</span></label>
        <InputText
          id="prompt"
          v-model="service.promptRequest.prompt"
          :disabled="service.isLoading"
          type="text"
          placeholder="A beautiful background for my product"
        />
      </div>
      <div class="field flex flex-column mb-3 mr-3 w-2">
        <label for="number">Amount of images:</label>
        <Select
          id="number"
          v-model="service.promptRequest.amount"
          :disabled="service.isLoading"
          :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          type="text"
        />
      </div>
      <div class="field flex flex-column mb-3 mr-3 w-2">
        <label for="number">Tags:</label>
        <MultiSelect
          v-model="service.tags"
          :disabled="service.isLoading"
          :options="service.tagOptions"
          placeholder="Select tags"
          type="text"
        />
      </div>
      <div class="field flex flex-column mb-3 mr-3 w-2">
        <label for="size">Size:</label>
        <Select
          id="size"
          v-model="service.promptRequest.size"
          :disabled="service.isLoading"
          :options="['256x256', '512x512', '1024x1024']"
          type="text"
        />
      </div>
      <Button
        class="h-3rem mt-2 w-3"
        label="Generate"
        :disabled="service.isLoading || service.isGenerateDisabled"
        @click="service.generateImages()"
      />
    </div>
  </div>
</template>
