export const featureFlagEnum = {
  PLEINRETAILERWORKFLOW: 'plein-retailer-workflow',
  STORY_MANAGER: 'aplus-storybuilder',
  STORY_MODULES_V2: 'aplus-storybuilder-modules-v2',
  NEW_LAYOUT: 'new-layout',
  BETA_TOGGLES: 'beta-toggles',
  APLUS_SUBMIT_BUTTON: 'aplus-content-submit-button',
  AI_ENHANCEMENTS: 'ai-enhancements',
  DEBUG: 'debug',
} as const;
