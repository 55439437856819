<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { inject } from 'vue';
import { productTableServiceKey } from '../../key/product-table-service.key';

const productTableService = inject(productTableServiceKey)!;
const ts = new TranslationService('supplier', 'search');
</script>

<template>
  <InputText
    v-model="productTableService.query"
    class="border-100"
    :placeholder="ts.tModule('simple_search_placeholder')"
  />
</template>
