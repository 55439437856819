<script setup lang="ts">
import { inject } from 'vue';

import { tsKey } from '@/general/services/translations/translation.key';
import DataTable from 'primevue/datatable';
import { serviceKey } from '../ts/keys/asset-types.keys';
import AssetTypeEmptyState from './asset-type-empty-state.vue';

/**
 * If this table is to be used in another view, the service must be provided.
 *
 * Or this component can be rewritten to also use props.
 * Service should then based on typed "TableService | OverViewService" that implement a common interface.
 */
const ts = inject(tsKey);
const service = inject(serviceKey);
</script>

<template>
  <DataTable
    v-if="service != null && ts != null"
    class="h-full"
    paginator
    lazy
    removable-sort
    row-hover
    auto-layout
    scrollable
    scroll-height="flex"
    :loading="service.isLoadingOverView"
    :value="service.assetTypes"
    :rows="service.limit"
    :first="service.first"
    :total-records="service.total"
    :sort-field="service.sortField"
    :sort-order="service.sortOrder"
    @page="service?.onPageChange($event.page)"
    @row-click="service?.openCrudComponent($event.data)"
    @sort="service?.onSortChange($event)"
  >
    <Column field="name" :width="5" :header="ts.tGlobal('name')" sortable></Column>
    <template #empty> <AssetTypeEmptyState /></template>
  </DataTable>
</template>

<style scoped lang="scss">
:deep(.p-datatable-wrapper) {
  height: 100% !important;
}
</style>
