<script setup lang="ts">
import StandardImageSidebarClass from '@/api/v1/content-story/ts/classes/image-sidebar.class';
import { SingleImageHighlightsClass } from '@/api/v1/content-story/ts/classes/single-image-highlights.class';
import { SingleImageSpecsDetailClass } from '@/api/v1/content-story/ts/classes/single-image-specs-detail.class';
import { TranslationService } from '@/general/services/translations/translation.service';
import { inject, ref } from 'vue';
import { storyBuilderServiceKey } from '../../../ts/helpers/story-manager-keys';
interface Props {
  uuid: string;
  overrideMinHeight?: string;
  selector?: any; //Needs to be a key of the interface that is used in the module as data. then the key should be of instance StnadardTextListBlock
}

const props = defineProps<Props>();

// inject / provide
const storyService = inject(storyBuilderServiceKey);
const ts = new TranslationService('supplier', 'storyManager');

// const / refs
const storyModule = storyService?.value.getModule<
  SingleImageSpecsDetailClass | SingleImageHighlightsClass | StandardImageSidebarClass
>(props.uuid);
const bulletpoint = ref('');

const clearValue = () => {
  bulletpoint.value = '';
};
</script>

<template>
  <div
    class="flex flex-column justify-content-between w-full"
    :style="{ 'min-height': overrideMinHeight ? overrideMinHeight : '35%' }"
  >
    <div class="flex flex-column justify-content-start">
      <div
        v-for="(point, index) in storyModule?.getBulletPoints(selector)"
        :key="index"
        class="flex mb-2 w-full"
      >
        <span class="mdi mdi-check w-1"></span>
        <p class="w-10">{{ point.text.value }}</p>
        <span
          class="mdi mdi-delete text-red-500 w-1"
          @click="storyModule?.removeBulletPoint(index, selector)"
        ></span>
      </div>
    </div>

    <div v-if="storyModule?.getBulletPoints(selector).length === 0" class="flex mb-2 w-full">
      <p class="w-10">{{ ts.tModule('storyBuilder.bulletPoints.noBulletPoints') }}</p>
    </div>

    <div class="flex w-full">
      <InputText
        v-model="bulletpoint"
        class="w-11"
        :placeholder="ts.tModule('storyBuilder.bulletPoints.inputPlaceHolder')"
        type="text"
        @keyup.enter="storyModule?.addBulletPoint(bulletpoint, selector), clearValue()"
      />
      <div class="flex justify-content-center w-1 w-full">
        <div
          v-tooltip.bottom="{
            value: ts.tModule('storyBuilder.bulletPoints.pressEnterToSubmit'),
          }"
          class="align-self-center mdi mdi-information-outline"
        ></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
