<script setup lang="ts">
import ClientModuleSelect from '@/general/ui/components/selects/client-module-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import CronGenerator from 'ui/components/cron-generator.vue';

import { tsKey } from '@/general/services/translations/translation.key';
import { TranslationService } from '@/general/services/translations/translation.service';
import { inject } from 'vue';
import { scheduledExportServiceKey } from '../ts/keys/exports.keys';

const ts = inject(tsKey)!;
const tsCore = new TranslationService('general', 'components');
const scheduledExportService = inject(scheduledExportServiceKey)!;
</script>
<template>
  <div>
    <div class="mb-3">
      <label>{{ ts.tGlobal('name') }}</label>
      <InputText v-model="scheduledExportService.partialObject.name" class="w-full" type="text" />
    </div>

    <div class="mb-2">
      <CronGenerator
        :cron="scheduledExportService.partialObject.cron"
        @generated-crone="(cron: string) => scheduledExportService.setCron(cron)"
      />
      <small :class="{ 'p-error block': !scheduledExportService.isCronValid() }" class="hidden">{{
        tsCore.tModule('cronGenerator.warning')
      }}</small>
    </div>

    <ClientModuleSelect
      v-model="scheduledExportService.partialObject.module"
      type="export"
      class="mb-2"
      :show-clear="false"
    />

    <LocaleSelect
      v-model="scheduledExportService.partialObject.locale"
      class="mb-2"
      :show-clear="false"
    />

    <div class="flex gap-3 my-3 w-full">
      <label for="is_paused">{{ ts.tModule('export_scheduled.is_paused') }}</label>
      <Checkbox v-model="scheduledExportService.partialObject.is_paused" binary />
    </div>

    <div class="flex gap-3 my-3 w-full">
      <label>{{ ts.tModule('export_scheduled.ignore_product_status') }}</label>
      <Checkbox v-model="scheduledExportService.partialObject.ignore_product_status" binary />
    </div>
  </div>
</template>
