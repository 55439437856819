<script setup lang="ts">
import { ContentStory } from '@/api/v1/content-story/ts/content-story.interface';
import { featureFlagEnum } from '@/core/featureflags/ts/service/feature-flags.enum';
import { FeatureFlagService } from '@/core/featureflags/ts/service/feature-flags.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { computed, onMounted, ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const currentStep = computed(() => route.fullPath.includes('link-and-publish'));
const ts = new TranslationService('supplier', 'storyManager');

const storyBeingLoaded = computed<boolean>(() => {
  return store.getters['storyManager/loading'];
});

const currentStory = computed<ContentStory>(() => {
  return store.getters['storyManager/activeStoryInStoryBuilder'];
});

const isSubmitActive = ref<boolean>(false);

onBeforeRouteLeave((_to, _from, next) => {
  store.dispatch('storyManager/UPDATE_LOADING', true);
  next();
});

onMounted(async () => {
  const featureFlagService = FeatureFlagService.getInstance();

  isSubmitActive.value = await featureFlagService.isOn(featureFlagEnum.APLUS_SUBMIT_BUTTON);
});
</script>

<template>
  <div class="flex h-6rem justify-content-between lower-border px-6 py-4">
    <div class="align-items-center flex flex-grow-1 w-3">
      <RouterLink :to="{ name: 'story-manager' }">
        <Button
          class="lg:text-base md:text-sm mr-5 p-button-outlined text-gray-500"
          icon="mdi mdi-chevron-left"
          :label="ts.tModule('storyManagerTitle')"
        />
      </RouterLink>
      <p
        v-if="!storyBeingLoaded"
        v-tooltip.bottom="currentStory.story_name"
        class="lg:w-7 md:w-6 overflow-hidden sm:mr-3 sm:w-3 text-overflow-ellipsis white-space-nowrap"
      >
        {{ currentStory.story_name }}
      </p>
      <Skeleton v-else class="lg:w-7 md:w-6 sm:mr-3 sm:w-3" />
    </div>
    <div class="flex flex-shrink-0">
      <div class="flex justify-content-center p-inputgroup">
        <RouterLink to="build">
          <Button
            :disabled="storyBeingLoaded"
            style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important"
            :class="[currentStep ? 'p-button-outlined' : '']"
            :label="ts.tModule('storyBuilder.title')"
          />
        </RouterLink>
        <RouterLink to="link-and-publish">
          <Button
            style="border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important"
            :disabled="storyBeingLoaded"
            :class="[currentStep ? '' : 'p-button-outlined']"
            :label="ts.tModule('storyBuilder.header.linkProducts')"
          />
        </RouterLink>
      </div>
    </div>

    <div class="flex flex-grow-1 justify-content-end w-3">
      <div class="align-items-center flex mr-4 text-gray-400">
        <p class="mr-2">{{ ts.tGlobal('autosaved') }}</p>
        <i
          v-tooltip="ts.tModule('storyBuilder.header.autoSaveTooltip')"
          class="mdi mdi-information-outline"
        />
      </div>
      <Button :label="ts.tGlobal('submit')" :disabled="!isSubmitActive" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
$border-color: #dce5ef;
.lower-border {
  border-bottom: 1px solid $border-color;
}
</style>
