<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { defineEmits, ref } from 'vue';

/**Props */
defineProps<{
  buttonClass?: string;
}>();

const emit = defineEmits(['gtinSearchValues']);
const ts = new TranslationService('supplier', 'products');
const op = ref();
const gtinSearch = ref('');
const toggle = (event: MouseEvent): void => {
  op.value.toggle(event);
};

const formatText = () => {
  const input = gtinSearch.value;
  const rawValueArray: string[] = input.split('\n'); //each line => one element in the array
  const numbersArray: string[] = rawValueArray.map((el) =>
    el.replace(/\D/g, ' ').replace(/\s\s+/g, ' '),
  ); //removing letters and replacing multiple spaces with single space
  const number2dArray: string[][] = numbersArray.map((number) => number.split(' ')); //each element is an array of different numbers
  const converted2dArray: string[] = ([] as string[]).concat(...number2dArray); // convert 2d array to 1d array. we have an array of numbers
  const gtinsArray = converted2dArray.filter((el) => el !== '' && el.length > 5); // removing empty elements and unrelated numbers
  gtinSearch.value = gtinsArray.join('\n');
};

const applyGtinFilter = (clear = false) => {
  if (clear) {
    gtinSearch.value = '';
  }

  const gtinsArray = gtinSearch.value.split('\n');
  const validGtins = gtinsArray.filter(
    (gtin) => gtin.length == 8 || gtin.length == 12 || gtin.length == 13 || gtin.length == 14,
  );

  emit('gtinSearchValues', validGtins);
};
</script>

<template>
  <Button
    v-tooltip.bottom="{ value: ts.tModule('gtin_search'), class: 'text-sm' }"
    class="bg-white border-100 p-button-sm text-800"
    :class="buttonClass"
    aria:haspopup="true"
    :label="ts.tModule('gtins')"
    icon="mdi mdi-magnify text-xl"
    icon-pos="right"
    aria-controls="overlay_panel"
    @click="toggle"
  />
  <Popover
    id="overlay_panel"
    ref="op"
    append-to="body"
    :show-close-icon="true"
    style="width: 450px"
    :breakpoints="{ '960px': '75vw' }"
  >
    <div>
      <h3 class="font-bold mb-3 text-2xl">{{ ts.tModule('filters') }}</h3>
      <div class="field mb-3">
        <label class="mb-1" for="eans">{{ ts.tModule('gtins') }}</label>
        <Textarea id="eans" v-model="gtinSearch" class="w-full" rows="12" @change="formatText()" />
        <small class="flex justify-content-end"
          ><span :class="gtinSearch.split('\n').length > 500 ? 'text-red-500 font-bold' : ''">
            {{ gtinSearch ? gtinSearch.split('\n').length : 0 }}</span
          >
          / {{ 500 }}</small
        >
      </div>
      <div
        class="bg-white border-100 border-top-1 bottom-0 justify-content-between mt-5 py-3 sticky"
      >
        <div class="flex justify-content-between ml-auto mr-0">
          <Button
            plain
            text
            :label="ts.tGlobal('clear')"
            class="mr-2"
            @click="(event: MouseEvent) => {
              (gtinSearch = ''),
                applyGtinFilter(true),
                toggle(event)}
            "
          />
          <Button
            :label="ts.tGlobal('apply')"
            @click="(event: MouseEvent ) =>{ applyGtinFilter(), toggle(event)}"
          />
        </div>
      </div>
    </div>
  </Popover>
</template>
