<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import axios, { Method } from 'axios';
import { CoreRestService } from 'platform-unit2-api/core';
import { CreateUploadRequest, Upload, UploadsRestService } from 'platform-unit2-api/uploads';
import { ref, watch } from 'vue';

/** Props */
interface Props {
  hasAssets: boolean;
}

const props = defineProps<Props>();

/** Emits */
const emit = defineEmits<{
  (e: 'uploaded', uploads?: Upload[]): void;
  (e: 'disabled', value?: boolean): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'uploads');
const toastService = ToastService.getInstance();
const coreApi = new CoreRestService();
const uploadApi = new UploadsRestService();

/** Variables */
const uploadPercentage = ref(0);
const uploading = ref(false);
const filePercentages = ref<number[]>([]);
const assetsArray = ref<Upload[]>([]);

function setPogress() {
  uploadPercentage.value =
    filePercentages.value.reduce((acc, curr) => acc + curr) / filePercentages.value.length;
}

/** Constants */
async function upload(file: File, index: number): Promise<Upload> {
  try {
    const sign = await coreApi.signFile(file);

    await axios.request({
      url: sign.url,
      method: sign.method as Method,
      data: file,
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (progressEvent) => {
        const currentLoadedAmount = (progressEvent.progress ?? 0) * 100;

        filePercentages.value[index] = currentLoadedAmount;

        setPogress();
      },
    });

    filePercentages.value[index] = 100;

    const upload: CreateUploadRequest = {
      filename: file.name,
      contenttype: file.type,
      reference: sign.reference,
      resolution_in_pixels_height: 0,
      resolution_in_pixels_width: 0,
    };

    return await uploadApi.post(upload);
  } catch (err: any) {
    toastService.displayErrorToast(ts.createFailed());

    throw err;
  }
}

function getUploadPromises(event: Record<'files', File | File[]>): Promise<Upload>[] {
  const files = Array.isArray(event.files) ? event.files : [event.files];
  return files.map((file, index) => {
    filePercentages.value[index] = 0;

    return upload(file, index);
  });
}

async function handleUploadPromises(
  uploadPromises: Promise<Upload>[],
  event: Record<'files', File | File[]>,
  uploadIndex: number,
) {
  const files = Array.isArray(event.files) ? event.files : [event.files];

  const uploadResults: Upload[] = await Promise.all(uploadPromises);

  for (let x = 0; x < uploadResults.length; x++) {
    const result = uploadResults[x];

    const upload = await uploadApi.get(Number(result.id));

    assetsArray.value.push(upload);

    filePercentages.value[uploadIndex] = (100 / files.length) * x;

    setPogress();
  }
}

async function uploadAssets(event: Record<'files', File | File[]>) {
  uploading.value = true;

  try {
    const uploadPromises = getUploadPromises(event);

    const uploadIndex = filePercentages.value.length + 1;

    filePercentages.value[uploadIndex] = 0;

    await handleUploadPromises(uploadPromises, event, uploadIndex);

    emit('uploaded', assetsArray.value ?? []);

    toastService.displaySuccessToast(
      ts.tModule('uploadedSuccessfully', { params: { count: assetsArray.value.length } }),
    );
  } catch (err: any) {
    toastService.displayErrorToast(ts.createFailed());
  } finally {
    uploadPercentage.value = 100;
    emit('disabled', false);
  }
}

watch(
  () => props.hasAssets,
  () => {
    if (!props.hasAssets) {
      uploading.value = false;
      assetsArray.value = [];
      uploadPercentage.value = 0;
      filePercentages.value = [];
    }
  },
);
</script>

<template>
  <div>
    <ProgressBar v-if="uploading" class="mb-3" :progress="Math.round(uploadPercentage) ?? 0" />

    <FileUpload
      :custom-upload="true"
      :multiple="true"
      :disabled="uploading || hasAssets"
      name="names[]"
      @uploader="uploadAssets"
    >
      <template #empty>
        <p>{{ ts.tModule('dragDrop') }}</p>
      </template>
    </FileUpload>
  </div>
</template>

<style lang="scss" scoped>
.p-fileupload :deep(.p-fileupload-content) {
  height: 30vh;
  overflow: scroll;
}

.p-fileupload :deep(.p-fileupload-file) {
  gap: 1rem;
}
.p-fileupload :deep(.p-fileupload-file-size) {
  margin-right: 1rem;
  color: gray;
}
.p-fileupload :deep(.p-fileupload-file-name) {
  margin-right: 3px;
}
</style>
