<script setup lang="ts">
//UI
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import StatusChip, { StatusSeverity } from '@/general/ui/components/status-chip.vue';
//Keys
import { tsKey } from '@/general/services/translations/translation.key';

//Vue
import { ContentStory } from '@/api/v1/content-story/ts/content-story.interface';
import { formatDate } from '@/general/utils/format-date';
import { MenuItem } from 'primevue/menuitem';
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import { serviceKey } from '../../ts/helpers/story-manager-keys';

/**
 * If this table is to be used in another view, the service must be provided.
 *
 * Or this component can be rewritten to also use props.
 * Service should then based on typed "TableService | OverViewService" that implement a common interface.
 */
const ts = inject(tsKey)!;
const service = inject(serviceKey)!;
const router = useRouter(); // use for the swap of pages

const menuItems = (story: ContentStory): MenuItem[] => [
  {
    icon: 'mdi mdi-pencil-outline',
    label: ts.value.tGlobal('rename'),
    command: () => {
      service.value.openCrudComponent(story, false);
    },
  },
  {
    icon: 'mdi mdi-content-copy',
    label: ts.value.tGlobal('duplicate'),
    command: () => {
      service.value.duplicationAction(story.id);
    },
  },
  {
    separator: true,
  },
  {
    icon: 'mdi mdi-delete-outline',
    label: ts.value.tGlobal('delete'),
    class: 'delete',
    command: () => {
      service.value.deleteAction(story.id);
    },
  },
];

const getStatus = (
  row: ContentStory,
): { label: string; severity: StatusSeverity; labelOnly: boolean } => {
  if (!row.is_draft && (row.product_ids ?? [])?.length === 0) {
    return { label: ts.value.tModule('waitingForPublish'), severity: 'paused', labelOnly: true };
  }

  if (!row.is_draft && (row.product_ids ?? [])?.length > 0) {
    return {
      label: `${(row.published_product_ids ?? [])?.length ?? 0} / ${
        (row.product_ids ?? [])?.length ?? 0
      } ${ts.value.tGlobal('published')}`,
      severity: 'info',
      labelOnly: true,
    };
  }

  return { label: ts.value.tGlobal('concept'), severity: 'primary', labelOnly: true };
};
</script>

<template>
  <DataTable
    scrollable
    scroll-height="flex"
    :value="service.contentStories"
    removable-sort
    :row-hover="true"
    :lazy="true"
    :paginator="service.total > (service.limit ?? 5)"
    :loading="service.isLoadingOverView"
    :rows="service.limit"
    :total-records="service.total"
    :sort-field="service.sortField"
    :sort-order="service.sortOrder"
    :first="service.first"
    @row-click="
      router.push({ name: 'story-builder-build', params: { id: String($event.data.id) } })
    "
    @page="service.onPageChange($event.page)"
    @sort="service.onSortChange($event)"
  >
    <Column
      field="story_name"
      :header="ts.tGlobal('name')"
      sortable
      style="
         {
          justify-content: start;
        }
      "
    />
    <Column field="product_ids" :header="ts.tModule('columnLables.products')" sortable>
      <template #body="{ data }: { data: ContentStory }"
        >{{ data.product_ids?.length?.toString() ?? '0' }}
      </template>
    </Column>
    <Column field="is_draft" :header="ts.tGlobal('status')" sortable>
      <template #body="{ data }: { data: ContentStory }">
        <StatusChip
          :label="getStatus(data).label"
          :severity="getStatus(data).severity"
          label-only
        />
      </template>
    </Column>
    <Column field="updated_at" :header="ts.tGlobal('lastUpdate')" sortable>
      <template #body="{ data }: { data: ContentStory }">
        {{ data.updated_at ? formatDate(new Date(data.updated_at)) : '' }}
      </template>
    </Column>
    <Column style="min-width: 5rem; flex-grow: 0; border-right: 0">
      <template #body="{ data }: { data: ContentStory }">
        <ActionsMenu :menu-items="menuItems(data)" />
      </template>
    </Column>

    <template #empty>
      <EmptyState
        :translation-service="ts"
        icon-name="empty_story_manager_overview"
        @clicked="router.push({ name: 'new-story' })"
      />
    </template>
  </DataTable>
</template>
