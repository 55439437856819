<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import { Thumbnail } from 'platform-unit2-api/thumbnails';
import ThumbnailUploader from 'ui/components/thumbnail-uploader.vue';
import { inject } from 'vue';
import { brandServiceKey } from '../ts/keys/brand.keys';

const ts = inject(tsKey)!;
const service = inject(brandServiceKey)!;
</script>

<template>
  <div class="field mb-3">
    <label for="name">{{ ts.tForms('name') }}</label>
    <InputText
      id="name"
      v-model="service.partialObject.name"
      class="w-full"
      :class="{ 'p-invalid': service.hasError('name') }"
      type="text"
    />
    <small
      v-if="service.hasError('name')"
      :class="{ 'p-error block': service.hasError('name') }"
      class="hidden"
      >{{ service.fieldErrorMessage('name').toString() }}</small
    >
  </div>
  <div class="field mb-3">
    <label for="description">{{ ts.tForms('description') }}</label>
    <Textarea
      id="description"
      v-model="service.partialObject.description"
      class="w-full"
      :class="{ 'p-invalid': service.hasError('description') }"
      rows="5"
      cols="60"
    />
    <small
      v-if="service.hasError('description')"
      :class="{ 'p-error block': service.hasError('description') }"
      class="hidden"
      >{{ service.fieldErrorMessage('description').toString() }}</small
    >
  </div>
  <div class="field mb-3">
    <label for="logo">{{ ts.tForms('logo') }}</label>
    <div class="align-items-center flex formgroup-inline">
      <ThumbnailUploader
        :is-avatar="false"
        :thumbnail="service.partialObject.thumbnail"
        :class="{ 'p-error block': service.hasError('thumbnail_id') }"
        @uploaded="(thumbnailValue?:Thumbnail) => ((service.partialObject.thumbnail = thumbnailValue), (service.uploading = false))"
        @uploading="() => (service.uploading = true)"
      />
      <small
        v-if="service.hasError('thumbnail_id')"
        :class="{ 'p-error block': service.hasError('thumbnail_id') }"
        class="hidden"
        >{{ service.fieldErrorMessage('thumbnail_id').toString() }}</small
      >
    </div>
  </div>
</template>
