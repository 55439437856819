import { RefetchService } from '@/general/services/overview-service/refetch.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { TableColumnInterface } from '@/platforms/supplier/modules/products/services/product-table.constants';
import { FilterMatchMode } from '@primevue/core/api';
import {
  Brand,
  Client,
  Datamodel,
  Module,
  Product,
  ProductsRestService,
  ProductStatus,
} from 'platform-unit2-api';
import {
  DataTableFilterEvent,
  DataTableFilterMeta,
  DataTableFilterMetaData,
} from 'primevue/datatable';

export class ProductsTableGenericService extends RefetchService {
  public tableColumns: TableColumnInterface[];
  public checkedProductRows: Product[];

  private _productsRestService = new ProductsRestService();
  public loading = false;
  public productFilters: DataTableFilterMeta = {
    variant_name: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    'datamodel.name': {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    owner_id: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    brand_id: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    product_status_id: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    module_id: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    created_at: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
  };
  public readonly ts = new TranslationService('supplier', 'products');
  public readonly toastService = ToastService.getInstance();

  private _data: Product[] = [];

  constructor(tableColumns: TableColumnInterface[], checkedProductRows: Product[]) {
    super();
    this.tableColumns = tableColumns;
    this.checkedProductRows = checkedProductRows;

    this.refetch = this.getProducts;
  }

  public get products(): Product[] {
    return this._data;
  }

  public get isAllChecked() {
    return this.products.every((product) =>
      this.checkedProductRows.map((product) => product.id).includes(product.id),
    );
  }
  public set isAllChecked(value: boolean) {
    this._toggleCheckAll(value);
  }

  private _toggleCheckAll(checked: boolean) {
    const productIds = this.products.map((product) => product.id);

    if (checked) {
      this.checkedProductRows = [
        ...this.checkedProductRows,
        ...this.products.filter(
          (product) => !this.checkedProductRows.map((prod) => prod.id).includes(product.id),
        ),
      ];
    } else {
      this.checkedProductRows = this.checkedProductRows.filter(
        (row: Product) => !productIds.includes(row.id),
      );
    }
  }

  public getSelectedProductIds(): number[] {
    return this.checkedProductRows.map((product) => product.id);
  }

  public getProducts() {
    this.loading = true;

    this._productsRestService
      .getAll(this._paginationParams, this.filterParams)
      .then((response) => {
        if (this.page === 1) {
          // Merge the selected products with the new products and display them only in the first page of the products table
          this._data = this.mergeProductArrays(response.data, this.checkedProductRows);
        } else {
          this._data = response.data;
        }

        this.total = response.meta?.total;
      })
      .catch(() => {
        this.toastService.displayErrorToast(
          this.ts.loadFailed(this.ts.tModule('snackbars.loadingFailed')),
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public mergeProductArrays(products: Product[], selectedProducts: Product[]): Product[] {
    const mergedArray = [...selectedProducts, ...products];
    const uniqueProducts = new Map<number, Product>();

    mergedArray.forEach((product) => {
      uniqueProducts.set(product.id, product);
    });

    return Array.from(uniqueProducts.values());
  }

  public applyFilter(event: DataTableFilterEvent) {
    this.filterParams = {
      ...this.filterParams,
      datamodel_ids: (event.filters['datamodel.name'] as DataTableFilterMetaData).value?.map(
        (datamodel: Datamodel) => datamodel.id,
      ),
      module_ids: (event.filters['module_id'] as DataTableFilterMetaData).value?.map(
        (module: Module) => module.id,
      ),
      variants: (event.filters['variant_name'] as DataTableFilterMetaData).value?.map(
        (variant: Product) => variant.display_name,
      ),
      owner_ids: (event.filters['owner_id'] as DataTableFilterMetaData).value?.map(
        (owner: Client) => owner.id,
      ),
      brand_ids: (event.filters['brand_id'] as DataTableFilterMetaData).value?.map(
        (brand: Brand) => brand.id,
      ),
      status_labels: (event.filters['product_status_id'] as DataTableFilterMetaData).value?.map(
        (status: ProductStatus) => status.label,
      ),
      created_at: (event.filters['created_at'] as DataTableFilterMetaData).value?.id,
    };

    this.getProducts();
  }

  public applyGTINFilter(gtins: string[]) {
    this.filterParams = {
      ...this.filterParams,
      gtins: gtins,
    };

    this.getProducts();
  }
}
