<script setup lang="ts">
import { InputSelectType } from '@/general/services/attribute-fields/interfaces/input-select.interface';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { InputSelectFieldService } from 'services/attribute-fields/field-services/input-select-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import { inject, ref } from 'vue';
import WrapperField from './wrapper-field.vue';

/** Props */
interface Props {
  attributeField: AttributeField<InputSelectType>;
  productAttributeField: ProductAttributeField<InputSelectType>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const inputSelectFieldService = ref(
  new InputSelectFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>
<template>
  <WrapperField :attribute-field-service="inputSelectFieldService">
    <InputGroup
      :class="
        inputSelectFieldService.getErrorTypeClass() === ''
          ? 'input-group-focus'
          : `${inputSelectFieldService.getErrorTypeClass()} border-1`
      "
    >
      <InputNumber
        v-model="inputSelectFieldService.decimal"
        input-class="border-right-none border-noround-left "
        :min-fraction-digits="0"
        :max-fraction-digits="5"
        :disabled="inputSelectFieldService.productAttributeField.locked"
        :placeholder="inputSelectFieldService.ts.tGlobal('placeholder')"
      />
      <InputGroupAddon class="p-0">
        <Select
          v-model="inputSelectFieldService.unit"
          class="bg-white border-left-none border-noround-left h-full min-w-min"
          :class="
            inputSelectFieldService.getErrorTypeClass() === ''
              ? 'input-group-focus'
              : `${inputSelectFieldService.getErrorTypeClass()} border-1`
          "
          :show-clear="
            inputSelectFieldService.unit != null &&
            !inputSelectFieldService.productAttributeField.locked
          "
          :placeholder="inputSelectFieldService.ts.tGlobal('unit')"
          :options="inputSelectFieldService.attributeField.attribute?.options?.selectValues"
          :disabled="inputSelectFieldService.productAttributeField.locked"
          :pt="{
            input: {
              class: 'align-items-center flex',
            },
          }"
        />
      </InputGroupAddon>
    </InputGroup>
  </WrapperField>
</template>
<style lang="scss" scoped>
.input-group-focus:focus-within {
  border: 1px solid $focus-border-color;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
</style>
