<script setup lang="ts">
import { useDispatch } from '@/general/composables/UseDispatch';
import { DispatchSuccess, DisplayToastSetting } from '@/general/composables/UseDispatch/types';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { UploadedFile } from 'platform-unit2-api/core';
import { Upload } from 'platform-unit2-api/uploads';
import { FileUploadUploadEvent } from 'primevue/fileupload';
import { ref, watch } from 'vue';

// Interfaces
export interface UploadBoxFile extends File {
  type: string;
  objectURL?: string;
  isFromMediaPicker?: boolean;
  loading?: boolean;
  hasBeenUploadedInSession?: boolean;
}

// Props
interface Props {
  hasDragAndDropBox?: boolean;
  removeHeader?: boolean;
  additionalFiles?: UploadBoxFile[];
  dropboxFullHeight?: boolean;
  wrapperClass?: string;
  customLoading?: boolean;
  accept?: string;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'input', data: UploadedFile): void;
  (e: 'remove', data: UploadedFile): void;
  (e: 'filter-additional-files', files: UploadBoxFile[]): void;
}>();

// Constants
const ts = new TranslationService('general', 'components');
const uploader = ref();
const { dispatch, loading } = useDispatch();
const refresh = ref(false);
const pupDragAndDropFileInput = ref<HTMLElement>();

// Helper functions
const upload = async (_?: FileUploadUploadEvent): Promise<void> => {
  const filteredFiles = ((uploader.value?.files ?? []) as UploadBoxFile[]).filter(
    (file) => !file.isFromMediaPicker && !file.hasBeenUploadedInSession,
  );
  if (filteredFiles.length === 0) {
    return;
  }

  for await (const file of filteredFiles) {
    file.loading = true;
    await dispatch('uploads/UPLOAD', {
      parameters: file,
      successCallback: dispatchSuccessCallBack(file),
      rejectCallback: () => {
        file.loading = false;
      },
      toast: {
        rejectMessage: ts.uploadFailed(),
        displaySetting: DisplayToastSetting.REJECT_ONLY,
      },
    });
    refresh.value = !refresh.value; // update array so that the view can be rerendered
  }
};

const dispatchSuccessCallBack =
  (file: UploadBoxFile) => (successResponse: DispatchSuccess<Upload>) => {
    const dataToUploadedFile: UploadedFile = {
      name: successResponse.data?.filename ?? '',
      uploadId: successResponse.data?.id ?? 0,
      url: successResponse.data?.public_url ?? '',
      type: successResponse.data?.contenttype ?? '',
      upload: successResponse.data,
    };
    file.loading = false;
    file.objectURL = successResponse.data?.public_url;
    file.hasBeenUploadedInSession = true;
    emit('input', dataToUploadedFile);
  };

const handleFileRemove = (file: UploadBoxFile, index: number) => {
  if (file.isFromMediaPicker) {
    emit('filter-additional-files', [file]);
  }

  emit('remove', {
    name: file.name,
    type: file.type,
    url: file.objectURL ?? '',
    uploadId: 0, // not needed
  });
  uploader.value?.remove(index);
};

const openFileSelector = () => {
  pupDragAndDropFileInput.value?.click();
};

// Lifecycle hooks
watch(
  () => uploader.value?.files,
  () => {
    upload();
  },
  { deep: true },
);

watch(
  () => uploader.value?.messages,
  () => {
    if (uploader.value?.messages?.length === 0) {
      return;
    }

    uploader.value?.messages?.forEach((message: string) => {
      ToastService.getInstance().displayErrorToast(message);
    });
  },
);

watch(
  () => props.additionalFiles,
  () => {
    if (uploader.value) {
      //remove all files from the media picker and add the newly passed array
      uploader.value.files = (uploader.value.files ?? [])
        .filter((file: UploadBoxFile) => !file.isFromMediaPicker)
        .concat(props.additionalFiles);
    }
  },
  { deep: true },
);
</script>

<template>
  <div
    :class="`pup-file-upload
      ${removeHeader && 'remove-header'}
      ${dropboxFullHeight && 'full-height'}
      ${wrapperClass ?? ''}`"
  >
    <FileUpload
      ref="uploader"
      :disabled="loading || customLoading"
      mode="advanced"
      v-bind="{ ...props }"
    >
      <template
        #content="{
          files,
          uploadedFiles,
          removeUploadedFileCallback,
          removeFileCallback,
          progress,
          messages,
        }"
      >
        <slot
          name="custom-content"
          :files="files"
          :uploaded-files="uploadedFiles"
          :remove-uploaded-file-callback="removeUploadedFileCallback"
          :remove-file-callback="removeFileCallback"
          :progress="progress"
          :messages="messages"
          :handle-file-remove="handleFileRemove"
          :open-file-select="openFileSelector"
          :loading="loading"
        />
        <input
          ref="pupDragAndDropFileInput"
          data-testid="upload-box-custom-content-input"
          class="hidden"
          :accept="accept"
          type="file"
          :multiple="uploader?.multiple"
          @change="(e) => uploader?.onFileSelect?.(e)"
        />
      </template>
      <template v-if="hasDragAndDropBox && additionalFiles?.length === 0" #empty>
        <div class="align-items-center flex flex-column justify-content-center no-files-div">
          <img src="@/assets/images/emptyFileUpload.svg" />
          <p class="mb-0 mt-4">
            {{ ts.tModule('uploadBox.generalUploadFilesMessage') }}
          </p>
          {{ ts.tGlobal('or') }}
          <label for="pup-drag-and-drop-file-input">{{ ts.tModule('uploadBox.selectFile') }}</label>
          <input
            ref="pupDragAndDropFileInput"
            :accept="accept"
            type="file"
            :multiple="uploader?.multiple"
            @change="(e) => uploader?.onFileSelect?.(e)"
          />
        </div>
      </template>
    </FileUpload>
  </div>
</template>
<style lang="scss">
.pup-file-upload {
  height: 100%;
}
.pup-file-upload.full-height {
  .p-fileupload {
    height: 100%;
  }
  .p-fileupload .p-fileupload-empty {
    height: 100%;
  }
  .p-fileupload .p-fileupload-content {
    height: 100%;
    padding: 1rem 1rem;
    border: none;
  }
}
.pup-file-upload.remove-header {
  .p-fileupload-buttonbar {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.no-files-div {
  height: 100%;
  border: #0089d7 dashed 1px;
  input {
    display: none;
  }
  label {
    cursor: pointer;
    color: #0089d7;
    text-decoration: underline;
  }
}
.p-fileupload :deep(.p-fileupload-content) {
  border-radius: 6px !important;
}

.p-fileupload :deep(.p-fileupload-highlight) {
  color: #2a71e5 !important;
  border: 1px dashed #377ef2 !important;
  background: #f0f8ff !important;
  border-radius: 6px !important;
}
</style>
