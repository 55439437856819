<script setup lang="ts">
import { TabFieldService } from '@/general/services/attribute-fields/field-services/tab-field.service';
import AttributeFieldsRenderer from '@/general/ui/components/attribute-fields/attribute-fields-renderer.vue';
import { uniqueId } from 'lodash';
import { AttributeField } from 'platform-unit2-api/attribute-fields';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import Flag from 'ui/components/attribute-fields/flag.vue';
import TabsFieldDnd from 'ui/components/attribute-fields/tabs-field/tabs-field-dnd.vue';
import { v4 as uuidv4 } from 'uuid';
import { inject, provide, ref, unref } from 'vue';
import { lockableKey } from '../inject.keys';
import TabsFieldHeader from './tabs-field-header.vue';
/** Props */
interface Props {
  attributeField: AttributeField<string[]>;
}

const props = defineProps<Props>();
const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;
const tabUUID = uuidv4();
const confirmGroup = ref(tabUUID);
/** Services */
const tabFieldService = ref(
  new TabFieldService(
    props.attributeField,
    unref(productAttributeFieldsService.value),
    confirmGroup.value,
  ),
);

provide(lockableKey, false);
</script>

<template>
  <div
    v-for="(locale, localeIndex) in tabFieldService.getLocalesToRender(
      productAttributeFieldsService.chosenLocaleIds,
    )"
    :key="
      uniqueId(attributeField.attribute.id.toString() + localeIndex.toString() + locale.toString())
    "
    class="mb-3 mt-2"
  >
    <div class="flex">
      <Flag :locale="locale" :global="attributeField.attribute.global_attribute" />
      <Accordion class="tab-accordion w-full">
        <AccordionPanel value="">
          <AccordionHeader
            :pt="{
              root: () => ({
                class: 'border-noround-left',
              }),
            }"
          >
            {{ attributeField.attribute.key }}
          </AccordionHeader>
          <AccordionContent>
            <TabsFieldDnd
              :locale="locale"
              :tab-field-service="(tabFieldService as TabFieldService)"
              :tab-u-u-i-d="tabUUID"
            >
              <template #tabHeader="{ tab, index, activeIndex, deleteTabConfirmCallback }">
                <TabsFieldHeader
                  :tab="tab"
                  :index="index"
                  :active-index="activeIndex"
                  :tab-field-service="(tabFieldService as TabFieldService)"
                  :locale="locale"
                  @delete-tab-confirm-callback="deleteTabConfirmCallback"
                />
              </template>
              <template #default="{ tab }">
                <AttributeFieldsRenderer
                  :attribute-fields="tab.attributes"
                  :advance-key="tab.header"
                />
              </template>
            </TabsFieldDnd>
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
    </div>
  </div>

  <!-- delete / duplicate  -->
  <ConfirmDialog :group="confirmGroup" />
</template>

<style lang="scss" scoped>
.tab-accordion {
  max-width: calc(100% - 35px);
}
</style>
