<script setup lang="ts">
// Third party imports
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';

// Composables
import { useRoute, useRouter } from 'vue-router';

// Components imports
import Upload from '@/general/ui/components/upload.vue';
import SvgIcon from 'ui/components/svg-icon.vue';

// Import types
import PuPLogo from '@/assets/images/pup-logo-white.svg';
import { TranslationService } from '@/general/services/translations/translation.service';
import { isNegative } from '@/general/utils/isNegative';
import { openMailClientWithSupportPrefilled } from '@/general/utils/mail-tp-rpx';
import { ListResponse } from 'platform-unit2-api/core';
import { Download, ExportRestService } from 'platform-unit2-api/exports';

/** Composables */
const router = useRouter();
const route = useRoute();

/** Services */
const ts = new TranslationService('retailer', 'downloads');
const exportApi = new ExportRestService();

/** Constants */
const loading = ref(false);
const downloads = ref<ListResponse<Download>>();
const nonZipDownloads = computed(() => {
  const filename = downloads.value?.data?.filter(
    (download: Download) => download.filename.slice(-4) !== '.zip',
  );
  return filename;
});

const zipDownload = computed((): Download | undefined => {
  const zippedFiles = downloads.value?.data?.filter(
    (download: Download) => download.filename.slice(-4) === '.zip',
  );
  return zippedFiles?.[0];
});

const getData = async () => {
  if (isNegative(route.params.id)) {
    return;
  }

  loading.value = true;

  if (typeof route.query.expires !== 'string' || typeof route.query.signature !== 'string') {
    router.push({ name: 'default' });
    return;
  }

  try {
    downloads.value = await exportApi.getDownloads(
      Number(route.params.id),
      route.query.expires,
      route.query.signature,
    );
  } catch {
    router.push({ name: 'default' });
  } finally {
    loading.value = false;
  }
};

const download = (file?: Download) => {
  if (!file) {
    return;
  }

  loading.value = true;
  axios
    .request({
      url: file.public_url,
      headers: {
        'Content-Disposition': 'attachment; filename="' + encodeURIComponent(file.filename) + '"',
      },
      responseType: 'blob',
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      link.setAttribute('download', encodeURIComponent(file.filename));
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      setTimeout(() => {
        loading.value = false;
      }, 1000);
    });
};

const checkFileTypeThumbnail = (file: Download) => {
  const fileTypes = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.svg',
    '.ico',
    '.tiff',
    '.apng',
    '.bmp',
    'dib',
    '.avif',
  ];
  const fileHasThumbnail = fileTypes.some((displayedFiletype) =>
    file.filename.includes(displayedFiletype),
  );

  return fileHasThumbnail;
};

/** Lifecycle */
onMounted(() => {
  getData();
});
</script>
<template>
  <div>
    <!-- Background -->
    <div
      class="absolute h-full w-full"
      style="clip-path: polygon(0 0, 100% 0, 100% 50%, 0 70%); background-color: #172533"
    ></div>
    <img
      class="absolute bottom-0 h-full left-0 right-0 top-0 w-full"
      src="@/assets/images/downloads-background.svg"
      alt="Productsup"
    />
    <div class="flex flex-column h-full max-h-screen overflow-y-hidden p-4 relative">
      <img
        class="cursor-pointer mb-5 w-8rem"
        :src="PuPLogo"
        alt="Productsup"
        @click="
          router.push({
            name: 'dashboard',
          })
        "
      />

      <!-- Header information and information about the sender of the export.-->
      <div class="flex flex-column h-full md:w-6 mx-auto my-auto overflow-y-hidden w-full">
        <div
          class="bg-white border-1 border-200 border-round flex flex-column h-full mb-5 overflow-y-hidden"
        >
          <header class="p-4 sticky text-center top-0">
            <h3
              class="mb-1"
              v-html="downloads ? ts.tModule('downloadReady') : ts.tModule('noDownloadsTitle')"
            ></h3>
            <p
              class="text-blue-800"
              v-html="
                downloads
                  ? ts.tModule('senderMessage', {
                      params: {
                        name: downloads.meta?.user_name,
                        workspace: downloads.meta?.client_name,
                      },
                    })
                  : ts.tModule('noDownloadsSubtitle')
              "
            ></p>
          </header>
          <!-- amount of files to download or return to export button.-->
          <section v-if="!loading" class="px-4 py-2">
            <p v-if="nonZipDownloads && nonZipDownloads.length">
              {{ nonZipDownloads.length }} Files
            </p>
            <div v-else class="align-items-center flex flex-column justify-content-center">
              <i class="mb-5 mdi mdi-file-remove-outline opacity-40 text-400 text-8xl" />
              <Button
                :label="ts.tModule('goToExports')"
                class="p-button-rounded"
                @click="
                  router.push({
                    name: 'export',
                  })
                "
              ></Button>
            </div>
          </section>

          <!-- Skeleton loaders -->
          <section v-if="loading">
            <div v-for="i in 5" :key="i" class="m-3">
              <Skeleton height="50px" />
            </div>
          </section>

          <!-- Generating the individual files to download -->
          <section v-if="!loading" class="flex-1 overflow-y-auto p-4">
            <li
              v-for="file in nonZipDownloads"
              :key="file.public_url"
              class="align-items-center border-1 border-200 border-round flex mb-2 p-1"
            >
              <Upload
                v-if="checkFileTypeThumbnail(file)"
                class="mr-5 p-2 w-5rem"
                :preview="true"
                :upload="file"
                size="50"
              />

              <SvgIcon
                v-else
                :filename="file.filename"
                custom-style="width: 4rem"
                class="flex-shrink-0 mr-5"
              />

              <div>
                <p class="font-bold mb-1">{{ file.filename }}</p>
              </div>
              <Button
                style="color: #cad9e7"
                class="flex-shrink-0 ml-auto mr-2 p-button-outlined p-button-rounded"
                icon="mdi mdi-tray-arrow-down"
                @click="download(file)"
              />
            </li>
          </section>
          <!-- Footer shown at the bottom of the downloadable files. allows to download all files as a zip -->
          <footer
            v-if="zipDownload && zipDownload.public_url != null"
            style="background-color: #f5f7fa"
            class="border-200 border-round-bottom border-top-1 bottom-0 py-4 sticky text-center"
          >
            <Button
              v-if="zipDownload && zipDownload.public_url != null"
              class="p-button-rounded"
              icon="mdi mdi-tray-arrow-down"
              :loading="loading"
              :label="
                loading
                  ? ts.tModule('downloading')
                  : ts.tModule('downloadAll', { params: { amount: nonZipDownloads?.length } })
              "
              @click="download(zipDownload)"
            >
            </Button>
          </footer>
        </div>
        <!-- Support button underneath the downloads-->
        <div
          class="text-blue-700 text-right"
          @click="openMailClientWithSupportPrefilled()"
          v-html="ts.tModule('helpText')"
        ></div>
      </div>
    </div>
  </div>
</template>
