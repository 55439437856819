<script setup lang="ts">
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import { inject } from 'vue';

import { OverviewSidebarBase } from '@/general/services/overview-service/interfaces/overview-sidebar.interface';
import { tsKey } from '@/general/services/translations/translation.key';
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';

interface Props {
  service: OverviewSidebarBase;
  title?: string;
  subtitle?: string;
}

defineProps<Props>();

const ts = inject(tsKey)!;
</script>
<template>
  <Drawer
    :visible="service.crudComponent"
    class="p-drawer-md"
    position="right"
    @update:visible="service.closeCrudComponent()"
  >
    <template #header>
      <div>
        <h3 class="font-bold mb-1 text-2xl">
          {{ title ?? (service.isCreating ? ts.moduleCreateTitle : ts.moduleUpdateTitle) }}
        </h3>
        <p class="text-lg">
          {{ subtitle ?? (service.isCreating ? ts.moduleCreateSubTitle : ts.moduleUpdateSubTitle) }}
        </p>
      </div>
    </template>
    <CrudSidebar
      v-if="service"
      :disable-save="service.disableSave ?? true"
      :loading="service.isLoadingCrudComponent"
      :has-delete-button="!service.isCreating"
      @cancel="service.closeCrudComponent()"
      @save="service.save()"
      @delete="service.confirmDelete($event)"
    >
      <template #sidebar-data>
        <SidebarSkeleton v-if="service.isLoadingCrudComponent" />
        <slot v-else name="form" />

        <ConfirmPopup :group="service.confirmPopUpGroup" />
      </template>
    </CrudSidebar>
  </Drawer>
</template>

<style scoped lang="scss"></style>
