<script setup lang="ts">
import { FilterValueInterface } from '@/general/services/data-table-filter-service/data-table-filter.service';
import { dateTimeToIsoString } from '@/general/utils/date-time-to-utc-date';
import { ColumnFilterModelType } from 'primevue/column';
import { onMounted, ref } from 'vue';

interface Props {
  filterModel: ColumnFilterModelType | FilterValueInterface | undefined;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[] | null[]): void;
}>();

/** Consts */
const dates = ref<Date[] | undefined>();
const maxDate = ref(new Date());

//this is needed because the calander closes in the filter when trying to open
//this is because the calander wants to open and take focus.
const canCalanderOpen = ref(false);

const valueUpdated = () => {
  if (!dates.value) {
    emit('update:modelValue', [null, null]);
    return;
  }

  if (dates.value[0] == null || dates.value[1] == null) {
    emit('update:modelValue', [null, null]);
    return;
  }

  emit('update:modelValue', [
    dateTimeToIsoString(dates.value[0]),
    dateTimeToIsoString(dates.value[1]),
  ]);
};

const getInitialDate = () => {
  if (props.filterModel?.value == null) {
    dates.value = undefined;
    canCalanderOpen.value = true;
    return;
  }

  if (
    typeof props.filterModel?.value[0] === 'string' &&
    typeof props.filterModel?.value[1] === 'string'
  ) {
    dates.value = [
      new Date(props.filterModel?.value[0] as any),
      new Date(props.filterModel?.value[1] as any),
    ];
  }

  canCalanderOpen.value = true;
};

onMounted(() => {
  getInitialDate();
});
</script>

<template>
  <div class="filter-style">
    <DatePicker
      v-model="dates"
      class="mb-1"
      placeholder="Select date"
      :max-date="maxDate"
      selection-mode="range"
      :manual-input="false"
      :show-button-bar="true"
      :show-icon="true"
      :show-on-focus="canCalanderOpen"
      @update:model-value="valueUpdated"
    />
  </div>
</template>
<style lang="scss" scoped>
.filter-style {
  font-size: 11px;
}
</style>
