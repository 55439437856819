<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { computed } from 'vue';
import SvgIcon from './svg-icon.vue';
/* Define Props */
interface Props {
  translationService?: TranslationService;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  emptyStateTitleVisible?: boolean;
  iconName: string;
  buttonVisible?: boolean;
  buttonLabel?: string;
  buttonIcon?: string;
}

const props = withDefaults(defineProps<Props>(), {
  translationService: undefined,
  buttonVisible: true,
  emptyStateTitleVisible: true,
  emptyStateTitle: undefined,
  emptyStateSubtitle: undefined,
  buttonLabel: undefined,
  buttonIcon: undefined,
});

/** Emits */
const emits = defineEmits<{
  (e: 'clicked'): void;
}>();

/** Retrieves the Icon */
const iconPath = computed(() => {
  return '/src/assets/empty-state/' + props.iconName + '.svg';
});
</script>

<template>
  <div class="align-items-center flex flex-column h-full justify-content-center my-6 w-full">
    <div class="mb-6">
      <SvgIcon :iconpath="iconPath" custom-style="width: 8rem" />
    </div>
    <div class="mb-2">
      <h2 class="empty-state-title" :style="{ display: emptyStateTitleVisible ? 'block' : 'none' }">
        {{ emptyStateTitle ?? translationService?.emptyStateTitle }}
      </h2>
    </div>
    <div class="mb-2 mb-5">
      <p class="empty-state-subtitle">
        {{ emptyStateSubtitle ?? translationService?.emptyStateSubtitle }}
      </p>
    </div>
    <div class="mt-2">
      <Button
        :label="(buttonLabel ?? translationService?.createButton ?? '').toUpperCase()"
        rounded
        :style="{ display: buttonVisible ? 'block' : 'none' }"
        :icon="buttonIcon ?? ''"
        class="font-bold primary-button"
        @click="emits('clicked')"
      />
    </div>
    <slot name="body"> </slot>
  </div>
</template>

<style>
.empty-state-title {
  text-align: center;
  font-size: 18px;
}

.empty-state-subtitle {
  white-space: pre-line;
  text-align: center;
  font-size: 14px;
  color: #8f9099;
}
</style>
