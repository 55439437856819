<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import { DataTableFilterEvent, DataTableRowClickEvent } from 'primevue/datatable';
import { inject, onUnmounted } from 'vue';
import { exportServiceKey } from '../ts/keys/exports.keys';

// Types
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import Upload from '@/general/ui/components/upload.vue';
import { formatDate } from '@/general/utils/format-date';
import { Pipeline, PipelinesRestService } from 'platform-unit2-api/pipelines';
import { MenuItem } from 'primevue/menuitem';
import ExportDetails from '../components/export-details.vue';

const ts = inject(tsKey)!;
const exportService = inject(exportServiceKey)!;

/* Menu implementation for non-pending status */
const menuItems = (pipeline: Pipeline): MenuItem[] => [
  {
    label: ts.value.tModule('actionMenu.download'),
    icon: 'mdi mdi-tray-arrow-down',
    command: () => exportService.value.openDownload(pipeline),
  },
  {
    label: ts.value.tModule('actionMenu.goTo'),
    icon: 'mdi mdi-open-in-new',
    command: () => exportService.value.goToProducts(pipeline?.id),
  },
  {
    label: ts.value.tModule('actionMenu.restartWhole'),
    icon: 'mdi mdi-restart',
    command: () => exportService.value.openCrudComponent(pipeline),
  },
  {
    separator: true,
  },
  {
    label: ts.value.tModule('actionMenu.delete'),
    class: 'delete',
    icon: 'mdi mdi-delete',
    command: () => exportService.value.confirmExportDelete(pipeline?.id),
  },
];

onUnmounted(() => {
  clearInterval(exportService.value.timer);
});
</script>
<template>
  <section class="h-full">
    <DataTable
      v-model:filters="exportService.filters"
      auto-layout
      scrollable
      :loading="exportService.isLoadingOverView"
      filter-display="menu"
      scroll-height="flex"
      :value="exportService.pipelines"
      row-hover
      lazy
      :paginator="exportService.limit ? (exportService.total ?? 0) > exportService.limit : false"
      :rows="exportService.limit"
      :total-records="exportService.total"
      :first="exportService.first"
      :global-filter-fields="['status.name', 'user', 'module.name']"
      data-key="id"
      @page="exportService.onPageChange($event.page)"
      @sort="exportService.onSortChange($event)"
      @row-click="(event: DataTableRowClickEvent) => exportService.togglePipelineDetails(true, event.data)"
      @filter="(event: DataTableFilterEvent) => exportService.applyFilter(event)"
    >
      <Column>
        <template #body="slotProps">
          <Upload :upload="slotProps.data?.retailer?.thumbnail" class="w-5rem" />
        </template>
      </Column>

      <Column :header="ts.tGlobal('channel')" field="module.name" :show-filter-match-modes="false">
        <template #filter="{ filterModel }">
          <ModuleSelect
            v-model="filterModel.value"
            hide-label
            multiselect
            class="max-w-20rem"
            :rest-service="PipelinesRestService"
            fetch-function-name="getFilterChannels"
          />
        </template>
      </Column>

      <Column :header="ts.tModule('table_header.products')" field="product_count">
        <template #body="slotProps">
          <span>{{ slotProps.data.product_ids?.length ?? 0 }}</span>
        </template>
      </Column>

      <Column :header="ts.tGlobal('user')" field="user" :show-filter-match-modes="false">
        <template #body="slotProps">
          <div v-if="slotProps.data.user">
            {{
              exportService.currentUser && slotProps.data.user.id === exportService.currentUser.id
                ? ts.tGlobal('me')
                : slotProps.data.user.name
            }}
          </div>
          <div v-else>{{ ts.tGlobal('unknown_user') }}</div>
        </template>
        <!-- Add translation -->
        <template #filter="{ filterModel }">
          <Select
            v-model="filterModel.value"
            :options="exportService.userFilterOptions"
            option-label="name"
            placeholder="Search filters"
            display="chip"
            class="w-full"
          />
        </template>
      </Column>

      <Column :header="ts.tModule('table_header.started_at')" sortable field="created_at">
        <template #body="slotProps">
          {{ formatDate(slotProps.data.created_at) }}
        </template>
      </Column>

      <Column
        :header="ts.tModule('table_header.status')"
        field="status"
        :show-filter-match-modes="false"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.current_status">
            <StatusChip
              :label="exportService.getExportStatus(slotProps.data).label"
              :severity="exportService.getExportStatus(slotProps.data).severity"
            />
          </span>
        </template>

        <template #filter="{ filterModel }">
          <Select
            v-model="filterModel.value"
            :options="exportService.statusFilterOptions"
            option-label="name"
            placeholder="Search filters"
            display="chip"
            class="w-full"
          />
        </template>
      </Column>

      <Column :header="ts.tModule('table_header.errors')">
        <template #body="slotProps">
          <span v-if="slotProps.data.error_count >= 0" class="align-items-center flex">
            <img v-if="slotProps.data.error_count" src="@/assets/icons/error-icon.svg" />
            <span class="pup-ml-1">{{ slotProps.data.error_count }}</span>
          </span>
        </template>
      </Column>
      <Column :header="ts.tModule('table_header.actions')">
        <template #body="slotProps">
          <ActionsMenu :menu-items="menuItems(slotProps.data)" />
        </template>
      </Column>

      <template #empty>
        <EmptyState
          platform="supplier"
          :translation-service="ts"
          icon-name="exports"
          @clicked="exportService.goToProducts()"
        />
      </template>
    </DataTable>
    <ExportDetails
      v-if="exportService.pipelineDetails"
      :visible="exportService.isDetailsModalVisible"
      :pipeline="exportService.pipelineDetails"
    />
  </section>
</template>
