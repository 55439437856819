<script setup lang="ts">
/** vue */
import { inject, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

/** components */
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import TableGeneric from '@/general/ui/components/table-generic.vue';

/** types */
import { ContentStoryProduct } from '@/api/v1/content-story/ts/content-story-product.interface';
import { TableProps } from '@/general/composables/useTable/table-props';
import { ITable } from '@/general/composables/useTable/table.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { Product } from 'platform-unit2-api/products';
import linkAndPublishColumns from '../ts/helpers/link-and-publish-columns';
import linkProductsColumns from '../ts/helpers/link-products-columns';
import { storyBuilderServiceKey } from '../ts/helpers/story-manager-keys';

/** services / composables */
import { featureFlagEnum } from '@/core/featureflags/ts/service/feature-flags.enum';
import { FeatureFlagService } from '@/core/featureflags/ts/service/feature-flags.service';
import useTable from '@/general/composables/useTable/table';
import { TranslationService } from '@/general/services/translations/translation.service';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import AdvancedSearch from '../../search/components/advanced-search.vue';

interface Props {
  currentStoryLoading: boolean;
}

const props = defineProps<Props>();

//services
const storyService = inject(storyBuilderServiceKey);
const ts = new TranslationService('supplier', 'storyManager');

//tables
const linkedProductsProps = new TableProps<ContentStoryProduct>({
  columns: linkAndPublishColumns,
  loadData: () =>
    storyService?.value.getContentStoryProducts(storyService?.value.currentStory?.id ?? 0),
});
const linkedProductsTableComposable = useTable<ContentStoryProduct>(linkedProductsProps, false);

const linkProductsModalTable = new TableProps<Product>({
  columns: linkProductsColumns,
  loadData: (params) => storyService?.value?.getProducts(params),
});
const linkProductsModalTableComposable = useTable<Product>(linkProductsModalTable, false);

//composables
const router = useRouter();

//const / refs
const isCreateDialogOpen = ref(false);

const openChangeDraftModel = ref(false);

const modalButtonsDraftModal = ref<CrudButtonsOptions>({
  saving: () => false,
  buttons: [
    new CancelButton({
      label: ts.tGlobal('exit'),
      position: CrudButtonPosition.RIGHT,
      onClick: () => {
        router.push({ name: 'story-manager' });
        openChangeDraftModel.value = false;
      },
    }),
    new CreateButton({
      label: ts.tGlobal('continue'),
      position: CrudButtonPosition.RIGHT,
      type: 'submit',
      styleClasses: 'p-button white-space-nowrap',
      onClick: () => {},
    }),
  ],
});

const modalButtons = ref<CrudButtonsOptions>({
  saving: () => false,
  buttons: [
    new CancelButton({
      label: ts.tGlobal('cancel'),
      position: CrudButtonPosition.RIGHT,
      onClick: () => {
        toggleDialog();
      },
    }),
    new CreateButton({
      label: ts.tGlobal('add'),
      position: CrudButtonPosition.RIGHT,
      type: 'submit',
      styleClasses: 'p-button white-space-nowrap',
      onClick: () => {},
    }),
  ],
});

//helper functions
const toggleDialog = () => {
  isCreateDialogOpen.value = !isCreateDialogOpen.value;
};

const handleDetachCallback = () => {
  linkedProductsTableComposable.fetchData();
  linkProductsModalTableComposable.fetchData();
};

const handleDetachButton = async (product: ContentStoryProduct) => {
  await storyService?.value.detachProductFromStory(
    storyService?.value.currentStory?.id ?? 0,
    [product.id],
    handleDetachCallback,
  );
};

const submitForm = async () => {
  await storyService?.value.attachProductToStory(
    storyService?.value.currentStory?.id ?? 0,
    linkProductsModalTableComposable.checkedIds.value,
  );
  toggleDialog();
  linkedProductsTableComposable.fetchData();
  linkProductsModalTableComposable.fetchData();
};

const handleRedirectToProductsPage = () => {
  router.push({ name: 'products' });
};

onMounted(async () => {
  if (!(await FeatureFlagService.getInstance().isOn(featureFlagEnum.NEW_LAYOUT))) {
    if (storyService?.value.currentStory?.is_draft) {
      openChangeDraftModel.value = true;
    }
  }

  linkedProductsTableComposable.shouldLoadData.value = !props.currentStoryLoading;
  linkProductsModalTableComposable.shouldLoadData.value = !props.currentStoryLoading;
});

watch(
  () => props.currentStoryLoading,
  () => {
    if (storyService?.value.currentStory?.is_draft) {
      openChangeDraftModel.value = true;
    }

    linkedProductsTableComposable.shouldLoadData.value = !props.currentStoryLoading;
    linkProductsModalTableComposable.shouldLoadData.value = !props.currentStoryLoading;
  },
);

watch(
  () => storyService?.value.currentStory?.story_name,
  () => {
    const hasPublishedProductWhichIsTheSameAsCurrentStory =
      linkedProductsTableComposable.rows.value.some((prod) =>
        prod.content_stories.find(
          (story) =>
            story.id === storyService?.value.currentStory?.id &&
            story.status.status === 'published',
        ),
      );
    if (
      storyService?.value.currentStory?.product_ids?.length === 0 ||
      !hasPublishedProductWhichIsTheSameAsCurrentStory
    ) {
      return;
    }

    linkedProductsTableComposable.fetchData();
  },
);
</script>

<template>
  <section>
    <div
      v-if="!currentStoryLoading"
      class="flex flex-column gray-link-publish h-full overflow-auto pt-4 px-4"
    >
      <div class="bg-white flex flex-column h-full p-4 w-full work-field">
        <h3 class="align-items-center flex">
          {{ ts.tModule('linkAndPublish.titlePage') }}
        </h3>

        <div class="align-items-end flex flex-row justify-content-between mb-3">
          <p class="subtitle">
            {{ ts.tModule('linkAndPublish.subtitle') }}
          </p>
          <Button
            class="align-self-end p-button-outlined"
            icon="mdi mdi-plus"
            :label="ts.tModule('linkAndPublish.addProducts')"
            @click="toggleDialog"
          />
        </div>

        <TableGeneric
          selectable
          :composable="(linkedProductsTableComposable as ITable<unknown>)"
          :table-props="(linkedProductsProps as TableProps<unknown>)"
        >
          <template #emptyState>
            <EmptyState
              :translation-service="ts"
              :empty-state-title="ts.tModule('linkAndPublish.linkProducts')"
              :button-label="ts.tModule('linkAndPublish.addProducts')"
              icon-name="linked_products"
              @clicked="toggleDialog"
            />
          </template>
          <template #actions="{ data }">
            <Button text icon="mdi mdi-close" @click="() => handleDetachButton(data)" />
          </template>
        </TableGeneric>
        <BaseDialog
          :visible="isCreateDialogOpen"
          size="large"
          :buttons-options="modalButtons"
          @update:visible="toggleDialog"
          @submit="submitForm"
        >
          <template #header>
            <div class="p-2">
              <h2 class="text-gray-800">
                {{ ts.tModule('linkAndPublish.addCountProducts') }}
              </h2>
              <p class="mt-1 text-gray-500">
                {{ ts.tModule('linkAndPublish.selectProductsSubtitle') }}
                <b>"{{ storyService?.currentStory?.story_name ?? '' }}"</b>
              </p>
            </div>
          </template>
          <div class="px-2">
            <TableGeneric
              selectable
              :composable="(linkProductsModalTableComposable as ITable<unknown>)"
              :table-props="(linkProductsModalTable as TableProps<unknown>)"
            >
              <template #emptyState>
                <EmptyState
                  :translation-service="ts"
                  icon-name="linked_products"
                  :empty-state-title="ts.tModule('linkAndPublish.noVariants')"
                  :button-label="ts.tModule('linkAndPublish.createVariants')"
                  @clicked="handleRedirectToProductsPage"
                />
              </template>

              <template #header>
                <AdvancedSearch
                  :columns="linkProductsModalTable.toAdvancesSearchColumns()"
                  @update:model-value="linkProductsModalTableComposable.changeSearch"
                />
              </template>
            </TableGeneric>
          </div>
        </BaseDialog>
        <BaseDialog
          :is-closable="false"
          size="small"
          :visible="openChangeDraftModel"
          :buttons-options="modalButtonsDraftModal"
          @update:visible="openChangeDraftModel = false"
          @submit="
            async () => {
              await storyService?.saveStory();
              await storyService?.setIsDraft(false).then(() => {
                openChangeDraftModel = false;
                router.push('link-and-publish');
              });
            }
          "
        >
          <template #header>
            <div class="p-2">
              <h2 class="text-gray-800">{{ ts.tModule('unDraft.title') }}</h2>
              <p class="text-gray-500">{{ ts.tModule('unDraft.subTitle') }}</p>
            </div>
          </template>
        </BaseDialog>
      </div>
    </div>

    <Skeleton v-else class="border-noround-bottom border-round h-full mt-3 p-5" />
    <ConfirmDialog group="publish-content-dialog" />
  </section>
</template>

<style lang="scss">
$border-color: #dce5ef;
.subtitle {
  font-size: 14px;
  color: #8f9099;
}
.linked-products {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.work-field {
  border-radius: 6px;
  border: 1px solid $border-color;
}

.gray-link-publish {
  background-color: #f5f7fa;
}

main {
  gap: 0 !important;
}
</style>
