<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import Chart from 'chart.js/auto';
import { Metric, MetricData } from 'platform-unit2-api/dashboard';
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

/** Props */

interface Props {
  uriKey: string;
  refreshKey: string;
}

const props = withDefaults(defineProps<Props>(), {
  refreshKey: '',
});

//Services
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'dashboard');

/** Constants */
const store = useStore();
const chart = ref();
let chartInstance: Chart;
const loading = ref(true);
const completeness = ref<MetricData>();

const startDataFetching = async () => {
  loading.value = true;
  try {
    await store.dispatch('dashboard/GET_METRIC_DATA', {
      rangeStart: null,
      rangeEnd: null,
      uriKey: props.uriKey,
    });
    setCompleteness();
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

const setCompleteness = () => {
  const result = store.getters['dashboard/metrics'].find(
    (metric: Metric) => metric.uri_key === props.uriKey,
  );
  if (result) {
    completeness.value = result.data;
  }
};

const drawFigures = () => {
  const plugin = {
    id: 'custom_canvas_background_color',
    beforeInit: (chart: any) => {
      const dataset = chart.data.datasets[0];
      chart.data.labels = [dataset.label];
      dataset.data = [dataset.percent, 100 - dataset.percent];
    },
    beforeDraw: (chart: any) => {
      const width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      const fontSize = (height / 75).toFixed(2);
      ctx.font = fontSize + 'rem sans-serif';
      ctx.fillStyle = '#172533';
      ctx.textBaseline = 'middle';
      const text = chart.data.datasets[0].percent + '%',
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };
  if (chartInstance != null) {
    chartInstance.destroy();
  }

  if (chart.value == null) {
    return;
  }

  chartInstance = new Chart(chart.value, {
    type: 'doughnut',
    data: {
      datasets: [
        {
          label: ts.tModule('userDashboard.overall_completeness.title'),
          // @ts-ignore
          percent: completeness.value.total,
          backgroundColor: ['#5283ff', '#d0d0d04d'],
        },
      ],
    },
    plugins: [plugin],
    options: {
      cutout: 85,
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: '#FFF',
          titleFont: { size: 16 },
          titleColor: '#0080FA',
          bodyColor: '#000',
          bodyFont: { size: 14 },
          displayColors: false,
          filter: function (tooltipItem: any) {
            return tooltipItem.data === 0;
          },
        },
      },
    },
  }) as any; //TODO Find this type
};

watch(
  () => props.refreshKey,
  async () => {
    await startDataFetching();
    drawFigures();
  },
);

/** Lifecycle Methods */
onMounted(async () => {
  await startDataFetching();
  drawFigures();
});
</script>
<template>
  <Card>
    <template #content>
      <div class="flex flex-column h-full justify-content-center">
        <h4 class="mb-4 text-left">Your Overall Completeness</h4>
        <div v-if="loading" class="align-items-center flex justify-content-center">
          <div class="h-25rem mt-4 relative w-25rem">
            <Skeleton class="border-circle h-full w-full" />
            <div
              class="absolute bg-white border-circle h-15rem left-50 top-50 w-15rem"
              style="transform: translate(-50%, -50%)"
            />
          </div>
        </div>
        <div v-if="!loading" class="align-items-center flex">
          <canvas ref="chart" />
        </div>
      </div>
    </template>
  </Card>
</template>
