<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { ExportMappingViewService } from '@/general/services/overview-service/modules/export-mapping/export-mapping-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import OverViewDialog from 'ui/components/over-view/over-view-dialog.vue';
import { exportMappingServiceKey } from '../services/keys/export-mapping.keys';

const ts = new TranslationService('retailer', 'export_mappings');
const service = useService(exportMappingServiceKey, ExportMappingViewService);
</script>

<template>
  <OverViewDialog class="w-4" size="small" :title="ts.tModule('createTitle')" :service="service">
    <template #form>
      <div class="flex flex-column gap-3">
        <DatamodelSelect v-model="service.partialObject.datamodel" flatten-children-attributes />
        <ModuleSelect v-model="service.partialObject.module" type="export" />
        <SelectButton
          v-model="service.selectedOption"
          :options="service.dropdownOptions"
          option-label="label"
          aria-labelledby="basic"
        />
      </div>
    </template>
  </OverViewDialog>
</template>
