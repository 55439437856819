import { RouteRecordRaw } from 'vue-router';
import brandsRoutes from 'supplier/modules/brands/routes/brands.routes';
import DatamodelsRoutes from 'supplier/modules/datamodels/routes/datamodels.routes';
import downloadsRoutes from 'supplier/modules/downloads/routes/downloads.routes';
import developersRoutes from 'supplier/modules/developers/routes/developers.routes';
import editorRoutes from 'supplier/modules/editor/routes/editor.routes';
import { getSupplierExportRoutes } from 'supplier/modules/export/routes/export.routes';
import importRoutes from 'supplier/modules/import/routes/import.routes';
import mediaRoutes from 'supplier/modules/media/routes/media.routes';
import modulesRoutes from 'supplier/modules/modules/routes/modules.routes';
import categoryRoutes from 'supplier/modules/categories/routes/categories.routes';
import { getProductRoutes } from 'supplier/modules/products/routes/products.routes';
import settingRoutes from 'supplier/modules/settings/routes/settings.routes';
import sharedRoutes from 'supplier/modules/shared/routes/shared.routes';
import taskRoutes from 'supplier/modules/tasks/routes/tasks.routes';
import userRoutes from 'supplier/modules/users/routes/users.routes';
import dashboardRoutes from 'supplier/modules/dashboard/routes/dashboard.routes';
import mappingsRoutes from 'supplier/modules/mappings/routes/mappings.routes';
import toolRoutes from 'supplier/modules/tools/routes/tools.routes';
import getAIRoutes from 'supplier/modules/ai-enhancements/routes/ai-enchancements';
import datamodelAttributesRoutes from '../modules/datamodel-attributes/datamodel-attributes.routes';
import workspaceSettingsRoutes from 'supplier/modules/workspace-settings/routes/workspace-settings.routes';
import workspaceSelectorRoutes from '@/general/ui/views/workspace-selector/workspace-selector.routes';

export async function getSupplierRoutes(): Promise<RouteRecordRaw[][]> {
  return [
    dashboardRoutes,
    brandsRoutes,
    categoryRoutes,
    DatamodelsRoutes,
    userRoutes,
    await getSupplierExportRoutes(),
    importRoutes,
    await getProductRoutes(),
    mappingsRoutes,
    sharedRoutes,
    modulesRoutes,
    downloadsRoutes,
    editorRoutes,
    taskRoutes,
    settingRoutes,
    mediaRoutes,
    await toolRoutes(),
    await getAIRoutes(),
    datamodelAttributesRoutes,
    developersRoutes,
    workspaceSettingsRoutes,
    workspaceSelectorRoutes,
  ];
}
