<script setup lang="ts">
import { Platform } from '@/core/router/route.factory';
import { OverviewBase } from '@/general/services/overview-service/interfaces/overview-base.interface';
import { TranslationService } from '@/general/services/translations/translation.service';
import TableSkeleton from 'ui/components/skeletons/table-skeleton.vue';
import { onBeforeMount, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
  service: OverviewBase;
  platform: Platform;
  moduleName: string;
  hasSearch?: boolean;
  searchPlaceholder?: string;
}
const props = withDefaults(defineProps<Props>(), {
  hasSearch: true,
  searchPlaceholder: undefined,
});

const ts = ref(new TranslationService(props.platform, props.moduleName));
const baseService: Ref<OverviewBase> = ref(props.service);
const route = useRoute();

onBeforeMount(() => {
  baseService.value.fetchAll();
  baseService.value.resolveCrudComponent();
});

watch(route, () => {
  if (!baseService.value.crudComponent) {
    baseService.value.resolveCrudComponent();
  }
});
</script>
<template>
  <section class="flex flex-column h-full pt-3 px-4">
    <!-- Actions -->
    <div v-if="$slots.actions || hasSearch" class="flex w-full" :class="{ 'pup-my-4': hasSearch }">
      <!-- Search input -->
      <IconField v-if="hasSearch" icon-position="left" class="pup-pl-1">
        <InputIcon class="pi pi-search" />
        <InputText
          v-model="baseService.query"
          :placeholder="searchPlaceholder ?? ts.tGlobal('search')"
        />
      </IconField>
      <slot name="actions" />
    </div>

    <!-- Loading page -->
    <div
      v-show="baseService.isLoadingOverView || !baseService.isInitialized"
      class="h-full"
      :class="{ 'pup-mt-4': !hasSearch }"
    >
      <slot name="skeleton">
        <TableSkeleton />
      </slot>
    </div>

    <!-- After loading -->
    <div
      v-show="!baseService.isLoadingOverView && baseService.isInitialized"
      class="h-full overflow-x-hidden overflow-y-auto"
      :class="{ 'pup-mt-5 pup-p-1': !hasSearch }"
    >
      <slot name="content" />
    </div>

    <!-- Create Update -->
    <div v-show="!baseService.isLoadingOverView && baseService.isInitialized">
      <slot name="crudComponent" />
    </div>

    <slot name="others" />
  </section>
</template>

<style scoped>
.search-input {
  max-width: 18rem;
  min-width: 16rem;
}
</style>
