<script setup lang="ts">
import { inject } from 'vue';
import { productTableServiceKey } from '../../key/product-table-service.key';

const productTableService = inject(productTableServiceKey)!;
</script>

<template>
  <Message
    v-if="productTableService.createdVariant"
    class="m-1"
    severity="info"
    :closable="true"
    @close="productTableService.createdVariant = false"
  >
    {{ productTableService.ts.tModule('active_variants_filter') }}
  </Message>
</template>
