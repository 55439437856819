<script setup lang="ts">
import { inject } from 'vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';

/** Constants */
const service = inject(datamodelAttributeServiceKey)!;
</script>

<template>
  <section>
    <div class="flex gap-2 pb-0">
      <div class="w-9">
        <DatamodelSelect v-model="service.selectedODMs" multiselect only-master hide-label>
          <template #option="slotProps">
            <div class="flex justify-content-between">
              <span>{{ slotProps.option.name }}</span>
              <span>{{ slotProps.option.attributes.length }}</span>
            </div>
          </template>
        </DatamodelSelect>
      </div>
      <IconField icon-position="left" class="w-full">
        <InputIcon class="pi pi-search" />
        <InputText
          v-model="service.odmSearchQuery"
          type="text"
          class="w-full"
          :placeholder="service.ts.searchTitle"
        />
      </IconField>
    </div>

    <!-- divider -->
    <Divider />

    <div class="align-items-center flex justify-content-between mb-3">
      <span class="font-bold"
        >{{ service.ts.tModule('datamodel_attributes.name') }}
        <span class="bg-gray-800 border-round-sm px-1 text-sm text-white">
          {{ service.attributesCount }}
        </span>
      </span>

      <Button
        v-if="service.attributesCount !== 0"
        text
        primary
        :label="service.ts.tModule('addAll')"
        @click="service.addAllODMsAttributes()"
      />
    </div>
  </section>
</template>
