<script setup lang="ts">
//Core
import OverView from 'ui/views/over-view/over-view.vue';
import { onBeforeUnmount } from 'vue';
//Types

//Composables and Services
import { useService } from '@/general/composables/useService';
import { ImportService } from '@/general/services/overview-service/modules/import.service';
import TableSkeleton from '@/general/ui/components/skeletons/table-skeleton.vue';
import StepperManager from '@/general/ui/components/stepper-manager.vue';
import ImportNewTable from '../components/import-table.vue';
import { importServiceKey } from '../ts/keys/import-service.key';

const service = useService(importServiceKey, ImportService);

onBeforeUnmount(() => {
  clearInterval(service.timer as ReturnType<typeof setInterval>);
});
</script>

<template>
  <OverView :has-search="false" platform="retailer" module-name="imports" :service="service">
    <template #skeleton>
      <TableSkeleton />
    </template>
    <template #content>
      <ImportNewTable />
    </template>
    <template #crudComponent>
      <StepperManager
        :is-active="service.crudComponent"
        :disable-buttons="service.getDisabledButtonsConditions"
        :header="service.dialogHeader"
        :child-routes="service.childRoutes"
        :form-data="{}"
        size="medium"
        @hide="service.closeCrudComponent()"
        @complete="service.submit()"
      />
    </template>
    <template #others>
      <ConfirmDialog group="import-dialog" />
    </template>
  </OverView>
</template>

<style lang="scss" scoped>
.p-menu :deep(.p-menu-list .p-menuitem-separator) {
  border-bottom: 1px solid rgb(227, 224, 224);
}
</style>
