<script setup lang="ts">
import { modulesServiceKey } from 'supplier/modules/modules/ts/keys/modules.keys';
import { inject } from 'vue';

interface Props {
  fieldName: string;
}
defineProps<Props>();
const service = inject(modulesServiceKey)!;
</script>
<template>
  <div>
    <label v-if="service.findFieldByFieldName(fieldName)?.type !== 'button'" class="block mb-1"
      >{{ service.getFieldLabel(fieldName) }}
      <i
        v-if="(service.findFieldByFieldName(fieldName)?.hint ?? '') !== ''"
        v-tooltip.top="service.findFieldByFieldName(fieldName)?.hint"
        class="mdi mdi-help-circle-outline"
      />
    </label>
    <span
      v-if="
        service.findFieldByFieldName(fieldName)?.type === 'string' ||
        service.findFieldByFieldName(fieldName)?.type === 'int'
      "
      class="flex p-input-icon-right"
    >
      <InputText
        v-if="service.isEncrypted(fieldName) == undefined"
        v-model="service.credentialValues[fieldName]"
        type="text"
        :placeholder="
          service.ts.tModule('credential_edit.new', {
            params: { field: service.getFieldLabel(fieldName) },
          })
        "
        class="mb-3 w-full"
      />
      <Password
        v-if="service.isEncrypted(fieldName) != undefined"
        v-model="service.credentialValues[fieldName]"
        toggle-mask
        :feedback="false"
        :placeholder="
          service.ts.tModule('credential_edit.new', {
            params: { field: service.getFieldLabel(fieldName) },
          })
        "
        class="mb-3 w-full"
      />
    </span>
    <span
      v-else-if="service.findFieldByFieldName(fieldName)?.type == 'boolean'"
      class="flex mb-3 p-input-icon-right"
    >
      <ToggleSwitch v-model="service.credentialValues[fieldName]" />
    </span>
    <span
      v-else-if="service.findFieldByFieldName(fieldName)?.type == 'select'"
      class="flex mb-3 p-input-icon-right"
    >
      <Select
        v-model="service.credentialValues[fieldName]"
        class="w-full"
        :options="service.findFieldByFieldName(fieldName)?.options"
        option-label="label"
        option-value="value"
      />
    </span>
    <span
      v-else-if="service.findFieldByFieldName(fieldName)?.type == 'button'"
      class="flex mb-3 p-input-icon-right"
    >
      <Button
        v-if="service.findFieldByFieldName(fieldName.toString())?.integration === 'amazon'"
        class="amazon p-0"
        aria-label="Amazon"
        @click="service.goToEndpoint(fieldName.toString())"
      >
        <i class="pi pi-amazon px-2"></i>
        <span class="px-3">{{ fieldName }}</span>
      </Button>
      <Button v-else :label="fieldName" @click="service.goToEndpoint(fieldName.toString())" />
    </span>
  </div>
</template>
<style lang="scss" scoped>
.p-button.amazon {
  background: var(--yellow-500) !important;
  background-size: 200% 100% !important;
  color: #000;
  border-color: var(--yellow-500);
  line-height: 2rem !important;
}
.p-button.amazon:hover {
  background: var(--yellow-300) !important;

  border-color: var(--yellow-500) !important;
  color: #000 !important;
}

.p-button.amazon:focus {
  box-shadow: 0 0 0 1px var(--yellow-200) !important;
}

// :pt pcInput prop is not working in primevue
.p-password :deep(.p-password-input) {
  width: 100%;
}
</style>
