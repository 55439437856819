<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { ExportMappingViewService } from '@/general/services/overview-service/modules/export-mapping/export-mapping-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import { DuplicateExportMappingRequest, MappingType } from 'platform-unit2-api';
import { ref } from 'vue';
import { exportMappingServiceKey } from '../services/keys/export-mapping.keys';
import ExportMappingDuplicateWarningModal from './export-mapping-duplicate-warning-modal.vue';

interface Props {
  visible: boolean;
}

/** Props */
withDefaults(defineProps<Props>(), {
  visible: false,
});

const ts = new TranslationService('supplier', 'export_mappings');
const service = useService(exportMappingServiceKey, ExportMappingViewService);

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => service.closeDuplicateDialog(),
});

const duplicateButton = new CreateButton({
  label: ts.tGlobal('duplicate'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !service.isDuplicateButtonDisabled(),
  onClick: () => {
    const payloadIsValid =
      service.selectedClientToDuplicate?.id != null &&
      service.selectedDatamodelToDuplicate?.id != null &&
      service.selectedModuleToDuplicate?.id != null;

    if (payloadIsValid) {
      service.checkIfMappingExists({
        workspace_id: service.selectedClientToDuplicate!.id,
        datamodel_id: service.selectedDatamodelToDuplicate!.id,
        module_id: service.selectedModuleToDuplicate!.id,
        source_datamodel_id: service.partialObject.datamodel?.id,
        source_module_id: service.partialObject.module?.id,
      } as DuplicateExportMappingRequest);
    }
  },
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, duplicateButton],
});
</script>

<template>
  <div>
    <BaseDialog
      :visible="visible"
      :buttons-options="buttonsOptions"
      size="medium"
      @update:visible="service.closeDuplicateDialog()"
    >
      <template #header>
        <div>
          <h2 class="mb-2 text-800">
            {{ ts.tModule('duplicate_mappings.modal_header') }}
          </h2>
          <p class="text-600">{{ ts.tModule('duplicate_mappings.modal_description') }}</p>
        </div>
      </template>

      <Message severity="warn" class="pup-my-3">
        {{ ts.tModule('duplicate_mappings.info_message') }}</Message
      >
      <!-- Workspace -->
      <div class="field mb-3">
        <label class="block mb-1">{{ ts.tGlobal('workspace') }}</label>
        <Select
          v-model="service.selectedClientToDuplicate"
          :options="service.currentUser?.clients"
          class="w-full"
          option-label="name"
          data-key="id"
          :filter="true"
          :placeholder="ts.tModule('duplicate_mappings.modal_client_placeholder')"
        />
      </div>

      <!-- Data model -->
      <div class="field mb-3">
        <DatamodelSelect
          v-model="service.selectedDatamodelToDuplicate"
          :workspace-id="service.selectedClientToDuplicate?.id"
          :disabled="!service.selectedClientToDuplicate"
        />
      </div>

      <!-- Channel -->
      <div class="field mb-3">
        <ModuleSelect
          v-model="service.selectedModuleToDuplicate"
          :workspace-id="service.selectedClientToDuplicate?.id"
          :type="MappingType.EXPORT"
          :disabled="!service.selectedDatamodelToDuplicate"
        />
      </div>
    </BaseDialog>

    <!-- If the mapping already exists -->
    <ExportMappingDuplicateWarningModal
      v-if="service.partialObject.datamodel && service.partialObject.module"
      :visible="service.duplicateWarningDialogVisible"
      :source-module="service.partialObject.module"
      :source-datamodel="service.partialObject.datamodel"
    />
  </div>
</template>
