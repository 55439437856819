<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import DragAndDrop from '@/general/ui/components/drag-and-drop.vue';
import { Attribute } from 'platform-unit2-api/attributes';
import {
  Datamodel,
  DatamodelAttributeConfig,
  DatamodelsRestService,
} from 'platform-unit2-api/datamodels';
import Override from 'retailer/modules/datamodels/components/create-datamodel/override.vue';
import useDebounce from 'utils/debounce';
import { computed, onMounted, ref, watch, watchEffect } from 'vue';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'datamodels');
const datamodelApi = new DatamodelsRestService();

/** Constants */
const { debounce } = useDebounce();
const filteredAttributes = ref<Attribute[]>([]);

/** Props */
const props = defineProps<{
  userAttributes: Attribute[];
  datamodels: Datamodel[];
  datamodelId?: number;
  datamodelName?: string;
}>();

/** Emits */
const emit = defineEmits<{
  (e: 'removeFromUserAttributes', attribute: Attribute[]): void;
  (e: 'update:datamodel-name', name?: string): void;
}>();

/** Remove from list */
const removeFromUserAttributes = (attributes: Attribute[]) => {
  emit('removeFromUserAttributes', attributes);
  showWarningForRemovingAttributes.value = true;
};

/** Show override form */
const showOverride = ref(false);
const showWarningForRemovingAttributes = ref(false);
const attributeToOverride = ref<Attribute>();
const openOverridedialog = (selectedAttribute: Attribute) => {
  attributeToOverride.value = selectedAttribute;
  showOverride.value = true;
};

//** Searched Attribute */
const searchedWord = ref<string>('');

/** Close Override form */
const closeOverrideDialog = () => {
  showOverride.value = false;
};

/** Add a new override to the existing object */
const addOverride = (override: DatamodelAttributeConfig) => {
  overrides.value.push(override);
};

/** Get all the overrides on Mounted */
const overrides = ref<DatamodelAttributeConfig[]>([]);
const getAllOverrides = async () => {
  try {
    if (!props.datamodelId) {
      toastService.displayErrorToast(ts.loadFailed());
      return;
    }

    overrides.value = await datamodelApi.getDatamodelAttributeConfig(props.datamodelId);
  } catch {
    toastService.displayErrorToast(ts.loadFailed());
  }
};

/** Filter out ODM datamodels that are not used in the new DM  */
const getOnlyUsedDatamodels = (): Datamodel[] => {
  return props.datamodels
    .filter((dm: Datamodel) => {
      if (
        dm.attributes.filter((attr: Attribute) =>
          attributesToUse().some((dmAttr: Attribute) => (attr.id === dmAttr.id ? true : false)),
        ).length > 0
      ) {
        return true;
      } else return false;
    })
    .sort((datamodelA: Datamodel, datamodelB: Datamodel) =>
      ('' + datamodelA.name).localeCompare(datamodelB.name),
    );
};

/** Retrieve the attributes for each of the filtered out datamodels */
const getAttributesForDatamodel = (dm: Datamodel): Attribute[] => {
  return attributesToUse().filter((userAttr: Attribute) => {
    return dm.attributes.some((dmAttr: Attribute) => (userAttr.id === dmAttr.id ? true : false));
  });
};

/** Determine whether to use the filtered attributes or the userAttributes */
const attributesToUse = (): Attribute[] => {
  if (searchedWord.value) {
    return filteredAttributes.value;
  }

  return props.userAttributes;
};

const overrideIds = computed(() => {
  return overrides.value.reduce((acc, curr) => {
    return curr.name ? [...acc, curr.attribute_id] : [...acc];
  }, [] as number[]);
});

const updateFilteredAttributes = debounce((searchedWord: string) => {
  filteredAttributes.value = JSON.parse(
    JSON.stringify(props.userAttributes),
  ) as typeof props.userAttributes;

  filteredAttributes.value = filteredAttributes.value.filter(
    (attribute) => attribute.key.toLowerCase().indexOf(searchedWord.toLocaleLowerCase()) != -1,
  );

  return [filteredAttributes];
}, 250);

const getOriginalAttributeName = (attributeId: number) => {
  return overrides?.value.find((attribute) => attribute.attribute_id === attributeId)?.name;
};

watch(
  () => [props.datamodelId, props.userAttributes],
  () => {
    getAllOverrides();
    if (searchedWord.value) {
      updateFilteredAttributes(searchedWord.value);
    }
  },
);

onMounted(() => {
  props.datamodelId && getAllOverrides();
});

/** display active/deactive status of overrides */
const attributeIsOverrided = (attributeId: number): boolean =>
  overrideIds.value?.includes(attributeId);
/** change the datamodel name */
const dmName = ref(props.datamodelName);
watchEffect(() => emit('update:datamodel-name', dmName.value));

watch(
  () => searchedWord.value,
  () => updateFilteredAttributes(searchedWord.value),
);
</script>

<template>
  <div class="flex flex-column" :style="{ width: '47%' }">
    <span class="font-bold text-lg">{{ ts.tModule('datamodel_new.title') }}</span>
    <div
      class="bg-gray-50 border-1 border-gray-100 flex flex-column flex-grow-1 my-2 p-3 pb-0"
      :style="{ minHeight: '100px' }"
    >
      <!-- Datamodel name -->
      <div class="flex pb-0">
        <InputText
          v-model="dmName"
          type="text"
          class="align-items-center mr-2 w-8"
          placeholder="Name the new datamodel"
        />
        <!-- Search Bar -->
        <IconField icon-position="left" class="w-4">
          <InputIcon class="pi pi-search" />
          <InputText
            v-model="searchedWord"
            type="text"
            class="w-full"
            placeholder="Search attributes..."
          />
        </IconField>
      </div>

      <!-- divider -->
      <Divider />
      <Message
        v-if="showWarningForRemovingAttributes"
        class="text-sm"
        @close="showWarningForRemovingAttributes = false"
        >{{ ts.tModule('warning_removing_attributes') }}</Message
      >
      <!-- Attributes -->
      <div class="align-items-center flex justify-content-between mb-1">
        <span class="font-bold"
          >{{ ts.tModule('attributes_added') }}
          <!-- Attributes count -->
          <span class="bg-primary border-round-sm inline-block ml-1 px-1 text-sm text-white">{{
            userAttributes.length
          }}</span>
        </span>
        <!-- Remove all -->
        <Button
          v-if="userAttributes.length !== 0"
          label="Remove all"
          text
          severity="danger"
          @click="removeFromUserAttributes(userAttributes)"
        />
      </div>
      <div class="grid place-items-center text-center" :style="{ minHeight: '30vh' }">
        <!-- No attributes added selected -->
        <div
          v-if="userAttributes && userAttributes.length === 0"
          class="align-items-center flex flex-column justify-content-center w-full"
        >
          <i
            class="bg-gray-100 border-circle mdi mdi-database-outline p-2 text-3xl text-primary"
          ></i>
          <span class="block font-bold mb-2 mt-4 text-lg">{{
            ts.tModule('datamodel_empty.title')
          }}</span>
          <p>{{ ts.tModule('datamodel_new.subtitle') }}</p>
        </div>
        <!-- Show the attributes -->
        <div v-else class="flex flex-column mx-2 w-full">
          <ScrollPanel :style="{ height: '31vh' }" class="datamodel-scrollpanel">
            <div
              v-for="dm of getOnlyUsedDatamodels()"
              :key="dm.id"
              class="font-bold my-3 text-left w-full"
            >
              <span>
                {{
                  ts.tModule('datamodel_attributes.taken_from_datamodel', {
                    params: { name: dm.name },
                  })
                }}
              </span>
              <DragAndDrop
                :list-items="getAttributesForDatamodel(dm)"
                item-classes="flex justify-content-between align-items-center border-1 border-gray-100 w-full p-2 pl-3 my-2 active"
                :sortable-options="{ direction: 'vertical', disabled: searchedWord !== '' }"
              >
                <template #default="{ item }">
                  <span
                    v-tooltip.bottom="{
                      value: `Original title: ${item.key}`,
                      disabled: !attributeIsOverrided(item.id),
                    }"
                    class="ml-2 mr-auto text-sm"
                    :class="[attributeIsOverrided(item.id) ? 'text-primary' : '']"
                  >
                    {{
                      attributeIsOverrided(item.id) ? getOriginalAttributeName(item.id) : item.key
                    }}
                  </span>
                  <div class="flex">
                    <i
                      v-tooltip.bottom="
                        item.public
                          ? ts.tModule('disallow_visibility')
                          : ts.tModule('allow_visibility')
                      "
                      class="align-items-center cursor-pointer flex justify-content-center mr-3 text-2xl"
                      :class="item.public ? 'mdi mdi-eye-outline ' : 'mdi mdi-eye-off-outline'"
                      @click="
                        () => {
                          item.public = !item.public;
                        }
                      "
                    />
                    <span
                      v-if="attributeIsOverrided(item.id)"
                      class="align-self-center font-normal mx-2 text-primary text-sm"
                    >
                      {{ ts.tModule('attributes_overwritten') }}</span
                    >
                    <i
                      v-tooltip.bottom="{
                        value: `${ts.tModule('create_overwrite')}`,
                        class: 'text-xs',
                        disabled: false,
                      }"
                      class="align-items-center border-round-sm cursor-pointer flex hover:bg-gray-50 hover:border-gray-400 hover:text-gray-800 justify-content-center mdi mdi-pencil p-0"
                      :class="
                        attributeIsOverrided(item.id)
                          ? 'border-primary border-1 text-primary'
                          : 'border-gray-200'
                      "
                      :style="{ width: '20px', height: '20px' }"
                      @click="openOverridedialog(item)"
                    />
                    <Divider layout="vertical" class="p-0" :style="{ margin: '0 10px' }" />
                    <i
                      class="bg-red-600 border-round-sm cursor-pointer hover:bg-red-500 mdi mdi-minus mr-3 p-1 text-white"
                      :style="{ width: '20px', height: '20px' }"
                      @click="removeFromUserAttributes([item])"
                    />
                  </div>
                </template>
              </DragAndDrop>
            </div>
          </ScrollPanel>
        </div>
      </div>
    </div>
    <Override
      v-if="showOverride && attributeToOverride"
      :attribute="attributeToOverride"
      :datamodel-id="datamodelId"
      @new-override="addOverride"
      @close="closeOverrideDialog"
      @refresh="getAllOverrides"
    />
  </div>
</template>
<style>
.datamodel-scrollpanel .active {
  background-color: #fff;
  opacity: 1;
  position: relative;
  animation-name: fadeIn;
  animation-duration: 1s;
}

@keyframes fadeIn {
  from {
    background-color: green;
    opacity: 0;
  }
  to {
    background-color: #fff;
    opacity: 1;
  }
}
</style>
