<script setup lang="ts">
//Core
import { inject, onMounted } from 'vue';

//Component
import ClientModuleSelect from '@/general/ui/components/selects/client-module-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';

//Types
import { ExportTypeEnum } from 'platform-unit2-api/exports';
import { ExportParameters } from 'platform-unit2-api/pipelines';

//Services
import { tsKey } from '@/general/services/translations/translation.key';
import { TranslationService } from '@/general/services/translations/translation.service';
import { exportServiceKey } from '../ts/keys/exports.keys';

const ts = inject(tsKey)!;
const exportService = inject(exportServiceKey)!;

/** Services */
const tsLocales = new TranslationService('supplier', 'locales');

onMounted(() => {
  exportService.value.loadAsyncData();
});
</script>
<template>
  <section class="flex flex-column gap-4">
    <ClientModuleSelect v-model="exportService.partialObject.module" type="export" />
    <div
      v-if="
        !exportService.partialObject?.payload?.parameters.files ||
        exportService.partialObject?.payload?.parameters.files.length <= 0
      "
      class="flex flex-column gap-4"
    >
      <LocaleSelect
        v-model="exportService.partialObject.locale"
        :label="tsLocales.tModule('locale_select.label')"
      />

      <!-- Check -->
      <div
        v-if="(exportService.partialObject?.payload?.parameters as ExportParameters)?.export_type === ExportTypeEnum.MAPPING_EXPORT"
      >
        <label>{{ ts.tModule('export_restart.sources') }}</label>
        <div class="flex mt-2">
          <div class="mr-4">
            <Checkbox
              v-model="(exportService.partialObject.payload?.parameters as ExportParameters).include_data"
              binary
            />
            <label>{{ ts.tModule('export_restart.product_attributes') }}</label>
          </div>

          <div class="mr-4">
            <Checkbox
              v-model="(exportService.partialObject.payload?.parameters as ExportParameters).include_assets"
              binary
            />
            <label>{{ ts.tModule('export_restart.product_assets') }}</label>
          </div>
        </div>
      </div>

      <div>
        <label class="block">{{ ts.tModule('export_restart.date_limit') }}</label>
        <Select
          v-model="exportService.restartPipelineChangedProductType"
          :options="[
            { value: 'always', label: ts.tModule('export_restart.since_always') },
            { value: 'export', label: ts.tModule('export_restart.since_last_export') },
            { value: 'dates', label: ts.tModule('export_restart.between_dates') },
          ]"
          option-label="label"
          data-key="value"
          display="chip"
          class="w-full"
          filter
        />

        <!-- Check -->
        <div v-if="exportService.restartPipelineChangedProductType.value === 'dates'">
          <label class="block">{{ ts.tModule('export_restart.select_dates') }}</label>
          <DatePicker
            v-model="exportService.restartPipelineDates"
            class="mb-1 w-full"
            placeholder="Select dates"
            :max-date="new Date()"
            selection-mode="range"
            :manual-input="false"
            show-button-bar
            show-icon
          />
        </div>
      </div>

      <div>
        <div>
          <label for="retailer_email">{{ ts.tGlobal('contact') }}</label>
          <div class="my-2 w-full">
            <AutoComplete
              v-model="(exportService.partialObject.payload?.parameters as ExportParameters).mail_to"
              class="w-full"
              :max="10"
              :placeholder="ts.tModule('details.mail_to')"
              add-on-blur
              :pt="{
                container: 'p-1',
              }"
              multiple
              :typeahead="false"
            />
          </div>
        </div>

        <Textarea
          v-model="(exportService.partialObject.payload?.parameters as ExportParameters).custom_message"
          class="w-full"
          rows="5"
          cols="60"
          :placeholder="ts.tModule('export_restart.custom_message')"
        />
      </div>
    </div>
  </section>
</template>
