<script setup lang="ts">
//Core
import * as cronParser from 'cron-parser';
import moment from 'moment';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

//Components
import EmptyState from '@/general/ui/components/empty-state.vue';
import TableSkeleton from '@/general/ui/components/skeletons/table-skeleton.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import ExportScheduledProductList from 'retailer/modules/export/components/export-scheduled-product-list.vue';

//Types
import { PaginationObject } from 'platform-unit2-api/core';
import {
  ScheduledPipeline,
  ScheduledPipelinesRestService,
  UpdateScheduledPipelineRequest,
} from 'platform-unit2-api/scheduled-pipelines';

//Composables and Services
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import usePagination from 'composables/usePagination/pagination';
import { DataTablePageEvent } from 'primevue/datatable';
import Menu from 'primevue/menu';
import { MenuItem } from 'primevue/menuitem';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'exports');
const confirmService = new ConfirmService();
const scheduledPipelineApi = new ScheduledPipelinesRestService();

/** Constants */
const store = useStore();
const router = useRouter();
const route = useRoute();
const { page, perPage, onPageChange: $onPageChange, query } = usePagination();
const loading = ref(false);
const menu = ref<typeof Menu>();
const showSidebar = ref(false);
const expandedRows = ref([]);
const scheduledPipelines = ref<ScheduledPipeline[]>([]);
const total = ref<number>();
const currentUser = computed(() => store.getters['users/currentUser']);

const hideDetails = () => {
  router.push({
    name: 'export-scheduled',
  });
};

const getNextRun = (cron: string): string => {
  return cronParser.parseExpression(cron).next().toISOString();
};

const toggleActionMenu = (event: MouseEvent) => {
  menu.value?.toggle(event);
};

const loadAsyncData = async (showLoadingIcon = true, searchQuery?: string): Promise<void> => {
  loading.value = showLoadingIcon;
  if (searchQuery != undefined) {
    query.value = searchQuery;
  }

  try {
    const response = await scheduledPipelineApi.getAll({
      page: page.value ?? 1,
      perPage: perPage.value ?? 50,
      query: query.value,
    } as PaginationObject);

    scheduledPipelines.value = response.data;
    if (response.meta === undefined) {
      return;
    }

    total.value = response.meta.total;
  } catch (err) {
    toastService.displayErrorToast(ts.getFailed());
  }

  loading.value = false;
};

const addProducts = (scheduledPipeline: ScheduledPipeline) => {
  router.push({
    name: 'products',
    params: {
      scheduledExportId: scheduledPipeline.id,
      scheduledExportName: scheduledPipeline.name,
      excludedProductsIds: scheduledPipeline.product_ids,
    },
  });
};

const updateScheduledPipeline = async (
  scheduledPipeline: ScheduledPipeline,
  status: boolean,
): Promise<void> => {
  loading.value = true;

  const scheduledExport: UpdateScheduledPipelineRequest = {
    id: scheduledPipeline.id,
    name: scheduledPipeline?.name,
    cron: scheduledPipeline?.cron,
    module_id: scheduledPipeline?.module?.id,
    locale_id: scheduledPipeline?.locale?.id,
    is_paused: status,
    update_variants: scheduledPipeline?.update_variants,
    client_id: scheduledPipeline?.client?.id,
  };

  try {
    await scheduledPipelineApi.update(scheduledPipeline.id, scheduledExport);
    toastService.displaySuccessToast(ts.updateSuccess());
    await loadAsyncData();
  } catch (err: any) {
    toastService.displayErrorToast(ts.updateFailed());
  } finally {
    loading.value = false;
  }
};

const removeProductFromScheduledPipeline = async (
  event: number[],
  scheduledPipeline: ScheduledPipeline,
  status: boolean,
): Promise<void> => {
  scheduledPipeline.product_ids = event;

  loading.value = true;
  try {
    const scheduledExport: UpdateScheduledPipelineRequest = {
      id: scheduledPipeline.id,
      name: scheduledPipeline?.name,
      cron: scheduledPipeline?.cron,
      module_id: scheduledPipeline?.module?.id,
      locale_id: scheduledPipeline?.locale?.id,
      is_paused: status,
      update_variants: scheduledPipeline?.update_variants,
      client_id: scheduledPipeline?.client?.id,
    };

    await scheduledPipelineApi.update(scheduledPipeline.id, scheduledExport);
    toastService.displaySuccessToast(ts.updateSuccess());
  } catch (err: any) {
    toastService.displayErrorToast(ts.updateFailed());
  } finally {
    loading.value = false;
  }
};

const editScheduledPipeline = (scheduledPipline: ScheduledPipeline) => {
  router.push({
    name: 'edit-scheduled-export',
    params: { id: (scheduledPipline.id ?? 0).toString() },
  });
};

const deleteScheduledPipeline = async (id: number): Promise<void> => {
  loading.value = true;
  try {
    await scheduledPipelineApi.delete(id);
    toastService.displaySuccessToast(ts.deleteSuccess());
    await loadAsyncData();
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  } finally {
    loading.value = false;
  }
};

const confirmScheduledPipelineDelete = (scheduledPipline: ScheduledPipeline) => {
  confirmService.confirmDelete({
    callback: () => deleteScheduledPipeline(scheduledPipline.id ?? 0),
    group: 'export-scheduled',
    message: ts.deleteConfirm(),
  });
};

const restartScheduledPipeline = async (id: number): Promise<void> => {
  loading.value = true;
  try {
    await scheduledPipelineApi.restartScheduledExport(id);
    toastService.displaySuccessToast(ts.tModule('success.restarted_successfully'));
    await loadAsyncData();
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('errors.restarted_failed'));
  } finally {
    loading.value = false;
  }
};

const onPageChange = (event: DataTablePageEvent) => {
  $onPageChange(event.page + 1, loadAsyncData);
};

const menuItems = (scheduledPipeline: ScheduledPipeline): MenuItem[] => [
  {
    label: ts.tModule('tooltips.restart'),
    icon: 'mdi mdi-restart',
    command: () => {
      restartScheduledPipeline(scheduledPipeline.id!);
    },
  },
  {
    label: ts.tModule('tooltips.start'),
    icon: 'mdi mdi-play-circle-outline',
    visible: () => !!scheduledPipeline.is_paused,
    command: () => {
      updateScheduledPipeline(scheduledPipeline, false);
    },
  },
  {
    label: ts.tModule('tooltips.pause'),
    icon: 'mdi mdi-pause-circle-outline',
    visible: () => !scheduledPipeline.is_paused,
    command: () => {
      updateScheduledPipeline(scheduledPipeline, true);
    },
  },
  {
    label: ts.tModule('tooltips.edit'),
    icon: 'mdi mdi-pencil-outline',
    command: () => {
      editScheduledPipeline(scheduledPipeline);
    },
  },
  {
    separator: true,
  },
  {
    label: ts.tGlobal('delete'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      confirmScheduledPipelineDelete(scheduledPipeline);
    },
  },
];

/** Lifecycle */
onMounted(() => {
  if (route.name === 'new-scheduled-export' || route.name === 'edit-scheduled-export') {
    showSidebar.value = true;
  }

  loadAsyncData();
});

watch(
  () => route,
  () => {
    if (route.name === 'new-scheduled-export' || route.name === 'edit-scheduled-export') {
      showSidebar.value = true;
    }

    if (route.name === 'export-scheduled') {
      showSidebar.value = false;
    }
  },
  {
    deep: true,
  },
);
</script>

<template>
  <section class="flex flex-column h-full pt-3 px-4">
    <TableSkeleton v-if="loading" />
    <DataTable
      v-else
      v-model:expanded-rows="expandedRows"
      scrollable
      scroll-height="flex"
      :value="scheduledPipelines"
      :row-hover="true"
      :lazy="true"
      :paginator="true"
      :rows="perPage"
      :total-records="total"
      :first="1"
      @page="(event:DataTablePageEvent) => onPageChange(event)"
    >
      <Column
        class="flex-none"
        body-style="width: 5rem"
        :expander="true"
        header-style="width: 5rem"
      />
      <!--#region Columns-->
      <Column field="name" :header="ts.tModule('table_header.name')" />

      <Column field="client" :header="ts.tModule('table_header.workspace')">
        <template #body="slotProps">
          {{ slotProps.data.client ? slotProps.data.client.name : 'Productsup' }}
        </template>
      </Column>

      <Column field="user" :header="ts.tModule('table_header.user')">
        <template #body="slotProps">
          {{
            slotProps.data?.user?.id === currentUser?.id
              ? ts.tGlobal('me')
              : slotProps.data.user.name
          }}
        </template>
      </Column>

      <Column field="status" :header="ts.tModule('table_header.status')">
        <template #body="slotProps">
          <div class="flex">
            <template v-if="slotProps.data.is_paused">
              <StatusChip :label="ts.tModule('export_scheduled.paused')" severity="paused" />
            </template>
            <template v-else>
              <StatusChip :label="ts.tModule('export_scheduled.running')" severity="success" />
            </template>
          </div>
        </template>
      </Column>

      <Column field="cron" :header="ts.tModule('table_header.next_at')">
        <template #body="slotProps">
          {{ moment(getNextRun(slotProps.data.cron)).format('DD MMM Y, HH:mm') }}
        </template>
      </Column>

      <Column field="icons" class="flex-none" header-style="width: 5rem" body-style="width: 5rem">
        <template #body="slotProps">
          <div class="flex justify-content-end">
            <Button
              plain
              text
              icon="mdi mdi-dots-vertical"
              aria-haspopup="true"
              aria-controls="overlay_menu"
              @click="(event: MouseEvent) =>toggleActionMenu(event)"
            />
            <Menu
              id="overlay_menu"
              ref="menu"
              class="w-auto"
              :model="menuItems(slotProps.data)"
              :popup="true"
            />
          </div>
        </template>
      </Column>
      <!-- message if their is no export scheduled-->
      <template #empty>
        <EmptyState
          :empty-state-title="
            ts.tGlobal('emptyStateTitle', {
              entity: ts.tModule('export_scheduled.title', { choice: 2 }).toLowerCase(),
            })
          "
          :translation-service="ts"
          :button-visible="true"
          :button-label="ts.tGlobal('productOverview')"
          :icon-name="'scheduled-exports'"
          @clicked="$router.push({ name: 'products' })"
        />
      </template>
      <template #expansion="slotProps">
        <div class="w-full">
          <ExportScheduledProductList
            :product-ids="slotProps.data.product_ids"
            @remove-product="removeProductFromScheduledPipeline($event, slotProps.data, false)"
          />

          <div class="flex justify-content-end">
            <Button
              rounded
              icon="mdi mdi-plus"
              label="Add more products"
              @click="addProducts(slotProps.data)"
            />
          </div>
        </div>
      </template>
      <!--#endregion-->
    </DataTable>

    <Drawer
      v-model:visible="showSidebar"
      class="p-drawer-md"
      :dismissable="false"
      position="right"
      @hide="hideDetails"
    >
      <router-view @hide="hideDetails" @refresh="loadAsyncData" />
    </Drawer>
    <ConfirmDialog group="export-scheduled" />
  </section>
</template>

<style lang="scss" scoped>
:deep(.p-datatable-tbody) .product {
  &.remove {
    background: red;
    opacity: 0;
    transition: all 1s ease;
  }
}

:deep(.p-datatable-row-expansion) > td {
  background: var(--surface-50);
}
</style>
