<script setup lang="ts">
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { KeyValueOptions } from 'platform-unit2-api/attributes';
import { KeyValueFieldService } from 'services/attribute-fields/field-services/key-value-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import { TranslationService } from 'services/translations/translation.service';
import { inject, ref } from 'vue';
import WrapperField from './wrapper-field.vue';

/** Props */
interface Props {
  attributeField: AttributeField<KeyValueOptions[] | undefined>;
  productAttributeField: ProductAttributeField<KeyValueOptions[] | undefined>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const ts = new TranslationService('general', 'components');
const keyValueFieldService = ref(
  new KeyValueFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>

<template>
  <WrapperField :attribute-field-service="keyValueFieldService">
    <Select
      v-if="!keyValueFieldService.attributeField.attribute.options?.multiSelect"
      v-model="keyValueFieldService.productAttributeFieldSingleArrayItem"
      :options="keyValueFieldService.attributeField.attribute.options?.choices"
      filter
      :disabled="keyValueFieldService.productAttributeField.locked"
      :placeholder="ts.tModule('attributeFields.chooseValue')"
      show-clear
      :class="keyValueFieldService.getErrorTypeClass()"
      option-label="key"
    >
      <template #empty>
        {{ ts.notFoundWarning }}
      </template>
    </Select>
    <MultiSelect
      v-else
      v-model="keyValueFieldService.productAttributeFieldValue"
      :options="keyValueFieldService.attributeField.attribute.options?.choices"
      option-label="key"
      :placeholder="ts.tModule('attributeFields.chooseValue')"
      display="chip"
      class="relative"
      :class="keyValueFieldService.getErrorTypeClass()"
      :disabled="keyValueFieldService.productAttributeField.locked"
      :show-toggle-all="!!keyValueFieldService.attributeField.attribute.options?.choices"
    />
  </WrapperField>
</template>
