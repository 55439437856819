<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import navigationSkeleton from '@/general/ui/components/skeletons/navigation-skeleton.vue';
import { authorizationMethods } from 'composables/authorization.plugin';
import { FeaturePermission } from 'platform-unit2-api/roles';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

// navigation types
import retailerNavigations from '@/platforms/retailer/navigation/index';
import userNavigations from '@/platforms/supplier/navigation/index';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import { Navigation } from 'platform-unit2-api/core';
import { User } from 'platform-unit2-api/users';

//assets
import SmallPuPLogo from '@/assets/images/pup_logo_all_white_small.svg';

// Props
defineProps<{
  isCollapsed: boolean;
  hasAccessToCrossNavigation: boolean;
}>();

const { hasPermission } = authorizationMethods;
const router = useRouter();

// Emits
const emit = defineEmits<{ (e: 'hide'): void }>();
// Constants
const store = useStore();
const route = useRoute();
const ts = new TranslationService('general', 'components');

const isNavLoading = ref(false);

const currentUser = computed<User>(() => store.getters['users/currentUser']);
const navigations = ref<Navigation[]>();
// const billingUsers = computed(() => {
//   return store.getters['users/currentUser'].organisation.billing_users.map((user: any) => user.id);
// });

async function getNavigation(): Promise<Navigation[]> {
  if (currentUser.value == null) {
    return [];
  }

  isNavLoading.value = true;
  switch (currentUser.value.workspace?.workspace_type?.type) {
    case ClientTypeEnum.RETAILER:
      isNavLoading.value = false;
      return retailerNavigations;
    case ClientTypeEnum.SUPPLIER:
      isNavLoading.value = false;
      return await userNavigations();
    default:
      isNavLoading.value = false;

      return await userNavigations();
  }
}

function userIsPermitted(permission?: FeaturePermission): boolean {
  return permission != null ? hasPermission(permission) : true;
}

// Lifecycle hooks
watch(
  () => currentUser.value,
  async () => {
    navigations.value = await getNavigation();
  },
  {
    deep: true,
  },
);

onMounted(async () => {
  navigations.value = await getNavigation();
});
</script>
<template>
  <div id="sidebar">
    <!-- Navigation items-->
    <nav class="menu-items">
      <div v-if="isNavLoading"><navigationSkeleton /></div>
      <ul v-else>
        <div class="align-items-center flex organisation-name">
          <!-- Logo -->
          <img
            v-if="!hasAccessToCrossNavigation"
            class="cursor-pointer h-2rem logo pup-mr-7 w-2rem"
            :src="SmallPuPLogo"
            @click="
              router.push({
                name: 'dashboard',
              })
            "
          />
          <h4 class="pup-ml-4 text-white" :class="{ hidden: isCollapsed }">
            {{ store.getters['users/currentUser']?.organisation?.name }}
          </h4>
        </div>

        <li v-for="(item, index) in navigations" :key="index" @click="emit('hide')">
          <router-link
            :is="item.disabled"
            v-if="item.to && userIsPermitted(item.permission)"
            v-tooltip.right="{
              value: ts.tModule(`navigation.entities.${item.title}`),
              disabled: !isCollapsed,
            }"
            :to="item.to"
            :class="[
              item.disabled ? 'pointer-events-none opacity-70' : '',
              (item.group != null && (item.group === (route?.meta as any)?.group) || route.path === item.to) && 'exact-active-item',
            ]"
          >
            <i
              v-if="item.icon"
              class="item-icon mdi"
              :class="`${item.icon} ${isCollapsed && 'scaleup'}`"
            />
            <span :class="{ hideTitle: isCollapsed }">
              {{ ts.tModule(`navigation.entities.${item.title}`) }}
            </span>
            <Chip
              v-if="item.disabled && !isCollapsed"
              class="bg-orange-600 font-bold ml-auto opacity-70 text-white uppercase"
            >
              deprecated
            </Chip>
            <Chip
              v-if="item.title === 'import_mappings' && !isCollapsed"
              class="beta-chip ml-2"
              :class="route.path.includes(item.to) ? 'bg-white' : 'bg-primary'"
            >
              beta
            </Chip>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
#sidebar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: #00456c;
}

.organisation-name {
  margin: 16px 0 32px 16px;
  height: 2.5rem;
  h4 {
    font-weight: 600;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

.menu-items {
  line-height: 12px;
  display: block;
  overflow-y: auto;

  ul {
    overflow-x: hidden;
  }

  li {
    margin: 4px 10px;
  }

  a {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0;
    width: 100%;
    display: flex;
    padding-left: 16px;
    padding: 12px;
    transition: all $animation-duration ease;
    cursor: pointer;
    border-radius: 2px;

    &:hover:not(.exact-active-item) {
      background-color: #00609766;
    }

    i {
      color: $icon-active;
      font-size: 16px;
      transition: all $animation-duration ease;

      &.scaleup {
        transform: scale(1.2);
      }
    }

    span {
      padding-left: 10px;
      max-width: 200px;
      transition: all $animation-duration ease;
      display: block;

      &.hideTitle {
        overflow: hidden;
        max-width: 0;
        padding-left: 0;
        opacity: 0;
      }
    }
  }

  .exact-active-item {
    background-color: #00609766;
    background-image: linear-gradient(to right, #00609766 0% 100%);
    background-repeat: no-repeat;
    animation: navigation $animation-duration ease forwards;
    color: $white;
    i {
      color: $white;
    }
  }
}
</style>
