<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import ProductSearchSelect from '@/general/ui/components/selects/product-search-select.vue';
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import Upload from '@/general/ui/components/upload.vue';
import { Product } from 'platform-unit2-api/products';
import { LinkProductService } from 'supplier/modules/products/services/link-product.service';
import { inject, onMounted, ref, watch } from 'vue';

/**Props */
interface Props {
  currentProduct?: Product;
  isActive: boolean;
  excludedProducts: number[];
}

const props = withDefaults(defineProps<Props>(), {
  currentProduct: undefined,
  isActive: false,
});

/**Emits */
const emit = defineEmits<{
  (e: 'hide', reload?: boolean): void; //If the parent component wants to reload the data
}>();

const loadAsyncProduct: (() => void | undefined) | undefined = inject('loadAsync');

function emitHide(): void {
  loadAsyncProduct?.();
  emit('hide', true);
}

/** Constants */
const ts = new TranslationService('supplier', 'products');

const linkProductService = ref(new LinkProductService({ emits: { hide: emitHide } }));

const closeSidebar = () => {
  if (linkProductService.value.doesNewLinkedProductExist()) {
    linkProductService.value.saveLinkedProducts(props.currentProduct?.id);
  }

  emit('hide', linkProductService.value.doesNewLinkedProductExist());
};

onMounted(() => {
  linkProductService.value.init({
    currentProduct: props.currentProduct,
    excludedProducts: props.excludedProducts,
  });
});

watch(
  () => props.currentProduct,
  (newProduct) => {
    linkProductService.value.init({
      currentProduct: newProduct,
      excludedProducts: props.excludedProducts,
    });
  },
);

watch(
  () => props.excludedProducts,
  (newExcludedProducts) => {
    linkProductService.value.excludedProducts = newExcludedProducts;
  },
);
</script>

<template>
  <Drawer
    :visible="isActive"
    class="p-drawer-md"
    :dismissable="false"
    position="right"
    @update:visible="closeSidebar"
  >
    <template #header>
      <div>
        <h3 class="font-bold mb-1 text-2xl">
          {{ ts.tModule('product_details.link_products.title') }}
        </h3>
        <p class="text-lg">
          {{ ts.tModule('product_details.link_products.description') }}
        </p>
      </div>
    </template>
    <div class="flex flex-column h-full">
      <sidebar-skeleton v-if="!currentProduct" />

      <!-- Linked Products -->
      <div>
        <!-- Current Product -->
        <div class="current linked-product">
          <div class="dot"></div>
          <div class="thumbnail">
            <Upload :upload="currentProduct?.thumbnail" />
          </div>
          <div class="data">
            <span class="gtin">{{ currentProduct?.gtin }}</span>
            <span class="name">{{ currentProduct?.display_name }}</span>
          </div>
        </div>

        <!-- Linked ones -->
        <div
          v-for="product in linkProductService.linkedProducts"
          :key="product.product.id"
          class="linked-product"
        >
          <div class="delete">
            <Button
              icon="mdi mdi-close"
              text
              plain
              rounded
              @click="linkProductService.removeLinkedProduct(product.product.id)"
            />
          </div>
          <div class="thumbnail">
            <Upload :upload="product.product.thumbnail" />
          </div>
          <div class="data">
            <span class="gtin">{{ product.product.gtin }}</span>
            <span class="name">{{ product.product.display_name }}</span>
            <span class="quantity">{{ product.quantity }}x</span>
          </div>
        </div>
      </div>

      <div class="bg-white bottom-0 mt-auto sticky">
        <!-- Linking form -->
        <div class="link-form">
          <div class="mr-3 w-8">
            <ProductSearchSelect
              v-model="linkProductService.selectedProduct"
              :excludes="excludedProducts"
            />
          </div>
          <div class="mr-3 w-2">
            <label>Quantity</label>
            <InputNumber
              id="quantity"
              v-model="linkProductService.selectedQuantity"
              class="mt-1 w-full"
              type="number"
              :min="1"
            />
          </div>
          <Button
            class="mt-auto"
            label="Link"
            :disabled="
              !linkProductService.selectedProduct || linkProductService.selectedQuantity < 1
            "
            @click="linkProductService.addLinkedProduct()"
          />
        </div>

        <!-- buttons -->
        <div
          class="bg-white border-200 border-top-1 bottom-0 flex justify-content-end mt-3 py-3 sticky"
        >
          <Button :label="ts.tGlobal('cancel')" text plain class="mr-2" @click="$emit('hide')" />

          <Button
            :disabled="
              linkProductService.isLoading || !linkProductService.doesNewLinkedProductExist()
            "
            :label="ts.tGlobal('save')"
            :icon="linkProductService.isLoading ? 'mdi mdi-loading pi-spin' : ''"
            icon-pos="right"
            @click="linkProductService.saveLinkedProducts(currentProduct?.id)"
          />
        </div>
      </div>
    </div>
  </Drawer>
</template>
<style lang="scss" scoped>
.linked-product {
  border: 1.5px solid #dce5ef;
  border-radius: 4px;
  margin: 16px;
  padding: 12px;
  display: flex;
  gap: 12px;
  position: relative;
  align-items: center;
  background-color: #fff;

  .thumbnail {
    width: 40px;
    max-height: 60px;
    overflow: hidden;
    object-fit: cover;
    overflow: hidden;
  }

  .data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5rem;
  }

  .dot {
    position: absolute;
    width: 11px;
    height: 11px;
    right: 9px;
    top: 9px;
    background-color: #377ef2;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  }

  .delete {
    position: absolute;
    right: 2px;
    top: 2px;
  }

  &.current {
    border: 1.5px solid #377ef2;
  }
}

.link-form {
  margin-top: auto;
  display: flex;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05);
  padding: 12px;
}
</style>
