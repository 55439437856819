<script setup lang="ts">
import { useService } from '@/general/composables/useService';

import { ExportMappingDetailsViewService } from '@/general/services/overview-service/modules/export-mapping/export-mapping-details.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import ApplicationHeader from '@/general/ui/layout/components/application-header.vue';
import { exportMappingDetailsServiceKey } from '../services/keys/export-mapping-details.keys';

const service = useService(exportMappingDetailsServiceKey, ExportMappingDetailsViewService);
const ts = new TranslationService('retailer', 'export_mappings');
</script>

<template>
  <ApplicationHeader>
    <template #breadcrumbs>
      <router-link :to="{ name: 'export-mappings' }">
        <Button
          outlined
          class="custom-button-styles p-button-sm"
          icon="mdi mdi-chevron-left"
          label="Export mappings"
        />
      </router-link>
    </template>

    <template #content>
      <div class="align-items-center flex gap-3 pb-4">
        <div v-if="!service.isLoadingDetailsPage" class="align-items-start flex flex-column gap-2">
          <h3>
            {{
              service?.selectedMapping?.owner ? ts.tModule('supported') : ts.tModule('unsupported')
            }}
            {{ ts?.tModule('title', { params: { count: 2 } }).toLowerCase() }}
          </h3>
          <p>{{ ts?.tModule('export_mapping_supported.mapAttributesSubtitles') }}</p>
        </div>
        <Skeleton v-else width="30rem" height="28px" />
      </div>
    </template>
  </ApplicationHeader>
  <ConfirmDialog group="dirty-dialog" />
</template>
<style lang="css" scoped>
.custom-button-styles {
  border-color: #dce5ef !important;
  color: black !important;
}
</style>
