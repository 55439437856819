import { isEmpty } from 'lodash';
import { RefetchService } from '@/general/services/overview-service/refetch.service';
import { useRoute } from 'vue-router';
import { ImportMapping, ImportMappingsRestService } from 'platform-unit2-api/import-mappings';
import { DirtyStateService } from '@/general/services/dirty-state/dirty-state.service';
import { FormValidationService } from '@/general/services/form-validation/form-validation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { OverviewBase } from '../../interfaces/overview-base.interface';
import {
  CreateOrUpdateMappingSettingRequest,
  MappingSetting,
  MappingSettingRestService,
  PresetRegex,
} from 'platform-unit2-api';

export class ImportMappingSettingViewService extends RefetchService implements OverviewBase {
  public parent?: ImportMapping;
  public dirtyState: DirtyStateService<MappingSetting>;
  public isLoadingParent = true;
  public deleted = false;
  private _route = useRoute();
  private _ts: TranslationService;
  private _formValidationService: FormValidationService;
  private _isLoadingOverview = true;
  private _isInitialized = false;
  private _toastService: ToastService;
  private _importMappingRestService: ImportMappingsRestService;

  private _mappingSettingRestService = new MappingSettingRestService();
  private _setting?: MappingSetting | Partial<MappingSetting>;
  public exampleHeader = '';

  public regexOptions = [
    {
      label: 'DataDump/Pup standard [*]',
      regex: PresetRegex.BRACKETBASE,
    },
    {
      label: 'GS1 pull api /*/',
      regex: PresetRegex.SLASHBASE,
    },
    {
      label: 'Custom',
      regex: PresetRegex.CUSTOM,
    },
  ];

  private _selectedRegex?: { label: string; regex: PresetRegex };

  public get selectedRegex(): { label: string; regex: PresetRegex } | undefined {
    return this._selectedRegex;
  }

  public set selectedRegex(value: { label: string; regex: PresetRegex }) {
    this._selectedRegex = value;

    this.partialObject.preset_regex = value?.regex;

    if (this.partialObject.preset_regex !== PresetRegex.CUSTOM) {
      this.partialObject.regex = undefined;
    }
  }

  public get customRegex(): boolean {
    return this._selectedRegex?.regex === PresetRegex.CUSTOM;
  }

  public get partialObject(): Partial<MappingSetting> {
    if (!this._setting) {
      this._setting = {};
    }

    return this._setting;
  }

  public get isInitialized(): boolean {
    return this._isInitialized;
  }

  public get isLoadingCrudComponent(): boolean {
    return false;
  }

  public get isLoadingOverView(): boolean {
    return this._isLoadingOverview;
  }

  public get headerResult(): string {
    let regex: string | RegExp | undefined;

    if (this.selectedRegex?.regex === PresetRegex.BRACKETBASE) {
      regex = /\[\d+\]/;
    }

    if (this.selectedRegex?.regex === PresetRegex.SLASHBASE) {
      regex = /\/\d+\/?/;
    }

    if (this.selectedRegex?.regex === PresetRegex.CUSTOM) {
      regex = this.partialObject.regex;
    }

    if (!regex) {
      return this.exampleHeader;
    }

    const regExp = new RegExp(regex, 'g');
    const matches = this.exampleHeader.match(regExp);

    //remove the matches
    let resultHeader = this.exampleHeader;

    if (matches) {
      for (const match of matches) {
        resultHeader = resultHeader.replace(match, this.partialObject.delimiter ?? '');
      }
    }

    return resultHeader;
  }

  public get pathResult(): string {
    let regex: string | RegExp | undefined;

    if (this.selectedRegex?.regex === PresetRegex.BRACKETBASE) {
      regex = /\[\d+\]/;
    }

    if (this.selectedRegex?.regex === PresetRegex.SLASHBASE) {
      regex = /\/\d+\/?/;
    }

    if (this.selectedRegex?.regex === PresetRegex.CUSTOM) {
      regex = this.partialObject.regex;
    }

    if (!regex) {
      return this._ts.tModule('noPathResult');
    }

    const regexObject = new RegExp(regex ?? '', 'g');
    const matches = this.exampleHeader.matchAll(regexObject);

    const numbers: number[] = [];
    const numberRegex = new RegExp(/\d+/, 'g');

    const matchStrings: string[] = [];

    for (const match of matches) {
      matchStrings.push(match.toString());
    }

    const stringedMatches = matchStrings.join('');
    const matchNumbers = stringedMatches.matchAll(numberRegex);

    for (const match of matchNumbers) {
      numbers.push(Number(match));
    }

    if (numbers.length === 0) {
      return this._ts.tModule('noPathResult');
    }

    const resultPath = numbers.join('.');

    return resultPath;
  }

  public fieldErrorMessage = (name: string) => this._formValidationService.fieldErrorMessage(name);

  public hasError = (field: string): boolean => this._formValidationService.hasError(field);

  constructor(ts: TranslationService) {
    super();
    this._ts = ts;

    this.dirtyState = new DirtyStateService<MappingSetting>();
    this._formValidationService = new FormValidationService();
    this._toastService = ToastService.getInstance();
    this._importMappingRestService = new ImportMappingsRestService();

    this.refetch = this.fetchSetting;
  }

  public fetchAll(): void {
    this.fetchSetting();
  }

  private _resetDirtyState() {
    this._formValidationService.resetErrors();

    this.dirtyState.resetData(this.partialObject as MappingSetting);
  }

  public fetchSetting(): void {
    this._isLoadingOverview = true;

    this._mappingSettingRestService
      .get(+this._route.params['id'])
      .then((result) => {
        if (result) {
          this._setting = result;

          this._selectedRegex = this.regexOptions.find(
            (option) => option.regex === result.preset_regex,
          );
        } else {
          this._setting = {
            mapping_id: +this._route.params['id'],
          };
        }

        this._resetDirtyState();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this._isInitialized = true;
      });
  }

  public resolveCrudComponent(): void {
    if (!isNaN(+this._route.params['id'])) {
      this.isLoadingParent = true;
      this._importMappingRestService
        .get(Number(this._route.params['id']))
        .then((res) => {
          this.parent = res;
        })
        .catch(() => {
          this._toastService.displayErrorToast(this._ts.loadFailed());
          this.parent = undefined;
        })
        .finally(() => {
          this.isLoadingParent = false;
        });
    }
  }

  private _updateOrCreateRequestBody(): CreateOrUpdateMappingSettingRequest {
    if (this.parent == null) {
      throw new Error('Parent is not defined');
    }

    const regex = this.partialObject.regex ?? null;

    const delimiter = this.partialObject.delimiter ?? null;

    return {
      id: this.partialObject?.id,
      mapping_id: this.parent?.id,
      regex: isEmpty(regex) ? null : regex,
      delimiter: isEmpty(delimiter) ? null : delimiter,
      preset_regex: this._selectedRegex?.regex ?? null,
    };
  }

  public updateSetting() {
    this._isLoadingOverview = true;

    this._mappingSettingRestService
      .put(+this._route.params['id'], this._updateOrCreateRequestBody())
      .then((result) => {
        this._setting = result;

        this._resetDirtyState();
        this._toastService.displaySuccessToast(this._ts.updateSuccess());
      })
      .catch((e) => {
        this._formValidationService.handleError(e, () => {
          this._toastService.displayErrorToast(this._ts.updateFailed());
        });
      })
      .finally(() => {
        this._isLoadingOverview = false;
      });
  }
}
