<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ProductStory } from 'platform-unit2-api/product-stories';
import StoryComponent from 'supplier/modules/products/components/product-story.vue';
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
//** Constants */
const store = useStore();
const loading = ref(false);
const creatingStory = ref(false);

const stories = ref<ProductStory[]>([]);

const reloadData = async () => {
  loading.value = true;
  try {
    stories.value = (
      await store.dispatch(
        'products/GET_PRODUCT_STORIES',
        store.getters['products/currentProduct'].id,
      )
    ).data;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed(ts.tModule('product_story.title')));
  } finally {
    loading.value = false;
  }
};

const addProductStory = async () => {
  creatingStory.value = true;
  try {
    await store.dispatch('products/CREATE_PRODUCT_STORY', {
      product_id: store.getters['products/currentProduct'].id,
      data: '',
    });
    await reloadData();
  } catch (ex) {
    toastService.displaySuccessToast(ts.createFailed(ts.tModule('product_story.title')));
  } finally {
    creatingStory.value = false;
  }
};

watch(
  () => store.getters['products/currentProduct'],
  () => {
    reloadData();
  },
);

onMounted(() => {
  reloadData();
});
</script>

<template>
  <div class="mb-5">
    <section v-if="!stories.length">
      <div class="align-items-center flex flex-column">
        <img src="@/assets/images/no_stories.svg" alt="Nothing here" />
        <p class="font-bold mb-5">{{ ts.tModule('product_story.empty') }}</p>
        <Button class="font-bold" :loading="creatingStory" @click="addProductStory">
          {{ ts.tModule('product_story.start_creating') }}
        </Button>
      </div>
    </section>
    <div v-else>
      <StoryComponent
        v-for="(story, i) in stories"
        :key="`key_${story.id}_${i}`"
        :story="story"
        @input="reloadData"
      />
      <div class="align-items-center flex flex-column">
        <Button class="font-bold" :disabled="creatingStory" @click="addProductStory">
          {{ ts.tModule('product_story.create_story') }}
        </Button>
      </div>
    </div>
  </div>
</template>
