<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { ScheduledImportViewService } from '@/general/services/overview-service/modules/scheduled-import-view.service';
import TableSkeleton from '@/general/ui/components/skeletons/table-skeleton.vue';
import StepperManager from '@/general/ui/components/stepper-manager.vue';
import OverView from 'ui/views/over-view/over-view.vue';
import ScheduledImportTable from '../components/scheduled-import-table.vue';
import { scheduledImportServiceKey } from '../ts/keys/scheduled-import-service.key';

const service = useService(scheduledImportServiceKey, ScheduledImportViewService);
</script>

<template>
  <OverView platform="supplier" module-name="scheduledImport" :service="service">
    <template #skeleton>
      <TableSkeleton />
    </template>
    <template #content>
      <ScheduledImportTable />
    </template>
    <template #crudComponent>
      <StepperManager
        :is-active="service.crudComponent"
        :disable-buttons="service.disableNextButtons"
        :header="service.dialogHeader"
        :child-routes="service.childRoutes"
        :form-data="{}"
        size="medium"
        @hide="service.closeCrudComponent()"
        @complete="service.save()"
      />
    </template>
    <template #others>
      <ConfirmDialog group="scheduled-import" />
    </template>
  </OverView>
</template>
