<script setup lang="ts">
import { inject } from 'vue';
import { productTableServiceKey } from '../../key/product-table-service.key';

const productTableService = inject(productTableServiceKey)!;
</script>

<template>
  <Button
    v-tooltip.bottom="{
      value: productTableService.ts.tModule('reset_filters'),
      class: 'text-sm',
    }"
    class="bg-white border-100 text-800"
    @click="productTableService.resetFilters()"
    ><i class="mdi mdi-filter-remove-outline"
  /></Button>
</template>
