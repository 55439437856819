<script setup lang="ts">
import { ProductAttributeFieldsService } from '@/general/services/attribute-fields/product-attribute-fields.service';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { TranslationService } from '@/general/services/translations/translation.service';
import AttributeFieldsRenderer from '@/general/ui/components/attribute-fields/attribute-fields-renderer.vue';
import { lockableKey } from '@/general/ui/components/attribute-fields/inject.keys';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import { cloneDeep } from 'lodash';
import { AttributeCompact, AttributeField, ProductAttributeField } from 'platform-unit2-api';
import { computed, onMounted, provide, Ref, ref, watch } from 'vue';
import { DefaultFieldService } from '../ts/services/default-fields.service';
import DefaultFieldOptionsDropdown from './default-field-options-dropdown.vue';

/** Props */
export interface Props {
  service: DefaultFieldService;
}

const props = defineProps<Props>();

const ts = new TranslationService('supplier', 'datamodelsConfig');

const mockAttributeField = computed<AttributeField<any>>(() => ({
  attribute: {
    ...cloneDeep(props.service.attribute),
    options: {
      ...cloneDeep(props.service.attribute.options),
      group_size: 12,
    },
    locked: false,
    category_attribute: false,
  } as AttributeCompact,
  children: [],
  path: null,
  values: getValuesFromLocales(),
}));

function getValuesFromLocales(): ProductAttributeField<any>[] {
  if (props.service.attribute.global_attribute) {
    // If the attribute is global, we only need one field and use the first one as a workaround until the backend fixes it
    const globalLocaleId = props.service.workspaceLocales[0].id;
    return [
      {
        id: 0,
        attribute_id: props.service.attribute.id,
        value: getValue(globalLocaleId),
        locale: { value: 'global', id: globalLocaleId ?? props.service.workspaceLocales[0].id },
        locked: false,
        path: null,
        errors: [],
      },
    ];
  }

  return props.service.workspaceLocales.map((locale) => ({
    id: 0,
    attribute_id: props.service.attribute.id,
    value: getValue(locale.id),
    locale: locale,
    locked: false,
    path: null,
    errors: [],
  }));
}

function getValue(localeId?: number) {
  return props.service.defaultValues.find(
    (df) => df.locale_id === localeId && df.attribute_id === props.service.attribute.id,
  )?.value;
}

const productAttributeFieldsService = ref(new ProductAttributeFieldsService());

provide(
  productAttributeFieldsServiceKey,
  productAttributeFieldsService as Ref<ProductAttributeFieldsService>,
);

provide(lockableKey, false);

/** Lifecycle */
onMounted(async () => {
  await props.service.init();
});

watch(
  () => productAttributeFieldsService.value.updatedFields,
  () => {
    productAttributeFieldsService.value.updatedFields.forEach((field) => {
      props.service.setValue(field.value, field.locale_id);
    });
  },
  { deep: true },
);
</script>

<template>
  <div>
    <div v-if="service.defaultFieldsLoading" class="h-20rem m-auto w-20rem">
      <LoadingIndicator />
    </div>

    <div v-else>
      <p class="my-5">
        {{ ts.tModule('configuration.default.informationText') }}
      </p>
      <p class="font-bold">{{ ts.tModule('configuration.default.fieldHeader') }}</p>
      <AttributeFieldsRenderer :attribute-fields="[mockAttributeField]">
        <template #afterField="{ productAttributeField }">
          <div
            v-if="productAttributeField.locale?.id != null"
            class="flex mb-4 ml-2 mt-2 w-5"
            style="max-width: 28% !important"
          >
            <DefaultFieldOptionsDropdown
              class="flex-grow-1 overflow-hidden"
              :service="service"
              :locale="productAttributeField.locale"
            />
            <div class="align-items-center flex ml-2">
              <Button
                plain
                text
                :disabled="!service.defaultField(productAttributeField.locale.id)?.id"
                icon="mdi mdi-delete-outline"
                @click="service.deleteDefaultField($event, productAttributeField.locale.id)"
              />
            </div>
          </div>
        </template>
      </AttributeFieldsRenderer>
      <ConfirmPopup group="delete-defaults" />
    </div>
  </div>
</template>
