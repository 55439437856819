<script setup lang="ts">
import { Attribute } from 'platform-unit2-api/attributes';
import { inject } from 'vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';

/** Props */
export interface Props {
  attribute: Attribute;
}

defineProps<Props>();

const service = inject(datamodelAttributeServiceKey)!;
</script>

<template>
  <div class="align-items-center flex justify-content-between w-full">
    <div class="align-items-center flex" style="width: 80%">
      <Tag
        v-tooltip.bottom="service.ts.tModule('datamodel_attributes.attribute_id')"
        :style="{
          background: 'var(--gray-100)',
          color: 'var(--gray-500)',
        }"
        :value="attribute.id"
        class="mr-2"
      />
      <span class="ml-1 overflow-hidden text-overflow-ellipsis"> {{ attribute.key }}</span>
    </div>
    <i
      v-if="service.isUserAttribute(attribute.id)"
      class="mdi mdi-check mr-2 p-1 text-blue-700"
      :style="{ width: '20px', height: '20px' }"
    />
    <i
      v-else
      class="bg-primary border-round-sm cursor-pointer hover:bg-blue-500 mdi mdi-plus mr-2 p-1 text-white"
      :style="{ width: '20px', height: '20px' }"
      @click="service.addAttribute(attribute)"
    />
  </div>
</template>
