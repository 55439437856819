<script setup lang="ts">
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ProductStatus, ProductStatusesRestService } from 'platform-unit2-api/product-statuses';
import { Product, ProductsRestService, UpdateProductRequest } from 'platform-unit2-api/products';
import { computed, inject, ref, watch } from 'vue';
import { useStore } from 'vuex';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;
const confirmService = new ConfirmService();
const productStatusApi = new ProductStatusesRestService();
const productRestApi = new ProductsRestService();

/**Consts */
const store = useStore();
const statuses = ref<(ProductStatus & { command: () => void })[]>([]);
const isSaving = ref(false);
const isDeleting = ref(false);
const isLoadingProductDetails = ref(false);
const visible = ref(false);

const isLoading = computed(() => {
  return isSaving.value || isDeleting.value || isLoadingProductDetails.value;
});

const transitionState = async (id: number) => {
  try {
    if (!productAttributeFieldsService.value.currentProduct) {
      return;
    }

    isSaving.value = true;

    const productUpdateRequest: UpdateProductRequest = {
      id: productAttributeFieldsService.value.currentProduct.id,
      product_status_id: id,
      display_name: productAttributeFieldsService.value.currentProduct.display_name ?? '',
      gtin: productAttributeFieldsService.value.currentProduct.gtin ?? '',
      introduced_at: productAttributeFieldsService.value.currentProduct.introduced_at ?? null,
      delisted_at: productAttributeFieldsService.value.currentProduct.delisted_at ?? null,
    };

    productAttributeFieldsService.value.currentProduct = (await productRestApi.update(
      productAttributeFieldsService.value.currentProduct?.id,
      productUpdateRequest,
    )) as Product;

    toastService.displaySuccessToast(ts.updateSuccess());
  } catch (ex) {
    toastService.displayErrorToast(ts.updateFailed());
  } finally {
    isSaving.value = false;
  }
};

const loadAsyncData = async (): Promise<void> => {
  try {
    const fetchedStatusses = (await productStatusApi.getAll()).data;

    statuses.value = fetchedStatusses.map((status) => {
      return {
        ...status,
        command: () => {
          transitionState(status?.id);
        },
      };
    });
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    visible.value = productAttributeFieldsService.value.currentProduct?.public ?? false;
  }
};

const saveProduct = async (overrides: { attributes: number[]; variants: number[] }) => {
  isSaving.value = true;

  productAttributeFieldsService.value
    .saveAttributeFields(overrides)
    .then(async () => {
      store.dispatch('products/SET_LOADING_COMPLETENESS', true);

      toastService.displaySuccessToast(
        ts.updateSuccess(productAttributeFieldsService.value.currentProduct?.display_name),
      );
    })
    .catch(() => {
      toastService.displayErrorToast(
        ts.updateFailed(productAttributeFieldsService.value.currentProduct?.display_name),
      );
    })
    .finally(() => {
      isSaving.value = false;
    });
};

const resetProductHandler = (event: MouseEvent) => {
  confirmService.confirmDelete({
    event: event,
    callback: () => productAttributeFieldsService.value.discardChanges(),
    group: 'revert-changes',
    message: ts.tModule('product_details.product_status_bar.reset_product'),
  });
};

watch(
  () => productAttributeFieldsService.value.currentProduct?.public,
  (newValue, _) => {
    if (newValue != null) {
      loadAsyncData();
    }
  },
);
</script>
<template>
  <div
    class="bg-white flex justify-content-between p-3"
    :style="{
      boxShadow: '0 -10px 10px -10px rgba(0, 0, 0, 0.12)',
    }"
  >
    <div class="align-items-center flex">
      <div
        v-for="(status, index) in statuses"
        :key="status?.id"
        class="align-items-center flex ml-1"
      >
        <span
          :style="{
            color:
              productAttributeFieldsService.currentProduct?.status?.id === status?.id
                ? '#ffffff'
                : '#0089d7',
            backgroundColor:
              productAttributeFieldsService.currentProduct?.status?.id === status?.id
                ? '#0089d7'
                : '#E0F0FF',
          }"
          class="align-items-center border-circle flex flex-shrink-0 h-2rem justify-content-center text-lg w-2rem"
          >{{ index + 1 }}</span
        >
        <p class="mx-2">{{ status.label }}</p>
        <Divider v-if="index < statuses?.length - 1" class="mx-2" />
      </div>
    </div>
    <div class="align-items-center flex">
      <ConfirmPopup group="product-delete" />
      <ConfirmDialog group="revert-changes" />

      <p
        v-if="
          productAttributeFieldsService.currentProduct &&
          productAttributeFieldsService.hasAttributeFieldChanges
        "
        class="text-gray-400"
      >
        {{ ts.tModule('product_details.product_status_bar.unsaved_changes') }}
      </p>
      <Button
        v-if="
          productAttributeFieldsService.currentProduct &&
          productAttributeFieldsService.hasAttributeFieldChanges
        "
        label="Discard changes"
        icon="mdi mdi-delete-outline"
        :disabled="isLoading || productAttributeFieldsService.loading"
        class="ml-3 p-button-danger p-button-text underline"
        @click="(event: MouseEvent) => resetProductHandler(event)"
      />

      <Button
        v-if="
          productAttributeFieldsService.currentProduct &&
          productAttributeFieldsService.hasAttributeFieldChanges &&
          !productAttributeFieldsService.isSaveButtonDisabled() &&
          !productAttributeFieldsService.loading
        "
        :label="ts.tGlobal('save')"
        :loading="isSaving"
        severity="success"
        @click="saveProduct({ attributes: [], variants: [] })"
      />
      <div
        v-else-if="
          productAttributeFieldsService.hasAttributeFieldChanges &&
          productAttributeFieldsService.isSaveButtonDisabled()
        "
        v-tooltip.top="{
          value: ts.tModule('disabledButtonErrorToolTips'),
          class: 'text-sm',
        }"
      >
        <Button :label="ts.tGlobal('save')" :loading="isSaving" disabled severity="success" />
      </div>
      <SplitButton
        v-if="
          productAttributeFieldsService.currentProduct &&
          !productAttributeFieldsService.hasAttributeFieldChanges
        "
        :disabled="isLoading || productAttributeFieldsService.hasAttributeFieldChanges"
        severity="success"
        :label="
          productAttributeFieldsService.currentProduct.status &&
          productAttributeFieldsService.currentProduct.status.label
        "
        :model="statuses"
      />
    </div>
  </div>
</template>
