<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import { tsKey } from '@/general/services/translations/translation.key';
import LabeledTag from '@/general/ui/components/labeled-tag.vue';
import AssetTypeSelect from '@/general/ui/components/selects/asset-type-select.vue';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import { formatDate } from '@/general/utils/format-date';
import { inject } from 'vue';
import { assetViewServiceKey } from '../keys/asset-view-service.key';

const service = useService(assetViewServiceKey, AssetViewService);
const ts = inject(tsKey);
</script>
<template>
  <div v-if="service != null" class="grid">
    <div class="col-12 flex justify-content-center">
      <Image
        v-if="service.partialObject.public_thumbnail_url"
        height="270"
        :src="service.partialObject.public_url"
        preview
      >
        <template #previewicon>
          <i class="mdi mdi-eye text-4xl"></i>
        </template>
      </Image>
      <SvgIcon v-else :filename="service.partialObject.filename" custom-style="width: 8rem" />
    </div>

    <Divider class="mb-2" />

    <div class="align-items-center col-12 flex h-3rem justify-content-between mb-3 text-sm">
      <div class="align-items-center flex">
        <LabeledTag :filename="service.partialObject.filename ?? ''" />
        <p class="ml-3 w-5rem">{{ service.getFileSize() }}</p>
      </div>
    </div>

    <div class="col-12 text-sm">
      <div class="grid">
        <p class="col-3 mr-4 text-400">{{ ts?.tModule('create.title') }}</p>
        <p class="col">{{ formatDate(new Date(service.partialObject.created_at ?? '')) }}</p>
      </div>
      <div class="grid">
        <p class="col-3 mr-4 text-400">{{ ts?.tGlobal('lastUpdate') }}</p>
        <p class="col">{{ formatDate(new Date(service.partialObject.updated_at ?? '')) }}</p>
      </div>
      <div
        v-if="
          service.partialObject.resolution_in_pixels_height &&
          service.partialObject.resolution_in_pixels_width
        "
        class="grid"
      >
        <p class="col-3 mr-4 text-400">{{ ts?.tModule('resolution') }}</p>
        <p class="col">
          {{
            service.partialObject.resolution_in_pixels_height +
            'x' +
            service.partialObject.resolution_in_pixels_width
          }}
        </p>
      </div>
    </div>
  </div>

  <div class="flex flex-column pup-mb-2">
    <label class="pup-mb-2">Filename</label>
    <InputText
      v-model="service.partialObject.filename"
      class="w-full"
      :class="{ 'p-invalid': service.hasError('filename') }"
      type="text"
    />
    <small
      v-if="service.hasError('filename')"
      :class="{ 'p-error block': service.hasError('filename') }"
      class="hidden"
      >{{ service.fieldErrorMessage('filename').toString() }}</small
    >
  </div>

  <div v-if="service != null" class="col-12 pl-0 pup-mb-2">
    <AssetTypeSelect v-model="service.partialObject.asset_type" />
  </div>
</template>
