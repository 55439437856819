import { RouteFactory } from '@/core/router/route.factory';
import { RouterService } from '@/core/router/router.service';
import ExportMapping from 'retailer/modules/export-mappings/views/export-mappings.view.vue';
import ImportMappingItemsHeader from 'retailer/modules/import-mapping-items/components/import-mapping-items-header.vue';
import ImportMappingItems from 'retailer/modules/import-mapping-items/views/import-mapping-items.view.vue';
import ImportMapping from 'retailer/modules/import-mappings/views/import-mapping.view.vue';
import { RouteRecordRaw } from 'vue-router';
import ExportMappingItemsHeader from '../../export-mappings/components/export-mapping-items-header.vue';
import ExportMappingSupportedItems from '../../export-mappings/views/export-mapping-supported-items.view.vue';
import ExportMappingUnsupportedItems from '../../export-mappings/views/export-mapping-unsupported-items.view.vue';

const routeFactory = new RouteFactory({
  platform: 'retailer',
  module: 'importMappings',
  navigationGroup: 'mappings',
  tabs: [
    {
      label: 'Import',
      key: 'import-mappings',
      command: () => {
        RouterService.getInstance().router.push({ name: 'import-mappings' });
      },
    },
    {
      label: 'Export',
      key: 'export-mappings',
      command: () => {
        RouterService.getInstance().router.push({ name: 'export-mappings' });
      },
    },
  ],
});

const mappingRoutes: RouteRecordRaw[] = [
  routeFactory.createAppRoute({
    path: '/mappings',
    name: 'mappings',
    redirect: { name: 'import-mappings' },
    title: 'Mappings',
  }),
  routeFactory.createAppRoute({
    path: '/mappings/import',
    name: 'import-mappings',
    component: ImportMapping,
    title: 'Import mappings',
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'new-import-mapping',
      }),
    ],
  }),
  routeFactory.createAppRoute({
    path: '/mappings/import/:id',
    name: 'edit-import-mapping',
    component: ImportMappingItems,
    header: ImportMappingItemsHeader,
    title: 'Edit mappings',
  }),
  routeFactory.createAppRoute({
    path: '/mappings/export',
    name: 'export-mappings',
    component: ExportMapping,
    title: 'Export mappings',
    children: [
      RouteFactory.createEmptyRoute({
        path: 'new',
        name: 'new-mapping',
      }),
    ],
  }),

  routeFactory.createAppRoute({
    path: '/mappings/export/:id/supported',
    name: 'supported-export-mapping',
    component: ExportMappingSupportedItems,
    header: ExportMappingItemsHeader,
    title: 'Supported export mappings',
  }),

  routeFactory.createAppRoute({
    path: '/mappings/export/:id/unsupported',
    name: 'unsupported-export-mapping',
    component: ExportMappingUnsupportedItems,
    header: ExportMappingItemsHeader,
    title: 'Unsupported export mappings',
  }),
];

export default mappingRoutes;
