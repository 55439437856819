<script setup lang="ts">
import { useService } from '@/general/composables/useService/useService';
import { ImportMappingSettingViewService } from '@/general/services/overview-service/modules/import-mapping/import-mapping-settings.view.service';
import { tsKey } from '@/general/services/translations/translation.key';
import { TranslationService } from '@/general/services/translations/translation.service';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import OverView from '@/general/ui/views/over-view/over-view.vue';
import { Ref, provide, ref } from 'vue';
import ImportMappingSettingForm from '../components/import-mapping-setting-form.vue';
import { importMappinsSettingServiceKey } from '../ts/keys/import-mapping-items.keys';

const ts = ref(new TranslationService('supplier', 'ImportMappingSetting'));
const service = useService(importMappinsSettingServiceKey, ImportMappingSettingViewService, ts);

provide(importMappinsSettingServiceKey, service);
provide(tsKey, ts as Ref<TranslationService>);
</script>
<template>
  <OverView
    platform="supplier"
    module-name="importMappingSetting"
    :service="service"
    :has-search="false"
  >
    <template #skeleton>
      <LoadingIndicator :columns="2" :has-thumbnail="false" />
    </template>
    <template #content>
      <div class="flex justify-content-between">
        <div class="w-9">
          <!-- Has the v-if so it rerenders the errors when form is made, this is probably some weird issue because the if is also in the baseview -->
          <ImportMappingSettingForm v-if="!service.isLoadingOverView && service.isInitialized" />
        </div>
        <div>
          <Button
            :disabled="service.isLoadingOverView || !service.dirtyState.isTouched()"
            class="ml-auto mr-2"
            :label="ts.saveButton()"
            @click="service?.updateSetting()"
          />
        </div>
      </div>
    </template>
  </OverView>
</template>
