<script lang="ts" setup>
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import LabeledTag from '@/general/ui/components/labeled-tag.vue';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import Upload from '@/general/ui/components/upload.vue';
import { Upload as UploadType } from 'platform-unit2-api/uploads';
import { assetViewServiceKey } from '../keys/asset-view-service.key';

interface Props {
  image: UploadType;
  hideSelect?: boolean;
  colSize?: number;
}

defineProps<Props>();

// Services
const service = useService(assetViewServiceKey, AssetViewService);
</script>

<template>
  <Card
    :class="`${!service?.disabled ? 'clickable' : ''} ${
      service?.checkedIds.includes(image.id) ? 'border-primary' : ''
    }`"
    class="align-items-between flex flex-column justify-content-between w-full"
    @click="!service.disabled ? service?.redirectToUpdate(image.id) : () => {}"
  >
    <template #content>
      <div class="align-items-center flex flex-column justify-content-center">
        <div class="align-items-center flex h-18rem justify-content-center max-w-full mb-4 w-18rem">
          <Upload v-if="image.public_thumbnail_url" :preview="service?.disabled" :upload="image" />
          <SvgIcon v-else :filename="image.filename" custom-style="width: 8rem" />
        </div>

        <div
          class="my-3 overflow-hidden text-center text-overflow-ellipsis w-full white-space-nowrap"
        >
          <span>{{ image.filename }}</span>
        </div>
      </div>

      <div class="flex justify-content-between">
        <Checkbox
          v-if="hideSelect !== true"
          v-model="service.checkedAssets"
          :value="image"
          @click.stop
        />

        <label v-if="image.asset_type" class="mr-auto">{{ image.asset_type.name }}</label>
        <LabeledTag :filename="image.filename" />
      </div>
    </template>
  </Card>
</template>
