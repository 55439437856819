<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { ImportService } from '@/general/services/overview-service/modules/import.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { FileUploadSelectEvent, FileUploadUploaderEvent } from 'primevue/fileupload';
import SvgIcon from 'ui/components/svg-icon.vue';
import { importServiceKey } from '../../ts/keys/import-service.key';

const service = useService(importServiceKey, ImportService);
const ts = new TranslationService('supplier', 'imports');

const fileObjectURL = (file: File) => {
  return URL.createObjectURL(file);
};
</script>

<template>
  <section class="m-3">
    <FileUpload
      multiple
      custom-upload
      :disabled="service.uploading"
      :accept="(service.partialObject.module?.allowed_file_types ?? []).join(',')"
      @uploader="(event: FileUploadUploaderEvent) => service.uploadFiles(event)"
      @select="(event: FileUploadSelectEvent) => service.disableUploadPageButtonCondition(event.files)"
    >
      <template #content="{ files, removeFileCallback }">
        <div class="overflow-y-auto" style="max-height: 160px">
          <Message
            v-if="service.disableNextButtonUploadPage"
            severity="warn"
            class="m-2"
            :pt="{
              content: {
                class: 'justify-content-center',
              },
            }"
          >
            <template #icon>
              <i class="mdi mdi-alert-outline text-orange-600" />
            </template>
            {{ ts.tModule('filesNotUploaded') }}
          </Message>
          <div
            v-for="(file, index) in files"
            :key="index"
            class="file-block flex justify-between m-2"
          >
            <div class="align-items-center flex flex-auto flex-row">
              <div v-if="fileObjectURL(file)" class="image-container">
                <img :src="fileObjectURL(file)" class="image-block" />
              </div>
              <SvgIcon v-else :filename="file.name" custom-style="width: 4rem" />
              <div class="flex flex-column gap-1 mx-4">
                <span>{{ file.name }}</span>
                <div class="align-items-center flex flex-row">
                  <span class="mr-3 text-gray-600"> {{ (file.size / 1024).toFixed(1) }} KB</span>
                  <Badge :value="ts.tModule('pending')" severity="warning" />
                </div>
              </div>
            </div>
            <Button
              icon="pi pi-times"
              text
              rounded
              severity="danger"
              @click="service.removeSelectedImage(index, files, removeFileCallback)"
            />
            <div></div>
          </div>
          <div
            v-for="(file, index) in service.partialObject.files"
            :key="index"
            class="file-block m-2"
          >
            <SvgIcon :filename="file" custom-style="width: 4rem" />
            <div class="flex flex-column gap-1 mx-4">
              <span>{{ file }}</span>
              <div><Badge :value="ts.tModule('completed')" severity="success" /></div>
            </div>
          </div>
        </div>
      </template>
    </FileUpload>
  </section>
</template>

<style scoped lang="scss">
.file-block {
  display: flex;
  height: 70px;
  padding: 6px 12px 6px 8px;
  align-items: center;
  align-self: stretch;
  border-radius: 3px;
  border: 1px solid #f5f7fa;
  background: #fff;
}

.image-container {
  width: 4rem;
  height: 4rem;
}

.image-block {
  object-fit: contain;
  height: 100%;
}
</style>
