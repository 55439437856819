<script setup lang="ts">
import { destroyService } from '@/general/composables/useService';
import { useService } from '@/general/composables/useService/useService';
import { onBeforeRouteLeave } from 'vue-router';
import { importMappinsSettingServiceKey } from '../ts/keys/import-mapping-items.keys';

import { RouterService } from '@/core/router/router.service';
import { ImportMappingSettingViewService } from '@/general/services/overview-service/modules/import-mapping/import-mapping-settings.view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import ApplicationHeader from '@/general/ui/layout/components/application-header.vue';
import { importMappinsAdvancedItemsServiceKey } from '../../import-mapping-items/ts/keys/import-mapping-items.keys';

const ts = new TranslationService('supplier', 'ImportMappingSetting');
const service = useService(importMappinsSettingServiceKey, ImportMappingSettingViewService, ts)!;

onBeforeRouteLeave((to, __, next) => {
  if (service.deleted) {
    destroyService(importMappinsAdvancedItemsServiceKey);
    next();
    return;
  }

  if (service.dirtyState.isTouched()) {
    service.dirtyState.showDirtyDialog(
      () => {
        if (to.fullPath === '/productsup') {
          window.open('https://platform.productsup.com', '_blank');
        } else {
          destroyService(importMappinsAdvancedItemsServiceKey);
          next();
        }
      },
      undefined,
      next,
    );
  } else {
    destroyService(importMappinsAdvancedItemsServiceKey);

    next();
  }
});

const tabs = [
  {
    label: 'Basic',
    key: 'edit-import-mapping',
    command: () => {
      RouterService.getInstance().router.push({
        name: 'edit-import-mapping',
        params: { id: service.parent?.id },
      });
    },
  },
  {
    label: 'Advanced',
    key: 'advanced-import-mapping',
    command: () => {
      RouterService.getInstance().router.push({
        name: 'advanced-import-mapping',
        params: { id: service.parent?.id },
      });
    },
  },
  {
    label: 'Settings',
    key: 'import-mapping-settings',
    command: () => {
      RouterService.getInstance().router.push({
        name: 'import-mapping-settings',
        params: { id: service.parent?.id },
      });
    },
  },
];
</script>

<template>
  <ApplicationHeader :tabs="tabs">
    <template #breadcrumbs>
      <router-link :to="{ name: 'mappings' }">
        <Button
          class="custom-button-styles p-button-outlined p-button-sm"
          icon="mdi mdi-chevron-left"
          label="Import mappings"
        />
      </router-link>
    </template>

    <template #content>
      <div class="align-items-center flex gap-3 pb-4">
        <h3 v-if="!service.isLoadingParent">
          {{ service.parent?.name }}
        </h3>
        <Skeleton v-else width="30rem" height="28px" />
      </div>
    </template>
  </ApplicationHeader>
  <ConfirmDialog group="dirty-dialog" />
</template>
<style lang="scss" scoped>
.border-bottom {
  border-bottom: 2px solid #e5e5e5;
}
.custom-button-styles {
  border-color: #dce5ef !important;
  color: black !important;
}
</style>
