<script setup lang="ts">
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { SwitchFieldService } from 'services/attribute-fields/field-services/switch-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import { inject, ref } from 'vue';
import WrapperField from './wrapper-field.vue';
/** Props */
interface Props {
  attributeField: AttributeField<boolean | null>;
  productAttributeField: ProductAttributeField<boolean | null>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const switchFieldService = ref(
  new SwitchFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>
<template>
  <WrapperField :attribute-field-service="switchFieldService">
    <InputGroupAddon
      class="bg-white justify-content-start p-2 pl-3 w-full"
      :class="switchFieldService.getErrorTypeClass()"
    >
      <Checkbox
        v-model="switchFieldService.productAttributeFieldValue"
        :disabled="switchFieldService.productAttributeField.locked"
        binary
        :indeterminate="switchFieldService.productAttributeFieldValue == null"
      />
    </InputGroupAddon>
    <InputGroupAddon v-if="switchFieldService.productAttributeFieldValue != null">
      <Button
        text
        plain
        size="small"
        class="p-0"
        icon="mdi mdi-close"
        @click="switchFieldService.productAttributeFieldValue = null"
      />
    </InputGroupAddon>
  </WrapperField>
</template>
