<script setup lang="ts">
import ContentPortalIcon from '@/assets/icons/app_nav_icons_content_portal.svg';
import ProjectsIcon from '@/assets/icons/app_nav_icons_projects.svg';
import WorkspacesIcon from '@/assets/icons/app_nav_icons_workspaces.svg';
import SmallPuPLogo from '@/assets/images/pup_logo_all_white_small.svg';
import { KeycloakService } from '@/core/security/keycloak.service';
import { useRouter } from 'vue-router';

const router = useRouter();

const unifiedNavItems: { url: string; icon: string; shouldRender: boolean }[] = [
  {
    url: '/productsup',
    icon: ProjectsIcon,
    shouldRender: true,
  },
  {
    url: '/dashboard',
    icon: WorkspacesIcon,
    shouldRender: true,
  },
  {
    url: '/content-portal',
    icon: ContentPortalIcon,
    shouldRender: KeycloakService.getInstance().hasAccessToResource('app-content-portal'),
  },
];
</script>

<template>
  <nav class="align-items-center flex flex-column unified-navigation w-5rem">
    <!-- Logo -->
    <img
      class="cursor-pointer logo"
      :src="SmallPuPLogo"
      @click="
        router.push({
          name: 'dashboard',
        })
      "
    />
    <!-- Unified navigation items -->
    <div
      v-for="navItem in unifiedNavItems"
      :key="navItem.url"
      class="align-items-center cursor-pointer flex flex-column relative unified-nav-item w-full"
    >
      <span v-if="navItem.url === '/dashboard'" class="absolute active-item h-full left-0" />
      <routerLink v-if="navItem.shouldRender" :to="navItem.url">
        <img :src="navItem.icon" />
      </routerLink>
    </div>
    <!-- <Button
      class="col-1 settings-button"
      icon="mdi mdi-cog"
      :pt="{
        root: {
          style: {
            width: '32px',
            height: '32px',
          },
        },
      }"
      @click="router.push({ name: 'organisation-settings' })"
    /> -->
  </nav>
</template>

<style scoped lang="scss">
.unified-navigation {
  background-color: #023a59;
  border-right: 1px solid #092d4f;
  .logo {
    width: 20px;
    height: 20px;
    margin: 18px 0 34px 0;
  }
  .unified-nav-item {
    padding: 8px 0 8px 0;
    width: 100%;
    .active-item {
      width: 2px;
      background-color: #bac6d2;
      transform: translate(0, -8px);
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    img {
      width: 32px;
      height: 32px;
    }
  }
  .settings-button {
    position: absolute;
    bottom: 18px;
    background-color: #062f46;
    border: 1px solid #062f46;
    border-radius: 10px;
  }
}
</style>
