<script setup lang="ts">
//services
import { TranslationService } from '@/general/services/translations/translation.service';

// /** Props */
export interface Props {
  additionalContainerStyle?: string;
  textbox: string | undefined;
}

withDefaults(defineProps<Props>(), {
  additionalContainerStyle: '100%',
  textbox: '',
});

/** Emits */
const emit = defineEmits<{
  (e: 'html-text-area', value: string): void;
}>();

//services
const ts = new TranslationService('supplier', 'storyManager');

//const / ref
const toolbarOptions = [
  ['bold', 'italic', 'underline'],
  [{ list: 'ordered' }, { list: 'bullet' }],
];

/** Lifecycle */
</script>

<template>
  <Editor
    :model-value="textbox"
    :editor-style="`height: ${additionalContainerStyle};`"
    :modules="{ toolbar: toolbarOptions }"
    :placeholder="ts.tModule('storyBuilder.threeImageAndText.paragraph')"
    @update:model-value="emit('html-text-area', $event)"
  >
    <template #toolbar>
      <span class="ql-formats"> </span>
    </template>
  </Editor>
</template>

<style lang="scss" scoped>
.p-editor-container {
  height: 95%;
  overflow: hidden;
}

.ql-editor {
  height: 75%;
}
</style>
