<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { useRouter } from 'vue-router';

const ts = new TranslationService('general', 'views');
const router = useRouter();
</script>

<template>
  <div style="min-height: 90vh" class="align-items-center bg-white flex justify-content-center p-4">
    <div class="mt-4 text-center">
      <svg viewBox="0 0 1172 513" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M352.958 460.726V347.552H308.945V285.934H185.71V347.552H141.698L264.933 84.7349H122.207L0.858398 347.552V460.726H185.71V489.022C225.746 481.75 266.874 475.527 308.945 470.424V460.726H352.958Z"
          fill="#0080FA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1172 460.726V347.552H1127.99V285.934H1004.75V347.552H960.74L1083.97 84.7349H941.249L819.9 347.552V460.726H1004.75V489.022C1044.79 481.75 1085.92 475.527 1127.99 470.424V460.726H1172Z"
          fill="#0080FA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M589.637 452.503C653.853 452.503 716.819 455.045 778.114 459.929C791.253 445.89 802.247 430.878 811.096 414.892C828.204 382.551 837.893 345.679 837.893 306.544C837.893 178.393 734.006 74.5059 605.855 74.5059C477.703 74.5059 373.816 178.393 373.816 306.544C373.816 364.339 394.946 417.199 429.902 457.82C482.087 454.316 535.417 452.503 589.637 452.503Z"
          fill="#E6F2FE"
        />
        <path
          d="M460 192C501.421 192 535 158.421 535 117C535 75.5786 501.421 42 460 42C418.579 42 385 75.5786 385 117C385 158.421 418.579 192 460 192Z"
          fill="#A5E2C9"
        />
        <path
          d="M452.833 138.5H467.166V152.833H452.833V138.5ZM452.833 81.1666H467.166V124.167H452.833V81.1666Z"
          fill="#3D57A4"
        />
        <path
          d="M408.085 8.87292L415.47 35.246"
          stroke="#3D57A4"
          stroke-opacity="0.6"
          stroke-width="5"
          stroke-linecap="round"
        />
        <path
          d="M369.842 35.4199L389.621 51.0344"
          stroke="#3D57A4"
          stroke-opacity="0.6"
          stroke-width="5"
          stroke-linecap="round"
        />
        <path
          d="M373.344 5.54944L400.919 41.2947"
          stroke="#3D57A4"
          stroke-opacity="0.9"
          stroke-width="10"
          stroke-linecap="round"
        />
      </svg>

      <h3 class="my-3 text-6xl">{{ ts.tModule('auth.notFound.title') }}</h3>
      <p class="mb-5 text-xl">
        {{ ts.tModule('auth.notFound.description') }}
      </p>
      <Button :label="ts.tModule('auth.previousRoute')" @click="router.push({ name: 'default' })" />
    </div>
  </div>
</template>
