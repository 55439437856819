<script setup lang="ts">
import store from '@/core/store';
import { TranslationService } from '@/general/services/translations/translation.service';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { User } from 'platform-unit2-api/users';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const ts = new TranslationService('general', 'components');
const displayPaymentDialog = ref(false);
const currentUser = computed<User>(() => store.getters['users/currentUser']);
const route = useRoute();

const billingUsers = computed(() => {
  return store.getters['users/currentUser'].organisation.billing_users.map((user: any) => user.id);
});

const showReminder = computed(() => {
  return (
    currentUser.value &&
    !currentUser.value.admin_mode &&
    currentUser.value.organisation?.urgent_payment_reminder &&
    billingUsers.value.includes(currentUser.value.id)
  );
});

const closeButton = new CreateButton({
  label: ts.tGlobal('close'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => (displayPaymentDialog.value = false),
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [closeButton],
});

onMounted(() => {
  if (showReminder.value) {
    displayPaymentDialog.value = true;
  }
});

const currentRoute = ref(route.path);

watch(
  () => route,
  () => {
    if (currentRoute.value != route.path) {
      displayPaymentDialog.value = true;
      currentRoute.value = route.path;
    }
  },
  { deep: true },
);
</script>

<template>
  <BaseDialog
    v-if="showReminder"
    :visible="displayPaymentDialog"
    :title="ts.tModule('app.payment_reminders')"
    :buttons-options="buttonsOptions"
    size="medium"
    @update:visible="displayPaymentDialog = false"
  >
    <Message severity="error" class="font-bold text-6xl" :closable="true">
      {{ ts.tModule('app.urgent_payment_reminder') }}
    </Message>
  </BaseDialog>
</template>
