<script setup lang="ts">
import { ModuleDetail } from 'platform-unit2-api/modules';
import { ref } from 'vue';

/** Props */
export interface Props {
  module?: ModuleDetail;
}

const props = defineProps<Props>();

/** Emits */
const emit = defineEmits<{
  (e: 'prompt-validated', value: boolean): void;
}>();

/** Constants */
const promptValue = ref<Record<string, any>>({
  prompt_fields: {},
});

const inputType = (type?: string) => {
  switch (type) {
    case 'string':
      return 'text';
    default:
      return 'text';
  }
};

const validate = (): void => {
  let validated = true;
  if (props.module?.settings_fields) {
    props.module?.settings_fields['prompt_fields']?.forEach((key: any, index: number) => {
      if (props.module?.settings_fields?.prompt_fields[index]?.required) {
        const value = promptValue.value.prompt_fields[index];

        (props.module?.settings_fields['prompt_fields'][index] as any).value = value;

        if (!value || value == '') {
          validated = false;
        }
      }
    });
  }

  emit('prompt-validated', validated);
};
</script>

<template>
  <div v-if="module?.settings_fields">
    <div v-for="(_, key) in module?.settings_fields['prompt_fields']" :key="key" class="mr-5">
      <span class="flex font-bold justify-content-between mb-1">
        <label class="block mb-1"
          >{{ module?.settings_fields?.prompt_fields[key]?.label }}
          <span v-if="module?.settings_fields?.prompt_fields[key]?.required" class="text-red-500">
            *
          </span>
        </label>
      </span>
      <InputText
        v-model="promptValue.prompt_fields[key]"
        class="field-checkbox w-20rem"
        :typeof="inputType(module?.settings_fields?.prompt_fields[key]?.type)"
        :placeholder="module?.settings_fields?.prompt_fields[key].label"
        @input="validate()"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
