<script setup lang="ts">
import Menu from 'primevue/menu';
import { MenuItem } from 'primevue/menuitem';
import { ref } from 'vue';

interface Props {
  menuItems: MenuItem[];
  disabled?: boolean;
}

withDefaults(defineProps<Props>(), {
  menuItems: () => [],
  disabled: false,
});

const menu = ref<typeof Menu>();

const toggleActionMenu = (e: MouseEvent) => {
  menu.value?.toggle(e);
};
</script>
<template>
  <Button
    data-testid="action-menu-button"
    icon="mdi mdi-dots-vertical"
    :disabled="disabled"
    text
    plain
    aria-haspopup="true"
    aria-controls="overlay_menu"
    @click="toggleActionMenu"
  />
  <Menu
    id="overlay_menu"
    ref="menu"
    data-testid="action-menu"
    popup
    class="w-auto"
    :model="menuItems"
  />
</template>
