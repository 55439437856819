import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const componentTranslationObject: TranslationObject = {
  title: '',
  retailerPlatform: 'Retailer Platform',
  applicationHeader: {
    support: 'Support',
    switchSuccessful: 'Successfully switched workspace',
    switchFailed: 'switching workspace failed',
    account: {
      projects: 'Projects',
    },
  },
  colorPicker: {
    copySuccessful: 'The color has successfully copied',
    copyFailed: 'Copying color failed',
  },
  cronGenerator: {
    every: 'Every',
    days: 'day(s)',
    at: 'at',
    title: 'How often do you want to schedule it?',
    warning: 'Please note that you can only select one of the options below',
    validDay: 'Number between 1- 29',
    validation: {
      day: 'Enter a valid number between 1- 29',
      week: 'Select the day(s) of the week',
      time: 'Select a time',
      month: 'Select the day(s) of the month',
    },
  },
  crudData: {
    entityCreate: 'Create {entity}',
  },

  dirtyState: {
    unsavedItems: 'Do you want to leave?',
    unsavedItemsMessage: 'You have {amount} of unsaved items, are you sure you want to leave?',
  },
  emptyUploadOrMediaPicker: {
    title: 'New asset',
    subTitle: 'Drag & drop an asset or',
    addFromLibrary: 'Add from library',
    uploadAssets: 'Upload',
    singleMode: 'You are only allowed to upload one image at the time',
    wrongFileType: "The file that you tried to upload isn't supported",
  },
  helpAndSupport: {
    title: 'Learn and be curious',
    helpCenter: {
      title: 'Help Center',
      description: 'Need help? Visit our Help Center to get answers to your questions.',
    },
    platformAcademy: {
      title: 'Platform Academy',
      description: 'Check out our Academy and get to know all the ins and outs of our platform.',
    },
    contactSupport: {
      title: 'Contact Support',
      description: 'Contact one of our customer experience team members.',
    },
  },
  navigation: {
    entities: {
      dashboard: 'Dashboard',
      developers: 'Developers',
      settings: 'Settings',
      calendar: 'Calendar',
      products: 'Products',
      labels: 'Labels',
      feeds: 'Feeds',
      imports: 'Imports',
      import_scheduled: 'Scheduled Imports',
      exports: 'Exports',
      export_scheduled: 'Scheduled Exports',
      shared: 'Shared',
      shared_by_me: 'Shared by me',
      shared_with_me: 'Shared with me',
      task: 'Tasks',
      media: 'Media',
      users: 'Users',
      clients: 'Workspaces',
      organisations: 'Organisations',
      brands: 'Brands',
      datamodels: 'Datamodels',
      templates: 'Templates',
      import_mappings: 'Import Mapping',
      export_mappings: 'Export Mapping',
      mappings: 'Mappings',
      story_manager: 'Story Manager',
      retailers: 'Retailers',
      categories: 'Categories',
      channels: 'Channels',
      fields: 'Fields',
      asset_types: 'Asset Types',
      roles: 'Roles',
      editor: 'Editor',
      tools: 'Tools',
      GLN: 'GLN',
      'asset-types': 'Asset types',
      validation_rules: 'Validations',
      status_notifications: 'Status Notifications',
      'AI+': 'AI+',
    },
    collapse: 'Collapse navigation',
    version: 'Version {version_number}',
    welcomeMessage: 'Welcome, {name}! Choose a workspace to start',
  },
  userMenu: {
    switchSuccessful: 'Successfully switched mode',
    switchFailed: 'Could not switch Mode',
    on: 'On',
    off: 'Off',
  },
  workspaceMenu: {
    switchSuccessful: 'Successfully switched workspace',
    switchFailed: 'Switching workspace failed',
  },
  composables: {
    defaultUpdateReject: 'Failed to update the object',
    defaultUpdateSuccess: 'Successfully updated the  object',
    defaultDeleteReject: 'Failed to delete the  object',
    defaultDeleteSuccess: 'Successfully deleted the object',
  },
  thumbnailUploader: {
    upload: 'Upload',
    remove: 'Remove',
  },
  uploadBox: {
    selectFile: 'Selected a file.',
    generalUploadFilesMessage: 'Drop your file(s) here to upload',
  },
  adminMode: 'Admin Mode',
  attributeFields: {
    chooseValue: 'Choose a value',
    addGroup: 'Add new {attributeName}',
  },

  app: {
    starting_import: 'Starting importing',
    payment_reminders: 'Payment Reminder | Payment Reminders',
    urgent_payment_reminder:
      'It seems that your payments have not been completed yet. Please contact Productsup for more information. Otherwise, your account will be deactivated shortly. ',
    friendly_payment_reminder:
      'It seems that your payments have not been completed yet. Please contact Productsup for more information.',
    demo_account: 'Demo account',
    expiration_days:
      'Your demo account will expire in {demoDurationPeriod} day | Your demo account will expire in {demoDurationPeriod} days',
    NewLayoutMessage:
      "Want to try our New Layout? It's currently in bèta mode, and you can start using it by enabling it in your",
    NewLayoutMessagePreferences: 'Preferences',
    NewLayoutGoBackMessage: 'Want to switch back to the old layout? Click',
  },
  select: {
    failedToLoad: 'Failed to load options',
    placeholder: 'Select {module}',
  },

  groupField: {
    resetsConfirmMessage: 'Are you sure you want reset all changes of this group?',
    deleteConfirmMessage: 'Are you sure you want to delete this group?',
    createPresetDisabled:
      "Adding a new group is currently disabled due to being in 'Show empty fields mode'",
    resetAndDeleteDisabled:
      "Reset or deletion of group is currently disabled due to being in 'Show empty fields mode'",
    dragDisabled: 'Reordering of a group is disabled due to being in "Show empty fields mode"',
  },

  tabField: {
    placeholderHeader: 'Enter Tab Name',
    createPresetDisabled:
      "Adding a new tab is currently disabled due to being in 'Show empty fields mode'",

    modifyTabHeaderDisabled:
      'Modifying the tab header is disabled due to being in "Show empty fields mode"',
  },
  tableColumns: {
    title: 'Table configuration',
    subtitle: 'Configure the columns of the table to display',
  },
  confirmPopupPhrase: {
    header: 'Delete {entity} confirmation',
    question:
      'Are you sure you want to delete this {entity}? | Are you sure you want to delete these {entity}?',
    confirmSentence: 'To confirm deletion, please type',
    confirmTypeInformation: 'in the box below:',
    copyPasteError: "This field doesn't support copy-paste, please type the information manually",
    inputPlaceholder: 'Delete phrase',
  },
  systemSettings: 'System Settings',
};

const tranlation: Translation = {
  components: componentTranslationObject,
};

export default tranlation;
