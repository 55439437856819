<script lang="ts" setup>
import BaseSelect from './base-select.vue';
import { computed } from 'vue';
import {
  Datamodel,
  DatamodelCompact,
  GetDatamodelsRequest,
  DatamodelsRestService,
} from 'platform-unit2-api/datamodels';
import { DefaultSelectPropsWithCompact } from './ts/default-select-props.interface';
import { MultiSelectSlots } from 'primevue/multiselect';
import { SelectSlots } from 'primevue/select';

interface Props extends DefaultSelectPropsWithCompact<Datamodel, DatamodelCompact> {
  datamodelsToExclude?: Datamodel[] | DatamodelCompact[];
  options?: Datamodel[] | DatamodelCompact[]; // only show these options if provided
  workspaceId?: number;
  flattenChildrenAttributes?: boolean;
  onlyMaster?: boolean;
  templateId?: number;
  channelId?: number;
}

const slots = defineSlots<MultiSelectSlots & SelectSlots>();
const multiselectSlotKeys: (keyof MultiSelectSlots)[] = Object.keys(
  slots,
) as unknown as (keyof MultiSelectSlots)[];

const props = defineProps<Props>();
const data = computed<GetDatamodelsRequest>(() => ({
  only_master: props.onlyMaster,
  template_id: props.templateId,
  channel_id: props.channelId,
  flatten_children_attributes: props.flattenChildrenAttributes,
  workspace_id: props.workspaceId,
}));

const datamodelRestService = DatamodelsRestService;
</script>
<template>
  <BaseSelect
    :rest-service="datamodelRestService"
    option-label="name"
    display="chip"
    :custom-fetch-params="[{ ...data }]"
    :edit-options="(datamodels: Datamodel[]) => datamodels.filter(datamodel => !props.datamodelsToExclude?.map((c) => c.id).includes(datamodel.id))"
    module-name="datamodel"
    v-bind="{ ...$attrs, ...props }"
  >
    <template
      v-for="(slot, _, key_index) of multiselectSlotKeys"
      :key="key_index"
      #[slot]="slotProps"
    >
      <slot :name="(slot as keyof MultiSelectSlots)" v-bind="slotProps" />
    </template>
  </BaseSelect>
</template>
