import { RouteFactory } from '@/core/router/route.factory';
import { RouteRecordRaw } from 'vue-router';
import WorkspaceSettings from '../views/workspace-settings.view.vue';

const factory = new RouteFactory({
  platform: 'supplier',
  module: 'workspace-settings',
});

const workspaceSettings: RouteRecordRaw[] = [
  factory.createAppRoute({
    path: '/workspace-settings',
    name: 'workspace-settings',
    component: WorkspaceSettings,
    title: 'Settings',
  }),
];

export default workspaceSettings;
