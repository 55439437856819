<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { DashboardRestService } from 'platform-unit2-api/dashboard';
import { Organisation } from 'platform-unit2-api/organisations';
import { onMounted, ref } from 'vue';

/** Services */
const ts = new TranslationService('supplier', 'dashboard');
const dashboardApi = new DashboardRestService();

//** Constants */
const organisation = ref<Organisation>();
const loading = ref(true);

const loadDataAsync = async () => {
  try {
    loading.value = true;
    organisation.value = await dashboardApi.getDashboardOrganisations();
  } catch (err) {
    //
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await loadDataAsync();
});
</script>
<template>
  <Card v-if="loading">
    <template #content>
      <h4 class="mb-4 text-left">{{ ts.tModule('user_dashboard.organisation_labels.title') }}</h4>
      <Skeleton class="h-10rem w-full" />
    </template>
  </Card>
  <Card v-else class="text-left">
    <template #content>
      <h4 class="mb-4 text-left">{{ ts.tModule('user_dashboard.organisation_labels.title') }}</h4>
      <Timeline :value="organisation?.statuses" layout="horizontal" align="top">
        <template #content="slotProps">
          <p>{{ slotProps.item.label }}</p>
          <small class="p-text-secondary">{{
            slotProps.item.products_count +
            ' ' +
            ts.tGlobal('products', slotProps.item.products_count)
          }}</small>
        </template>
        <template #marker="slotProps">
          <div class="p-timeline-event-marker" :style="'border-color: ' + slotProps.item.color" />
        </template>
      </Timeline>
    </template>
  </Card>
</template>
<style lang="scss">
.p-timeline-event:last-child {
  min-width: 80px;
}
</style>
