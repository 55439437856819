<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { KeyValueOptions } from 'platform-unit2-api/attributes';
import { ref } from 'vue';

interface Props {
  choices?: (KeyValueOptions | string)[];
}

const props = withDefaults(defineProps<Props>(), {
  choices: () => [],
});

const emit = defineEmits<{
  (e: 'update:choices', value: string[]): void;
}>();

const ts = new TranslationService('supplier', 'datamodels');

const options = ref<string[]>(props.choices as string[]);
</script>

<template>
  <div class="field mb-3">
    <label class="font-bold text-sm">{{ ts.tModule('datamodels_overwrite.form.choices') }}</label>

    <AutoComplete
      :model-value="options"
      multiple
      :typeahead="false"
      @update:model-value="
        (values: string[]) => {
          options = values;
          emit('update:choices', values);
        }
      "
    />
  </div>
</template>
