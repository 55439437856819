<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import Upload from '@/general/ui/components/upload.vue';
import { Client } from 'platform-unit2-api/clients';
import { Organisation, OrganisationRestService } from 'platform-unit2-api/organisations';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

/* Composables */
const store = useStore();

/* Services */
const ts = new TranslationService('retailer', 'settings');
const toastService = ToastService.getInstance();
const organisationApi = new OrganisationRestService();

/* Constants */
const clients = ref<Client[]>([]);
const loading = ref(true);

const modulesLength = 3;

const loadAsyncData = async (): Promise<void> => {
  loading.value = true;
  try {
    const response: Organisation = await organisationApi.get(
      store.getters['users/currentUser'].workspace?.organisation_id,
    );

    clients.value = response.workspaces ?? [];
  } catch (err: any) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

// Lifecycle hooks
onMounted(() => {
  loadAsyncData();
});
</script>

<template>
  <section class="h-full pt-3 px-4">
    <LoadingIndicator v-if="loading" />
    <div v-else class="grid">
      <div v-for="client in clients" :key="client.id" class="col-12 lg:col-3 md:col-6">
        <Card class="h-full">
          <template #title>
            <div class="align-items-center flex flex-column">
              <Upload class="h-18rem max-w-full mb-3 w-18rem" :upload="client.thumbnail" />
              <h2 class="font-bold text-lg">
                {{ client.name }}
              </h2>
              <Divider />
            </div>
          </template>
          <template #content>
            <div class="flex mb-2 text-sm">
              <p class="font-bold mr-3">{{ ts.tModule('workspace.generalContact') }}</p>
              <p>
                {{ client.general_contact ? client.general_contact.name : '-' }}
              </p>
            </div>
            <div class="flex mb-2 text-sm">
              <p class="font-bold mr-3">{{ ts.tModule('workspace.financialContact') }}</p>
              <p>{{ client.financial_contact ? client.financial_contact.name : '-' }}</p>
            </div>
          </template>
          <template #footer>
            <Avatar-group v-if="client.modules">
              <div v-if="client.modules.length <= modulesLength">
                <Avatar
                  v-for="module in client.modules"
                  :key="module.id"
                  v-tooltip.bottom="{ value: module.name, class: 'text-sm' }"
                  :label="(module.name ?? '').charAt(0)"
                  shape="circle"
                />
              </div>
              <div v-else>
                <Avatar
                  v-for="module in client.modules.slice(0, modulesLength)"
                  :key="module.id"
                  v-tooltip.bottom="{ value: module.name, class: 'text-sm' }"
                  :label="(module.name ?? '').charAt(0)"
                  shape="circle"
                />
                <Avatar
                  :label="`+${client.modules.length - modulesLength}`"
                  shape="circle"
                  style="background-color: var(--primary-color); color: var(--primary-color-text)"
                />
              </div>
            </Avatar-group>
          </template>
        </Card>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.p-card :deep(.p-card-content) {
  padding: 0;
}
</style>
