import HttpStatus from 'http-status-codes';

/**
 * Service to handle form validation errors
 */
export class FormValidationService {
  private errors?: { [key: string]: string[] };

  /**
   * Checks if there are errors
   * @returns true if there are errors
   */
  public hasErrors(): boolean {
    if (this.errors) {
      return true;
    }

    return false;
  }

  /**
   * Check if a field has an error
   * @param field string of field name
   * @returns boolean
   */
  public hasError = (field: string): boolean => {
    return !!(this.errors && this.errors.hasOwnProperty(field));
  };

  /**
   * Reset the errors
   */
  public resetErrors(): void {
    this.errors = undefined;
  }

  /**
   * Get the error message for a field
   * @param name string of field name
   * @returns string[]
   */
  public fieldErrorMessage(name: string): string[] {
    return this.errors?.hasOwnProperty(name) ? this.errors[name] : [];
  }

  /**
   * Handle the error
   * @param error any
   * @param callback function to call after handling the error
   * @returns void
   */
  public handleError(error: any, callback?: () => void): void {
    if (error?.response?.status === HttpStatus.UNPROCESSABLE_ENTITY) {
      if (error?.response?.data?.errors) {
        this.errors = error.response.data.errors;
      }
    }

    callback?.();
  }
}
