import { AttributeFieldService } from 'services/attribute-fields/attribute-field.service';
import {
  AttributeField,
  ProductAttributeField,
  ProductAttributeFieldError,
  FieldErrorEnum,
} from 'platform-unit2-api/attribute-fields';

import { UpdateProductField } from 'platform-unit2-api/products';
import { FieldFunctions } from '../interfaces/field-functions.interface';
import moment from 'moment';

export class DateTimeFieldService extends AttributeFieldService<Date | undefined> {
  constructor(
    attributeField: AttributeField<string | undefined>,
    productAttributeField: ProductAttributeField<string | undefined>,
    fieldFunctions: FieldFunctions,
  ) {
    const mappedAttributeFieldValues = {
      ...attributeField,
      value: attributeField.values?.map((value) => ({
        ...value,
        value: value.value ? new Date(value.value) : undefined,
      })),
    } as AttributeField<Date | undefined>;

    const newProductAttributeField = {
      ...productAttributeField,
      value: productAttributeField.value ? new Date(productAttributeField.value) : undefined,
    };

    super(mappedAttributeFieldValues, newProductAttributeField, fieldFunctions);
    this.validate();
  }

  /*
   * Format the string date value into a value readable by the user
   */
  get productAttributeFieldValue(): Date | undefined {
    return this._productAttributeField.value != null
      ? this._productAttributeField.value
      : undefined;
  }

  set productAttributeFieldValue(value: Date | undefined) {
    this._productAttributeField.value = value;
    this.updateValue(this.productAttributeField.value);
  }

  /*
   * Backend expects a string -> date in a specific format (ISO) or null
   */
  protected createPayload(): UpdateProductField {
    return {
      attribute_id: this._productAttributeField.attribute_id,
      value:
        this._productAttributeField.value != null &&
        moment(this._productAttributeField.value).isValid()
          ? this._productAttributeField.value.toISOString()
          : null,
      locale_id: this._productAttributeField.locale.id,
      path: this._productAttributeField?.path ?? null,
      overwrite: false,
    };
  }

  public validate(): boolean {
    // Call the base class validate method
    const baseValidationResult = super.validate();

    // Additional check for date correctness
    if (
      this.productAttributeField.value != null &&
      !moment(this._productAttributeField.value).isValid()
    ) {
      this.productAttributeField.errors.push({
        message: this.ts.t('validation.date'),
        severity: FieldErrorEnum.ERROR,
      } as ProductAttributeFieldError);

      return false;
    }

    return baseValidationResult;
  }
}
