<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { Attribute } from 'platform-unit2-api/attributes';
import { Datamodel, DatamodelAttributeConfig } from 'platform-unit2-api/datamodels';
import { ref } from 'vue';
import DatamodelConfigurationDialog from '../../datamodel-attribute-configs/components/datamodel-configuration-dialog.vue';

defineProps<{
  attribute: Attribute;
  datamodel?: Datamodel;
  override?: DatamodelAttributeConfig;
}>();

const emit = defineEmits<{
  (e: 'created', override: DatamodelAttributeConfig): void;
  (e: 'deleted'): void;
  (e: 'remove', attribute: Attribute): void;
}>();

const ts = ref(new TranslationService('supplier', 'datamodels'));

const showOverrideDiaglog = ref(false);
</script>

<template>
  <section v-if="datamodel" class="flex h-full w-full">
    <Tag
      v-tooltip.bottom="ts.tModule('datamodel_attributes.attribute_id')"
      :style="{
        background: 'var(--gray-100)',
        color: 'var(--gray-500)',
      }"
      :value="attribute.id"
      class="mx-2"
    />
    <span
      v-tooltip.bottom="{
        value: `Original title: ${attribute.key}`,
        disabled: !override,
      }"
      class="ml-2 mr-auto p-1 text-sm"
      :class="[!!override ? 'text-primary' : '']"
    >
      {{ override?.name ?? attribute.key }}
    </span>
    <div class="flex">
      <span v-if="override" class="align-self-center font-normal mx-2 text-primary text-sm">
        {{ ts.tModule('attributes_overwritten') }}</span
      >
      <i
        v-tooltip.bottom="{
          value: `${ts.tModule('create_configuration')}`,
          class: 'text-xs',
          disabled: false,
        }"
        class="align-items-center border-round-sm cursor-pointer flex hover:bg-gray-50 hover:border-gray-400 hover:text-gray-800 justify-content-center mdi mdi-cog-outline p-0"
        :class="!!override ? 'border-primary border-1 text-primary' : 'border-gray-200'"
        :style="{ width: '20px', height: '20px' }"
        @click="showOverrideDiaglog = true"
      />

      <Divider layout="vertical" class="p-0" :style="{ margin: '0 10px' }" />
      <i
        class="bg-red-600 border-round-sm cursor-pointer hover:bg-red-500 mdi mdi-minus mr-3 p-1 text-white"
        :style="{ width: '20px', height: '20px' }"
        @click="emit('remove', attribute)"
      />
    </div>
    <DatamodelConfigurationDialog
      :attribute="attribute"
      :visible="showOverrideDiaglog"
      @close="showOverrideDiaglog = false"
      @created="(config) => emit('created', config)"
      @deleted="() => emit('deleted')"
    />
  </section>
</template>
