<script setup lang="ts">
import { TabFieldService } from '@/general/services/attribute-fields/field-services/tab-field.service';
import { AdvancedFieldPanel } from '@/general/services/attribute-fields/interfaces/advanced-field-tab.interface';
import { TranslationService } from '@/general/services/translations/translation.service';
import { vOnClickOutside } from '@vueuse/components';
import { Locale } from 'platform-unit2-api/locales';
import { MenuItem } from 'primevue/menuitem';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import ActionsMenu from '../../actions-menu.vue';

interface Props {
  tab: AdvancedFieldPanel;
  index: number;
  activeIndex: number;
  tabFieldService: TabFieldService;
  locale: Locale;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'deleteTabConfirmCallback'): void;
}>();

const ts = new TranslationService('general', 'components');
const store = useStore();

const isInMissingFieldMode = computed(() => {
  return store.getters['products/showMissingFields'];
});

const inplaceRef = ref();

const menuItems = (localeId: number, index: number, path: string | null): MenuItem[] => [
  {
    label: ts.tGlobal('duplicate'),
    icon: 'mdi mdi-content-copy',
    command: () => props.tabFieldService.duplicateTabConfirm(localeId, index),
  },
  {
    separator: true,
  },
  {
    label: ts.tGlobal('delete'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      props.tabFieldService.deleteTabConfirm(localeId, path, () =>
        emit('deleteTabConfirmCallback'),
      );
    },
  },
];

const closeInplace = () => {
  inplaceRef.value.close();
};
</script>
<template>
  <span class="align-items-center flex mr-2 px-2">
    <Inplace
      ref="inplaceRef"
      closable
      :disabled="index !== activeIndex"
      :close-button-props="{ severity: 'success', text: true }"
      @close="() => tabFieldService.flushTempHeaders()"
    >
      <template #display>
        {{ !Boolean(tab.header) ? 'Click to Edit' : tab.header }}
      </template>
      <template #content="{ closeCallback }">
        <InputText
          v-on-click-outside="
            () => {
              tabFieldService.flushTempHeaders();
              closeInplace();
            }
          "
          :model-value="tab.header"
          class="mr-2"
          :placeholder="ts.tModule('tabField.placeholderHeader')"
          @keyup.enter="closeInplace()"
          @update:model-value="(value?: string) =>
            tabFieldService.tempHeader.set({ localeId: locale.id, path: tab.path }, value ?? '')
            "
        />
        <Button icon="mdi mdi-check" text @click="closeCallback" />
      </template>
    </Inplace>
    <ActionsMenu
      :disabled="isInMissingFieldMode"
      :menu-items="menuItems(locale.id, index, tab.path)"
    />
  </span>
</template>
