<script setup lang="ts">
import apiImage from '@/assets/images/setting-developers/api-no-access.svg';
import { TranslationService } from '@/general/services/translations/translation.service';

/* Services */
const ts = new TranslationService('retailer', 'developers');
</script>

<template>
  <div class="align-items-center flex flex-column gap-5 pt-6">
    <Image :src="apiImage" alt="API" class="thumbnail"></Image>
    <h3>{{ ts.tModule('no_access_title') }}</h3>
    <p class="text-500">{{ ts.tModule('no_access_description') }}</p>

    <a
      href="mailto:hello@productsup.com?subject=Request for API Access and Quote&body=Hello,%0D%0D
I am interested in gaining access to your API and would like to request a quote for a yearly license. Could you please provide me with the necessary information to proceed?%0D%0D Thank you."
    >
      <Button icon="mdi mdi-send" class="p-button-rounded" :label="ts.tModule('contact_sales')" />
    </a>
  </div>
</template>
<style lang="scss" scoped>
.p-button :deep(.p-button-icon) {
  transform: rotate(-45deg);
}
</style>
