<script setup lang="ts">
//Core
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

//Components
import ClientModuleSelect from '@/general/ui/components/selects/client-module-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import CronGenerator from 'ui/components/cron-generator.vue';
import CrudSidebar from 'ui/components/crud-sidebar.vue';

//Composables and Services
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import useFormValidation from 'composables/form-validation';

//Types
import {
  ScheduledPipeline,
  ScheduledPipelinesRestService,
  UpdateScheduledPipelineRequest,
} from 'platform-unit2-api/scheduled-pipelines';

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'exports');
const tsCore = new TranslationService('general', 'components');
const confirmService = new ConfirmService();
const scheduledPipelineApi = new ScheduledPipelinesRestService();

/** Constants */
const route = useRoute();

const { resetFormErrors } = useFormValidation();

const loading = ref(false);
const saving = ref(false);
const editorScheduledExport = ref<ScheduledPipeline>();
const exportCron = ref<string>();

const setCron = (cron: string) => {
  exportCron.value = cron;
};

const isCronValid = computed(() => {
  const cron = editorScheduledExport.value?.cron?.split(' ') ?? [];

  if (cron.filter((item: string) => item !== '').length !== 5) {
    return false;
  }

  const minutes = cron[0];

  if (cron[0] === '*') {
    return false;
  }

  if (minutes.includes('*/')) {
    if (parseInt(minutes.split('/')[1]) < 15 || minutes === '*/') {
      return false;
    }
  }

  if (minutes.includes(',')) {
    if (minutes.split(',').length > 5) {
      return false;
    }
  }

  if (minutes.includes('-')) {
    return false;
  }

  return true;
});

const handleSubmit = async (): Promise<void> => {
  resetFormErrors();
  try {
    if (!isCronValid.value) {
      return;
    }

    saving.value = true;
    const scheduledExport: UpdateScheduledPipelineRequest = {
      id: editorScheduledExport.value!.id,
      name: editorScheduledExport.value?.name,
      cron: exportCron.value ?? editorScheduledExport.value?.cron,
      module_id: editorScheduledExport.value?.module?.id,
      is_paused: editorScheduledExport.value?.is_paused,
      locale_id: editorScheduledExport.value?.locale?.id,
      ignore_product_status: editorScheduledExport.value?.ignore_product_status,
    };

    await scheduledPipelineApi.update(Number(route.params.id), scheduledExport);

    emit('refresh');
    emit('hide');

    toastService.displaySuccessToast(ts.updateSuccess());
  } catch (err) {
    toastService.displayErrorToast(ts.updateFailed());
  } finally {
    saving.value = false;
  }
};

const asyncDeleteClient = async (id: number): Promise<void> => {
  loading.value = true;
  try {
    await scheduledPipelineApi.delete(id);
    emit('refresh');
    emit('hide');
    toastService.displaySuccessToast(ts.deleteSuccess());
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  }

  loading.value = false;
};

const confirmClientDelete = (event: PointerEvent, id?: number) => {
  if (!id) return;
  confirmService.confirmDelete({
    event: event,
    callback: () => asyncDeleteClient(id),
    group: 'export-schedule-edit',
    message: ts.deleteConfirm(),
  });
};

/** Lyfecycle */
onMounted(async () => {
  loading.value = true;
  editorScheduledExport.value = await scheduledPipelineApi.get(Number(route.params.id));
  loading.value = false;
});
</script>

<template>
  <div class="h-full">
    <SidebarSkeleton v-if="loading" />
    <CrudSidebar
      v-else
      :title="ts.tModule('export_scheduled.edit.title')"
      :subtitle="ts.tModule('export_scheduled.edit.subtitle')"
      :saving="saving"
      @cancel="emit('hide')"
      @save="handleSubmit()"
      @delete="confirmClientDelete($event, editorScheduledExport?.id)"
    >
      <template #sidebar-data>
        <div class="field mb-3">
          <label for="name">{{ ts.tGlobal('name') }}</label>
          <InputText
            v-if="editorScheduledExport"
            id="name"
            v-model="editorScheduledExport.name"
            class="w-full"
            type="text"
          />
        </div>

        <div class="field mb-3">
          <CronGenerator
            v-if="editorScheduledExport"
            :cron="editorScheduledExport.cron"
            @generated-crone="setCron"
          />
          <small :class="{ 'p-error block': !isCronValid }" class="hidden">{{
            tsCore.tModule('cronGenerator.warning')
          }}</small>
        </div>

        <div class="field mb-3">
          <ClientModuleSelect
            v-if="editorScheduledExport"
            v-model="editorScheduledExport.module"
            type="export"
          />
        </div>

        <div class="field mb-3">
          <LocaleSelect v-if="editorScheduledExport" v-model="editorScheduledExport.locale" />
        </div>

        <div class="field mb-3">
          <label for="is_paused">{{ ts.tModule('export_scheduled.is_paused') }}</label>
          <Checkbox
            v-if="editorScheduledExport"
            v-model="editorScheduledExport.is_paused"
            :binary="true"
            class="w-full"
          />
        </div>

        <ConfirmPopup group="export-schedule-edit" />

        <div class="field mb-3">
          <label class="block font-bold mb-1" for="ignoreProductStatus">{{
            ts.tModule('export_scheduled.ignore_product_status')
          }}</label>
          <Checkbox
            v-if="editorScheduledExport"
            v-model="editorScheduledExport.ignore_product_status"
            input-id="ignoreProductStatus"
            :binary="true"
          />
        </div>
      </template>
    </CrudSidebar>
  </div>
</template>
