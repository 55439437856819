<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import { inject } from 'vue';
import { useSupplierImportMappingService } from '../../ts/useImportMappingService';

const service = useSupplierImportMappingService();
const ts = inject(tsKey)!;
</script>
<template>
  <Dialog
    :visible="service.duplicateWarningDialog"
    :closable="false"
    modal
    :style="{ width: '40vw' }"
    @update:visible="() => service.closeWarningDialog()"
  >
    <template #header>
      <div>
        <h2 class="mb-2 text-800">
          {{ ts.tModule('duplicate_mappings.overwrite_title') }}
        </h2>
      </div>
    </template>

    <p class="text-600">
      {{ ts.tModule('duplicate_mappings.overwrite_description') }}
    </p>

    <template #footer>
      <div>
        <Button
          plain
          text
          :label="ts.tGlobal('cancel')"
          class="mr-3"
          @click="service.closeWarningDialog()"
        />
        <Button
          :label="ts.tModule('duplicate_mappings.overwrite')"
          class="p-button-danger"
          @click="service.duplicate()"
        />
      </div>
    </template>
  </Dialog>
</template>

<style scoped lang="scss">
.p-button :deep(.p-button-label) {
  font-weight: normal;
}
</style>
