import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Import Mapping',
  subTitle: 'Here you can create a new Import Mapping.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Import Mapping',
};

const update: TranslationObjectAction = {
  title: 'Edit Import Mapping',
  subTitle: 'Here you can edit a Import Mapping.',
};

const translationObject: TranslationObject = {
  title: 'Mapping | Mappings',
  placeholder: 'Select Import Mapping | Select Import Mappings',
  emptyStateSubtitle:
    'Start by creating your first import mapping. When a new import\nimport mapping is created it will show here.',

  create: create,
  delete: deleteTranslation,
  update: update,
  emptyStateTitle: 'No import mappings found',
  emptyStateSubTitle: 'Start by creating your first import mapping.',
  newImportMappingSubtitle:
    'Pick a suitable name then the data model for which you want to create the import mapping.',
  import_module: 'Import Module',
  last_update: 'Last Update',
  attributesMapped: 'Attributes mapped',
  duplicate_mapping: 'Duplicate mappings',
  duplicate_description:
    'Select an import module and a product category to duplicate the mappings to.',
  duplicated_successfully: 'Successfully duplicated mappings.',
  duplication_failed: 'Failed to duplicate mappings. Please try again.',
  select_a_module:
    'Select an import module and then the data model for which you want to create the import mapping.',
  attributes_mapped: 'Attributes mapped',
  field_sources: {
    fill_list: 'Fill as list',
    fill_list_item: 'Fill as item',
    take_key: 'Key',
    take_value: 'Value',
  },
  edit: {
    input_column: 'Input column',
    selected_module: 'The import module is:',
    selected_datamodel: 'The datamodel is:',
    info: 'Import mappings define the relationship between the input of an import and the field definition in the system.',
    add_record: 'Add new records ',
    key_value_type_warning:
      "The key-value type will be matched against the fields available choices. If a given value in the import doesn't exist in system it wont be imported!",
  },
  modals: {
    delete_mapping: 'Are you sure you want to delete this import mapping?',
  },

  duplicate_mappings: {
    title: 'Duplicate mappings',
    modal_header: 'Duplicate import mappings',
    modal_description:
      'Choose the destination for the import mapping duplicate by selecting the appropriate workspace, data model and channel.',
    modal_client_placeholder: 'Select workspace',
    duplication_fail: 'Duplicating mappings failed. Please try again.',
    duplication_success: 'Mapping has been successfully duplicated',
    duplication_overwrite_success: 'Mapping has been successfully overwritten',
    duplication_warning:
      'Some attributes do not match in the destination of the duplicated mapping',
    duplication_error_same_destination:
      'The destination of the duplicate mapping is the same as the original',
    overwrite_title: 'Overwrite import mapping',
    overwrite_description:
      'An import mapping already exists for the selected destination. By choosing ‘overwrite’ this import mapping will be replaced with the duplicate. This can not be undone.',
    overwrite: 'Overwrite',
  },
};

const tranlation: Translation = {
  importMappings: translationObject,
};

export default tranlation;
