<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { CreateExport } from 'retailer/modules/products/products.types';
import CronGenerator from 'ui/components/cron-generator.vue';
import { onMounted, ref, watchEffect } from 'vue';

/** Props */
interface Props {
  formData: CreateExport;
}
const props = defineProps<Props>();

/** Emits */
const emit = defineEmits<{
  (
    e: 'nextPage',
    pageObject: {
      pageIndex: number;
      ignoreProductStatus: boolean;
      exportName: string | undefined;
      exportCron: string | undefined;
      isScheduled: boolean;
    },
  ): void;
  (
    e: 'prevPage',
    pageObject: {
      pageIndex: number;
    },
  ): void;
  (e: 'cancel-export'): void;
}>();

const ts = new TranslationService('retailer', 'products');
const exportName = ref('');
const exportCron = ref('');
const valid = ref(false);
const ignoreProductStatus = ref<boolean>(false);

const setCron = (cron: string) => {
  exportCron.value = cron;
};

const nextPage = () => {
  emit('nextPage', {
    pageIndex: 2,
    ignoreProductStatus: ignoreProductStatus.value,
    exportName: exportName.value,
    exportCron: exportCron.value,
    isScheduled: true,
  });
};

const previousPage = () => {
  emit('prevPage', {
    pageIndex: 2,
  });
};

onMounted(() => {
  if (props.formData.exportName) {
    exportName.value = props.formData.exportName;
  }

  if (props.formData.exportCron) {
    exportCron.value = props.formData.exportCron;
  }
});

watchEffect(() => {
  valid.value = exportName.value != null && exportCron.value != null;
});
</script>
<template>
  <div class="mt-5">
    <div class="px-4">
      <div class="field mb-3">
        <label class="block font-bold mb-1" for="name">{{
          ts.tModule('export_modal.schedule.type_name')
        }}</label>
        <InputText id="name" v-model="exportName" type="text" class="w-full" />
      </div>

      <div class="field mb-3">
        <label class="block font-bold mb-1" for="ignoreProductStatus">{{
          ts.tModule('export_modal.schedule.ignore_product_status')
        }}</label>
        <Checkbox v-model="ignoreProductStatus" input-id="ignoreProductStatus" :binary="true" />
      </div>

      <CronGenerator :cron="formData.exportCron ?? null" @generated-crone="setCron" />
    </div>
    <div
      class="border-round-bottom-md flex flex-row justify-content-between px-4 py-4"
      style="background-color: #f5f7fa"
    >
      <div>
        <Button
          :label="ts.tGlobal('cancel')"
          text
          plain
          class="mr-2"
          @click="emit('cancel-export')"
        />
      </div>
      <div>
        <Button
          :label="ts.tGlobal('previous')"
          severity="secondary"
          class="mr-2"
          icon="mdi mdi-chevron-left"
          icon-pos="left"
          @click="previousPage()"
        />
        <Button
          :label="ts.tGlobal('next')"
          :disabled="!valid"
          icon="mdi mdi-chevron-right"
          icon-pos="right"
          @click="nextPage()"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.types) div {
  width: 33%;
}

:deep(.weekdays) div {
  font-size: 0.8rem;
}
</style>
